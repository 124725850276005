// Filters
$articles-filters-breakpoint: 23.4375rem; // 375px

$articles-filterby-border: 1px solid $smoke-alpha;

$articles-filters-tab__color: $generic;
$articles-filters-tab__bgcolor: transparent;
$articles-filters-tab__border: 1px solid $generic;
$articles-filters-tab-selected__color: $white;
$articles-filters-tab-selected__bgcolor: $generic;
$articles-filters-tab-selected__border: 1px solid $generic;
$articles-filters-tab-hover__bgcolor: $generic-light;
$articles-filters-check__width: 20;
$articles-filters-check__height: 24;

$filters-button__color: $generic;
$filters-button__background: rgba($generic, 0.07);
$filters-button-desktop__background: $articles-filters-tab__bgcolor;
$filters-button__border: $smoke-alpha;
$filters-button-desktop__border: $generic;
$filters-button-hover__background: $articles-filters-tab-hover__bgcolor;

$filters-dropdown__background: $white;
$filters-dropdown__width: 375;
$filters-dropdown__shadow: 0 2px 5px rgba($black, 0.3);

$filters-button-chevron__size: 10;
