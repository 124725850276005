/* ---------------------------------------------
Notification banners
----------------------------------------------*/

// Global Emergency Banner
#globalAlertBanner {
    width: $width-full;
    @include rem-calc(64, height);
    z-index: 30;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $alert;
    display: flex;
    align-items: center;
    color: $white;

    @media screen and (min-width: $bp-desktop__minimum) {
        @include rem-calc(80, height);
    }

    a {
        color: $white;
        text-decoration: underline;
    }

    &.alert {
        border: 0;
        @include rem-calc(12, font-size);
        @include rem-calc(5, padding);

        @media screen and (min-width: $bp-desktop__minimum) {

            @include rem-calc(18, font-size);
        }

        .alertInfo {
            display: flex;
            align-items: center;
        }

        .alertIcon {
            color: $alert;
            background: $white;
        }

        .alertText {   
            @include rem-calc(16, font-size);
            @include rem-calc(18, line-height);

            @media screen and (min-width: $bp-desktop__minimum) {
                @include rem-calc(20, font-size);
            }
        }

        a {
            @include em-calc(5, margin-left);
        }
    }
}

// Local Announcement Banner
#announcement {
    width: $width-full;
    margin: 0;
    color: $white;
    background-color: $announcement__bgcolor;

    p {
        font-family: $announcement__fontfamily;
        @include rem-calc(15 0, padding);

        @media screen and (min-width: $bp-desktop__minimum) {
            @include rem-calc(20, font-size);
        }

        a {
            color: $white;
            text-decoration: underline;
        }
    }
}

// moves navigation down to accomodate banner when #globalAlertBanner present o page
.navigation.globalAlert {  
    @include rem-calc(60, top);

    @media screen and (min-width: $bp-desktop__minimum) { 
        @include rem-calc(80, top);
    }

}