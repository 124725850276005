// Brand Colour Variables
// Global branding
$global-brand-blue: #003a65;

// all primary branding colours
$brand-primary: $global-brand-blue !default;
$brand-secondary: $global-brand-blue !default;
$brand-tertiary: $global-brand-blue !default;
$brand-accent: $global-brand-blue !default;

// other colours that may be used in the theme
// must only be used in this spreadsheet and not directly in a component
$color-alpha: #330072 !default; /* changed from: #905184 */
$color-beta: #4a4a4a !default;
$color-gamma: #088300  !default;            /* was 003965 */
$color-delta: #000d17 !default;
$color-epsilon:#007494 !default;      
$color-foxtrot:#00634A !default;
$color-zeta: #D1E9FF  !default;
$alert: #D30303 !default;


// grayscale palettes
$black: #000000 !default;
$slate-alpha: #292e34 !default;
$slate-beta: #393f44 !default;
$slate-gamma: #4c4c4c !default;
$slate-delta: #626262 !default;
$slate-epsilon: #8b8d8f !default;
$slate-zeta: #dcdcdc !default;
$smoke-alpha: #bbbbbb !default;
$smoke-beta: #d1d1d1 !default;
$smoke-gamma: #ededed !default;
$snow-alpha: #f1f1f1 !default;
$snow-beta: #fafafa !default;
$snow-gamma: #f5f5f5 !default;
$white: #ffffff !default;

// generic default colours
$generic: $color-beta !default;
$generic-light: $snow-gamma !default;

// local heading colour controls
$headings: $generic !default;
$headings-accentA: $generic !default; // added to test different heading colour in Study
$headings-accentB: $generic !default; // added to test different heading colour in Study

// global colour controls
$bodytext: $slate-gamma !default;
$breadcrumb: $slate-gamma !default;
$linktext: $color-epsilon !default; /* old: $brand-secondary */
$linkhover: $black !default; /* old: $color-alpha */
$linkhover__bgcolor: $color-zeta !default; /* NEW */
$linkvisited: $color-alpha !default; /* # value changed above */
$accenthover: $black !default; // used for CTA hover states
$overlay: $black !default; // flat colour overlay
$gradient-bottom: rgba($overlay, 0.8) !default; // bottom colour for gradient - CHANGED
$gradient-top: rgba($overlay, 0) !default; // top colour for gradient
$imagepanel-bgcolor: $global-brand-blue !default;

// abstracted navigation colours, change only if required
$navigation-primary__bg: #282828 !default; // mobile only
$navigation-primary__fontcolor: $white !default; // mobile only
$navigation-secondary__bg: #282828 !default; // mobile and desktop
$navigation-secondary__bg-color: #003a65 !default; // secondary branding background
$navigation-secondary__fontcolor: $white !default; // mobile and desktop
$navigation-searchpanel__bg: $color-delta !default; // mobile only

// Clearing accent 2022
$clearing-brand-accent: #0080A5;

// Eurus specific template branding
$eurus-dark-blue: #051435;
$eurus-brand-blue: $global-brand-blue;
$eurus-mid-blue: #0073bc;
$eurus-bright-blue: #29b7ea;
$eurus-light-blue: #8ed8f8;
$eurus-white: $white;
$eurus-logo-red: #da1a35;
$eurus-dark-red: #8D1122;
$eurus-teal:#00DBCF;
$eurus-teal-alt:#00857E;
$eurus-yellow:#fdc82f; 

// Eurus base tints
$eurus-base-tint-primary: #92acca;
$eurus-base-tint-secondary: #aac7dc;
$eurus-base-tint-tertiary: #c0d3e8;
$eurus-base-tint-quaternary: #cee7f7;
$eurus-base-tint-quinternary: #f1f9fe;

// Eurus secondary tints
$eurus-secondary-tint-primary: #f6c3cb;
$eurus-secondary-tint-secondary: #fae4ea;
$eurus-secondary-tint-tertiary: #fef5f8;

$eurus-tertiary-tint-primary: #c3e4e8;
$eurus-tertiary-tint-secondary: #e4f3f5;
$eurus-tertiary-tint-tertiary: #f5fafb;

$eurus-quanternary-tint-primary: #feedbe;
$eurus-quanternary-tint-secondary: #fff8e4;
$eurus-quanternary-tint-tertiary: #fffdf6;

// Eurus additional colours
$eurus-video-play: rgba(218, 26, 53, 0.8);
$eurus-body-text: #4A4A4A;
$eurus-dualpanel-overlay: rgba(32,63,100,0.6);
$eurus-brand-blue-overlay-seventy: rgba(32,63,100,0.7);
$eurus-overlay: $eurus-dark-blue;
$eurus-gradient-bottom: rgba($eurus-overlay, 0.8) !default; // bottom colour for gradient - CHANGED