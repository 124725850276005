.resultsWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include rem-calc(20 0, padding);
    #sortResults {
        padding: 1rem;
        @include rem-calc(30, padding-right);
        border-radius: 5px;
        border: 1px solid $eurus-brand-blue;
        background: #FFF;
        color: #4A4A4A;
        font-family: $font-derailed__regular;
        @include fontsize(16);
        font-weight: 400;

        &:hover {
            border-color: $eurus-mid-blue;
            color: $eurus-mid-blue;
        }
    }
}

.search {
    .submitSearch {
        -webkit-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        display: inline-block;
        overflow: hidden;
        text-indent: 101%;
        white-space: nowrap;
        background: url(/webtemplate/img/icons/ncuvision__icon-search_light.png) 50% no-repeat;
        padding: 0;
        color: transparent;
        border: none;
        border-radius: 0;
        cursor: pointer;
        display: block;
        margin: 0;
        width: 100%;
        height: 100%;
    }
}