// News Articles Styles - simple news list & grid amends

.articleGeneralList {
    .filter{
        h3{
            display:none;
            @media screen and (min-width: $bp-tablet__portrait) {
                display: block;
            }
        }
        @media screen and (min-width: $bp-mobile__landscape) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        select{
            font-size:1rem;
            padding: .5rem;
            margin-bottom:1rem;
            width:100%;
            @media screen and (min-width: $bp-mobile__landscape) {
                padding:.25rem;
                width:auto;
            }
        }
        .filterContainer{
            span{
                font-size:1rem;
                color:$black;
            }
        }
    }

    .generalArticles{

        display:flex;
        flex-wrap:wrap;
        //justify-content: space-between;

        .item {
            border-top: $articles-items__border;
            
            .labelContainer{
                opacity:0;
            }

            &.hide{
                display:none;
            }

        }

        .headline { 
            @include chevron();
            @include rem-calc(16 0 0, margin);
            line-height: 1.25;
            padding-bottom: 0;

            a{
                color: $articles-items-headline__color;  
            }

            &:hover,
            &:focus {
                text-decoration: underline;
            }

        }

        .summary {
            @include rem-calc(0, margin-top);
        }

        .itemDate {
            @include rem-calc(12, margin-bottom);
            color: $articles-items-date__color;
            font-family: $articles-items-date__font;   
        }

        &.filtered{
            //justify-content: flex-start;
           
            .promo{
                @media screen and (min-width: $bp-tablet__portrait) {
                   @include rem-calc(22, margin-right);

                    flex: 1 0 31%;
                    max-width: 32%;
                }

            }
        
        }

    }

}