// CTA Styles

.ctaContainer {
    @media screen and (min-width: $bp-tablet__portrait) {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    &.back {
        @media screen and (min-width: $bp-tablet__portrait) {
            justify-content: flex-start;
        }
    }
}

.individualCTA{
    margin:0;
    .ctaContainer{
        justify-content: flex-start;
    }
}

.ctaColumn {                            
    @media screen and (min-width: $bp-tablet__portrait) {
        @include rem-calc(15, margin-right);

        flex: 1 1 $one-third;
        flex-grow: 1;
        max-width: $one-third;
    }

    &:last-of-type {
        @media screen and (min-width: $bp-tablet__portrait) {
            margin-right: 0;
        }
    }
}
.cta {
    @include fontsize(18);
    @include rem-calc(43, height);
    @include rem-calc(7.5 0, margin);
    @include rem-calc(0 35 0 15, padding);
    color: $cta__color;
    background-color: $cta__bgcolor;
    border: 1px solid $cta__bgcolor;
    border-radius: $cta_border-radius;
    cursor: pointer;
    display: inline-block;
    font-family: $cta__fontfamily;
    line-height: 2.3;
    overflow: hidden;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: background-color 0.2s ease, border-color 0.3s ease;

    @media screen and (min-width: $bp-tablet__landscape) {
        @include fontsize(24);
        @include rem-calc(60, height);
        @include rem-calc(15 0, margin);
        @include rem-calc(0 50 0 20, padding);
        
        line-height: 2.4;
    }

    &:visited{
        color: $cta__color;
    }

    &:focus,
    &:hover {
        color: $cta-hover__color;
        border-color: $cta-hover__bgcolor;
        text-decoration: none;
    }

    &:not(.ghost):not(.link){
        &:focus {
            color: $eurus-brand-blue;
        }
        &:hover{
            &:focus{
                color: $eurus-white;
            }
        } 
    }

    &:hover {
        background-color: $cta-hover__bgcolor;
        &:after {                            /* new styles for animated chevron */
            @include rem-calc(16, right);
        }
    }

    &:after {                                /* new styles for icons */
        @extend %fa-icon;
        @extend .fas;
        @include rem-calc(24, right);
        @include animation(right, 0.3s);
        /* for right animation */
        @include fontsize(18);

        color: inherit;
        content: fa-content($fa-var-chevron-right);
        font-family: 'Font Awesome 5 Free';
        font-weight: normal;
        line-height: 1.5;
        position: absolute;
        top: 0.4em;

        @media screen and (min-width: $bp-tablet__landscape) {
            @include fontsize(24);
            line-height: 1.7;
        }
    }

    /* add icons */
    &.extlink {
        @include icon()
    }

    &.back{
        @include icon($fa-var-angle-left);
    }

    &.download {
        @include icon($fa-var-download)
    }

    &.eraser {
        @include icon($fa-var-eraser)
    }

    &.chat {
        @include icon($fa-var-comment)
    }

    &.email {
        @include icon($fa-var-envelope)
    }

    &.attach {
        @include icon($fa-var-paperclip)
    }

    &.calendar {
        @include icon($fa-var-calendar-alt)
    }

    &.phone {
        @include icon($fa-var-phone)
    }

    &.signup {
        @include icon($fa-var-pencil-alt)
    }

    &.map {
        @include icon($fa-var-map-marked-alt)
    }
    /* end add icons */

    &.full {
        width: $width-full;
    }

    &.noIcon {
        @include rem-calc(0 15 0 15, padding);

        @media screen and (min-width: $bp-tablet__landscape) {
            @include rem-calc(0 20 0 20, padding);

            line-height: 2.4;
        }

        &:after {
            display: none;
        }
    }

    &:not(.ghost) {
        &.link {                                                                      
            background-color: $cta-link__bgcolor;
            border: 1px solid $cta-link__bghovercolor;

            &:hover,
            &:focus{
                background-color: $cta-link__bghovercolor;
            }
        }
    }

    &.ghost {
        background-color: $cta-ghost__bgcolor;
        border-color: $cta-ghost__bordercolor;
        color: $global-brand-blue;

        &:hover,
        &:focus {
            // background-color: $cta-link__bghovercolor;
            color: $cta-hover__color;
            // border-color: $cta-link__bghovercolor;
            background-color: $eurus-dark-blue;
            border-color: $eurus-dark-blue;

        }

    }
    &.smooth-scroll, &.course-scroll{
        @include icon($fa-var-angle-double-down);
        background-color: $cta-jump__bgcolor;
        border-color: $cta-jump__bgcolor;
        
        &:hover, &focus{
            background-color: $cta-hover__bgcolor;
            border-color: $cta-hover__bgcolor;
            color: $cta-hover__color;
            &:after{
                transform: translateY(6.75px);
            }
        }
    }
}
