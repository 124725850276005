/* ---------------------------------------------
Slick logo slider styles created by NCU

/* START SLICK LOGO STYLES */

/* Slider */

.ncl-slick.slick-slider {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}

.ncl-slick.slick-slider .slick-list {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
}

.ncl-slick.slick-slider .slick-list:focus {
    outline: none;
}

.ncl-slick.slick-slider .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.ncl-slick.slick-slider .slick-slider .slick-track,
.ncl-slick.slick-slider .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.ncl-slick.slick-slider .slick-track {
    display: block;
    left: 0;
    position: relative;
    top: 0;
}

.ncl-slick.slick-slider .slick-track:before,
.ncl-slick.slick-slider .slick-track:after {
    content: '';
    display: table;
}

.ncl-slick.slick-slider .slick-track:after {
    clear: both;
}

.ncl-slick.slick-slider .slick-loading .slick-track {
    visibility: hidden;
}

.ncl-slick.slick-slider .slick-slide {
    display: none;
    float: left;
    height: 100%;
    margin: 0 auto;
    min-height: 1px;
    text-align: center;
}

[dir='rtl'] .ncl-slick.slick-slider .slick-slide {
    float: right;
}

.ncl-slick.slick-slider .slick-slide img {
    display: block;
    margin: 0 auto;
    text-align: center;
}

.ncl-slick.slick-slider .slick-slide.slick-loading img {
    display: none;
}

.ncl-slick.slick-slider .slick-slide.dragging img {
    pointer-events: none;
}

.ncl-slick.slick-slider .slick-initialized .slick-slide {
    display: block;
}

.ncl-slick.slick-slider .slick-loading .slick-slide {
    visibility: hidden;
}

.ncl-slick.slick-slider .slick-vertical .slick-slide {
    border: 1px solid transparent;
    display: block;
    height: auto;
}

.ncl-slick.slick-slider .slick-arrow.slick-hidden {
    display: none;
}

/* Slider Navigation */

.ncl-slick.slick-slider .slick-loading .slick-list {
    background: #fff url($ajax-loading) center center no-repeat;
}

/* Icons */

@font-face {
    font-family: 'slick';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/slick.eot');
    src: url('../fonts/slick.eot?#iefix') format('embedded-opentype'), url('../fonts/slick.woff') format('woff'),
        url('../fonts/slick.ttf') format('truetype'), url('../fonts/slick.svg#slick') format('svg');
}

/* Arrows */

.ncl-slick.slick-slider button.slick-arrow {
    background-color: #4c4c4c;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    font-size: 1em;
    height: 4.6em;
    margin-top: -2.3em;
    opacity: 0.8;
    padding: 0;
    position: absolute;
    text-indent: -9999px;
    top: calc(((100vw - 4em) * 0.53) / 2);
    transition: opacity 0.3s ease-in-out;
    width: 4.6em;
    z-index: 1;
}

.ncl-slick.slick-slider button.slick-prev,
.ncl-slick.slick-slider button.slick-next {
    border: none;
    box-shadow: none;
    color: transparent;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 20px;
    line-height: 0;
    margin-top: -10px;
    outline: none;
    padding: 0;
    position: absolute;
    top: 50%;
    width: 20px;
}

.ncl-slick.slick-slider button.slick-prev:hover,
.ncl-slick.slick-slider button.slick-prev:focus,
.ncl-slick.slick-slider button.slick-next:hover,
.ncl-slick.slick-slider button.slick-next:focus {
    background: #ccc;
    box-shadow: none;
    color: transparent;
    display: block;
    height: 20px;
    outline: none;
    position: absolute;
    top: 50%;
    width: 20px;
}

.ncl-slick.slick-slider button.slick-prev:hover:before,
.ncl-slick.slick-slider button.slick-prev:focus:before,
.ncl-slick.slick-slider button.slick-next:hover:before,
.ncl-slick.slick-slider button.slick-next:focus:before {
    opacity: 1;
}

.ncl-slick.slick-slider button.slick-prev.slick-disabled:before,
.ncl-slick.slick-slider button.slick-next.slick-disabled:before {
    opacity: 0.25;
}

.ncl-slick.slick-slider button.slick-prev:before,
.ncl-slick.slick-slider button.slick-next:before {
    color: white;
    font-family: 'slick';
    font-size: 20px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1;
    opacity: 0.75;
}

.ncl-slick.slick-slider button.slick-prev {
    left: -10px;
    transform: translateY(-50%);
}

[dir='rtl'] .ncl-slick.slick-slider button.slick-prev {
    left: auto;
    right: -10px;
}

.ncl-slick.slick-slider button.slick-prev:before {
    background: url($footer-carousel__arrow) 0 0 no-repeat;
    background-size: 66% auto;
    content: '';
    display: block;
    height: 1rem;
    left: 66%;
    position: absolute;
    top: 50%;
    transform: translate(-60%, -50%);
    width: 1rem;
    /*content: '?';
    color: #444;*/
}

[dir='rtl'] .ncl-slick.slick-slider button.slick-prev:before {
    background: url($footer-carousel__arrow) 0 0 no-repeat;
    background-size: 66% auto;
    content: '';
    display: block;
    height: 1rem;
    left: 66%;
    position: absolute;
    top: 50%;
    transform: translate(-60%, -50%);
    width: 1rem;
    /*content: '?';
    color: #444;*/
}

.ncl-slick.slick-slider button.slick-next {
    right: -10px;
    transform: rotate(-180deg) translateY(50%);
}

[dir='rtl'] .ncl-slick.slick-slider button.slick-next {
    left: -10px;
    right: auto;
}

.ncl-slick.slick-slider button.slick-next:before {
    background: url($footer-carousel__arrow) 0 0 no-repeat;
    background-size: 66% auto;
    content: '';
    display: block;
    height: 1rem;
    left: 66%;
    position: absolute;
    top: 50%;
    transform: translate(-60%, -50%);
    width: 1rem;
    /*content: '?';
    color: #444;*/
}

[dir='rtl'] .ncl-slick.slick-slider button.slick-next:before {
    color: #444;
    content: '?';
}

/* Dots */

.ncl-slick.slick-slider {
    margin: auto;
}

.ncl-slick.slick-slider .slick-dots {
    bottom: 0;
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    width: 100%;
}

.ncl-slick.slick-slider .slick-dots li {
    cursor: pointer;
    display: inline-block;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    position: relative;
    width: 20px;
}

.ncl-slick.slick-slider .slick-dots li button {
    background: transparent;
    border: 0;
    color: #000;
    cursor: pointer;
    display: block;
    height: 20px;
    line-height: 0;
    outline: none;
    padding: 5px;
    width: 20px;
}

.ncl-slick.slick-slider .slick-dots li button:hover,
.ncl-slick.slick-slider .slick-dots li button:focus {
    outline: none;
}

.ncl-slick.slick-slider .slick-dots li button:hover:before,
.ncl-slick.slick-slider .slick-dots li button:focus:before {
    opacity: 1;
}

.ncl-slick.slick-slider .slick-dots li button:before {
    color: black;
    content: '?';
    font-family: 'slick';
    font-size: 6px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 20px;
    left: 0;
    line-height: 20px;
    opacity: 0.25;
    position: absolute;
    text-align: center;
    top: 0;
    width: 20px;
}

.ncl-slick.slick-slider .slick-dots li.slick-active button:before {
    color: black;
    opacity: 0.75;
}

.ncl-slick.slick-slider li.slick-active button {
    color: #c00;
}

/* Sliders: In-page/Footer Logos */

.ncl-slick.logo-slider {
    max-width: 67rem;
    /*margin: 30px auto 30px;*/
    padding: 0 1em 0 1em;
    width: 100%;
}

.ncl-slick.logo-slider .slick-slide img {
    display: block;
    margin: 0 auto;
    max-height: 100px;
    max-width: 80%;
    text-align: center;
}

.ncl-slick.logo-slider .slick-track {
    display: table;
}

.ncl-slick.logo-slider .slick-slide {
    display: table-cell;
    float: none;
    vertical-align: middle;
}

.ncl-slick.logo-slider p.caption {
    display: none;
}

/* Slider: Quotes */

.ncl-slick.quote-slider {
    max-width: 67rem;
    /*margin: 30px auto 30px;*/
    padding: 0 1em 0 1em;
    width: 100%;
}

.ncl-slick.quote-slider .slick-slide img {
    display: block;
    margin: 0 auto;
    max-height: 150px;
    max-width: 80%;
    text-align: center;
}

.ncl-slick.quote-slider .slick-track {
    display: table;
}

.ncl-slick.quote-slider .slick-slide {
    display: table-cell;
    float: none;
    vertical-align: middle;
}

.ncl-slick.quote-slider p.caption {
    display: none;
}

@media screen and (max-width: 1200px) {
    .ncl-slick.slick-slider button.slick-prev,
    .ncl-slick.slick-slider button.slick-next {
        visibility: hidden;
    }
}

.footerCarousel {
    overflow: hidden;
}

/* END SLICK LOGO STYLES */
