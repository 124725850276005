.alertButton {
    background: #D70929;
    @include rem-calc(0 20, padding);
    @include rem-calc(50, bottom);
    @include rem-calc(42, height);
    @include rem-calc(16, font-size);
    color: $white;
    border-radius: 5px 0px 0px 5px;
    position: fixed;
    right: 0;
    font-family: 'Bariol';
    font-style: normal;
    z-index:9999;

    @media screen and (min-width: $bp-desktop__minimum) {
        @include rem-calc(58, height);
        @include rem-calc(24, font-size);
        @include rem-calc(40, bottom);
    }

    .alertButtonText{
        &:after{
            @extend %fa-icon;
            @extend .fas;
            content: fa-content($fa-var-sign-out-alt);
            position: relative;
            left: 4px;
        }
    }

    .alertButtonMore {
        max-width: 0;
        transition: max-width 500ms;
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        font-weight:400;
    }
    
    &:hover, &:focus{
        .alertButtonMore{
            @media screen and (min-width: $bp-desktop__minimum) {
                max-width: 18rem;
                @include rem-calc(16,margin-left);
            }
        }
    }
}