#nhsApiContentWrapper {

    display: flex;
    flex-direction: column;

    .nhsDataWrapper {

        h3 {
            padding: 0 0 1.5rem 0;
        }

        li {
            p {
                padding: 0;
            }
        }

        ul {
            padding: 0 0 1.5rem 0;
            margin-left: 2rem;
        }

    }

    #nhs_accreditation {
        order: -9999;
    }
}