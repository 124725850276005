/* ---------------------------------------------
Accordion Content
----------------------------------------------*/
/* -----------------
Basic Text Styles
------------------*/
.dropdown {
    @include fontsize(10);

    & + & {
        @include rem-calc(-$section-margin-mobile, margin-top);

        @media screen and (min-width: $bp-tablet__landscape) {
            @include rem-calc(-$section-margin-desktop, margin-top);
        }
    }

    .dropDownHeading {
        @include fontsize(24);
        @include rem-calc(10 34 10 0, padding);

        border-bottom: 1px solid $accordion__bgcolor;
        color: $accordion-heading__color;
        display: block;
        font-family: $accordion-heading__fontfamily;
        position: relative;

        @media screen and (min-width: $bp-tablet__landscape) {
            @include fontsize(36);
            @include rem-calc(58, padding-right);
        }

        &:focus,
        &:hover {
            border-color: $accordion-hover__bgcolor;
            text-decoration: none;
        }
    }

    .toggle {
        @include rem-calc(24, height);
        @include rem-calc(24, width);

        background: $accordion__bgcolor;
        border-radius: $half;
        position: absolute;
        right: 0;
        top: $half;
        transform: translateY(-$half);
        transform-origin: center;
        transition: all 0.2s ease-in-out;

        @media screen and (min-width: $bp-tablet__landscape) {
            @include rem-calc(48, height);
            @include rem-calc(48, width);
        }

        &:after {
            @include rem-calc(7, height);
            @include rem-calc(12, width);

            background: url($accordion-static__arrow) 0 0 no-repeat;
            background-size: $width-full auto;
            content: '';
            left: $half;
            position: absolute;
            top: 52%;
            transform: translate(-$half, -$half);

            @media screen and (min-width: $bp-tablet__landscape) {
                @include rem-calc(17, height);
                @include rem-calc(27, width);
            }
        }
    }

    .dropDownSummary {
        @include fontsize(15);
        @include rem-calc(20, padding);

        display: none;

        @media screen and (min-width: $bp-tablet__landscape) {
            @include fontsize(18);
            @include rem-calc(40, padding);
        }
    }

    .dropdownOpen {
        .toggle {
            transform: rotate(180deg) translateY($half);
        }
    }

    &.colourA {
        .toggle {
            background-color: $colourA-accordion__bgcolor;
        }
    }

    &.colourB {
        .toggle {
            background-color: $colourB-accordion__bgcolor;
        }
    }

    &.colourC {
        .toggle {
            background-color: $colourC-accordion__bgcolor;
        }
    }

    .no-js & {
        .toggle {
            display: none;
        }

        .dropDownSummary {
            display: block;
        }
    }

    .dropDownHeading:hover {
        .toggle {
            background-color: $accordion-hover__bgcolor;
        }
    }
}

/* ---------------------------------------------
Course Accordion
----------------------------------------------*/

.courseDropDowns {
    @extend .dropdown;

    .dropDownSummary {
        padding: 0;
        display: none;

        @media screen and (min-width: $bp-desktop__minimum) {
            @include rem-calc(30, padding-bottom);
        }

        > * {
            transition: opacity 0.2s ease-in;
        }

        &.loading {
            @include rem-calc(180, min-height);

            background: url($ajax-loading) top 10% center no-repeat;

            > * {
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    .course {
        @include rem-calc(16, padding-top);
        @include rem-calc(16, padding-bottom);

        border-top: 1px solid $course__border-color;

        &.placeholder {
            display: none;
        }

        &:first-child {
            border-top: none;
        }
    }

    .courseKeyInfo {
        display: flex;
    }

    .courseLeft {
        @include rem-calc(14, line-height);
        @include rem-calc(8, margin-right);
        @include rem-calc(76, max-width);

        flex: 1 0 $half;

        @media screen and (min-width: $bp-content) {
            @include rem-calc(35, margin-right);
            @include rem-calc(175, max-width);
            @include rem-calc(19, line-height);
        }
    }

    .courseRight {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: $width-full;
    }

    .courseHeader {
        position: relative;
    }

    .courseTitle {
        @include fontsize(18);
        @include rem-calc(21, line-height);
        @include rem-calc(36, padding-right);

        font-weight: 600;
        margin: 0;
        text-transform: none;
        padding-bottom: 0;

        @media screen and (min-width: $bp-content) {
            @include fontsize(26);
            @include rem-calc(31, line-height);
            @include rem-calc(40, padding-right);
        }
    }

    .courseDetails {
        display: none;
    }

    .courseName {
        color: $course-name__color;
        display: block;
    }

    .courseQual {
        @include fontsize(15);
        @include rem-calc(8, margin-top);

        color: $course-qualification__color;
        display: block;
        font-family: $courseaccordion-subtitle__fontfamily;
        font-weight: 400;

        @media screen and (min-width: $bp-content) {
            @include fontsize(24);
        }
    }

    .courseSummary {
        @include fontsize(14);
        @include rem-calc(18, line-height);
        @include rem-calc(12, margin-bottom);
        @include rem-calc(12, margin-top);

        @media screen and (min-width: $bp-content) {
            @include fontsize(16);
            @include rem-calc(26, line-height);
        }
    }

    .courseImage {
        @include rem-calc(9, padding-bottom);
    }

    .courseHighlights {
        @include rem-calc(16, padding-top);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        @media screen and (min-width: $bp-tablet__portrait) {
            flex-wrap: nowrap;
            justify-content: space-between;
        }
    }

    .courseHighlight {
        @include rem-calc(20, margin-bottom);

        align-items: flex-start;
        display: flex;
        flex: 1 0 45%;
        max-width: 45%;

        @media screen and (min-width: $bp-tablet__portrait) {
            flex-basis: 22%;
            max-width: 22%;
        }
    }

    .courseHighlightImage {
        @include rem-calc(50, width);
        @include rem-calc(8, margin-right);

        @media screen and (min-width: $bp-content) {
            @include rem-calc(65, width);
            @include rem-calc(25, margin-right);
        }
    }

    .courseHighlightDetails {
        @include fontsize(12);
        @include rem-calc(14, line-height);

        @media screen and (min-width: $bp-content) {
            @include fontsize(16);
            @include rem-calc(21, line-height);
            width: 60%;
        }
    }

    .courseHighlightTitle {
        display: block;
        font-weight: 600;
        font-family: $courseaccordion-headline__fontfamily;

        @media screen and (min-width: $bp-content) {
            @include fontsize(16);
            @include rem-calc(4, padding-bottom);
        }
    }

    .ucasHeader {
        @include fontsize(12);

        color: $ucas-title__color;
        display: block;
        font-weight: 400;
        font-family: $courseaccordion-subtitle__fontfamily;
        text-transform: uppercase;

        @media screen and (min-width: $bp-content) {
            @include fontsize(16);
        }
    }

    .ucasCode {
        @include fontsize(14);
        @include rem-calc(16, line-height);

        color: $ucas-code__color;
        display: block;
        font-weight: 500;
        font-family: $courseaccordion-headline__fontfamily;

        @media screen and (min-width: $bp-content) {
            @include fontsize(18);
            @include rem-calc(21, line-height);
        }
    }

    .courseDownload {
        @include rem-calc(25, padding-bottom);
        @include rem-calc(10, padding-top);

        @media screen and (min-width: $bp-content) {
            @include rem-calc(22, padding-bottom);
            @include rem-calc(33, padding-top);
        }
    }

    .courseLess,
    .courseMore {
        @include fontsize(12);
        @include rem-calc(14, line-height);
        @include rem-calc(36, padding-right);
        @include rem-calc(28, height);

        position: relative;
        font-family: $courseaccordion-headline__fontfamily;
        color: $accordion__bgcolor;
        white-space: normal;
        -webkit-tap-highlight-color: transparent;

        @media screen and (min-width: $bp-mobile__landscape) {
            @include rem-calc(40, height);
            @include rem-calc(48, padding-right);
            @include fontsize(16);
            @include rem-calc(16, line-height);
        }

        @media screen and (min-width: $bp-content) {
            @include fontsize(19);
            @include rem-calc(19, line-height);
        }

        &:after {
            @extend .toggle;
            @include rem-calc(28, width);
            @include rem-calc(28, height);
            background-size: 16px !important;
            left: auto;
            right: 0;
            top: 0;
            background: $accordion__bgcolor url($accordion-static__arrow) center center no-repeat;
            transform: none;

            @media screen and (min-width: $bp-mobile__landscape) {
                @include rem-calc(40, width);
                @include rem-calc(40, height);
                @include rem-calc(24, background-size);
            }
        }

        &:hover {

            &:after {
                background-color: $accenthover;
            }
        }
    }

    .courseEntryRequirements,
    .courseOpportunities,
    .courseDuration,
    .courseUcasCodes {
        font-family: $courseaccordion-description__fontfamily;
    }

    .buttonWrapper {
        text-align: right;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-grow: 2;

        .courseLess {
            &:after {
                transform: rotate(180deg);
            }
        }
    }

    .cta {
        border-color: transparent;
    }

    // accent colour additions
    &.colourA {
        .courseName {
            color: $colourA-accordion__bgcolor;
        }

        .courseMore,
        .courseLess {
            color: $colourA-accordion__bgcolor;

            &:after {
                background-color: $colourA-accordion__bgcolor;
            }

            &:hover {
                &:after {
                    background-color: $accenthover;
                }
            }
        }

        .cta {
            background-color: $colourA-accordion__bgcolor;

            &:hover {
                background-color: $accenthover;
            }
        }
    }

    &.colourB {
        .courseName {
            color: $colourB-accordion__bgcolor;
        }

        .courseMore,
        .courseLess {
            color: $colourB-accordion__bgcolor;

            &:after {
                background-color: $colourB-accordion__bgcolor;
            }

            &:hover {
                &:after {
                    background-color: $accenthover;
                }
            }
        }

        .cta {
            background-color: $colourB-accordion__bgcolor;

            &:hover {
                background-color: $accenthover;
            }
        }
    }

    &.colourC {
        .courseName {
            color: $colourC-accordion__bgcolor;
        }

        .courseMore,
        .courseLess {
            color: $colourC-accordion__bgcolor;

            &:after {
                background-color: $colourC-accordion__bgcolor;
            }

            &:hover {
                &:after {
                    background-color: $accenthover;
                }
            }
        }

        .cta {
            background-color: $colourC-accordion__bgcolor;

            &:hover {
                background-color: $accenthover;
            }
        }
    }
}

.no-js {
    .courseDropDowns {
        .dropDownSummary {
            > * {
                opacity: 1;
            }

            &.loading {
                background: none;
                min-height: 0;
            }
        }
    }
}

.eurus {
    .dropdown {
        .dropDownHeading {
            &:hover {
                .toggle {
                    background: $eurus-light-blue;
                }
            }
        }
        .toggle {
            background: $eurus-light-blue;
            &:hover {
                background: $eurus-light-blue;
            }
            &:after {
                background: url($accordion-static__arrow-eurus) 0 0 no-repeat;
                height: 0.4375rem;
                width: 0.75rem;
                background-size: 100% auto;
                content: "";
                left: 50%;
                position: absolute;
                top: 52%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                @media screen and (min-width: $bp-tablet__landscape) {
                    height: 1.0625rem;
                    width: 1.6875rem;
                }
            }
        }
    }
}
