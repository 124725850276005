// Label Styles

.labelContainer {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
}

.label {
    @include fontsize(18);

    background-color: $label__bgcolor;
    color: $label__color;
    font-family: $label__fontfamily;
    font-weight: 400;
    left: 0;
    line-height: 1;
    top: 0;
    z-index: 1;

    @media screen and (min-width: $bp-tablet__landscape) {
        @include fontsize(24);
    }

    &.colourA {
        background-color: $colourA-label__bgcolor;
    }

    &.colourB {
        background-color: $colourB-label__bgcolor;
    }

    &.colourC {
        background-color: $colourC-label__bgcolor;
    }
}

.labelText {
    @include rem-calc($standard-unit/2, padding);

    color: $label__color;
    display: inline-block;
}

.labelLink {
    pointer-events: auto;

    &:focus,
    &:hover,
    &:visited {
        color: $label__color;
    }
}

.labelIcon {
    @include rem-calc(20, width);
    @include rem-calc(10 20, margin);

    display: block;
    height: auto;

    @media screen and (min-width: $bp-tablet__landscape) {
        @include rem-calc(15, margin-bottom);
        @include rem-calc(15, margin-top);
        @include rem-calc(25, width);
    }
}
