// Navigation Styles

/* --------- MIXINS ---------- */

@mixin child-section-theming($value) {
    @for $i from 1 through $value {
        &:nth-child(#{$i}) {
            &.has-dropdown .highlight {

                .arrow {
                    // @include css-variables(background-color, column#{$i}-accent, $navigation-menu-item-haschild__bgcolor);
                    background-color: $eurus-brand-blue;
                }
            }

            .has-child>a {

                .arrow {
                    // @include css-variables(background-color, column#{$i}-accent, $navigation-menu-item-haschild__bgcolor);
                    background-color: $eurus-brand-blue;
                }
            }
        }
    }

    @media screen and (min-width: $bp-desktop__minimum) {
        @for $i from 1 through $value {
            &:nth-child(#{$i}) {
                .highlight {
                    //@include css-variables(background-color, column#{$i}-accent, $navigation-menu-item-haschild__bgcolor);
                    background-color: $eurus-brand-blue;
                }

                .dropdown li:hover>a,
                .dropdown li:focus>a {
                    // @include css-variables(color, column#{$i}-accent, $navigation-menu-item-hover__color);
                    color: $eurus-brand-blue;
                }

                .dropdown-headline {
                    // @include css-variables(color, column#{$i}-accent, $navigation-menu-headline__color);
                    color: $eurus-brand-blue;
                }
            }
        }
    }
}

@mixin disc-arrow($width, $height, $arrow) {
    @include animation(all);
    background-image: url($arrow);
    background-repeat: no-repeat;
    background-position: center center;
    top: $half;
    content: '';
    position: absolute;
    border-radius: $half;
    width: $width;
    height: $height;
}

/* --------- DECLARATIONS ---------- */

body {

    &.menu-opened,
    &.search-opened {
        @extend .noScroll;
    }
}

.eurus {
    .navigation {

        position: relative;

        @media screen and (min-width: $bp-mobile__landscape) {
            position: absolute;
        }

        .primary {
            background-color: $eurus-brand-blue;
            color: $eurus-white;

            .search-input {
                border-radius: 2px;
            }

            .search-submit {
                background: $eurus-light-blue url($eurus__searchIcon) center center no-repeat;
            }

        }

        .secondary {
            background-color: $eurus-white;
            color: $eurus-brand-blue;

            a {
                color: $eurus-brand-blue;

                &:active,
                &:hover,
                &:focus,
                &:visited {
                    color: $eurus-brand-blue;
                }
            }

        }

        // no tabs on eurus pages (ug/pg etc)
        .section-tabs{
            display:none;
        }

        .menu-wrapper {

            li {

                &:hover,
                &:focus,
                &.touch-selected {

                    .dropdown {
                        @media screen and (min-width: $bp-desktop__minimum) {
                            top: 76%;
                        }
                    }
                }
            }
        }
    }
}



.navigation {
    width: $width-full;
    position: absolute;
    top: 0;
    left: 0;
    z-index: $zindex-navigation;

    /* --------- PRIMARY NAVIGATION STYLES ---------- */
    .primary {

        background-color: $eurus-brand-blue;
        color: $eurus-white;

        >.row {

            @include rem-calc($navigation-topbar__height, min-height);
            @include rem-calc(12, padding);
            display: flex;

            @media screen and (min-width: $bp-desktop__minimum) {
                @include rem-calc(0 15, padding);
                min-height: 0;

            }

            &:before {
                @include rem-calc($navigation-topbar__height, height); // same height as nav bar
                width: $width-full;
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                z-index: $zindex-navigation-topbar-bg; // applied to be above menu items, other element adjusted to sit on top
                background: $eurus-brand-blue;

                @media screen and (min-width: $bp-desktop__minimum) {
                    display: none;
                }
            }

            & .unit-bar {
                @include css-variables(background-color, section-accent, $navigation-sectionaccent__bgcolor);
                @include rem-calc(4, height);
                width: $width-full;
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                z-index: $zindex-navigation-topbar-bg; // applied to be above menu items, other element adjusted to sit on top
                display: none;

                @media screen and (min-width: $bp-desktop__minimum) {
                    display: none;
                }

                body.unit & {
                    display: block;
                }
            }
        }

        .no-items {
            @media screen and (min-width: $bp-desktop__minimum) {
                padding: 18px;
            }
        }
    }

    .logo {
        display: flex;
        align-items: center;
        z-index: $zindex-navigation-topbar-elements;
    }

    .school-name {
        @include rem-calc(300, max-width);
        @include rem-calc(0 8, padding);
        @include rem-calc(1, letter-spacing);
        @include fontsize(12);
        font-family: $navigation-unit-title__fontfamily;
        display: inline-block;
        text-transform: uppercase;
        color: $white;
        flex-grow: 0;
        display: none;
        line-height: 1.4; // adjusting three-line school titles to not break layout

        @media screen and (min-width: $bp-mobile__landscape) {
            @include rem-calc(2, letter-spacing);
        }

        body.unit & {
            display: block;

            @media screen and (min-width: $bp-desktop__minimum) {
                display: none;
            }
        }
    }

    .menu {
        @include animation(all);
        -webkit-overflow-scrolling: touch;
        position: fixed;
        top: 0;
        left: 0;
        height: 0;
        max-height: $viewport-height-max;
        background-color: $navigation-primary-mobile__bgcolor;
        overflow: hidden;
        padding: 0;
        width: $width-full;
        z-index: $zindex-navigation-menus;

        @media screen and (min-width: $bp-desktop__minimum) {
            position: static;
            top: $height-full;
            flex-grow: 1;
            top: auto;
            left: auto;
            height: auto;
            overflow: auto;
            padding-top: 0;
            background-color: transparent;
        }

        &.open {
            @include rem-calc(76, padding-top);
            height: $height-full;
            overflow: scroll;

            .primary>.row:before {
                display: block;
            }
        }

        &:not(.open) {

            .menu-back {
                display: none;
            }
        }

        &.active {
            background-color: $white;
        }
    }

    .menu-back {
        @include animation(opacity);
        @include rem-calc(55, min-height);
        @include rem-calc(16 24 16 60, padding);
        @include fontsize(14);
        @include rem-calc(-1, margin-top);
        font-family: $navigation-toplevel__fontfamily; // set this to soemthing else later
        position: fixed;
        color: $white;
        background-color: $navigation-dropdown-back-bar__bgcolor;
        z-index: $zindex-navigation-back;
        width: $width-full;
        opacity: 0;
        display: none;
        line-height: 1.65;

        &.active {
            opacity: 1;
            display: block;
        }

        @media screen and (min-width: $bp-desktop__minimum) {
            display: none;
        }
    }

    .menu-back-arrow {
        @include disc-arrow(24px, 24px, $navigation-chevron__left);
        @include rem-calc(24, left);
        transform: translateY(-$half);
        // background-color: $navigation-dropdown-back__bgcolor;
        background-color: $eurus-brand-blue !important;
        background-position: left 7px center;
    }

    .menu-wrapper {
        @include animation(transform);
        width: $width-full;
        background-color: $navigation-menu-mobile__bgcolor;

        @media screen and (min-width: $bp-desktop__minimum) {
            @include rem-calc(16, margin-right);
            display: flex;
            justify-content: flex-end;
            width: auto;
            background-color: transparent;

            // global li styling
            li {

                &:hover,
                &:focus,
                &.touch-selected {

                    .dropdown {
                        display: block;
                        position: absolute;
                        top: 100%;
                        left: 0;
                    }
                }
            }
        }

        // affects the top-most level only
        >ul {
            @include zero;

            &+ul {
                @include rem-calc(5, margin-top);
            }

            @media screen and (min-width: $bp-desktop__minimum) {
                display: flex;
                justify-content: flex-end;
            }

            >li {
                box-shadow: $navigation-toplevel-mobile__boxshadow;
                border-top: $navigation-toplevel-mobile__bordertop;
                border-bottom: $navigation-toplevel-mobile__borderbottom;

                &+li {
                    @include rem-calc(5, margin-top);

                    @media screen and (min-width: $bp-desktop__minimum) {
                        margin-top: 0;
                    }
                }

                @media screen and (min-width: $bp-desktop__minimum) {
                    @include animation(all);
                    box-shadow: none;
                    border-top: none;
                    border-bottom: none;

                    &:hover,
                    &:focus {
                        background-color: $navigation-toplevel-hover__bgcolor;

                        >a {
                            color: $navigation-toplevel-hover__color;
                        }

                        .highlight {
                            opacity: 1;
                        }
                    }
                }

                /* --------- CHILD SECTION THEMING ---------- */
                @include child-section-theming(10);
                /* --------- END - CHILD SECTION THEMING ---------- */

                >a {
                    position: relative;
                    color: $navigation-toplevel__color;
                    display: block;
                    text-decoration: none;
                    @include rem-calc(16 80 16 24, padding);

                    @media screen and (min-width: $bp-desktop__minimum) {
                        @include animation(all);
                        @include rem-calc(36 16, padding);
                    }
                }

            }

            &.menu-toplevel {

                &>li>a {
                    font-family: $navigation-toplevel__fontfamily;
                }
            }

            &.menu-secondary {

                &>li>a {
                    font-family: $navigation-toplevel-mobile__fontfamily;
                }
            }
        }

        // global li styling
        li {
            list-style-type: none;
            margin-bottom: 0;
            cursor: pointer;
        }

        .menu-secondary {
            display: block;
            overflow-y: visible;

            @media screen and (min-width: $bp-desktop__minimum) {
                display: none;
            }
        }
    }

    .highlight {
        @include rem-calc(80, width);
        position: absolute;
        height: 80%;
        top: $half;
        right: 0;
        transform: translateY(-$half);
        border-left: $navigation-toplevel-highlight__borderleft;
        display: none;

        @media screen and (min-width: $bp-desktop__minimum) {
            @include rem-calc(2, height);
            @include animation(opacity);
            display: block;
            position: static;
            width: $width-full;
            transform: translateY(0.5rem);
            opacity: 0;
            border-left: none;
            top: auto;
            right: auto;
        }

        .arrow {
            @include disc-arrow(24px, 24px, $navigation-chevron__right);
            left: $half;
            transform: translateX(-$half) translateY(-$half);

            @media screen and (min-width: $bp-desktop__minimum) {
                display: none;
            }
        }
    }

    .has-dropdown {

        .highlight {
            display: block;
        }
    }

    // dropdown menu styling
    .dropdown {
        display: none;
        width: $width-full;
        z-index: $zindex-navigation-menus;

        &.selected {
            display: block;
            position: absolute;
            left: $width-full;
            top: 0;
        }

        .row {
            position: relative;
            height: $height-full;

            .js & {
                background-color: $navigation-dropdown-mobile__bgcolor;

                @media screen and (min-width: $bp-desktop__minimum) {
                    box-shadow: $navigation-dropdown__boxshadow;
                    background-color: $navigation-menu__bgcolor;
                }
            }
        }

        ul {
            width: $width-full;
            display: block;
            position: relative;
            margin: 0;

            .no-js & {
                background-color: $navigation-menu__bgcolor;
            }

            .js & {
                @include rem-calc(55 0 24, padding);

                @media screen and (min-width: $bp-desktop__minimum) {
                    @include rem-calc(72 0 24, padding);

                    // styling changes for second and fourth column, js only
                    &[data-depth="1"],
                    &[data-depth="3"] {
                        background-color: $navigation-column__bgcolor;

                        >li:hover>a,
                        >li:focus>a {
                            background-color: $white;
                        }
                    }
                }
            }

            @media screen and (min-width: $bp-desktop__minimum) {
                width: 25vw;
            }

            @media screen and (min-width: $bp-content) {
                @include rem-calc(300, max-width);
                width: $width-full;
            }

            ul {
                position: absolute;
                display: none;
                left: $width-full;
                top: 0;
            }
        }

        li {
            @include zero;

            .no-js & {
                position: relative;
            }

            &+li {
                border-top: $navigation-menu-item-mobile__bordertop;

                @media screen and (min-width: $bp-desktop__minimum) {
                    border-top: none;
                }
            }

            @media screen and (min-width: $bp-desktop__minimum) {

                &:hover,
                &:focus,
                &.touch-selected {

                    >ul {
                        display: block;

                        .js & {
                            height: $height-full;
                        }
                    }

                    >a {
                        background-color: $navigation-column__bgcolor;
                    }
                }
            }
        }

        a {
            @include rem-calc(16 24, padding);
            @include fontsize(15);
            display: block;
            position: relative;
            color: $navigation-menu-item__color;
            font-family: $navigation-menu-item__fontfamily;

            .no-js & {
                @include rem-calc(8 24, padding);
            }

            .js & {

                &:not(.dropdown-headline) {

                    @media screen and (min-width: $bp-desktop__minimum) {
                        @include rem-calc(8 54 8 24, padding); // additional padding to accommodate the child menu marker
                    }
                }
            }

            &:active,
            &:visited {
                color: $navigation-menu-item__color;
            }

            &.dropdown-headline {

                @media screen and (min-width: $bp-desktop__minimum) {
                    z-index: $zindex-navigation-menus;
                }
            }
        }
    }

    .dropdown-headline {

        .no-js & {
            display: none;
        }

        .js & {
            @include rem-calc(24 0 0 24, padding);
            @include fontsize(18);
            position: absolute;
            top: 0;
            left: 0;
            font-family: $font-heading-h4_fontfamily;
            font-weight: $font-heading-h4_fontweight;
            line-height: 1;
            display: none;

            @media screen and (min-width: $bp-tablet__landscape) {
                @include fontsize(24);
                display: block;
            }
        }
    }

    // specific styling for list elements that have a child menu
    .has-child {

        >a {
            @include rem-calc(16 80 16 24, padding);

            & .arrow {
                @include disc-arrow(24px, 24px, $navigation-chevron__right);
                @include rem-calc(30, right);
                transform: translateY(-$half);

                @media screen and (min-width: $bp-desktop__minimum) {
                    @include disc-arrow(20px, 20px, $navigation-chevron__right);
                    @include rem-calc(24, right);
                    opacity: 0.33;
                }
            }
        }

        @media screen and (min-width: $bp-desktop__minimum) {

            &:hover,
            &:active,
            &:focus {

                &>a .arrow {
                    @include rem-calc(16, right);
                    opacity: 1;
                }
            }
        }

        &.selected {

            >ul {
                display: block;

                .js & {
                    height: $height-full;
                }
            }
        }
    }

    .child-toggle {
        @include rem-calc(80, width);
        height: 80%;
        position: absolute;
        display: block;
        right: 0;
        top: $half;
        transform: translateY(-$half);
        z-index: $zindex-navigation-toggles;
        border-left: $navigation-menu-item-mobile__borderleft;

        @media screen and (min-width: $bp-desktop__minimum) {
            border-left: none;
        }
    }

    .search-inline {
        @include rem-calc(0 32 0, padding);
        @include rem-calc($navigation-topbar__height, top);
        @include animation(all);
        align-items: center;
        z-index: $zindex-navigation-menus;
        width: $width-full;
        height: 0;
        overflow: hidden;
        position: absolute;
        left: 0;
        background: $navigation-search-inline__bgcolor;

        @media screen and (min-width: $bp-desktop__minimum) {
            @include rem-calc(160, max-width);
            position: relative;
            display: flex;
            height: auto;
            top: auto;
            padding: 0;
            background-color: transparent;
        }

        fieldset {
            position: relative;
        }

        &.active {
            @include rem-calc(32, padding);
            height: calc(#{$viewport-height-max} - #{rem-calculation($navigation-topbar__height)});
        }
    }

    .search-filters {
        @include rem-calc(16, margin-bottom);

        .legend-head,
        .label-head {
            text-align: center;
            font-family: $navigation-toplevel__fontfamily; // set this to soemthing else later
            width: $width-full;
            display: block;
        }

        .legend-head {
            @include fontsize(20);
            // @include css-variables(color, section-accent, $navigation-sectionaccent__bgcolor);
            color: $eurus-brand-blue;
        }

        .label-head {
            @include fontsize(16);
        }

        @media screen and (min-width: $bp-desktop__minimum) {
            display: none;
        }
    }

    .search-input {
        @include fontsize(16);
        @include rem-calc(8 $navigation-search-icon-mobile__size 8 12, padding);
        @include rem-calc($navigation-search-icon-mobile__size, height);
        border: $navigation-search-input-mobile__border;
        border-radius: 2px;
        color: $slate-gamma;
        font-family: $search-box__fontfamily;
        width: $width-full;
        @include css-variables(border-color, section-accent, $navigation-sectionaccent__bgcolor);

        @media screen and (min-width: $bp-desktop__minimum) {
            @include rem-calc($navigation-search-icon-desktop__size, height);
            @include fontsize(15);
            @include rem-calc(4 $navigation-search-icon-desktop__size 4 8, padding);
            border: $navigation-search-input__border
        }
    }

    .search-submit {
        @include animation(all);
        @include rem-calc($navigation-search-icon-mobile__size, width);
        @include rem-calc($navigation-search-icon-mobile__size, height);
        @include image-replace;
        background: $eurus-light-blue url($eurus__searchIcon) center center no-repeat;
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        cursor: pointer;
        // @include css-variables(background-color, section-accent, $navigation-sectionaccent__bgcolor);

        &:hover,
        &:active,
        &:focus {
            // background-color: $accenthover;
            background-color: $eurus-light-blue;
        }

        @media screen and (min-width: $bp-desktop__minimum) {
            @include rem-calc($navigation-search-icon-desktop__size, width);
            @include rem-calc($navigation-search-icon-desktop__size, height);
            border: $navigation-search-input__border;
            // background-color: $slate-gamma;
            background-color: $eurus-light-blue;
        }
    }



    // filter styling overrides
    .filters {
        @include rem-calc(16, margin-top);

        input+label {
            @include fontsize(14);
        }

        ul {

            @media screen and (min-width: 540px) {
                display: flex;
                justify-content: center;
            }
        }

        li {

            @media screen and (min-width: 540px) {
                width: auto;

                &+li {
                    @include rem-calc(8, margin-left);
                    margin-top: 0;
                }
            }
        }

        input[type='radio']:checked~label,
        input[type='checkbox']:checked~label {
            @include css-variables(background-color, section-accent, $navigation-sectionaccent__bgcolor);
            @include css-variables(border-color, section-accent, $navigation-sectionaccent__bgcolor);
        }

        .filter-labels {
            @include css-variables(color, section-accent, $navigation-sectionaccent__bgcolor);
            @include css-variables(border-color, section-accent, $navigation-sectionaccent__bgcolor);
        }
    }

    .controls {
        display: flex;
        z-index: $zindex-navigation-topbar-elements;
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;

        .search-toggle,
        .search-close,
        .menu-toggle,
        .menu-close {
            @include size(40);
            border-radius: 4px;
            cursor: pointer;
        }

        .menu-toggle {
            background: transparent url($navigation__menu) center center no-repeat;
        }

        .menu-close {
            background: transparent url($navigation__menu-close) center center no-repeat;
        }

        .search-toggle {
            // background: $slate-gamma url($search__icon) center center no-repeat;
            background: $eurus-light-blue url($eurus__searchIcon) center center no-repeat;
        }

        .search-close {
            // background: $slate-gamma url($navigation__menu-close) center center no-repeat;
            background: $eurus-light-blue url($eurus__closeIcon) center center no-repeat;
            margin-left: 0;
        }

        >*+* {
            @include rem-calc(8, margin-left);
        }

        @media screen and (min-width: $bp-desktop__minimum) {
            display: none;
        }
    }

    .section-tabs {
        position: absolute;
        width: $width-full;

        .row {
            padding: 0;
        }

        .tab {
            background-color: $navigation-sectionaccent__bgcolor;
            @include rem-calc(2, letter-spacing);
            @include rem-calc(6 16, padding);
            @include fontsize(14);
            display: inline-block;
            text-transform: uppercase;
            color: $navigation-sectionaccent__color;
            font-family: $navigation-sectionaccent__fontfamily;
        }
    }

    .menu,
    .search-inline {

        &.index-adjust {
            z-index: $zindex-navigation-menus__index-adjusted;
        }
    }

    /* --------- // PRIMARY NAVIGATION STYLES ---------- */

    /* --------- SECONDARY NAVIGATION STYLES ---------- */

    .secondary {

        @include rem-calc(8 0, padding);
        // @include css-variables(background-color, secondarynav-accent, $navigation-secondary__bgcolor);
        background-color: $eurus-white;
        color: $eurus-brand-blue;
        display: none;

        @media screen and (min-width: $bp-desktop__minimum) {
            display: block;
        }

        li {
            list-style-type: none;
            margin-bottom: 0;

            &+li {
                @include rem-calc(32, margin-left);
            }
        }

        a {
            @include fontsize(13);
            color: $eurus-brand-blue;
            font-family: $navigation-secondary__fontfamily;

            &:active,
            &:hover,
            &:focus,
            &:visited {
                color: $eurus-brand-blue;
            }
        }

        .row {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .section-home {
            flex-grow: 1;
        }

        .section-label {
            @include rem-calc(26, padding-right);
            @include rem-calc(2, letter-spacing);
            @include fontsize(13);
            display: inline-block;
            font-weight:bold;
            text-transform: uppercase;
            color: $eurus-brand-blue;
            position: relative;
            font-family: $navigation-unit-title__fontfamily;
        }

        .menu-secondary {
            @include zero;
            display: flex;
            justify-content: flex-end;
        }
    }

    /* --------- // SECONDARY NAVIGATION STYLES ---------- */
}

/* body#htmlbody {
    &.menu-opened {
        .navigation {
            .primary>.row:before {
                background: $eurus-brand-blue;
            }
        }
    }
}  */