.tabs{
    margin: 1.25rem 0 2.5rem;
    width: 100%;
    background: $white;
    .tabButtons{
        align-items: stretch;
        border-bottom: 1px solid $tabBtns_border;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        text-align: center; 
    }
    .tabLinks{
        @include rem-calc(16, font-size);
        @media screen and (min-width: $bp-tablet__landscape) {
            @include rem-calc(21, font-size);
        }
        font-family: 'Derailed-Bold';
        padding: 1rem;
        align-items: center;
        color: $tabLinks_colour;
        cursor: pointer;
        display: flex;
        justify-content: center;
        position: relative;
        text-align: center;
        transition: .2s;
        width: 100%;
        text-decoration:none;
        font-weight:600;
        &.active{
            color: $tabLinks_colour--active;
        }
        &.active:after{
            background: $tabLinks_active_bkground;
            bottom: 0;
            content: "";
            display: block;
            height: 4px;
            left: 0;
            position: absolute;
            width: 100%;
        }
    }
    .tabContent.open{
        display: block;
    }
    .tabContent{
        display: none;
        padding:1rem .15rem;
        @media screen and (min-width: $bp-tablet__landscape) {
            padding: 1rem .15rem;
        }
    }
}