.findMoreLink{
    @include rem-calc(7.5, padding-bottom);
    display:block;
    &.align-right{
        text-align:right;
        margin-right:26px;
    }
    a{
        color: #0080A5;
        @include rem-calc(17, font-size);
        font-family: 'Derailed-Bold';
        @include chevron();
        position: relative;
        &:hover, &:focus{
            text-decoration: underline;
        }
        &:visited{
            color: $global-hyperlink-visited__color;
            &:after{
                color: $global-hyperlink-visited__color;
            }
        }
        @media screen and (min-width: $bp-tablet__portrait) {
            @include rem-calc(18, font-size); 
        }
        &:after{
            font-weight:600;
            @include rem-calc(19.9, line-height); 
            @include rem-calc(21, font-size);
            @media screen and (min-width: $bp-tablet__portrait) {
                @include rem-calc(23.2, line-height);
            }
        }
    }
}
.eurus {
    .findMoreLink {
        a {
            color: $eurus-mid-blue;
            &:hover, &:focus{
                text-decoration: none;
            }
            &:visited{
                color: $eurus-teal-alt;
                &:after{
                    color: $eurus-teal-alt;
                }
            }

        }
    }
    .bgPresent {
        &.brandDefault {
            a {
                &:not(.cta){
                    color: $eurus-light-blue;
                    &:hover {
                        color: $eurus-bright-blue;
                    }
                    &:visited {
                        color: $eurus-teal;
                        &:after {
                            color: $eurus-teal;
                        }
                    }
                }
            }
        }
    }
}