.careersResearchersForm{
    padding:1rem 0;
    .spinnerBox{
        display:block;
        text-align:center;
        margin:3rem 0;
        span{
            color: $clearing-blue;
            @extend %fa-icon;
            @extend .fas;
            @include spinIcon(1s, $fa-var-spinner);
            @include rem-calc(60, font-size);
        }
    }
    .toggleBox{
        display:none;
        text-align:right;
        margin-bottom:10px;
        a{
            text-decoration: none;
            @extend %fa-icon;
            @extend .fas;
            color: #4a4a4a;
            border: 1px solid #b3b3b3;
            padding: 0.5rem;
            border-radius: 3px;
            cursor: pointer;

            &:before {
                content: fa-content($fa-var-filter);
            }
        }
    }
    &.wpd-form{
        margin-bottom:0; 
        .genericForm{
            display:none;
            margin-top:0;
        }
        .genericForm{
            margin-top: 10px;
            background: #eeeeee;
            padding: 1rem;
            border-radius: 4px; 
            .genericSearchBox{
                flex-wrap: wrap;
                justify-content: flex-start;
            }

            .genericSearchBox > div{
                @media screen and (min-width: $bp-tablet__portrait) {
                    @include rem-calc(10 0 0 15, margin);
                }
            }
        }
    }
}
.careersResearchersList{
    .personList{
        .person {
            margin-top: .7rem;
            padding: 1.2rem 0;
            border-top: 1px solid #eeeeee;
            .personInfo {
                @media screen and (min-width: $bp-desktop__minimum) {
                    display: flex;
                    justify-content: space-between;
                }
    
                &__table{
                    width:100%;
                    @media screen and (min-width: $bp-desktop__minimum) {
                        width:60%;
                    }
                    table{
                        td{
                            @media screen and (max-width: $bp-desktop__minimum){
                                max-width:0;
                            }
                        }
                    }
                }
    
                .category {
                    flex: 1;
                    padding-top: 0;
                    margin-left:0;
                    list-style-type: none;
                    @media screen and (min-width: $bp-desktop__minimum) {
                        margin-left:3rem;
                        list-style-type: disc;
                    }
                }
            }
        }
        &.column-grid{
            display:flex;
            flex-wrap:wrap;
            .person{
                flex: 50%;
                padding:2rem;
            }
        }
    }
    &.wpd-list{
        display:none;
        margin-top:0;
        .personList{
            .person{
                padding:1.5rem 0;
                h4{
                 padding-bottom:.5rem;
                }
                .personInfo{
                    &__table{
                        ul{
                            margin-left:0;
                            list-style-type: none;
                        }
                    }
                }
            }
        }
    }
}