// Modal Window Styles  

.modalWindow {

    .modal {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        opacity: 0;
        visibility: hidden;
        transform: scale(1.1);
        transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
        z-index: 100;               // sit above all content
    }
  
    .modalContent {
        @include rem-calc(24, padding);
        @include rem-calc(4, border-radius);
        
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $white;
        width: 90%;   
        max-height: 70%;
        overflow-y: auto;

            @media screen and (min-width: $bp-tablet__landscape) {
                @include rem-calc(32, padding);

                width: 50%; 
            }

        video{
           position:relative;
           width: 100%;
        }

        .videoWrapper{
            cursor: pointer;
        }

        .playpause {
            cursor: pointer;
            z-index:1;
            background: #00523e url("/webtemplate/img/icons/ncuvision__icon-play.svg") left 60% center no-repeat;
            border-radius:50%;
            height: 5.6875rem;
            width: 5.6875rem;
            position:absolute;
            left:0%;
            right:0%;
            top:0%;
            bottom:0%;
            margin:auto;
            background-size: auto 60%;
            opacity:.8;
            transition: opacity .3s ease-in-out;
            &:hover{
                opacity:1;
            }
        }

    }
    
    .close-button {                     
        @include fontsize(32);
        @include rem-calc(0 6, padding);
        @include rem-calc(16, line-height);

        position: absolute;
        top: 2px;
        right: 2px;
        font-family: $font-bariol; 
        color: $slate-epsilon;
        background-color: transparent;
        cursor: pointer;
        transition: color 0.5s ease;

        @media screen and (min-width: $bp-tablet__landscape) { 
            @include fontsize(48);
            @include rem-calc(0 8, padding);
            @include rem-calc(24, line-height);
           
        }

        &:hover{
            color:#d42222;
        }
    }

    .show-modal {
        opacity: 1;
        visibility: visible;
        transform: scale(1.0);
        transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
    }
}