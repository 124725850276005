.eurus {
    .ctaBanner {
        .bannerContainer {
            display: flex;
            height: auto;
            width: $width-full;         /* NEW */
            position: relative;
            background-color: $imagepanel-bgcolor;
    
            @media screen and (min-width: $bp-desktop__minimum) {
                @include rem-calc($promoBanner_min-height, min-height);
                @include rem-calc($promoBanner_max-height, max-height);
            }
    
            &:before {
                background: linear-gradient(270deg, rgba(142, 216, 248, 0.60) 0%, rgba(0, 58, 101, 0.60) 100%), url(<path-to-image>), lightgray 50% / cover no-repeat;
                background-blend-mode: normal, luminosity;
            }
        }
        
        .row {
            display: flex;
        }
    
        .banner-wrapper {
            display: flex;
            flex-direction: column;
            width: $width-full;
            z-index: 2;
            @include rem-calc(20, padding);
    
            @media screen and (min-width: $bp-desktop__minimum) {
                flex-direction: row;
                justify-content: space-between;
                @include rem-calc(40, padding);
            }

            .text-container {
                width: 100%;
                color: $eurus-white;
                @include rem-calc(20, padding-bottom);
                @media screen and (min-width: $bp-desktop__minimum) {
                   padding-bottom: 0;
                }
                h2 {
                    color: $eurus-white;
                }
            }

            .ctaContainer {
                justify-content: flex-end;
                width: 100%;
                .ctaColumn {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    align-items: flex-end;
                    gap: 10px;
                    max-width: 100%;
                    @media screen and (min-width: $bp-desktop__minimum) {
                        @include rem-calc(100, padding-left);
                        max-width: 80%;
                    }

                    .cta {
                        margin: 0;
                        width: 100%;
                    }
                }
            }
        }
        .backgroundOverlay {
            left: 0;
            top: 0;
            width: 100%;
            position: absolute;
            height: 100%;
            z-index: 1;
            background: linear-gradient(270deg,rgb(142,216,248),rgb(0,58,101));
            opacity: 0.6;
        }
        .image-overlay {
            left: 0;
            top: 0;
            width: 100%;
            position: absolute;
            height: 100%;

            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}