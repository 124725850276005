.languageSelector{
    background:#007a74;
    padding:1.2rem 0;
    h3{
        color:$white;
    }
    ul{
        padding: 0;
        display: flex;
        flex-wrap:wrap;
        list-style-type: none;
        margin-left: 0;
        li {
            span{
                display:none;
            }
        }
        @include not-last-child('li'){
            span{
                display:inline;
                margin:.2rem;
                color:$white;
            }
        }
        a{
            color:$white;
        }
    }
}