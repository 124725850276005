.rssFeedComponent {
    #rss-search {
        padding-bottom: 0;

        .searchBox {
            margin-bottom: .5rem;
        }

        .spinnerBox {
            display: block;
            text-align: center;
            margin: 3rem 0;

            span {
                color: $clearing-blue;
                @extend %fa-icon;
                @extend .fas;
                @include spinIcon(1s, $fa-var-spinner);
                @include rem-calc(60, font-size);
            }
        }
    }

    .rssFilters {
        ul {
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            @include rem-calc(-5, margin);

            li {
                flex: 1 1 19%;
                @include rem-calc(-5 5 5 5, margin);

                @media screen and (max-width: $bp-desktop__minimum) {
                    flex: 0 1 30%;
                }

                .cta {
                    @media screen and (max-width: $bp-desktop__minimum) {
                        width: 100%;
                        padding: 0;
                        margin: 0;
                        @include rem-calc(16, font-size);
                        @include rem-calc(38, line-height);
                    }

                    border: 1px solid #4a4a4a !important;
                    text-align: center;
                    padding: 0 1rem;
                    margin: 0;
                    width:100%;
                    @include rem-calc(22, font-size);
                    @include rem-calc(57, line-height);

                    &.active {
                        background-color: #4a4a4a;
                        color: #fff;
                    }

                    &::after {
                        display: none;
                    }
                }

            }
        }
    }
    .promo{
        margin-bottom:2rem;
        h4{
            margin:1rem 0;
        }
        .description{
            height:77px;
            @include rem-calc(77, min-height);
            overflow: hidden;
            margin-bottom: 1.5rem;
        }
        .readMore{
            text-align:right;
            margin:1rem 0;
            a{
                @include rem-calc(16, font-size);
            }
        }
        &.show{
            @media screen and (min-width: $bp-desktop__minimum){
                margin-right: 1.375rem;
            }
        }
    }
}