// Carousel Variables

$gallery-media-lighttheme__bgcolor: $generic-light;
$gallery-media-darktheme__bgcolor: $generic;

$gallery-slide__height: 560px;
$gallery-slide-mobile__spacing: 48;
$gallery-slide__spacing: 20;
$gallery-slide-arrow__bgcolor: $generic;
$gallery-slide-arrow__opacity: 0.8;
$gallery-slide-video__width: 996px;
$gallery-slide-video__minheight: 560px;
$gallery-slide-video__height: $gallery-slide-video__minheight;
$gallery-slide-caption-title__texttransform: none;
$gallery-slide-description-leading__fontweight: 700;
$gallery-slide-overlay-gradientfrom__bgcolor: $gradient-bottom;
$gallery-slide-overlay-gradientto__bgcolor: $gradient-top;

$gallery-original__height: 375;

$gallery-original-arrow__color: $generic;
$colourA-gallery-original-arrow__color: $brand-primary;
$colourB-gallery-original-arrow__color: $brand-secondary;
$colourC-gallery-original-arrow__color: $brand-tertiary;