// Dual Panel
.dualPanel {
    height: auto;

    @media screen and (min-width: $bp-desktop__minimum) {
        min-height: auto;
    }

    &.breakout-container {
        @include rem-calc(50 0, margin);

        @media screen and (min-width: $bp-desktop__minimum) {
            @include rem-calc(80 0 50 0, margin);
        }

        .panelContainer .panel-wrapper {
            min-height: auto;

            @media screen and (min-width: $bp-desktop__minimum) {
                @include rem-calc(350, min-height);
            }
        }
    }

    &.reverse {
        .panelContainer {
            .panelContainerInner {
                @media screen and (min-width: $bp-desktop__minimum) {
                    flex-direction: row-reverse;
                }

                .introColumn {
                    &:first-of-type {
                        margin: 0;
                    }
                }
                
            }
        }
    }


    .panelContainer {
        display: block;
        position: relative;

        @media screen and (min-width: $bp-desktop__minimum) {
            display: flex;
        }
    }

    &.panelPanelGradient {
        .panelContainer {
            .row {
                display: grid;
                gap: 10px;
                grid-template-columns: repeat(1, minmax(0, 1fr));

                @media screen and (min-width: $bp-tablet__small) {
                    gap: 20px;
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                }

                .panel-wrapper {
                    width: 100%;
                    margin-bottom: 0;
                    @include rem-calc(280, height);
                    @media screen and (min-width: $bp-desktop__minimum) {
                        @include rem-calc(355, height);
                    }

                    &:hover {
                        .headline {
                            text-decoration: underline;
                            &::after {
                                left: 12px;
                            }
                        }
                    }
                }

                &.min-height-fit {
                    min-height: auto;
                }
            }
        }
    }

    .row {
        display: block;
        justify-content: space-between;
        overflow: visible;

        @media screen and (min-width: $bp-desktop__minimum) {
            display: flex;
        }

        &.reverse {
            flex-direction: row-reverse;
        }
    }

    .panel-wrapper {
        @include rem-calc(24, padding);
        @include rem-calc(20, margin-bottom);

        align-items: center;
        background-color: $panel_background-colour;
        display: block;
        height: auto;
        overflow: visible;
        position: relative;
        width: $width-full;

        @media screen and (min-width: $bp-desktop__minimum) {
            display: flex;
            flex-direction: column;
            align-items: start;
            margin-bottom: 0;
            width: 49%;
        }

        &:hover,
        &:visited,
        &:active {
            text-decoration: none;
        }

        // target any dual panel side that has a [href]
        &:not(.no-breakout)[href] {
            .headline {
                // @include chevron($line-height: 0.8);
                &::after {
                    font-family: 'Font Awesome\ 5 Free';
                    content: '\f061';
                    position: relative;
                    display: inline-block;
                    left: 8px;
                    transition: left 0.3s ease;                   
                }
                
            }
        }

        &.no-breakout[href] {
            .headline {
                // @include chevron($line-height: 1.1);
                &::after {
                    font-family: 'Font Awesome\ 5 Free';
                    content: '\f061';
                    position: relative;
                    display: inline-block;
                    left: 8px;
                    transition: left 0.3s ease;                    
                }

                &:hover {
                    &::after {
                        left: 12px;
                    }
                }

            }
        }

        &.media {
            @include rem-calc(50, margin-bottom);
            @include videoOverride();

            background: none;
            overflow: hidden;
            padding: 0;

            @media screen and (min-width: $bp-desktop__minimum) {
                @include rem-calc(0, margin-bottom);
            }
        }

        &.quote {
            background: none;
            align-items: start;
            padding-top: 0;
            padding-bottom: 0;

            .quote-container {
                width: 100%;
            }
        }

        &.no-breakout {
            background-color: $imagepanel-bgcolor;
            background-position-y: center;
            background-repeat: no-repeat;
            background-size: cover;
            display: block;
            padding: 0;
            min-height: 18.75rem;
            @media screen and (min-width: $bp-mobile__landscape) {
                @include rem-calc(300, min-height);
            }

            .gradient {
                /**
                bottom: 0;
                position: absolute;
                display: flex;
                width: $width-full;
                background-color: rgba(0, 58, 101, 0.93);
                @include rem-calc(130, height);
                @media screen and (min-width: $bp-mobile__landscape) {
                    @include rem-calc(125, height);
                }
                **/
                background-color: transparent;
                background-image: linear-gradient(0deg,rgba(5,20,53,.8),transparent);
                bottom: 0;
                display: flex;
                width: 100%;
                position: absolute;
                height: 100%;
                @media screen and (min-width: 30rem) {
                    background-color: transparent;
                    background-image: linear-gradient(0deg,rgba(5,20,53,.8),transparent);
                    height: 75%;
                    position: absolute
                }
            }

            .text-container {
                /**
                justify-content: flex-start;
                @include rem-calc(10, padding);
                @media screen and (min-width: $bp-desktop__minimum) {
                    @include rem-calc(20, padding);
                }
                **/
                padding: .84375rem 1.25rem;
                justify-content: flex-end;

                @media screen and (min-width: 30rem) {
                    padding:1.5rem;
                }
            }

            .headline {
                /**
                color: $eurus-white;
                font-family: $font-derailed__extrabold;
                @include rem-calc(0 0 5 0, padding);
                @include fontsize(18);
                @media screen and (min-width: $bp-desktop__minimum) {
                    @include fontsize(22);
                }
                **/
                color: #fff;
                line-height: 1.3;
                padding: 0;
                @media screen and (min-width: 64rem) {
                    .dualPanel .headline {
                        font-size: 2rem;
                    }
                }
            }

            p {
                /**
                color: $eurus-white;
                font-family: $font-sans-serif;
                @include fontsize(12);
                @media screen and (min-width: $bp-desktop__minimum) {
                    @include fontsize(16);
                }
                **/

                color: #fff;
                font-size: .875rem;
                padding: 1rem 0 0;
                @media screen and (min-width: 30rem) {
                    .dualPanel .panel-wrapper.no-breakout p {
                        font-size: 1rem;
                        padding: 1.25rem 0 0;
                    }
                }
            }
        }
        &.no-background {
            background-color: transparent;
        }
    }

    .media-container {
        @include rem-calc(20, margin-bottom);
        @include videoOverride();

        width: $width-full;

        @media screen and (min-width: $bp-desktop__minimum) {
            @include rem-calc(0, margin-bottom);
        }
 
        &.youtubeVid {
            left: 0;
            position: absolute;
            top: 0;
        }

        .videoOverlay {
            img {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .videoBlock {
        @include videoOverride();

        margin: 0;
    }

    .video-container {
        @include videoOverride();

        .videoOverlay {
            left: 0;
            width: $width-full;
        }

        .fluid-width-video-wrapper {
            height: $height-full;
        }
    }

    .image-container {
        @include rem-calc(-24, margin-bottom);

        bottom: 0;
        display: block;
        position: relative;
        width: $width-full;

        @media screen and (min-width: $bp-desktop__minimum) {
            @include rem-calc(0, margin-bottom);

            position: absolute;
            right: 0;
            width: $half;
        }

        &:only-child {
            width: $width-full;
        }
    }

    .panel-img {
        @include rem-calc($panel_banner_image_mobile_height, height);
        @include rem-calc(16, margin-top);

        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        width: $width-full;

        @media screen and (min-width: $bp-desktop__minimum) {
            @include rem-calc($panel_banner_image_height, height);

            margin-top: 0;
        }
    }

    .text-container {
        @include fontsize(18);

        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        width: $width-full;

        @media screen and (min-width: $bp-desktop__minimum) {
            @include rem-calc(24, margin-right);

            width: $half;
        }

        &:only-child {
            margin-right: 0;
            width: $width-full;
        }

        p {
            color: $panel_text_colour;
        }
    }

    .headline {
        @extend .h2;

        @include fontsize(20);

        color: $panel_text_header_colour;
        line-height: 1;

        @media screen and (min-width: $bp-tablet__landscape) {
            @include fontsize(32);
        }

        &:only-child {
            padding: 0;
        }

        &:after {
            line-height: 0.8;
        }
    }

    .row.min-height-fix {
        height: auto;
        min-height: auto;

        @media screen and (min-width: $bp-desktop__minimum) {
            @include rem-calc(10, height);
            @include rem-calc(350, min-height);

            display: flex;
        }
    }
}

.eurus {
    .dualPanel {
        margin: 0;
        padding: 1.25rem 0;
        @media screen and (min-width: $bp-desktop__minimum) {
            padding: 2.5rem 0;
        }

        a {
            color: $eurus-mid-blue;
            text-decoration: none;
            &:hover {
                color: $eurus-brand-blue;
                text-decoration: underline;
            }
            &:visited {
                color: $eurus-teal-alt;
            }
        }

        &.brandDefault {
            background-color: $eurus-brand-blue;
            color: $eurus-white;
            h2, h3, h4, h5, p {
                color: $eurus-white;
            }
            a {
                color: $eurus-light-blue;
                &:hover {
                    color: $eurus-bright-blue;
                }
                &:visited {
                    color: $eurus-teal;
                }
            }
               
        }
        &.baseTintFive {
            background-color: $eurus-base-tint-quinternary;
        }
        .panel-wrapper {
            &.no-breakout {
                .gradient {
                    //background-color: $eurus-dualpanel-overlay;
                    @media screen and (min-width: $bp-mobile__landscape) {
                        // background-color: transparent;
                        // background-image: none;
                    }
                }
            }
            &.media {
                height: auto;
                &.videoPanel {
                    margin-bottom: 0;
                }
                &.reverse {
                    @media screen and (min-width: $bp-desktop__minimum) {
                    order: 1;                        
                    }

                    .introColumn {
                        &:first-of-type {
                            @media screen and (min-width: $bp-tablet__portrait) {
                                margin: 0;
                            }
                            &.introText{
                                @media screen and (min-width: $bp-desktop__minimum) {
                                    padding-left: 0;
                                }
                            }
                        }
                    }

                }
            }

            .media-container {

                display: flex;
                flex-direction: column;
                justify-content: center;

                img {                                   /* NEW TO FIX IMG HEIGHT */
                    height: 100%;
                }
                @media screen and (min-width: $bp-desktop__minimum) {
                    img {
                        height: auto;
                    }
                }

                &.fullImg {
                    img {                         
                        height: 100%;
                    }
                }
            }

            .introColumn {

                .cta {

                    color: $eurus-brand-blue;

                    &::after {
                        font-family: 'Font Awesome\ 5 Free';
                        content: '\f061';
                        position: absolute;
                        right: 20px;
                        transition: right 0.3s ease;
                    }
        
                    &:hover {
        
                        background-color: $eurus-dark-blue;
                        color: $eurus-white;
                        text-decoration: none;
        
                        &::after {
                            right: 15px;
                        }
                    }
                }

                .additionalLinks {

                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .additionalLink {
                        font-family: $font-derailed__extrabold;
                        color: $eurus-mid-blue;
                        @include fontsize(18);
                        text-decoration: none;

                       
                            &::after {
                                font-family: 'Font Awesome\ 5 Free';
                                content: '\f061';
                                position: absolute;
                                right: 20px;
                                transition: right 0.3s ease;
                            }

                            &:hover {
            
                                &::after {
                                    right: 15px;
                                }
                            }
                        
                    }

                }

            }



        }
        .row {
            &.min-fix-height {
                height: auto;
            }
        }
        .media-container {
            margin-bottom: 0;

            img {
                object-fit: cover;
            }

            &.reel-video-wrapper {

                justify-content: start;

                .reel-video {

                    @include rem-calc(100, margin-left);
                    overflow: hidden;
                    border-radius: 20px;
                    border: 2px solid $eurus-base-tint-quaternary;

                    .videoOverlay {
                        img {
                            height: 100%;
                        }
                        &::after {
                            width: 5rem;
                            height: 5rem;
                            opacity: 1;
                            background: rgba(142, 216, 248, .8) url(/webtemplate/img/icons/eurus-icons/eurus-play-icon.svg) left 60% center no-repeat;
                        }
                    }
                }
            }



        }

        &.fullSize {
            margin: 0;
            padding: 0;

            &.bgPresent {
                &.baseTintFive {
                    background-color: $eurus-base-tint-quinternary;                   
                }
                &.brandDefault {
                    background-color: $eurus-brand-blue;
                    h2 {
                        color: $eurus-white;
                    }
                    p {
                        color: $eurus-white;
                    }
                }
            }

            .panelContainer {
                display: block; 
 
                .panelInnerContainer{ 
                    display: flex; 
                    flex-direction: column;
                    justify-content: space-between; 
                    width: 100%; 
                    max-width: 100%; 
                    height: auto; 
                    padding: 0;
                    @media screen and (min-width: $bp-desktop__minimum) {
                        flex-direction: row;
                    }

                    .panel-wrapper {
                        margin-bottom: 0;
                    }

                    .introColumn {
                        &.introText {
                            padding: 1.25rem;
                            @media screen and (min-width: $bp-desktop__minimum) {
                                padding: 2.5rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

.dualPanels {
    &.eurusDual {
        height: auto;



        &.brandDefault {
            margin: 0;
            padding: 1.25rem 0;
            @media screen and (min-width: $bp-desktop__minimum) {
                padding: 2.5rem 0;
            }
            background-color: $eurus-brand-blue;
            color: $eurus-white;
            h2, h3, h4, h5, p {
                color: $eurus-white;
            }
            a {
                color: $eurus-light-blue;
                &:hover {
                    color: $eurus-bright-blue;
                }
                &:visited {
                    color: $eurus-teal;
                }
            }
               
        }
        &.baseTintFive {
            margin: 0;
            padding: 1.25rem 0;
            @media screen and (min-width: $bp-desktop__minimum) {
                padding: 2.5rem 0;
            }
            background-color: $eurus-base-tint-quinternary;
        }


        .panelContainerOuter {
            display: block;
            position: relative;
    
            @media screen and (min-width: $bp-desktop__minimum) {
                display: flex;
            }

            .panelContainerInner {
                display: grid;
                gap: 10px;
                grid-template-columns: repeat(1, minmax(0, 1fr));

                @media screen and (min-width: $bp-tablet__small) {
                    gap: 20px;
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                }

                &.reverse {
                    @media screen and (min-width: $bp-desktop__minimum) {
                        
                        .panel {
                            &:nth-child(1) {
                                order: 2
                            }
                            &:nth-child(2) {
                                order: 1
                            }
                        }

                    }
                }

                .panel {
                    width: 100%;
                    margin-bottom: 0;
                    position: relative;
                    border-radius: 5px;
                    overflow: hidden;

                    &.gradient {

                        @include rem-calc(280, height);
                        @media screen and (min-width: $bp-desktop__minimum) {
                            @include rem-calc(355, height);
                        }

                        .panelContent{
                            bottom: 0;
                            position: absolute;
                            display: flex;
                            width: $width-full;
                            background-color: rgba(0, 58, 101, 0.93);
                            @include rem-calc(130, height);
                            @media screen and (min-width: $bp-mobile__landscape) {
                                @include rem-calc(125, height);
                            }

                            .text-container {
                                justify-content: flex-start;
                                padding: 1rem;

                                .headline {
                                    color: $eurus-white;
                                    font-family: $font-derailed__extrabold;
                                    @include rem-calc(0 0 5 0, padding);
                                    @include fontsize(18);
                                    line-clamp: 2;
                                    @media screen and (min-width: $bp-desktop__minimum) {
                                        @include fontsize(22);
                                        line-clamp: 1;
                                    }

                                    &::after {
                                        font-family: 'Font Awesome\ 5 Free';
                                        content: '\f061';
                                        position: relative;
                                        display: inline-block;
                                        left: 8px;
                                        transition: left 0.3s ease;                   
                                    }
                                }
                    
                                p {
                                    color: $eurus-white;
                                    font-family: $font-sans-serif;
                                    line-height: 1.2rem;
                                    line-clamp: 3;
                                    @include fontsize(12);
                                    @media screen and (min-width: $bp-desktop__minimum) {
                                        line-clamp: 2;
                                        @include fontsize(16);
                                    }
                                }                            
                            }
                        }
 
                    }

                    &.media {
                        .media-container {
                            height: 100%;

                            img {
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                    &.quote {
                        .quote-container {
                            width: 90%;
                            @include rem-calc(20 0, padding);
                            margin: 0 auto;
                            position: relative;
                            @media screen and (min-width: $bp-tablet__small) {
                                padding: 0;
                                top: 10px;   
                            }
                            @media screen and (min-width: $bp-tablet__small) and (max-width: 948px) {
                                width: 80%;
                            }
                            
                        }
                    }
    
                    &.video {
                        
                        .mediaContainer {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            height: 100%;

                            .videoBlock {
                                width: 100;
                                height: 100%;
                                flex-grow: 1;

                                .videoContainer {

                                    min-height: 250px;
                                    @media screen and (min-width: $bp-tablet__portrait) {
                                        min-height: none;  
                                    }
                                    height: 100%;
                                    display: flex;
                                    align-items: center;

                                    .fluid-width-video-wrapper {
                                        height: $height-full;
                                    }

                                    .videoOverlay {
                                        width: 100%;
                                        left: 0;

                                        img {
                                            height: 100%;
                                            object-fit: cover;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &:hover {
                        .panelContent {
                            .text-container {
                                .headline {
                                    text-decoration: underline;
                                    &::after {
                                        left: 12px;
                                    }
                                }                            
                            }                            
                        }


                    }
                }

            }

        }

    }
}