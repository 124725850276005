.contentContainer {

    display: flex;

    #sideBar {

        display: none;
        min-width: 20%;          
        background-color: $global-brand-blue;
        margin: 0;
        @include rem-calc(50 20, padding);
        @include rem-calc(345, max-width);
        @media screen and (min-width: $bp-desktop__minimum) {
            display: block;
        }
        &.linkSideBar {

            #sideBarMenu {
                border-left: 2px solid #0A97FF;
            }      
            .sideMenuContainer {
    
                a {
                    display: block;
                    text-decoration: none;
                    color: #CCE9FF;
                    @include rem-calc(15 0, margin);
                    @include rem-calc(25, padding-left);
                    @include fontsize(16);
                    @include rem-calc(19, line-height);
                    font-weight: 400;
                    font-family: $font-sans-serif;
                    cursor: pointer;
                    
                    &.active {
                    font-weight: 700;
                    border-left: 2px solid #F5F4F4;
                    color: #ffffff;
                    position: relative;
                    left: -2px;
                    }
                    &:hover {
                        text-decoration: underline;
                    }
                    &:first-of-type {
                        margin-top: 0;
                    }
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
    
        }

        &.progressSideBar {
            .sideMenuContainer {
                #sideBarMenu {
                    display: flex;

                    .progress-bar-wrapper {
                        position: relative;
                        display: flex;
                        left: 20px;

                        .progress-bar {

                            display: flex;
                            flex-direction: column;
                            flex: 1;
                            width: 5px;
                            background-color: $eurus-mid-blue;

                            &.progress-bar-cover {
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                background-color: $eurus-teal;
                                height: 0;
                            }

                            .step-wrapper {

                                display: flex;
                                position: relative;
                                min-height: 60px;
        
                                .step {
                                    .step-number {
                                        background-color: $eurus-mid-blue;
                                        color: $eurus-white;
                                    }
                                }
                                .step-number {
                                    position: relative;
                                    width: 35px;
                                    height: 35px;
                                    min-width: 35px;
                                    border-radius: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-weight: 600;
        
                                }
        
                                &:last-of-type {
                                    a {
                                        margin-bottom: 0;
                                    }
                                }
        
                                &.passed {
                                    .step-number {
                                        background-color: $eurus-teal;
                                        color: $eurus-dark-blue;
                                        &::before {
                                            background-color: $eurus-teal;
                                        }
                                    }
                                }
        
        
                                /* Keyframes to animate the gradient fill from top to bottom */
                                @keyframes gradient-fill {
                                    0% {
                                    background: linear-gradient(0deg, $eurus-mid-blue 0%, blue 100%);
                                    }
                                    100% {
                                    background: linear-gradient(180deg, blue 0%, teal 100%);
                                    }
                                }
        
                            }
                        }

                    }

                    .menu-wrapper {

                        &.test {
                            a {

                                display: flex;
                                margin-left: 20px;
                                padding-left: 0;
                                position: relative;

                                .step-number {
                                    position: absolute;
                                    top: 0;
                                    left: -20px;
                                    width: 35px;
                                    height: 35px;
                                    min-width: 35px;
                                    border-radius: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-weight: 600;
                                    background-color: $eurus-mid-blue;
                                    color: $eurus-white;
                                }
                                
                                .side-menu-item-text {
                                    position: relative;
                                    left: 25px;
                                    width: 93%;
                                }

                                &.passed {
                                    .step-number{
                                        background-color: $eurus-teal;
                                        color: $eurus-dark-blue;
                                    }
                                }

                            }

                        }


                        a {
                            display: block;
                            text-decoration: none;
                            color: $eurus-light-blue;
                            @include rem-calc(15, padding-left);
                            @include rem-calc(4, padding-top);
                            @include fontsize(16);
                            @include rem-calc(19, line-height);
                            height: 60px;
                            margin-top: 0;
                            font-weight: 400;
                            font-family: $font-sans-serif;
                            cursor: pointer;
                            
                            &.active {
                                color: $eurus-white;
                            }
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }

        .sideMenuContainer {

            display: flex;
            flex-direction: column;
            position: sticky;
            @include rem-calc(40, top);

            h3 {
                @include fontsize(27);
                @include rem-calc(31.62, line-height);
                color: white;
                font-family: $font-derailed__extrabold;      
            }
        }

    }

    #mainContent {

        width: 100%;
        margin: 0;

        #mobileSideBar {

            width: 100%;
            border-radius: 0px;
            @include rem-calc(10 15 10 15, padding);
            position: sticky;
            top: -1px;
            left: 0;
            background: #ffffff;
            z-index: 10;
            box-shadow: 2px 4px 10px rgba(0,0,0,.44);

            @media screen and (min-width: $bp-desktop__minimum) {
                display: none;
            }

            &.active {
                background: $global-brand-blue;
                height: 100vh;
                position: fixed;
                top: 0;
                left: -2px;
                z-index: 100;
            }

            .mobileSidebarLinkContainer {
                list-style-type: none;
                margin: 0;
                border-left: 2px solid #0A97FF;
                @include rem-calc(25 15 30 0, margin);
            }

            a {
                margin: 0;
                font-weight: 700;
                color: #CCE9FF;
                background-color: inherit;
                width: 100%;
                display: block;
                text-decoration: none;
                line-height: 1rem;
                margin: 0.75rem 0;
                cursor: pointer;
                @include fontsize(14);
                @include rem-calc(3 0 3 15, padding);
                &.active {
                    border-left: 2px solid #ffffff;;
                    color: #ffffff;
                    background-color: inherit;
                    position: relative;
                    left: -2px;
                }
            }

            #mobileSideBarBurgerOpen span::before {
                content: "\f0ca";
                font-family: Font Awesome\ 5 Free;
                font-weight: 900;
                vertical-align: -0.125em;
                text-align: center;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                font-size: 20px;
                color: white;
            }

            #mobileSideBarBurgerClose {

                border: 1px solid #ffffff;

                span::before {
                    content: "\f00d";
                    font-family: Font Awesome\ 5 Free;
                    font-weight: 900;
                    vertical-align: -0.125em;
                    text-align: center;
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    font-size: 20px;
                    color: white;
                }
            }

            .mobileHeaderContainer {
            
                button {
                    width: 100%;
                    border-radius: 10px;
                    display: flex;
                    justify-content: space-between;
                    background: #fff;
                    font-family: $font-derailed__regular;               
                    background-color: $global-brand-blue;
                    color: white;
                    @include rem-calc(11 20, padding);
                    @include fontsize(18);
                    @include rem-calc(21, line-height);
                    font-weight: 700;
                    &:focus{
                        outline: 4px dashed #007494;
                    }

                }
                h4 {
                    padding: 0;
                    color: white;
                    font-weight: 800;
                }

              }

              .menuFixed {
                height: 100vh;
                position: fixed;
                top: 0;
                left: 0;
                transition: 0.4s;
              }

              .preventScroll {
                overflow: hidden;
                pointer-events: none;
              }

        }


    }
}






.eurus {
    #sideBar {
        background-color: $eurus-dark-blue;

        #sideBarMenu {
            border-left: 2px solid $eurus-mid-blue;
        }

    }

    .sideMenuContainer {

        h3 {
            color: $eurus-white;  
        }

        a {
            color: $eurus-light-blue; 
            &.active {
            border-left: 2px solid $eurus-white; 
            color: $eurus-white; 
            } 
        } 
    } 
} 