.uspContainer {

    &.backgroundPresent  {
        background-color: $eurus-base-tint-quinternary;
        @include rem-calc(30, padding-top);
        @include rem-calc(30, padding-bottom);

        @media screen and (min-width: $bp-tablet__portrait) {
            @include rem-calc(60, padding-top);
            @include rem-calc(60, padding-bottom);
        }
    }

    .USP {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        @include rem-calc(20, gap);

        @media screen and (min-width: $bp-tablet__small) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            @include rem-calc(35, gap);
        }

        @media screen and (min-width: $bp-desktop__minimum) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            @include rem-calc(50, gap);
        }

        &.three-col{
            @media screen and (min-width: $bp-desktop__minimum) {
                grid-template-columns: repeat(3, minmax(0, 1fr));
                @include rem-calc(50, gap);
            } 
        }

        .uspItem {

            .uspItemInner {
                border-left: 8px solid $eurus-teal;
                padding-left: 12px;

                .uspHeading {
                    display: block;
                    font-family: $font-derailed__extrabold;
                    @include fontsize(21);
                    color: $eurus-brand-blue;
                    line-height: normal;

                    @media screen and (min-width: $bp-tablet__portrait) {
                        @include fontsize(30);
                    }

                    @media screen and (min-width: $bp-desktop__minimum) {
                        @include fontsize(35);
                    }
                } 
                .uspSubheading {
                    display: block;
                    font-family: $font-derailed__regular;
                    font-weight: 600;
                    line-height: normal;
                    @include fontsize(15);
                    color: $eurus-teal-alt;
                    padding-top: 0.5rem;

                    @media screen and (min-width: $bp-tablet__portrait) {
                        @include fontsize(18);
                        padding-top: 0.8rem;
                    }
                }
            }
        }
    }
}