// Article Grid - article grid with filters

$articles-items__border: 1px solid $smoke-alpha;

$articles-label-featured__color: $linktext;


// Article List - simple news listing

$articles-items-headline__color: $brand-accent;

$articles-items-date__color: $brand-accent;

$articles-items-date__font: $font-derailed__bold;
