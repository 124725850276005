
// Article List & Filtering - grid version with filters

.articleGrid {
    .row {
        padding: 0;

        @media screen and (min-width: $bp-desktop__minimum) {
            @include rem-calc(0 15, padding);
        }
    }

    .articles {
        @include rem-calc($standard-unit 0, padding);

        @media screen and (min-width: $bp-desktop__minimum) {
            @include rem-calc($standard-unit * 2 0, padding);
        }

        .featured {
            @include rem-calc(32, margin-bottom);

            .no-js & {
                display: none;
            }

            &:empty {
                display: none;
            }

            .item {
                @include rem-calc(15, padding);

                background-color: $generic-light;

                @media screen and (min-width: $bp-desktop__minimum) {
                    background-color: transparent;
                    padding: 0;
                }
            }

            .link {
                background-color: $generic-light;
                height: 100%;

                @media screen and (min-width: $bp-desktop__minimum) {
                    display: flex;
                    flex-direction: row;
                }
            }

            .image {
                @include rem-calc(320, min-height);

                height: $height-full;

                @media screen and (min-width: $bp-desktop__minimum) {
                    @include rem-calc(320, max-height);

                    padding-top: 0;
                    width: $half;
                }
            }

            .content {
                @include rem-calc($standard-unit, padding-top);

                @media screen and (min-width: $bp-desktop__minimum) {
                    @include rem-calc($standard-unit * 1.25 $standard-unit * 2, padding);

                    width: $half;
                }

                // create a 'Featured' label using a pseudo element (extend .label and update padding to match)
                &:before {
                    @include rem-calc($standard-unit/2, margin-bottom);
                    @include rem-calc(3, letter-spacing);
                    @include fontsize(14);

                    color: $articles-label-featured__color;
                    content: 'Featured';
                    display: inline-block;
                    font-weight: 600;
                    text-transform: uppercase;
                }
            }

            .headline {
                @include fontsize(20);
                @include rem-calc($standard-unit, margin-bottom);

                font-family: $font-article-featured;
                font-weight: 400;

                @media screen and (min-width: $bp-desktop__minimum) {
                    @include fontsize(28);

                    margin-bottom: 0;
                }
            }

            .summary {
                display: none;

                @media screen and (min-width: $bp-desktop__minimum) {
                    display: block;
                }
            }

            .labelContainer {
                @include rem-calc(15, top);
                @include rem-calc(15, left);

                @media screen and (min-width: $bp-desktop__minimum) {
                    left: 0;
                    top: 0;
                }
            }
        }

        .list {
            @include rem-calc(0 15, padding);

            @media screen and (min-width: $bp-desktop__minimum) {
                padding: 0;
            }

            .js & {
                font-size: 0; // removes whitespace between page elements
                overflow: hidden;
                white-space: nowrap;

                // hide all .item on initial load
                > .item {
                    display: none;
                }
            }

            .no-js & {
                @include createArticleList();
            }

            .link {
                min-height: 100%;

                @media screen and (min-width: $articles-filters-breakpoint) and (max-width: adjust-breakpoint($bp-desktop__minimum)) {
                    flex-direction: row;
                }
            }

            .item {
                @media screen and (min-width: $articles-filters-breakpoint) and (max-width: adjust-breakpoint($bp-desktop__minimum)) {
                    @include rem-calc($standard-unit, padding-top);

                    border-top: $articles-items__border;

                    &:last-child {
                        @include rem-calc($standard-unit, padding-bottom);

                        border-bottom: $articles-items__border;
                    }
                }

                &.noContent {
                    @include fontsize($fontsize-body);

                    width: $width-full;
                }
            }

            .image {
                @include rem-calc(200, min-height);

                @media screen and (min-width: $articles-filters-breakpoint) and (max-width: adjust-breakpoint($bp-desktop__minimum)) {
                    @include rem-calc(200, max-width);
                    @include rem-calc(200, max-height);

                    height: $viewport-width-onethird;
                    padding-top: 0;
                    width: $viewport-width-onethird;
                }
            }

            .headline {
                @include fontsize(18);

                font-family: $font-article-list;
                font-weight: 400;

                @media screen and (min-width: $bp-desktop__minimum) {
                    @include fontsize(22);
                }
            }

            .content {
                @include rem-calc($standard-unit * 1.5, margin-top);

                display: flex;
                flex-direction: column;
                flex-grow: 1; // grow to fill space

                @media screen and (min-width: $articles-filters-breakpoint) and (max-width: adjust-breakpoint($bp-desktop__minimum)) {
                    @include rem-calc(0 0 0 $standard-unit, padding);

                    margin-top: 0;
                    width: $viewport-width-twothirds;
                }
            }

            .label {
                display: none;

                @media screen and (min-width: $bp-desktop__minimum) {
                    display: block;
                }
            }
        }

        .page {
            @include createArticleList(inline-flex);

            display: inline-block;
            vertical-align: top;
            white-space: normal;
            width: $height-full;

            &[data-selected='false'] {
                display: none;
            }
        }

        .item {
            position: relative;
        }

        .link {
            color: $bodytext;
            display: flex;
            flex-direction: column;
            text-decoration: none;

            &:hover,
            &:focus {
                text-decoration: none;
            }

            &:active,
            &:visited {
                color: $bodytext;
            }
        }

        .image {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            padding-top: 50%;
            width: 100%;
        }

        .headline {
            @extend .h2;

            @include chevron();

            line-height: 1.25;
            padding-bottom: 0;

            &:hover {
                text-decoration: underline;
            }
        }

        .summary {
            @include rem-calc($standard-unit, margin-top);

            flex-grow: 1;

            &:empty {
                display: none;
            }
        }

        .pagination {
            @include rem-calc(0 15, padding);

            justify-content: flex-end;

            @media screen and (min-width: $bp-desktop__minimum) {
                padding: 0;
            }

            .no-js & {
                display: none;
            }

            &-top {
                @include rem-calc(0 0 $standard-unit, margin);
            }

            &-bottom {
                @include rem-calc($standard-unit 0 0, margin);
            }
        }

        .js-viewAll {
            text-decoration: underline;
        }
    }
}
