// Structure Variables

// standard unit of measurement, equal to base font size in pixels
$standard-unit: $font-context;

$width-full: 100%;
$height-full: 100%;
$height-three-quarters: 75%;
$three-quarters: 75%;
$quarter: 25%;
$half: 50%;
$one-third: 33%;
$two-thirds: 66%;
$inline-half: 49.5%; // accounts for 4px space between inline-block elements
$column-thirds: 32%;

$sixty: 60%;
$forty: 40%;

$viewport-height-max: 100vh;
$viewport-width-max: 100vw;
$viewport-width-twothirds: 66vw;
$viewport-width-onethird: 33vw;
