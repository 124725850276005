/* ---------------------------------------------
Basic Text
----------------------------------------------*/
/* -----------------
Basic Text Styles
------------------*/
.textArea, .introPanel {
    a {
        &:not(.cta) {
            text-decoration: underline;
        }
        &:hover:not(.cta) { 
            text-decoration: none; 
            background-color: $global-hyperlink-hover__bgcolor; /* New variable */ 
        } 
    }
}

.introPanel {
    .findMoreLink {
        a{
            text-decoration: none;
            &:hover{
                background-color: transparent !important;
                text-decoration: underline;
            }
        }
    }
}

.eurus {
    .textArea, .introPanel {
        a {
            &:not(.cta) {
                text-decoration: none;
                color: $eurus-mid-blue;
            }
            &:hover:not(.cta) {  
                //background-color: $global-hyperlink-hover__bgcolor; /* New variable */ 
                background-color: transparent;
                color: $eurus-brand-blue;
                &:not(.findMoreLink){
                    text-decoration: underline;
                }
            } 
            &:visited:not(.cta){
                color: $eurus-teal-alt;
            }
        }
        :not(.findMoreLink){
            a {
                &:hover:not(.cta) {  
                    text-decoration: underline;
                } 
            }
        }
        .findMoreLink {
            a {
                &:hover:not(.cta) {  
                    text-decoration: none;
                } 
            }
        }
    }
    
    .introPanel {
        .findMoreLink {
            a {
                text-decoration: none;
                &:hover{
                    background-color: transparent !important;
                    text-decoration: none;
                }
            }
        }
    }
    .textArea {
        &.bgPresent {
            margin: 0;
            padding: 1.25rem 0;
            @media screen and (min-width: $bp-tablet__portrait) {
                padding: 2.5rem 0;
            }
            &.baseTintFive {
                background-color: $eurus-base-tint-quinternary;
            }
            &.brandDefault {
                background-color: $eurus-brand-blue;
                // color: $eurus-brand-blue;
                h2, h3, h4, h5, p, ul, li {
                    color: $eurus-white;
                }
                a {
                    &:not(.cta){
                        color: $eurus-light-blue;
                        &:hover {
                            color: $eurus-bright-blue;
                        }
                        &:visited {
                            color: $eurus-teal;
                        }
                    }
                }
                .findMoreLink {
                    color: $eurus-light-blue;
                }
            }   
        }
    }
}