// News Feed Variables

$newsfeed__fontfamily: $font-newsfeed;
$newsfeed-button__fontfamily: $font-newsfeed-button;

$newsfeed-item__color: $white;
$newsfeed-readmore__bgcolor: transparent;
$newsfeed-readmore-hover__bgcolor: $accenthover;

$newsfeed-item__bgcolor: $generic;
$colourA-newsfeed-item__bgcolor: $brand-primary;
$colourB-newsfeed-item__bgcolor: $brand-secondary;
$colourC-newsfeed-item__bgcolor: $brand-tertiary;