.publicLecturesLayout {

    .header {

        padding-bottom: 0;

        .headerContent {
            margin-bottom: 0 !important;
        }

        h1 {
            padding-bottom: 0;
        }
        p {
            font-family: $font-bariol;
            padding-left: 20px !important;
            margin-left: 0 !important;
            @include fontsize(18);
            @media screen and (min-width: $bp-tablet__small) {
                padding-left: 40px !important;
                @include fontsize(25);
            }
        }
    }
 
    .dateTime {
        font-family: $font-sans-serif;
        @include fontsize(16);
        color: $eurus-brand-blue;
        @include rem-calc(30, padding-top);
        @include rem-calc(30, padding-bottom);

        @media screen and (min-width: $bp-tablet__small) {
            @include rem-calc(40, padding-top);
            @include rem-calc(50, padding-bottom);
    }
    }

    .articleContent {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        @include rem-calc(62.5, padding-bottom);

        @media screen and (min-width: $bp-desktop__minimum) {
            @include rem-calc(100, padding-bottom);
        }
        .contentWrapper {
            font-family: $font-sans-serif;
            color: #4A4A4A;
            @include fontsize(14);
            @include rem-calc(23, line-height);

            @media screen and (min-width: $bp-desktop__minimum) {
                @include fontsize(16);
            }

            h2, h3, h4, h5 {
                color: #4A4A4A;
                @include fontsize(16);
                @include rem-calc(23, line-height);
                font-weight: 700;
                @include rem-calc(10, padding-bottom);
                padding-top: 0;

            }

            a {
                &:not(.cta){
                    color: $eurus-mid-blue;
                    text-decoration: underline;
                    cursor: pointer;                 
                }

            }

            p {
                padding: 0;
                @include rem-calc(20, padding-bottom);
            }

            &:last-child {
                &:not(.cta){
                    padding-bottom: 0;
                }
            }

            .cta {
                @include rem-calc(48 0, margin);
                @media screen and (min-width: $bp-desktop__minimum) {
                    margin: 0;
                    @include rem-calc(15, margin-top);
                    min-width: 66%;
                    width: fit-content;
                }
                &::after {
                    content: '\f061';
                }
            }

        }

        &.imagePresent {
            @media screen and (min-width: $bp-desktop__minimum) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
                @include rem-calc(22, column-gap);
            }
        }
    }

    .fullWidthRibbon {

        .cta {
            &.midBlue {
                background-color: $eurus-light-blue;
            }
            &.left {
                @include rem-calc(0 20 0 46, padding);
                border: 1px solid $eurus-light-blue;

                @media screen and (min-width: $bp-desktop__minimum) {
                    @include rem-calc(0 20 0 56, padding); 
                }

                &::after {
                    content: '\f060';
                    @include rem-calc(24, left);
                    transition: left .3s ease-in-out;
                }

                &:hover {
                    background-color: $eurus-dark-blue;
                    border: 1px solid $eurus-dark-blue;
                    &::after {
                        @include rem-calc(18, left);
                    }
                }

            }
            &.right {
                @include rem-calc(0 46 0 20, padding);

                @media screen and (min-width: $bp-desktop__minimum) {
                    @include rem-calc(0 56 0 20, padding); 
                }
                &::after {
                    content: '\f061';
                }
            }
        }

    }
}