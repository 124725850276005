// Media Widget Styles

.mediaWidget {
    @include fontsize(10);

    iframe {
        @include rem-calc(450, height);

        width: $width-full;

        @media screen and (min-width: $bp-tablet__landscape) {
            @include rem-calc(600, height);
        }
    }
}
