// Drop Down Data Widgets styling

.dataWidget {

    & + .dataWidget {
    	@include rem-calc(48, margin-top);
    	@include rem-calc(32, margin-bottom);
    	@include rem-calc(32, padding-bottom);
    	padding-top: 0;
    	border-top: 0px solid transparent;
    }

	* {
		@include box-sizing();
	}

	.row + .row {
		@include rem-calc(16, margin-top);
	}

	select {
		display: none;
	}

	.title {
		@extend .h4;
		padding-bottom: 0;
	}

	.selectWrapper,
	.infoWrapper {
		width: $width-full;
	}

	.infoWrapper {
		@include rem-calc(16, padding);
		@include rem-calc(-4, margin-top);
		border-color: $infopanel__bordercolor;
		border-width: 0 2px 2px 2px;
		border-style: solid;
		border-radius: 0 0 $select-input__borderradius $select-input__borderradius;

		> :first-child {
			margin-top: 0;
			padding-top: 0;
		}

		> :last-child {
			margin-bottom: 0;
			padding-bottom: 0;
		}

		&:empty {
			display: none;
		}
	}

	// Style the dropdown
	.nice-select {
		@include rem-calc($select-dropdown__padding/2 $select-dropdown__padding*3 $select-dropdown__padding/2 $select-dropdown__padding, padding);
		@include fontsize($select__fontsize);
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		background-color: $select-input__bgcolor;
		border-radius: $select-input__borderradius;
		color: $select-input__color;
		cursor: pointer;
		display: block;
		font-family: inherit;
		font-weight: normal;
		outline: none;
		position: relative;
		text-align: left;
		transition: all 0.2s ease-in-out;
		user-select: none;
		white-space: nowrap;
		width: auto;

		&:hover {
			border-color: darken($smoke-gamma, 5%);
		}

		&:active, &:focus {
			border-color: $slate-epsilon;
		}

		// Arrow
		&:after {
			@include rem-calc(16, height);
			@include rem-calc(16, width);
			@include rem-calc(-4, margin-top);
			@include rem-calc(24, right);
			border-bottom: 3px solid $select-arrow__bordercolor;
			border-right: 3px solid $select-arrow__bordercolor;
			content: '';
			display: block; 
			pointer-events: none;
			position: absolute; 
			top: $half;
			transform-origin: 70% 40%;
			transform: rotate(45deg) translateY(-50%);
			transition: all 0.15s ease-in-out;
		}

		.current {
			font-family: $select-current__fontfamily;
		}

		ul {
			font-family: $select-default__fontfamily;
		}

		&.open {
			@extend :active;

			&:after {
				transform: rotate(-135deg);
			}

			.list {
				opacity: 1;
				pointer-events: auto;
				transform: scale(1) translateY(0);
			}
		}

		&.disabled {
			border-color: lighten($smoke-gamma, 2%);
			color: $slate-epsilon;
			pointer-events: none;

			&:after { 
				border-color: lighten($select-arrow__bordercolor, 20%);
			}
		}

		&.right {
			float: right;

			.list {
				left: auto;
				right: 0;
			}
		}

		&.small {
			@include fontsize($select-small__fontsize);
			@include rem-calc($select-small-input__height, height);
			@include rem-calc($select-small-input__height - 2, line-height);

			&:after { 
				@include rem-calc(4, height);
				@include rem-calc(4, width);
			}

			.option {
				@include rem-calc($select-small-input__height - 2, line-height);
				@include rem-calc($select-small-input__height - 2, min-height);
			}
		}

		// List and options
		.list {
			@include rem-calc($select-list__maxheight, max-height);
			@include rem-calc(-1, margin-top);
			background-color: $white;
			color: $black;
			margin-left: 0;
			opacity: 0;
			overflow: hidden;
			padding: 0;
			pointer-events: none;
			position: absolute;
			top: $height-full;
			left: 0;
			transform-origin: 50% 0;
			transform: translateY(- $select-input__height / 2);
			transition: all .2s cubic-bezier(0.5, 0, 0, 1.25), opacity .15s ease-out;
			width: $width-full;
			z-index: 3;
		    overflow-y: auto;
		}

		.option {
			@include rem-calc(1, margin-bottom);
			@include rem-calc($select-input__height - 2, line-height);
			@include rem-calc($select-input__height - 2, min-height);
			@include rem-calc($select-dropdown__padding, padding-left);
			@include rem-calc($select-dropdown__padding + 11, padding-right);
			background-color: $select-dropdown-option__bgcolor;
			cursor: pointer;
			font-weight: 400;
			list-style: none;
			outline: none;
			text-align: left;
			transition: all 0.2s;

			&:hover,
			&.focus { 
				background-color: $select-dropdown-option-highlight__bgcolor;
			}

			&.selected,
			&.selected.focus { 
				font-weight: 700;
				background-color: darken($select-dropdown-option-highlight__bgcolor, 10%);
			}

			&.disabled {
				background-color: transparent;
				color: $slate-epsilon;
				cursor: default;
			}
		}
	}

	// colour theme overrides
	&.colourA {

		.nice-select {
			background-color: $select-input-colourA__bgcolor;
		}

		.infoWrapper {
			border-color: $infopanel-colourA__bordercolor;
		}
	}

	&.colourB {

		.nice-select {
			background-color: $select-input-colourB__bgcolor;
		}

		.infoWrapper {
			border-color: $infopanel-colourB__bordercolor;
		}
	}

	&.colourC {

		.nice-select {
			background-color: $select-input-colourC__bgcolor;
		}

		.infoWrapper {
			border-color: $infopanel-colourC__bordercolor;
		}
	}
}

// Use display instead of opacity for IE <= 10
.no-csspointerevents .nice-select {

	.list {
		display: none;
	}

	&.open {

		.list {
			display: block;
		}
	}
}