@keyframes slide-down-top {
    0% {
        height: 0;
    }
    100% {
        height: 100%;
    }
}
@keyframes slide-in-left {
    0% {
        background-color: transparent;
        transform: translateX(-150%);
    }
    100% {
        transform: translateX(0);
    }
}