.contentContainer {

    display: flex;

    #searchFilterSideBar {

        min-width: 20%;          
        background: $eurus-white;
        margin: 0;
        @include rem-calc(50 30, padding);
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        width: 100%;
        max-width: 100%;
        transform: translateY(-100%);
        transition: 0.5s;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: hidden;
        &.open {
            display: block;
            transform: translateY(0);
            transition: 1.0s;
            overflow-y: scroll;
        }


        @media screen and (min-width: $bp-tablet__portrait){
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1000;
            width: 100%;
            max-width: 100%;
            padding: 6.125rem 1.875rem;
            transform: translateY(-100%);
            &.open {
                display: block;
                transform: translateY(0);
                transition: 1.0s;
            }
        }

        @media screen and (min-width: $bp-desktop__minimum) {
            display: block;
            position: sticky;
            z-index: 2;
            transform: none;
            min-height: 100%;
            height: 100vh;
            overflow-x: auto;
            padding: 0 1.875rem 6.125rem 1.875rem;
            @include rem-calc(345, max-width);
        }

        .sideBarContainer {

            display: flex;
            flex-direction: column;
            position: relative;

            @media screen and (min-width: $bp-desktop__minimum){
                position: sticky;
                @include rem-calc(58, top);
            }

            #sideBarMenu {

                // @include customScrollbar($eurus-mid-blue, 1, $eurus-tertiary-tint-secondary, 10px, 10px, 10px);

                @include customScrollbar2($eurus-mid-blue, 1, $eurus-tertiary-tint-secondary, 8px, 8px, 12px);

                @media screen and (min-width: $bp-desktop__minimum){
                    overflow: auto;
                    @include rem-calc(650, max-height);
                    width: 100%;
                }        
            }

            h3 {
                @include fontsize(27);
                @include rem-calc(31.62, line-height);
                color: $eurus-brand-blue;
                font-family: $font-derailed__extrabold;      
                @media screen and (min-width: $bp-desktop__minimum){
                    padding-top: 1rem;
                }  
            }

            button {

                &#clearFilters {
                    text-align: left;
                    font-weight: 600;
                    color: $eurus-mid-blue;
                
                    &:after {
                        font-family: 'Font Awesome 5 Free';
                        content: '\f00d';
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                
                }

            }

            #clearFilters {
                &[data-filter-count="0"]{
                    display: none;
                }
            }

            .filterContainer {

                @include rem-calc(15 0, padding);
                @media screen and (min-width: $bp-desktop__minimum){
                    margin-right: 18px;
                }

                &.expandable {

                    &:hover {
                        h4 {
                            color: $eurus-mid-blue;
                        }
                        button {
                            &::after {
                                color: $eurus-mid-blue !important;
                            }
                        }
                    }

                    &:not(.open) {
                        .dropdownContainer{
                            //height: 0;
                            //overflow: hidden;
                            display: none;
                            padding-top: 0;
                            transition: 0.4s;
                        }

                        button{
                            &:after {
                                font-family: 'Font Awesome 5 Free';
                                content: "\f078";
                                color: $eurus-brand-blue;
                            }
                        }
                    }

                    &.open {

                        .dropdownContainer{
                            //height: auto;
                            //overflow: visible;
                            display: block;
                            padding-top: 15px;
                            transition: 0.4s;
                        }

                        button {
                            &:after {
                                font-family: 'Font Awesome 5 Free';
                                content: "\f078";
                                color: $eurus-brand-blue;
                                transform: rotate(180deg)
                            }
                        }
                    }
                }

                &.non-expandable {
                    height: auto;
                    overflow: visible;
                    padding-top: 15px;
                }

                &:not(:last-of-type){
                    border-bottom: 1px solid $eurus-tertiary-tint-primary;
                }
                button {

                    display: flex;
                    width: 100%;
                    align-items: center;

                    h4 {
                        @include fontsize(18);
                        font-family: $font-sans-serif;
                        font-weight: 700;
                        color: $eurus-brand-blue;
                        width: 100%;
                        display: flex;
                        padding: 0;

                        span {
                            padding-left: 5px;
                        }
                    }

                }

                .dropdownContainer {
                    padding-top: 15px;
                }

                .checkbox {
                    display: flex;
                    margin-bottom: 12px;
                    cursor:pointer;

                    input[type=checkbox] {
                        border-radius: 5px;
                        border: 1.5px solid $eurus-dark-blue;
                        background: #FFF;
                        height: 24px;
                        width: 24px;
                        min-height: 24px;
                        min-width: 24px;
                        margin-right: 10px;
                        cursor: pointer;

                        &:checked {
                            accent-color: $eurus-mid-blue;
                        }
                    }

                    label {
                        font-family: $font-sans-serif;
                        font-weight: 400;
                        color: #4C4C4C;
                        @include fontsize(16);
                    }
                }

                &#dateFilter {

                    .dateWrapper {

                        display: flex;
                        align-items: center;
                        column-gap: 5px;


                        &:first-of-type {
                            margin-bottom: 10px;
                        }

                        label {
                            width: 2.5rem;
                            white-space: nowrap;
                            font-size: 16px;
                            color: #4C4C4C;
                        }

                        input {
                            flex: 1;
                            border-radius: 10px;
                            border: solid 1px $eurus-dark-blue;
                            padding: 5px;
                            font-size: 16px;
                            color: $eurus-dark-blue;
                            background-color: $eurus-white;
                        }

                        .clear {
                            width: 20px;
                            height: 20px;
                        }                        
                    }


                }
            }


        }

        .sideBarCloseWrapper {

/*             position: absolute;
            width: 100%;
            left: 0;
            background-color: $eurus-mid-blue;
            @include rem-calc(20 15, padding); */

            @media screen and (min-width: $bp-desktop__minimum){
                display: none;
            }

            button {

                border-radius: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0.6875rem 1.25rem;
                font-family: $font-derailed__bold;      
                @include rem-calc(23, line-height);
                @include fontsize(14);
                padding: 0;
                display: flex;
                justify-content: center;
                width: 100%;

                h4 {
                    padding: 0;
                }

                span:first-of-type {
                    font-family: $font-derailed__regular;
                    padding-right: 5px;
                }
                span:nth-of-type(2){
                    font-family: $font-derailed__regular;
                    padding-right: 3px;
                }
                span:nth-of-type(3){
                    font-family: $font-derailed__regular;
                }

                &.cta:after{
                    content: "";
                }

                &:hover {
                    h4 {
                        color: $eurus-white;
                    }
                    span {
                        color: $eurus-white;
                    }
                }

            }

        }

    }

    #mainContent {

        width: 100%;
        margin: 0;

        #mobileSideBar {

            width: 100%;
            border-radius: 0px;
            @include rem-calc(10 15 10 15, padding);
            position: sticky;
            top: -1px;
            left: 0;
            background: #ffffff;
            z-index: 10;
            border-bottom: 1px solid var(--placeholder-for-image, #F5F4F4);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

            @media screen and (min-width: $bp-desktop__minimum) {
                display: none;
            }

            &.active {
                background: $global-brand-blue;
                height: 100vh;
                position: fixed;
                top: 0;
                left: -2px;
                z-index: 100;
            }

            .mobileSidebarLinkContainer {
                list-style-type: none;
                margin: 0;
                @include rem-calc(25 15 30 0, margin);
            }

            a {
                margin: 0;
                font-weight: 700;
                color: #CCE9FF;
                background-color: inherit;
                width: 100%;
                display: block;
                text-decoration: none;
                line-height: 1rem;
                margin: 0.75rem 0;
                cursor: pointer;
                @include fontsize(14);
                @include rem-calc(3 0 3 15, padding);
                &.active {
                    border-left: 2px solid #ffffff;;
                    color: #ffffff;
                    background-color: inherit;
                    position: relative;
                    left: -2px;
                }
            }

            #mobileSideBarBurgerOpen {
                
                h3 {
                    @include rem-calc(23, line-height);
                    @include fontsize(14);
                }
                
                span::before {
                    content: "\f0ca";
                    font-family: Font Awesome\ 5 Free;
                    font-weight: 700;
                    vertical-align: -0.125em;
                    text-align: center;
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    @include rem-calc(23, line-height);
                    @include fontsize(14);
                    color: white;
                }
            }

            #mobileSideBarBurgerClose {

                border: 1px solid #ffffff;

                span::before {
                    content: "\f00d";
                    font-family: Font Awesome\ 5 Free;
                    font-weight: 900;
                    vertical-align: -0.125em;
                    text-align: center;
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    font-size: 20px;
                    color: white;
                }
            }

            .mobileHeaderContainer {
            
                button {
                    width: 100%;
                    border-radius: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background: #fff;
                    font-family: $font-derailed__regular;               
                    background-color: $eurus-mid-blue;
                    color: white;
                    @include rem-calc(11 20, padding);
                    @include fontsize(18);
                    @include rem-calc(21, line-height);
                    font-weight: 700;
                    &:focus{
                        outline: 4px dashed #007494;
                    }
                    &:hover {
                        background-color: $eurus-dark-blue;
                    }

                }
                h3 {
                    padding: 0;
                    color: white;
                    font-family: $font-derailed__bold;
                    font-weight: 700;
                }

              }

              .menuFixed {
                height: 100vh;
                position: fixed;
                top: 0;
                left: 0;
                transition: 0.4s;
              }

              .preventScroll {
                overflow: hidden;
                pointer-events: none;
              }

        }

        .card.course-card {

            &:not(.shown){
                display: none;
            }


            text-decoration: none;
            @include rem-calc(20, padding);
            @media screen and (min-width: $bp-desktop__minimum){
                @include rem-calc(30, padding);
            }

            border-radius: 10px;
            border: 2px solid $eurus-base-tint-primary;
            background: $eurus-white;
            
            /* Card drop shadow */
            box-shadow: 0px 0px 9px 0px $eurus-base-tint-tertiary;


            &:hover {
                &:not(.withdrawn){
                    border: 2px solid $eurus-mid-blue;
                    background: $eurus-white;
                    box-shadow: 0px 0px 9px 0px $eurus-light-blue;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
    
            &:focus {
                background-color: transparent;
                outline: 4px dashed #007494;
                text-decoration: none;
            }
    
            .card__details {
                margin-bottom: 0;
            }

            .subjectAreaIcon {
                align-items: center;
                @include fontsize(16);
                font-weight: 600;
                @include rem-calc(5 0, padding);
                // background-color: #EFEFEF;
                display: inline-flex;
                border-radius: 100px;
                gap: 10px;
                color: $eurus-brand-blue;
                @include rem-calc(24, line-height);
                @include rem-calc(15, margin-top);
            }

            h4 {
                @include rem-calc(10, padding-bottom);
            }
    
            p {
                color: #4A4A4A;
                font-family: $font-sans-serif;
                @include fontsize(16);
    
                &.courseSubject {
                    @include rem-calc(20, padding-bottom);
    
                    span:not(.subject) {
                        font-weight: 600;
                    }
                }
            }
    
            .card__details {
                width: 100%;
    
                &.withdrawn {
                    h4 {
                        color: #4A4A4A;
    
                        &:after {
                            content: "";
                        }
                    }
                    p {
                        color: #4A4A4A;
                        font-family: $font-derailed__bold;
                        @include fontsize(18);
    
                        &:before {
                            font-family: 'Font Awesome\ 5 Free';
                            content: '\f06a';
                            color: #0080A5;
                            padding-right: 5px;
                        }
                    }
                    .expandable {
    
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 1px solid #E5E5E5;
                        @include rem-calc(10, padding-bottom);
    
                        span:after {
                            content: '\f067';
                            font-family: 'Font Awesome\ 5 Free';
                            padding: 5px;
                            background: #f1f1f1;
                            color: #0080A5;
                            border-radius: 100%;
                        }
                    }
                }
    
    
            }
    
        }
    
        .courseSearchWrapper {
    
            .searchCourse {
                border: 1px solid $eurus-dark-blue;
                border-radius: 5px;
                position: relative; // Ensure this is set for absolute positioning of the pseudo-elements to work correctly.
                transition: background-color 250ms ease-in;

                &:after {
                    content: '\f002';
                    font-family: 'Font Awesome 5 Free';
                    font-size: 1.5rem;
                    color: $eurus-brand-blue;
                    position: absolute;
                    transform: translate(-50%, -50%);
                    top: 50%;
                    left: 50%;
                    @media screen and (min-width: $bp-desktop__minimum) {
                        font-size: 1.5rem;
                    }
                    transition: opacity 0.3s ease; // Smooth transition for opacity
                    opacity: 1; // Default visible
                }
            
                &:before {
                    content: '\f002';
                    font-family: 'Font Awesome 5 Free';
                    font-size: 1.5rem;
                    color: $eurus-white;
                    position: absolute;
                    transform: translate(-50%, -50%);
                    top: 50%;
                    left: 50%;
                    @media screen and (min-width: $bp-desktop__minimum) {
                        font-size: 1.5rem;
                    }
                    transition: opacity 0.3s ease; // Smooth transition for opacity
                    opacity: 0; // Default hidden
                }
            
                &:hover {
                    &:after {
                        opacity: 0; // Hide on hover
                    }
            
                    &:before {
                        opacity: 1; // Show on hover
                    }
                }
            }
            
        }
    
    }

    #mobileSidebarHeader {
        margin: 0 auto;
        max-width: 75rem;
        width: 100%;
        margin-top: 0;

        @media screen and (max-width: $bp-desktop__minimum) {
            position: sticky;
            top: 0;
            background: white;
            z-index: 10;
            @include rem-calc(10, padding-top);
        }

        @media screen and (min-width: $bp-desktop__minimum){
            margin-top: 0;
        }

        #searchPrompt{
            margin:0;
            @media screen and (min-width: $bp-desktop__minimum){
                margin-top: 2.5rem;
            }
        }
    
        .courseSearchWrapper {
            display: flex;
            padding: 0 0.9375rem;
            /*@media screen and (min-width: $bp-desktop__minimum){
                @include rem-calc(90, margin-top);
            }*/
            .searchInput {

                @include rem-calc(10 20, padding);
                @include fontsize(16);
                @include rem-calc(10, margin-right);
                font-family: $font-derailed__regular;
                color: #4A4A4A;
                border: 1px solid $eurus-dark-blue;
                font-weight: 600;
                flex-grow: 1;
                width: auto;

                @media screen and (min-width: $bp-tablet__portrait){
                    min-height: 52px;
                }

                @media screen and (min-width: $bp-desktop__minimum){
                    @include rem-calc(11 17, padding);
                    @include fontsize(25);
                    min-height: 64px;
                }

                &:placeholder{
                    font-weight: 400;
                }

                &:active {
                    border-color: $eurus-mid-blue;
                }
            }

            button {

                &.searchCourse {
                    height: 40px;
                    width: 40px;
                    @media screen and (min-width: $bp-tablet__portrait){
                        height: 52px;
                        width: 52px;
                    }
                    @media screen and (min-width: $bp-desktop__minimum){
                        height: 64px;
                        width: 64px;
                    }
                    &:after {
                        padding: 0;
                    }
                    &:hover {
                        background-color: $eurus-dark-blue;
                    }
                    &::after {
                        &:hover {
                            color: $eurus-white;
                        }
                    }
                }
                
            }
        }
    }
    .searchResultCount {
        padding: 1.875rem 0.9375rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include fontsize(16);
        
        label {

            overflow: hidden;
            position: relative;
            display: block;
            padding: 4px;

            &::after {
                content: "\f0d7";
                font-family: 'Font Awesome 5 Free';
                position: absolute;
                pointer-events: none;
                color: $eurus-brand-blue;
                right: 1rem;
                top: 13px;
                font-size: 1.25rem;

                &:hover {
                    color: $eurus-mid-blue;
                }
            }

            select {
                display: flex;
                color: #4A4A4A;
                @include fontsize(16);
                @include rem-calc(10 20, padding);
                justify-content: space-between;
                align-items: center;
                flex-shrink: 0;
                border-radius: 5px;
                border: 1px solid #4A4A4A;
                background: #FFF;
                appearance: none;
                min-width: 12.5rem;
                min-height: 3.25rem;
            }
        }
        .resultCount {
            font-weight: 600;
            color: $eurus-brand-blue;
        }

        .sort-by {
            font-family: $font-derailed__extrabold; 
            color: $eurus-brand-blue;
        }
    }
    .spinnerBox{
        display:block;
        text-align:center;
        margin:3rem 0;
        grid-column: span 2 / span 2;
        span{
            color: $clearing-blue;
            @extend %fa-icon;
            @extend .fas;
            @include spinIcon(1s, $fa-var-spinner);
            @include rem-calc(60, font-size);
        }
    }
    .invisible { 
        visibility: hidden;
    }
    
}