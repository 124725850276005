.alertLink {
    @include rem-calc(10, padding);
    font-family: $font-derailed__bold;
    color: $eurus-mid-blue;
    border: 1px solid $eurus-mid-blue;
    min-height: 55px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    @include fontsize(18);

    @media screen and (min-width: $bp-desktop__minimum) {
        @include fontsize(22);
    }
    .alertIcon {
        align-self: start;

        &::before {
            content: url('/webtemplate/img/icons/eurus-icons/eurus-info-icon-mid-blue.svg');
            height: 24px;
            display: block;
            margin-right: 10px;
            transform: translateY(10%);
            color: $eurus-mid-blue;
        }  
    }
    .alertTextWrapper {
        line-height: 1.3;
        .alertText {
            color: $eurus-mid-blue;
            line-height: 1.2;
        }
        .alertArrow {
            &::after {
                font-family: Font Awesome\ 5 Free;
                content: "\f061";
                font-size: 18px;
                text-decoration: none;
                position: relative;

                @media screen and (min-width: $bp-desktop__minimum) {
                    font-size: 20px;
                }
            }
        }
    }
    &:hover {
        text-decoration: none; /* Ensure the link itself is not underlined */
        
        .alertText {
            color: $eurus-mid-blue;
            text-decoration: underline; /* Ensure the span itself is not underlined */
            text-underline-offset: 2px;

            &:after {
                color: $eurus-mid-blue;
                text-decoration: none !important; /* Ensure the after pseudo-element is not underlined */
                left: 5px;
                transition: all ease-in-out 0.2s;
            }
        }
        .alertArrow {
            &::after {
                color: $eurus-mid-blue;
                text-decoration: none; /* Ensure the after pseudo-element is not underlined */
                left: 5px;
                transition: all ease-in-out 0.2s;
            }
            
        }
    }
    &:visited {
        color: $eurus-mid-blue;
    }
}
