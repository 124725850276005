// Pagination Styles

.pagination {
    @include fontsize(10);
    @include rem-calc(10 0 10, padding);

    display: flex;
    flex-wrap: wrap;
    justify-content:flex-end;

    &:empty {
        display: none;
    }
}

.paginationButton {
    @include fontsize(18);
    @include rem-calc(40, min-height);
    @include rem-calc(40, min-width);

    -webkit-appearance: none;
    background: none;
    border: 1px solid $pagination__bordercolor;
    border-radius: 0;
    color: $pagination__color;
    cursor: pointer;
    flex: 0 1;
    font-family: $pagination__fontfamily;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0;
    text-align: center;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: darken($pagination-disabled__bgcolor, 5%);
    }

    &.disabled {
        background-color: $pagination-disabled__bgcolor;
        color: $pagination-disabled__color;
        cursor: default;
    }

    & + & {
        border-left: none;
    }
}

.paginationButtonPrev,
.paginationButtonNext {
    @include fontsize(24);
    @include rem-calc(6.4, padding-bottom);

    font-family: $pagination-arrows__fontfamily;
}


.paginationWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    align-items: center;
    justify-content: center;
    .paginationButtons {
        
        display: flex;
        column-gap: 10px;

        .paginationNumberButtons {
            display: flex;
            column-gap: 10px;
        }

        .navigationButton {
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            color: $eurus-brand-blue;
            background-color: $eurus-light-blue;

            &[disabled] {
                pointer-events: none;
                color: $eurus-base-tint-tertiary;
                background-color: $eurus-base-tint-quaternary;
            }

        }
        .pageButton {
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            border: 1px solid $eurus-base-tint-primary;
            line-height: 23.1px;
            font-size: 16px;
            color: $eurus-mid-blue;
            font-weight: 400;

            &[disabled] {
                pointer-events: none;
                color: #4A4A4A;
                border: none;
            }
        }

    }
    .pageCountWrapper {
        p {
            padding: 0;
        }
    }

}