// Baseline Global Elements

*,
*:before,
*:after {
    @include box-sizing;

    -webkit-backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-size-adjust: 100%;
    word-wrap: break-word;
}

* {
    &:focus {
        outline: none;
    }
}

html,
body {
    @include zero;

    height: $height-full;
    width: $width-full;
}

html {
    font-size: 100%; // all font sizes must be calculated by the formula (target/16)
    overflow-y: overlay;
}

body {
    @include fontsize(14);
    @include customScrollbar($white, $scrollbar__opacity, $slate-delta);

    background-color: $body__bgcolor;
    color: $font-body__color;
    font-family: $font-primary;
    font-weight: $font-body__fontweight;
    line-height: $line-height;

    @media screen and (min-width: $bp-tablet__portrait) {
        @include fontsize(18);
    }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    @include rem-calc(12 0, padding);

    color: $heading__color;
    display: block;
    font-family: $font-heading;
    font-weight: $font-heading__fontweight;
    line-height: $line-height-adjust;
    position: relative;
    text-transform: $font-heading__texttransform;
    font-variant-ligatures: none;
    font-variant-numeric: normal;

    @media screen and (min-width: $bp-tablet__landscape) {
        @include rem-calc(24 0, padding);
    }
}

.h2,
.h3,
.h4,
.h5,
.h6 {
    &:first-child {
        margin-top: 0;
        padding-top: 0;
    }
}

.h1 {
    @include fontsize(30);

    font-family: $font-heading-h1_fontfamily;
    font-weight: $font-heading-h1_fontweight;

    @media screen and (min-width: $bp-tablet__landscape) {
        @include fontsize(54);
    }
}

.h2 {
    @include fontsize(20);

    font-family: $font-heading-h2_fontfamily;
    font-weight: $font-heading-h2_fontweight;

    @media screen and (min-width: $bp-tablet__landscape) {
        @include fontsize(40);
    }
}

.h3 {
    @include fontsize(20);

    color: $heading-h3__color;
    font-family: $font-heading-h3_fontfamily;
    font-weight: $font-heading-h3_fontweight;

    @media screen and (min-width: $bp-tablet__landscape) {
        @include fontsize(40);
    }
}


.h4 {
                   
    @include fontsize(18);

    color: $heading-h4__color;
    font-family: $font-heading-h4_fontfamily;
    font-weight: $font-heading-h4_fontweight;

    @media screen and (min-width: $bp-tablet__landscape) {
       
        @include fontsize(24);

    }
}

.h5,
.h6 {                                           /* CMcD NEW */

    @include fontsize(16);
    font-family: $font-heading-h5_fontfamily;
    font-weight: $font-heading-h5_fontweight;

    @media screen and (min-width: $bp-tablet__landscape) {

        @include fontsize(16);

    }
}


h1 {
    @extend .h1;
}

h2 {
    @extend .h2;
}

h3 {
    @extend .h3;
}

h4 {
    @extend .h4;
}

h5 {
    @extend .h5;
}

h6 {
    @extend .h6;
}

img {
    display: block;
    height: auto;
    width: $width-full;
}

p,
ol,
ul {
    @include fontsize(14);

    font-family: $font-primary;

    @media screen and (min-width: $bp-tablet__portrait) {
        @include fontsize(16);
    }
}

ol,
ul,
p,
pre {
    @include rem-calc(15 0, padding);

    h1 + &,
    h2 + &,
    h3 + &,
    h4 + &,
    h5 + &,
    h6 + &,
    & + & {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }
}

ul,
ol {
    @include rem-calc(48, margin-left);
}

// add additional margins to nested lists
ol ol,
ul ul,
ol ul,
ul ol {
    @include rem-calc(20, margin-left);
    @include rem-calc(5.5, margin-top);

    padding-top: 0;
}

li {
    @include rem-calc(5.5, margin-bottom);

    &:last-child {
        margin-bottom: 0;
    }
}

fieldset {
    border: 0;
    margin: 0;
    outline: 0;
    padding: 0;
}

blockquote {
    @include rem-calc(8 16, padding);

    border-left: $global-htmlcontent-blockquote__borderleft;
    color: $global-htmlcontent-blockquote__color;
    font-family: $global-htmlcontent-blockquote__fontfamily;
    font-weight: $global-htmlcontent-blockquote__fontweight;
    margin: 0;

    p {
        @include zero;
    }
}

cite {
    @include fontsize($standard-unit - 2);

    color: $global-htmlcontent-cite__color;
    display: block;
}

dl {
    dt {
        font-weight: 600;
    }

    dd + dt {
        @include rem-calc($standard-unit/2, margin-top);
    }
}

table {
    @include rem-calc($standard-unit * 2, margin-bottom);

    border-collapse: collapse;
    width: $width-full;
    background-color: $white; 

    thead {
            
        border-bottom: 1px solid $eurus-brand-blue;

        th {
            @include rem-calc(10, padding);
            background-color: $eurus-white;
            color: #4A4A4A;
            font-family: $font-derailed__bold;
            @include fontsize(15);
            border: none;
            text-align: left;

            @media screen and (min-width: $bp-tablet__portrait) {
                @include fontsize(18);
            }
        }
    }

    th,
    td {
        // Set a min width on table cells so that they're touch accessible
        @include rem-calc($standard-unit * 4, min-width);
        @include rem-calc($standard-unit / 2, padding);

        // border: $global-htmlcontent-table__border;

        @media screen and (min-width: $bp-tablet__landscape) {
            min-width: auto;
        }
    }
    
    tbody {
        tr {
            border-bottom: 1px solid $eurus-brand-blue;
            
            td {
                @include rem-calc(10, padding);
                border: none;  
                @include fontsize(14);
                font-family: $font-sans-serif;
                @media screen and (min-width: $bp-tablet__portrait) {
                    @include fontsize(16);
                }
            }
        }
    }

    caption {
        font-weight: 600;
    }

    &.data-table{
        caption{
            color:$table__data-table-caption__color;
            background-color: $table__data-table-caption__bgcolor;
        }
    }
}

a {
    color: $global-hyperlink__color;
    text-decoration: none;

    &:visited {
        color: $global-hyperlink-visited__color;
    }

    &:hover,
    &:focus {
        color: $global-hyperlink-hover__color;
        text-decoration: underline;
    }
}

pre {
    @include fontsize(14);

    font-family: monospace;
}

iframe {
    width: $width-full;
}

button {
    background: none;
    border: none;
    border-radius: 0;
    cursor: pointer;
    font-family: $font-primary;
    font-weight: 600;
    margin: 0;
    padding: 0;
    text-decoration: none;
    white-space: nowrap;
}

main {
    background-color: $body__bgcolor;
}

.lastSection {
    &.gallery.dark,
    &.imageBanner {
        margin-bottom: 0;
    }
}

.fit-vids-style {
    display: none !important;
}

::selection {
    background: $selection__bgcolor;
    color: $selection__color;

    .colourA & {
        color: $selection-primary__bgcolor;
    }

    .colourB & {
        color: $selection-secondary__bgcolor;
    }

    .colourC & {
        color: $selection-tertiary__bgcolor;
    }
}

.eurus h1 {
    @include fontsize(28);
    @media screen and (min-width: $bp-tablet__portrait) {
        @include fontsize(56); 
    }
}
.eurus h2 {
    @include fontsize(24);
    @media screen and (min-width: $bp-tablet__portrait) {
        @include fontsize(35);
    }
}
.eurus h3 {
    font-family: $font-heading__heavy;
    @include fontsize(21);
    @media screen and (min-width: $bp-tablet__portrait) {
        @include fontsize(27); 
    }
}
.eurus h4 {
    @include fontsize(18);
    @media screen and (min-width: $bp-tablet__portrait) {
        @include fontsize(22); 
    }
}
.eurus h5 {
    @include fontsize(15);
    @media screen and (min-width: $bp-tablet__portrait) {
        @include fontsize(18); 
    }
}
.eurus p {
    @include fontsize(14);
    @media screen and (min-width: $bp-tablet__portrait) {
        @include fontsize(16); 
    }
}
.eurus {
    .h1,
    .h3,
    .h4,
    .h5,
    .h6 {
        color: $eurus-brand-blue;
    }
    .h2 {
        color: $eurus-dark-blue;  
    }
}