// Master Structure

// Declarations --------------------------

.row {
    @include rem-calc(0 15, padding);

    margin: 0 auto;
    max-width: $bp-content;
    width: 100%;

    & & {
        padding-left: 0;
        padding-right: 0;
    }
}

section {
    @include rem-calc($section-margin-mobile 0, margin);

    @media screen and (min-width: $bp-tablet__landscape) {
        @include rem-calc($section-margin-desktop 0, margin);
    }

    &.no-margin-tb{
        margin:0;
    }

    &.no-margin-top{
        margin-top:0;
    }

    &.no-margin-bottom{
        margin-bottom:0;
    }

    // first element of section
    & > :first-child {
        margin-top: 0;
    }

    main & {
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}