.studyLevels {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    @include rem-calc(10, gap);

    @media screen and (min-width: $bp-tablet__portrait) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        @include rem-calc(22, gap);
    }

    @media screen and (min-width: $bp-desktop__minimum) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .studyLevelLink {
        font-family: $font-derailed__semibold;
        color: $eurus-mid-blue;
        @include fontsize(15);

        @media screen and (min-width: $bp-desktop__minimum) {
            @include fontsize(18);
        }

        &::after {
            font-family: 'Font Awesome\ 5 Free';
            content: '\f061';
            position: relative;
            right: -10px;
            transition: right 0.3s ease;
        }

        &:hover,
        &:focus {
            text-decoration: none;
            color: $eurus-mid-blue;

            &::after {
                right: -15px;
            }
        }

    }

}