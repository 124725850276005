details {

    &[open]{
        summary {
            .chevron {
                &:after {
                    content: "\f077" !important;
                }
            }
        }
    }
  
    &.accordionBanner {
        border-bottom: solid 1px;
        border-color: $eurus-tertiary-tint-primary;
  
        summary {
            position: relative;
            display:flex;
            @include rem-calc(55, min-height);
            cursor: pointer;
            user-select: none;
            @include rem-calc(10 50 10 0, padding);
          
          &::marker {
          content: "";
          }
          &:hover {
              .chevron {
                  background-color: $eurus-dark-blue;
                  color: $white;
              }
          }
            .summaryText {
                font-family: $font-derailed__bold;
                color: $eurus-brand-blue;
                text-decoration: none;
                top: 50%;
                @include rem-calc(10, left);
                display: flex;
                align-items: center;
                @include fontsize (18);
                @media screen and (min-width: $bp-desktop__minimum) {
                    @include fontsize(22);
                }
                padding: 0;
                &::before {
                    @include rem-calc(8, margin-right);
                    font-family: Font Awesome\ 5 Free;
                    font-size: 26px;
                }
            }
            .chevron {
                display: block;
                position: absolute;
                text-decoration: none;
                color: $eurus-dark-blue;
                @include rem-calc(10, top);
                @include rem-calc(10, right);
                @include rem-calc(35, width);
                @include rem-calc(35, height);
                border-radius: 5px;
                &::after {
                    content: "\f078";
                    font-family: Font Awesome\ 5 Free;
                    position: absolute;
                    transform: translate(-50%, -50%);
                    top: 55%;
                    left: 50%;
                    @media screen and (min-width: $bp-tablet__portrait) {
                        top: 50%;
                    }
                }
                background-color: $eurus-light-blue;
                &:hover {
                    background-color: $eurus-dark-blue;
                    color: $white;
              }
            }
        }
        .accordionContent {
            @include rem-calc(10 10 20 10, padding);
            p {
                padding-top: 0;
                &:empty {
                    display: none;
                }
            }
            h4 {
                padding-top: 0;
                color: $slate-gamma;
                @include rem-calc(5, padding-bottom);
                @include fontsize(18);
                @media screen and (min-width: $bp-desktop__minimum) {
                    @include fontsize(22);
                }
            }
            h5 {
                padding-top: 0;
                color: $slate-gamma;
                @include rem-calc(5, padding-bottom);
                @include fontsize(15);
                @media screen and (min-width: $bp-desktop__minimum) {
                    @include fontsize(18);
                }
            }
        }
    }
  }
  
  details {
    summary::-webkit-details-marker {
        display: none;
    }
  }