.subjectAreaContent{transform: translateY(-10%);}
.subjectArea{
    h1{
        @include rem-calc(5, padding);
        @media screen and (min-width: $bp-tablet__landscape) {
            @include rem-calc(8 15 16 15, padding);
            @include rem-calc(3, margin-bottom);

        }
    }
    display:inline-block;
    &__info{
        display:flex;
        p{
            @include rem-calc(9, font-size);
            @include rem-calc(3, letter-spacing);
            @include rem-calc(5, margin-right);
            @media screen and (min-width: $bp-tablet__landscape) {
                @include rem-calc(20, font-size);
                @include rem-calc(6.67, letter-spacing);
                @include rem-calc(16, margin-right);
            }
            @include rem-calc(4, padding-bottom);
            font-family: 'Derailed-Bold';
            text-transform: uppercase;
        }
    }
    &__wrap{
        flex:1;
        position:relative;
        &:before{
            content: '';
            position: absolute;
            top:64%;
            @media screen and (min-width: $bp-tablet__landscape) {
                top: 58%;
            }
            left: 0;
            border-top: .5px solid $white;
            width: 100%;
            transform: translateY(-50%);
        }
    }
}