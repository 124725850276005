.eurus {
    h2 {
        @include rem-calc(30, padding-bottom);
        @include rem-calc(10, padding-top);
    }
    

    .media-carousel-swiper-wrapper {
        background-color: $global-brand-blue;
        color: $eurus-white;
        @include rem-calc(20 0, padding);

        h2 {
            color: $eurus-white;
            padding-left: 15px !important;
        }

        .media-carousel-swiper {
            .slick-list {
                padding: 0px !important;  

                .slick-slide {
                    padding: 0 10px;
                    height: auto;

                    &:not(.slick-current){
                        div:first-of-type:before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0, 58, 101, 0.7); /* Adjust the color and opacity as needed */
                            z-index: 1; /* Ensure the overlay is on top of the iframe */
                        }
                        .carouselDescription {
                            visibility: hidden;
                        }
                    }

                    .carouselDescription {
                        border-left: $eurus-logo-red solid 10px;
                        font-weight: 700;
                        @include rem-calc(10, padding-left);
                        @include fontsize(16);
                        @include rem-calc(20 0, margin);

                        a {
                            color: $eurus-bright-blue;
                            font-family: $font-derailed__bold;
                            font-weight: 700;
                            @include fontsize(16);
                            &:after {
                                @extend %fa-icon;
                                @extend .fas;
                                content: fa-content($fa-var-chevron-right);
                                position: relative;
                                @include rem-calc(16.5, font-size);
                                @include rem-calc(-2.3, top);
                                @include rem-calc(11, left);
                                @include animation(left, 0.3s);
                            }
                            &:hover {
                                text-decoration: none;
                                &:after {
                                @include rem-calc(20, left);
                                }
                            }
                        }
                        
                        p {
                            padding: 0;
                            padding-bottom: 5px;
                            font-family: $font-sans-serif;
                            font-weight: 700;
                            @include fontsize(16);
                        }
                    }
                }
            }
            .slider-prev {
                position: absolute;
                transform: translateY(-50%);
                top: 30%;
                left: 5%;
                width: 42px;
                z-index: 1;
                cursor: pointer;
                color: $eurus-light-blue;
                @media screen and (min-width: $bp-tablet__small) {
                    top: 40%;
                    left: 3%;
                    width: 50px;
                } 
                @media screen and (min-width: $bp-desktop__minimum) {
                    top: 33%;
                    left: 15%;
                    width: 55px;
                }
            }
            .slider-next {
                position: absolute;
                transform: translateY(-50%);
                top: 30%;
                right: 5%;
                width: 42px;
                z-index: 1;
                cursor: pointer;
                color: $eurus-light-blue;
                @media screen and (min-width: $bp-tablet__small) {
                    top: 40%;
                    right: 3%;
                    width: 50px;
                } 
                @media screen and (min-width: $bp-desktop__minimum) {
                    top: 33%;
                    right: 15%;
                    width: 55px;
                } 
            }
        }
    }
    
}