// Overlay Styling

.overlay {
    background-color: rgba($overlay__bgcolor, 0.7);
    height: $height-full;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: $width-full;
    z-index: $zindex-header-overlay;
}

.overlayImage {
    background-size: cover;
    background-position: center center;
    height: $height-full;
    @media screen and (min-width: $bp-tablet__landscape) {
        height: auto;
    }
    left: $half;
    min-height: $height-full;
    min-width: $viewport-width-max;
    position: absolute;
    top: $half;
    transform: translate(-$half, -$half);
    width: auto;
    z-index: 0;
}
