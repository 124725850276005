// Breakpoint Variables

$bp-mobile__portrait: 20rem; // 320 / 16
$bp-mobile__large: 26.563rem; // 425 / 16
$bp-mobile__landscape: 30rem; // 480 / 16 -> SACS $small-breakpoint
$bp-tablet__small: 40rem; // 640 / 16
$bp-tablet__portrait: 48rem; // 768 / 16 -> SACS $medium-breakpoint
$bp-desktop__minimum: 60rem; // 960 / 16
$bp-tablet__landscape: 64rem; // 1024 / 16 -> SACS $large-breakpoint
$bp-content: 75rem; // 1200 / 16
$bp-desktop: 85.375rem; // 1366 / 16
$bp-desktop__medium: 100rem; // 1600 / 16
$bp-desktop__large: 120rem; // 1920 / 16
$bp-desktop__extra-large: 134.375rem; // 2150 / 16
