// Footer Styling

.eurus {
    .footer {
        background-color: $eurus-brand-blue;
        color: white;
        border-top: 40px solid $eurus-logo-red;
        padding-bottom: 0;
        .footerContent {

            border-bottom: none;
            color: white;
        
            h5 {
                color: white;
            }

            a:not(.cta) {
                color: white;
        
                &:focus,
                &:hover,
                &:visited {
                    color: white;
                }
            }
        
        }

        .footerColumn {

            &:not(:last-child) {
                border-bottom: 1px solid $eurus-logo-red;
        
                @media screen and (min-width: $bp-tablet__landscape) {
                    border-bottom: none;
                    border-right: 1px solid $eurus-logo-red;
                }
            }

        }

        .footerLink {

            color: white;

            &:focus,
            &:hover,
            &:visited {
                color: white;
            }

        }

        .socialIcon {
            fill: white;
        }
    }
}

.footer {

    @include fontsize(10);
    @include rem-calc(73, padding-bottom);

    // background-color: $footer__background-color;
    background-color: $eurus-brand-blue;
    color: $eurus-white;
    border-top: 40px solid $eurus-logo-red;
    padding-bottom: 0;
}

.footerContent {
    @include fontsize(14);
    @include rem-calc(50 0, padding);
    @include rem-calc(35, margin-bottom);

    // border-bottom: 1px solid $footer__bordercolor;
    // color: $footer__color;

    border-bottom: none;
    color: white;

    @media screen and (min-width: $bp-tablet__portrait) {
        @include fontsize(16);
    }

    @media screen and (min-width: $bp-tablet__landscape) {
        display: flex;
    }

/*     a {
        color: $footer__link__color;
        &:focus,
        &:hover,
        &:visited {
            color: $footer__link__color;
        }
    } */

    a:not(.cta) {
        color: $eurus-white;

        &:focus,
        &:hover,
        &:visited {
            color: $eurus-white;
        }
    }

    h5 {                                            /* NEW */ 

        @include fontsize(16); 
        @include rem-calc(0, padding); 
        @include rem-calc(0, margin);
        color: $eurus-white; 
    } 
}

.footerColumn {
    @include rem-calc(25, padding);

    width: $width-full;

    @media screen and (min-width: $bp-tablet__landscape) {
        @include rem-calc(0 25, padding);

        flex: 1 1 $quarter;
    }

    &:not(:last-child) {
        // border-bottom: 1px solid $footer__bordercolor;
        border-bottom: 1px solid $eurus-logo-red;

        @media screen and (min-width: $bp-tablet__landscape) {
            border-bottom: none;
            // border-right: 1px solid $footer__bordercolor;
            border-right: 1px solid $eurus-logo-red;
        }
    }

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }

    .cta {
        color: $eurus-dark-blue;
        &:hover {
            color: $eurus-white;
        }
    }
}

.footerHeading {
    // color: $footer__heading__color;
    color: $eurus-white;
    display: block;
    padding-top: 0;
}

.footerSubHeading {
    @include fontsize(14);

    // color: $footer__heading__color;
    color: $eurus-white;
    margin: 0;
    padding: 0;

    @media screen and (min-width: $bp-tablet__portrait) {
        @include fontsize(16);
    }
}

.footerContentBlock {
    @include fontsize(14);

    @media screen and (min-width: $bp-tablet__portrait) {
        @include fontsize(16);
    }

    & + & {
        @include rem-calc(20, margin-top);
    }

    p {
        @include fontsize(16);
    }
}

.footerList {
    list-style: none;
    margin: 0;
    padding: 0;
}

.footerListItem {
    @include rem-calc(20, padding-bottom);

    margin-bottom: 0;
}

.footerLink {
    @include fontsize(16);
    @include chevron($line-height: 1.25);

    // color: $footer__link__color;
    color: $eurus-white;
    font-family: $footer__link__font;
    line-height: 1.3;

    @media screen and (min-width: $bp-tablet__portrait) {
        @include fontsize(18);
    }

    &:focus,
    &:hover,
    &:visited {
        // color: $footer__link__color;
        color: $eurus-white;
    }

    &:focus,
    &:hover {
        text-decoration: none;

        span {
            text-decoration: underline;
        }
    }
}

.footerSocialIcons {
    @include rem-calc(0 -10 16, margin);		/* changed from (0 -10, margin) */

    display: flex;
    flex-wrap: wrap;

    @media screen and (min-width: $bp-tablet__landscape) {
        justify-content: flex-end;
    }
}

.footerSocialIcon {
    @include rem-calc(0 10 16, margin);

    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;

    &:focus,
    &:hover {
        text-decoration: none;
    }
}

.socialIconContainer {
    @include rem-calc(28, height);
    @include rem-calc(35, width);
}

.socialIcon {
    // fill: $footer__social-icon__fill;
    fill: $eurus-white;
    width: $width-full;
}

.schoolLocation {
    line-height: 1.25;
    padding-top: 0;

    span {
        display: block;
    }
}