#countriesNavigation {
    display: grid;
    grid-template-columns: repeat(4, (minmax(0, 1fr)));
    row-gap: 10px;
    column-gap: 15px;
    padding: 20px 0;

    @media screen and (min-width: $bp-tablet__portrait) {
        column-gap: 25px;
        padding: 40px 0;
        grid-template-columns: repeat(8, (minmax(0, 1fr)));
    }

    @media screen and (min-width: $bp-content) {
        grid-template-columns: repeat(12, (minmax(0, 1fr)));
    }

    .countryNavLink {
        display: flex;
        height: 50px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 5px;
        background-color: $eurus-tertiary-tint-tertiary;
        color: $eurus-mid-blue;
        font-family: $font-derailed__bold;
        @include fontsize(18);
        text-align: center;

        @media screen and (min-width: $bp-tablet__portrait) {
            @include fontsize(25);  
        }

        &.active {
            background-color: $eurus-mid-blue;
            color: $eurus-white;
        }

        &.disabled {
            pointer-events: none;
            opacity: 0.44;
        }

        &:hover {
            background-color: $eurus-brand-blue;
            color: $eurus-white;
        }
    }
}

.clearCountries {

    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    @media screen and (min-width: $bp-tablet__portrait) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    button {
        &.cta {
            width: 100%;
            &::after {
                content: "\f00d";
            }

            &:hover {
                &::after {
                    right: 1.5rem;
                }
            }
        }
    }
}

#countriesListContainer {

    .countryByLetter {

        &:not(:last-of-type){
            border-bottom: 1px solid $eurus-tertiary-tint-primary;
        }

        h2 {

            color: $eurus-brand-blue;
            font-family: $font-derailed__bold;
            @include fontsize(20);
            @include rem-calc(35, padding-top);
            padding-bottom: 0;
    
            @media screen and (min-width: $bp-tablet__portrait) {
                @include rem-calc(30, padding-top);
                @include fontsize(25);
            }        
        }
    
        ul {
            margin-left: 0;
            list-style: none;
            padding: 15px 0 30px;

            @media screen and (min-width: $bp-tablet__portrait) {
            padding: 35px 0 35px;
                
            }
    
            li {
    
                a {
                    color: $eurus-mid-blue;
                    font-family: $font-derailed__semibold;
                    @include fontsize(16);
                    font-style: normal;
                    line-height: 25px;     
                    
                    &:hover {
                        text-decoration: underline;
                    }

                }
            }
        }
    }

 



}