#clearingMobileFilter {

    width: 100%;
    border-radius: 0px;
    @include rem-calc(10 15 10 15, padding);
    @include rem-calc(20, margin-bottom);
    z-index: 10000;
    display: block;

    @media screen and (min-width: $bp-desktop__minimum) {
        display: none;
    }

    &.active {
        position: fixed;
        top: 0;
        left: 0;
        -webkit-transition: .4s;
        -o-transition: .4s;
        transition: .4s;        
    }

    .clearingFilterMenuContainer {
        list-style-type: none;
        margin: 0;
        border-left: 2px solid #0A97FF;        
    }

    a {
        margin: 0;
        font-weight: 700;
        color: #CCE9FF;
        background-color: inherit;
        width: 100%;
        display: block;
        text-decoration: none;
        line-height: 1rem;
        margin: 0.75rem 0;
        cursor: pointer;
        @include fontsize(14);
        @include rem-calc(3 0 3 15, padding);
        &.active {
            border-left: 2px solid #ffffff;;
            color: #ffffff;
            background-color: inherit;
            position: relative;
            left: -2px;
        }
    }

    #clearingFilterBurgerOpen span::before {
        content: "\f0b0";
        font-family: Font Awesome\ 5 Free;
        font-weight: 900;
        vertical-align: -0.125em;
        text-align: center;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        font-size: 20px;
        color: white;
    }

    .clearingMobileFilterContainer {
    
        button {
            width: 100%;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            background: #fff;
            font-family: $font-derailed__regular;               
            background-color: $global-brand-blue;
            color: white;
            @include rem-calc(11 20, padding);
            @include fontsize(18);
            @include rem-calc(21, line-height);
            font-weight: 700;
            &:focus{
                outline: 4px dashed #007494;
            }

        }
        h4 {
            padding: 0;
            color: white;
            font-weight: 800;
        }

        display:flex;
        align-items: flex-start;
        flex-direction: column;
        align-items: baseline;
        @media screen and (min-width: $bp-tablet__portrait) {
            flex-direction: row;
        }

      }

      .filterMenuFixed {
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        transition: 0.4s;
      }

      .preventScroll {
        overflow: hidden;
        pointer-events: none;
      }

}

.simpleSearch .courseSearchContainer{
    display:none;
    &.searchEnabled{
        @media screen and (min-width: $bp-desktop__minimum) {
            max-height:25rem;
            overflow-y:scroll;
            margin-bottom:3rem;
        }
    }
} 
.courseSearchContainer{

    &.clearingSearchContainer{
        display:block;

        @media screen and (min-width: $bp-desktop__minimum) {
            .filter-results-title .searchCount {
                display: none;
            }
        }

        .courseSearch{
            @media screen and (min-width: $bp-desktop__minimum) {
                text-align: right;
            }
            .searchCount{
                padding:0;
            }
        }
        
            .sidebar {
                padding: 2rem;
                @include rem-calc(60, padding-top);
                height: 100vh;
                position: fixed;
                z-index: 9999;
                max-width: none;
                width: 100%;
                top: 0;
                right: 0;
                left: 100%;
                overflow-x: hidden;
                -webkit-transition: .4s;
                -o-transition: .4s;
                transition: .4s;

                &.active {
                    left: 0px;
                    
                }
            }        
        
        @media (min-width: $bp-desktop__minimum) {
            .sidebar {
                width: 34%;
                max-width: 21.5625rem;
                margin-right: 2.5rem;
                padding: 0;
                height: auto;
                position: relative;
                z-index: auto;
                top: auto;
                right: auto;
                left: auto;
                overflow-x: auto;
                transition: none;                  
            }
        }

        .toggleFilters{
            display:none;
            text-align:right;
            @include rem-calc(10 0 10 0, margin);
            @media screen and (min-width: $bp-desktop__minimum) {
                display:none;
            }
            a{
                text-decoration: none;
                @extend %fa-icon;
                @extend .fas;
                color: #4a4a4a;
                border: 1px solid #b3b3b3;
                padding: 0.5rem;
                border-radius: 3px;
                cursor: pointer;
    
                &:before {
                    content: fa-content($fa-var-filter);
                }
            }
        }

        #clearingFilterBurgerClose {

            border: 1px solid #ffffff;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            background: #fff;
            font-family: $font-derailed__regular;               
            background-color: $global-brand-blue;
            color: white;
            position: absolute;
            top: 10px;
            width: calc(100% - 30px);
            left: 15px;
            @include rem-calc(11 20, padding);
            @include fontsize(18);
            @include rem-calc(21, line-height);
            font-weight: 700;   
            
            @media (min-width: $bp-desktop__minimum) {
                display: none;
            }
    
            h4 {
                color: white;
                padding-bottom: 0;
            }

            span::before {
                content: "\f00d";
                font-family: Font Awesome\ 5 Free;
                font-weight: 900;
                vertical-align: -0.125em;
                text-align: center;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                font-size: 20px;
                color: white;
            }
        }


    }

    .into__icon{
        &:before{
            content:"" !important;
        }
        @include rem-calc(1.85 5, margin);
        @include image-retina('/webtemplate/img/logos/into-logo','png',54px,20px);
        
        @include rem-calc(54, width);
        @include rem-calc(20, height);
    }

    .london__icon{
        &:before{
            content:"" !important;
        }
        @include rem-calc(1.85 5, margin);
        @include image-retina('/webtemplate/img/logos/london-logo','png',77px,20px);
        
        @include rem-calc(77, width);
        @include rem-calc(20, height);
    }

    .courseSearch{
        background:$white;
        .searchCount{
            color: $slate-gamma;
            span{
                font-weight: bold;
            }
        }
        .alert{
            margin-top:0;
            p{
                @media screen and (min-width: $bp-desktop__minimum) {
                    @include rem-calc(18, font-size);
                }
            }
        }
        .spinnerBox{
            display:none;
            text-align:center;
            margin:3rem 0;
            span{
                color: $clearing-blue;
                @extend %fa-icon;
                @extend .fas;
                @include spinIcon(1s, $fa-var-spinner);
                @include rem-calc(60, font-size);
            }
        }
    }

    .clearingLayout{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        @media screen and (min-width: $bp-desktop__minimum) {
            flex-direction: row;
            margin-bottom:2rem;
            margin-top:2rem;
        }
        .filterSidebar{
            max-height:none;
            margin-top:0;
        }
        &__filterBox{
            .row{
                p{
                    border-bottom: 1px solid #dddddd;
                    @include rem-calc(14, font-size);
                    @include rem-calc(14, margin-bottom);
                }
                .checkbox{
                    margin: .5rem 0;
                    label{
                        font-size:1rem;
                        margin-left:4px;
                    }
                }
            }
        }

        // clearing here
        .clearingSearchResults{
            width:100%;
            margin-top:0;
            &__clearingList{
                .clearingContainer{
                    padding: 1.5rem 0;
                    @media screen and (min-width: $bp-desktop__minimum) {
                        padding:0;
                        margin-bottom:2.8rem;
                    }
                    border-bottom: 1px solid #d1d1d1;
                    p{
                        font-family: $search-regular;
                    }
                    .clearingName{
                        h3{
                            font-family: $search-bold;
                            padding-bottom:1rem;
                            @include rem-calc(25, font-size);
                            a{
                                color:$clearing-green;
                            }
                        }
                    }

                    .clearingCourseInfo{
                        ul{
                            margin-left:0;
                            padding:0;
                            list-style-type: none;
                            display:block;
                            @media screen and (min-width: $bp-desktop__minimum) {
                                display:flex;
                            }
                            li{
                                font-family: $search-regular;
                                margin-right: 2rem;
                                span{
                                    font-weight:700;
                                    margin-right:.6rem;
                                }
                            }
                        }
                    }

                    .clearingStatus{
                        list-style-type: none;
                        margin-left:0;
                        @media screen and (min-width: $bp-desktop__minimum) {
                            margin:0.25rem 0 0.25rem 1rem;
                        }
                        li{
                            font-family: $search-regular;
                            font-weight:700;
                            line-height:34px;
                            .alert{
                                .alertIcon{
                                    @include rem-calc(5, margin-right);
                                    vertical-align: 1px;
                                    &.tick{
                                        background-color:$clearing-blue;
                                    }
                                    &.int{
                                        background-color:$clearing-green;
                                    }
                                }
                            }
                        }
                    }
                    .not-available-msg{
                        @include rem-calc(10 0 10 0, margin);
                    }
                    .courseLink {
                        text-align:right;
                        .cta{
                            width:100%;
                            @media screen and (min-width: $bp-desktop__minimum) {
                                width:auto;
                            }
                        }
                    }
                }
            }
        }
    }
    .searchBoxInput{
        color: $slate-gamma;
        border:1px solid $color-beta;
        &:hover{
            background-color: $search-off-white;
        }
        &:focus{
            background-color: $white;
            border:2px solid #037EAF;
        }
        &::placeholder{
            color: $slate-gamma;
        }
    }
    .filterSubmit{
        border:1px solid $color-beta;
        background-color: $slate-gamma;
        &:hover{
            background-color: $black;
        }
    }
    .courseSearchResults{
        margin:0;
        width:100%;
        .dropdown{
            margin:1rem 0 !important;
        }
        h3{
            font-family: $search-extrabold;
            @media screen and (min-width: $bp-tablet__portrait) {
                @include rem-calc(25, font-size);
            }
            font-weight:bold;
            padding-bottom:0;
            a{
                text-decoration: none;
                color: $color-foxtrot;
                &:hover{
                    text-decoration:underline;
                }
            }
        }
        p{
            font-family: $search-regular;
        }
        &__box{
            border-bottom: 1px solid $search-letter-border;
            padding: 1.5rem 0;
            h3{
                font-family: $search-bold;
            }
        }
        &__info{
            font-family: $search-regular;
            margin: 0;
            padding: 1.2rem 0px .5rem 0;
            display: flex;
            flex-direction:column;
            justify-content: space-between;
            li{
                @include rem-calc(16, font-size);
                list-style-type: none;
                flex:1;
                &#studyLevel{
                    @media screen and (min-width: $bp-tablet__portrait) {
                        min-width: 17rem;
                    }
                }
            }
            @media screen and (min-width: $bp-desktop__minimum) {
                flex-direction:row;
            }
            .course_key{
                font-family: $search-bold;
                @include rem-calc(5, margin-right);
            }
            #courseCode{
                display:flex;
                span{
                    @include rem-calc(258, max-width);
                }
            }
        }
        &__alphabet-list{
            font-family: $search-bold;
            display:flex;
            list-style-type:none;
            margin-left:0;
            justify-content:flex-start;
            flex-wrap:wrap;
            @media screen and (min-width: $bp-tablet__portrait) {
                padding: 1.4rem 0;
            }
            
            li{
                text-align: center;
                background: $search-off-white;
                @include rem-calc(80, width);
                @include rem-calc(48, height);
                @include rem-calc(50, line-height);
                flex:1 0 calc(15% - 1px);
                margin: 1px;
                border-radius:3px;
                @media screen and (min-width: $bp-desktop__minimum) {
                    flex:0 1 auto;
                    margin: 12px 17px 8px 0px;
                }
                a{
                    @include rem-calc(20, font-size);
                    display: block;
                    width: 100%;
                    height: 100%;
                    color: $slate-zeta;
                    font-weight:bold;
                    transition: all .2s ease;
                    cursor:default;
                    &:hover{
                        text-decoration:none;
                        background-color: $search-off-white;
                    }
                    &:focus{
                        text-decoration:none;
                    }
                    &.has__content{
                        color:$slate-gamma;
                        cursor:pointer;
                        &:hover{
                            background-color: $search-cta-blue;
                            color:$white;
                        }
                    }
                }
                &.listletterY, &.listletterZ{
                    @media screen and (max-width: $bp-mobile__large) {
                        flex: 0 1 16.25%;
                    }
                }
            }
        }
        &__courseList, &__clearingList{
            list-style-type:none;
            margin-left:0;
            padding-top:0;
            .btn.bk-to-top{
                margin:3rem 0 4.45rem 0;
                background-color: $search-cta-blue;
                height: 58px;
                width: 151px;
                display: block;
                text-align: center;
                color: $white;
                line-height: 58px;
                font-family: Bariol;
                @include rem-calc(24, font-size);
                &:hover, &:focus{
                    text-decoration:none;
                }
            }
        }
        &__courseName{
            
            @media screen and (min-width: $bp-desktop__minimum) {
                @include rem-calc(26, line-height);
                display:flex;
                align-items:center;
            }
            a{
                color: #007a74;
                text-decoration:none;
                font-family: $search-bold;
                line-height:34px;
                @include rem-calc(16, font-size);
                @media screen and (min-width: $bp-tablet__portrait) {
                    @include rem-calc(18, font-size);
                }
                
            }
            span{
                font-family: $search-regular;
                display:block;
                margin-bottom:7px;
                @media screen and (min-width: $bp-tablet__portrait) {
                    margin-bottom:0;
                }
                &::before{
                    @media screen and (min-width: $bp-desktop__minimum) {
                        content: " | ";
                        margin-left: 0.31rem;
                    }
                }
                @media screen and (min-width: $bp-desktop__minimum) {
                    display:inline-block;
                }
            }

            &.alt__list{
               @media screen and (min-width: $bp-tablet__portrait) {
                @include rem-calc (0 0 10 0, margin);
               }
            }

            .london__icon, .into__icon{
                @media screen and (max-width: $bp-tablet__portrait) {
                    margin:0;
                }   
            }

            &.hide{
                display:none;
            }
        }
        &__courseTitle{
            p{
                padding:0;
            }
            width:100%;
            margin-bottom:.5rem;
        }
        &__letterSort{
            h3{
                font-weight: bolder;	
                color: $color-beta;
                margin: 1.4rem 0 0 0;
                padding-bottom: 0.6rem;
                border-bottom: 1px solid $search-letter-border;
                @media screen and (min-width: $bp-tablet__portrait) {
                    @include rem-calc(40, font-size);
                    margin: 1.5rem 0 2.45rem 0;
                    padding-bottom: 1.4rem;
                }
            }
        }
        .courseSuggestionBox{
            margin-bottom:1rem;
            ul{
                padding-top:0;
                li{
                    .tabLinks{
                        justify-content: left;
                        padding: 0;
                        color: #008283;
                        font-family: $search-regular;
                        font-size:1rem;
                    }
                }
            }
        }
    }
    .courseBrowseResults{
        .dropDownBlock{
            .dropDownSummary{
                p{
                    padding:0;
                }
                padding:1rem 0;
                ul{
                    h3{
                        font-family: $search-bold;
                        @media screen and (min-width: $bp-tablet__portrait) {
                            @include rem-calc(21, font-size);
                            padding-bottom: 1rem; 
                        }
                        color:$slate-gamma;
                    }
                    font-family: $search-regular;
                    list-style-type: none;
                    margin-left: 0;
                    .courseSearchResults__courseName{
                        span{
                            display:block;
                            @media screen and (min-width: $bp-desktop__minimum) {
                                display:inline-block;
                                &::before{
                                    @media screen and (min-width: $bp-desktop__minimum) {
                                        content: " | ";
                                        margin-left: 0.3rem;
                                    }
                                }
                            }
                        }
                    }
                }
                .courseDropdown{
                    &:hover{
                        background-color:transparent;
                    }
                    &.dropdownOpen{
                        border-bottom:none;
                    }
                    font-family: $search-bold;
                    @include rem-calc(16, font-size);
                    color: #007a74;
                    border-bottom:1px solid #d1d1d1;
                    padding:0.8rem 0;
                    cursor:pointer;
                    .toggle{
                        background: #007a74;
                        @media screen and (min-width: $bp-tablet__portrait) {
                            @include rem-calc(36, height);
                            @include rem-calc(36, width);
                            &::after{
                                @include rem-calc(13, height);
                                @include rem-calc(21, width);
                            }
                        }
                    }
                    p{
                        padding:0;
                        color:$slate-gamma;
                        padding-top: .2rem;
                        font-family: $search-regular;
                    }
                }
                .courseExtra{
                    display:none;
                    border-bottom: 1px solid #d1d1d1;
                    .courseExtraBox{
                        display:flex;
                        justify-content:space-between;
                        padding:1rem 0;
                        &__left, &__right{
                            flex:1 0 50%;
                        }
                        &__left{
                            .courseBrief{
                                .ucasCode{
                                    background: #007a74;
                                    padding: .225rem .5rem;
                                    margin-right: 1.125rem;
                                    color: $white;
                                    border-radius: 6px;
                                    font-family: $search-regular;
                                }
                                .courseYear{
                                    font-family: $search-bold;
                                }
                                span{
                                    font-family: $search-regular;
                                    @include rem-calc(16, font-size);
                                }
                                p{
                                    max-width:95%;
                                    font-family:$search-regular;
                                    padding: .9375rem 0;
                                }
                                .courseInfo{
                                    @media screen and (min-width: $bp-tablet__portrait) {
                                        display: flex;
                                        justify-content: flex-end;
                                    }
                                    .cta::after{
                                        @media screen and (max-width: $bp-tablet__portrait) {
                                            display:none;
                                        }
                                    }
                                }
                                .requirements{
                                    display:flex;
                                    margin: 1rem 0;
                                    .block{
                                        flex:1 0 50%;
                                        border-left: 2px solid #007a74;
                                        padding: .2rem .5rem;
                                        p{
                                            padding:0;
                                            font-family: $search-bold;
                                        }
                                        ul{
                                            li{
                                                margin-bottom:0;
                                                span{
                                                    font-family: $search-bold;
                                                }
                                            }
                                        }
                                    }
                                }
                                .opportunity{
                                    ul{
                                        li{
                                            margin-bottom:.5rem;
                                        }
                                    }
                                    margin:1.5rem 0 1rem 0;
                                    .alert{
                                        border:none;
                                        padding:0;
                                        font-family: $search-bold;
                                        @include rem-calc(14, font-size);
                                        .alertIcon{
                                            background-color:#007a74;
                                        }
                                    }
                                }
                                .cta{
                                    height: auto;
                                    line-height:auto;
                                    @include rem-calc(18, font-size);
                                    &::after{
                                        @include rem-calc(18, font-size);
                                        line-height: 1.98rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .toggle{
                background: #007a74;
            }
        }
    }
}