#eventsFeed {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $bp-desktop__minimum) {
        flex-direction: row;
        padding: 0 0.9375rem;
    }
    .left {
        width: 20%;
        background-color: lightgray;
        @include rem-calc(25, margin-right);
    }
    .right {
        flex-grow: 1;
        .eventFilterButtons {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 1rem;
            border-bottom: 1px solid #CCDBE5;
            @media screen and (min-width: $bp-tablet__portrait) {
                display: flex;
            }

            @include rem-calc(35, padding-bottom);
            button {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-shrink: 0;
                @include rem-calc(0 20, padding);
                border-radius: 5px;
                border: 1px solid #4A4A4A;
                @include rem-calc(50, height);
                font-weight: 400;
                color: #4A4A4A;
                font-family: $font-derailed__regular;
                @include fontsize(16);
                font-style: normal;
                font-weight: 400;
                @include rem-calc(24, line-height);
                justify-content: center;
                flex-grow: 1;
            }
        }

        .eventResultsDisplay {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include rem-calc(20 0, padding);
            #sortEvents {
                // @include rem-calc(5 10, padding);
                padding: 1rem;
                border-radius: 5px;
                border: 1px solid #4A4A4A;
                background: #FFF;
                color: #4A4A4A;
                font-family: $font-derailed__regular;
                @include fontsize(16);
                font-weight: 400;
                @include rem-calc(21, line-height);
            }
        }

        .eventsWrapper {
            display: flex;
            flex-direction: column;
            @include rem-calc(20, gap);       
            .card {
                &:not(.shown){
                    display: none;
                }
            }     
            .eventCard {
                border-radius: 10px;
                // box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.25);
                position: relative;

                &.hasAdditionalTag {
                    margin-top: 25px;
                    .imageWrapper{
                        h4 {
                            padding-top: 25px;
                            @media screen and (min-width: $bp-tablet__portrait) {
                                padding-top: 30px;
                            }
                        }
                    }
                }

                .additionalTag {
                    position: absolute;
                    top: -20px;
                    right: 20px;
                    z-index: 5;
                    padding: 5px 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;
                    border: 2px solid #003A65;
                    background: #FFF;
                    color: #003A65;
                    height: 40px;

                    @media screen and (min-width: $bp-tablet__portrait) {
                        top: -25px;
                        height: 50px;
                    }
                }

                &.careersEvent {
                    border: 2px solid $eurus-brand-blue;
                    &:hover {
                        // box-shadow: 0 0 9px 0 #AE0160;
                    }
                    &:focus {
                        outline: 4px dashed #AE0160;
                    }              
                    .imageWrapper {
                        .gradientOverlay {
                            background: linear-gradient(90deg, #AE0160 63.29%, rgba(46, 1, 174, 0.00) 99.96%);
                        }
                    }
                    .contentWrapper {
                        .title {
                            &.icon {
                                &.date::before,
                                &.time::before,
                                &.location::before,
                                &.star::before,
                                &.child::before,
                                &.exclamation::before {
                                    color: #AE0160;    
                                }                           
                            }
                        }
                        .additionalPointsWrapper {
                            color: #AD005F;
                        }
                    }
                }

                &.t4Event {
                    border: 2px solid $eurus-brand-blue;
                    /*&:hover {
                        box-shadow: 0 0 9px 0 #003A65;
                    }
                    &:focus {
                        outline: 4px dashed #003A65;
                    }*/             
                    .imageWrapper {
                        .gradientOverlay {
                            // background: linear-gradient(90deg, #003A65 63.29%, rgba(46, 1, 174, 0.00) 99.96%);
                            background: $eurus-brand-blue;
                        }
                    }
                    .contentWrapper {
                        .title {
                            &.icon {
                                &.date::before,
                                &.time::before,
                                &.location::before,
                                &.duration::before,
                                &.star::before,
                                &.child::before,
                                &.exclamation::before {
                                    color: #003A65;  
                                }                             
                            }
                        }
                        .additionalPointsWrapper {
                            color: #003A65;
                        }
                    }

                    &:not(.no-link){
                        &:hover,
                        &:focus {
                            // box-shadow: 0px 0px 9px 0px #0080a5;
                            // border-color: $eurus-mid-blue;
                            .imageWrapper {

                                    h4 {
                                        text-decoration: underline;
                                        &:after {
                                            @include rem-calc(20, left);
                                            display: inline-block;
                                        }
                                    }
                                 
                                

                            }
                        }
                    }
            
                    &.no-link {
                        cursor: default;
                        .imageWrapper{
                            h4{
                                &::after{
                                    content: "" !important;
                                }
                            }
                        }
                    }
                }
                &:hover {
                    text-decoration: none;
                    cursor: pointer;
                }

                &:focus {
                    background-color: transparent;
                    text-decoration: none;
                }

                .imageWrapper {
                    position: relative;

                    h4 {
                        @include rem-calc(20 0 20 20, padding);
                        color: white;
                        font-family: $font-derailed__bold;
                        display: inline-block;
                        @include fontsize(18);
                        @media screen and (min-width: $bp-desktop__minimum) {
                            @include fontsize(27);
                            @include rem-calc(20 0 20 30, padding);
                        }

                        &:after {
                            @extend %fa-icon;
                            @extend .fas;
                            content: fa-content($fa-var-arrow-right);
                            position: relative;
                            @include rem-calc(15, font-size);
                            @include rem-calc(-2, top);
                            @include rem-calc(11, left);
                            @include animation(left, 0.3s);
                            display: inline-block;

                            @media screen and (min-width: $bp-mobile__landscape) {
                                @include rem-calc(-2, top);      
                                @include rem-calc(16, font-size);
                            }
                            @media screen and (min-width: $bp-desktop__minimum) {
                                @include rem-calc(-4, top);
                                @include rem-calc(20, font-size);
                            }
                        }

                    }
                       
                    img {
                        position: absolute;
                        width: 100%;
                        left: 0;
                        top: 0;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 10px 10px 0 10px;
                    }
                    .gradientOverlay {
                        border-radius: 7px 7px 0px 0px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                    }

                    .alertWrapper {
                        position: absolute;
                        bottom: -20px;
                        transform: translateX(-50%);
                        left: 50%;
                        .alertInner {
                            color: #00523E;
                            font-feature-settings: 'clig' off, 'liga' off;
                            font-family: $font-derailed__bold;
                            @include fontsize(15);
                            font-size: 15px;
                            border-radius: 40px;
                            border: 4px solid #FFF;
                            background: #B6F9CD;
                            height: 45px;
                            min-width: 250px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            @include rem-calc(0 10, padding);       
                            
                            span {
                                display: flex;
                                align-items: center;
                                position: relative;
                                &:before {
                                    content: '\f06a';
                                    font-family: 'Font Awesome 5 Free';
                                    padding-right: 5px;
                                    @include fontsize(20);
                                }
                            }
                        }
                    }
                }
                .contentWrapper {
                    @include rem-calc(16 20 22 20, padding);
                    @media screen and (min-width: $bp-tablet__portrait) {
                        @include rem-calc(16 16 22 16, padding);
                    }
                    @media screen and (min-width: $bp-desktop__minimum) {
                        @include rem-calc(20 30 32 30, padding);
                    }

                    &:active {
                        background-color: transparent;
                    }

                    .contentWrapperInner {

                        p {
                            font-family: $font-sans-serif;
                            @include fontsize(14);
                            @include rem-calc(24, line-height);
                            color: #4A4A4A;
                            padding: 0;
                            @include rem-calc(20, padding-bottom);

                            @media screen and (min-width: $bp-desktop__minimum) {
                                @include fontsize(16);
                            }
                        }
                        .detailsWrapper {
                            @include rem-calc(21, padding-top);
                            border-top: 1px solid $eurus-tertiary-tint-primary;
                            display: flex;
                            flex-direction: column;
                            @include rem-calc(25, gap);
                            @media screen and (min-width: $bp-tablet__portrait) {
                                @include rem-calc(25, gap);
                                flex-direction: row;
                            }

                            .infoWrapper {
                                flex-grow: 1;
                                display: flex;
                                gap: 12px;

                                div {
                                    display: flex;
                                    flex-direction: column;
                                    .title {
                                        display: flex;
                                        color: $eurus-brand-blue;
                                        @include fontsize(16);
                                        font-weight: 700;
                                        @include rem-calc(24, line-height);
                                        @media screen and (min-width: $bp-desktop__minimum) {
                                            @include fontsize(16);
                                        }
                                        &:not(:last-of-type){
                                            @include rem-calc(5, padding-bottom)
                                        }
                                        &.icon {
                                            &.date::before,
                                            &.time::before,
                                            &.location::before,
                                            &.duration::before {
                                            font-family: 'Font Awesome 5 Free';
                                            width: 25px;
                                            display: inline-block;
                                            }
                                        
                                            &.date::before {
                                            content: "\f133";
                                            }
                                        
                                            &.time::before {
                                            content: "\f017";
                                            }
                                        
                                            &.location::before {
                                            content: "\f041";
                                            }

                                            &.duration::before {
                                            content: "\f254";
                                            }
                                        }
                                    }
                                    .detail {
                                        color: $eurus-brand-blue;
                                        @include fontsize(16);
                                        font-weight: 400;
                                        @include rem-calc(24, line-height);
                                        @media screen and (min-width: $bp-desktop__minimum) {
                                            @include fontsize(16);
                                        }
                                        &:not(:last-of-type){
                                            @include rem-calc(5, padding-bottom)
                                        }
                                    }
                                }
                            }
                            .additionalPointsWrapper {
                                border-radius: 5px;
                                border: 2px solid $eurus-tertiary-tint-primary;
                                @include rem-calc(10, padding);
                                @media screen and (min-width: $bp-desktop__minimum) {
                                    @include rem-calc(15, padding);
                                }
                                ul {
                                    list-style-type: none;
                                    margin: 0;
                                    padding: 0;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    height: 100%;
                                    gap: 5px;
                                }
                                li {
                                    display: flex;
                                    align-items: center;
                                    gap: 8px;
                                    @include fontsize(16);
                                    font-weight: 700;
                                    @include rem-calc(24, line-height);
                                }
                                .title {
                                    display: flex;
                                    color: $eurus-brand-blue;
                                    @include fontsize(16);
                                    font-weight: 700;
                                    @include rem-calc(24, line-height);
                                    @media screen and (min-width: $bp-desktop__minimum) {
                                        @include fontsize(16);
                                    }
                                    &:not(:last-of-type){
                                        @include rem-calc(5, padding-bottom)
                                    }
                                    &.icon {
                                        &.star::before,
                                        &.child::before,
                                        &.exclamation::before {
                                        font-family: 'Font Awesome 5 Free';
                                        width: 25px;
                                        display: inline-block;
                                        }                            
                                        &.star::before {
                                        content: "\f005";    
                                        }
                                        &.child::before {
                                            content: "\f1ae";
                                        }
                                        &.exclamation::before {
                                        content: "\f06a";
                                        }
                                    }
                                }

                            }
                        }
                    
                    }
                }

            }
        }

    }
    .spinnerBox{
        display:block;
        text-align:center;
        margin:3rem 0;
        grid-column: span 2 / span 2;
        span{
            color: $clearing-blue;
            @extend %fa-icon;
            @extend .fas;
            @include spinIcon(1s, $fa-var-spinner);
            @include rem-calc(60, font-size);
        }
    }
}