// Skip To Content Styles

.skipToContentWrap {
    @include fontsize(10);

    width: $width-full;
}

.skipToContent {
    @include rem-calc(-1, margin);
    @include size(1, 1);
    @include fontsize(18);

    background: $skip__bgcolor;
    border: 2px solid $skip-content__bordercolor;
    clip: rect(0 0 0 0);
    display: block;
    overflow: hidden;
    position: absolute;

    &:focus {
        @include rem-calc(16, padding);

        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
    }
}
