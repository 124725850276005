details {

    &[open]{
        summary {
            .chevron {
                &:after {
                    content: "\f077" !important;
                }
            }
        }
    }


    &.alertBanner {
        border: solid 1px;
        summary {
            font-family: $font-derailed__bold;
            position: relative;
            @include rem-calc(55, min-height);
            cursor: pointer;
            user-select: none;
            @include rem-calc(10 50 10 10, padding);
            display: flex;

            .summaryText {

                top: 50%;
                @include rem-calc(10, left);
                display: flex;
                align-items: center;
                @include fontsize (18);
                @media screen and (min-width: $bp-desktop__minimum) {
                    @include fontsize(22);
                }
                padding: 0;
                &::before {
                    @include rem-calc(8, margin-right);
                    font-family: Font Awesome\ 5 Free;
                    font-size: 26px;
                }
            }
            .chevron {
                display: block;
                position: absolute;
                text-decoration: none;
                @include rem-calc(10, top);
                @include rem-calc(10, right);
                @include rem-calc(35, width);
                @include rem-calc(35, height);
                border-radius: 5px;
                &::after {
                    content: "\f078";
                    font-family: Font Awesome\ 5 Free;
                    position: absolute;
                    transform: translate(-50%, -50%);
                    top: 55%;
                    left: 50%;
                    color: $eurus-white;

                    @media screen and (min-width: $bp-tablet__portrait) {
                        top: 50%;
                    }
                }
            }
        }
        .alertContent {
            @include rem-calc(10 34 20 34, padding);
            @media screen and (min-width: $bp-desktop__minimum) {
                @include rem-calc(10 40 20 40, padding);
            }
            p {
                padding-top: 0;
                &:empty {
                    display: none;
                }
            }
            h4 {
                padding-top: 0;
                @include rem-calc(5, padding-bottom);
                @include fontsize(18);
                @media screen and (min-width: $bp-desktop__minimum) {
                    @include fontsize(22);
                }
            }
            h5 {
                padding-top: 0;
                @include rem-calc(5, padding-bottom);
                @include fontsize(15);
                @media screen and (min-width: $bp-desktop__minimum) {
                    @include fontsize(18);
                }
            }
            .findMoreLink {
                font-family: $font-derailed__extrabold;
                @include fontsize(15);
                @media screen and (min-width: $bp-desktop__minimum) {
                    @include fontsize(18);
                }
                a {
                    color: $eurus-mid-blue;
                    &:visited {
                        color: $eurus-mid-blue;
                        &:after {
                            color: $eurus-mid-blue;
                        }
                    }
                    &:after {
                        color: $eurus-mid-blue;
                        font-family: Font Awesome\ 5 Free;
                        content: "\f061";
                        top: 0;
                        @include fontsize(15);
                        @media screen and (min-width: $bp-desktop__minimum) {
                            @include fontsize(18);
                        }
                    }
                }
            }
        }
    }


    &.high {
        border-color: $eurus-logo-red;
        
        summary {
            color: $eurus-dark-red;
            &::marker {
                color: $eurus-logo-red;
                content: "\f071";
                font-family: Font Awesome\ 5 Free;
                content: "";
            }
            .summaryText {
                color: $eurus-dark-red;
                &::before {
                    content: "\f071";
                    color: $eurus-logo-red;
                }
            }
            .chevron {
                background-color: $eurus-logo-red;
                &:hover {
                    background-color: $eurus-dark-blue;
                }
            }
        }
        .alertContent {
            h4, h5 {
                color: $eurus-dark-red;
            }
        }
       
    }
    &.low {
        border-color: $eurus-brand-blue;
        
        summary {
            color: $eurus-brand-blue;
            &::marker {
                color: $eurus-brand-blue;
                font-family: Font Awesome\ 5 Free;
                content: "";
            }
            .summaryText {
                color: $eurus-brand-blue;
                &::before {
                    content: "\f071";
                    color: $eurus-brand-blue;
                }
            }
            .chevron {
                background-color: $eurus-brand-blue;
                &:hover {
                    background-color: $eurus-dark-blue;
                }
            }
        }
        .alertContent {
            h4, h5 {
                color: $eurus-dark-blue;
            }
        }
       
    }
    &.information {
        border-color: $eurus-dark-blue;
        
        summary {
            color: $eurus-dark-blue;
            &::marker {
                color: $eurus-dark-blue;
                font-family: Font Awesome\ 5 Free;
                content: "";
            }
            &::-webkit-details-marker {
                display: none;
            }
            .summaryText {
                color: $eurus-dark-blue;
                &::before {
                    content: url('/webtemplate/img/icons/eurus-icons/eurus-info-icon.svg');
                    color: $eurus-dark-blue;
                    @include rem-calc(30, height);
                }
            }
            .chevron {
                background-color: $eurus-dark-blue;
                &:hover {
                    background-color: $eurus-mid-blue;
                }
            }
        }
        .alertContent {
            h4, h5 {
                color: $eurus-dark-blue;
            }
        }
    }
    &.positive {
        border-color: $eurus-teal-alt;
        
        summary {
            color: $eurus-teal-alt;
            &::marker {
                color: $eurus-teal-alt;
                font-family: Font Awesome\ 5 Free;
                content: "";
            }
            .summaryText {
                color: $eurus-teal-alt;
                &::before {
                    content: "\f058";
                    color: $eurus-teal-alt;
                }
            }
            .chevron {
                background-color: $eurus-teal-alt;
                &:hover {
                    background-color: $eurus-dark-blue;
                }
            }
        }
        .alertContent {
            h4, h5 {
                color: $eurus-teal-alt;
            }
        }
    }
}

details {
    summary::-webkit-details-marker {
        display: none;
    }
}