#eventSwiper {
    // display: flex;
    // flex-direction: column;
    //padding: 30px 0px 40px 0px; 
    // background: linear-gradient(45deg, #07828D 0%, #A907A3 100%);


    &.blueBg {
        background-color: $eurus-base-tint-quaternary;
        padding: 50px 0;
        margin: 0;

        @media screen and (min-width: $bp-tablet__portrait) {
            padding: 100px 0px 50px 0px;
        }   

    }

    @media screen and (min-width: $bp-desktop__minimum) {
        flex-direction: row;
    }

    .eventSwiperWrapper {
        flex-grow: 1; 

        h2 {
            color: #FFF;
            @include rem-calc(40, padding-bottom);
        }

        .eventFilterButtons {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 1rem;
            border-bottom: 1px solid #CCDBE5;
            @media screen and (min-width: $bp-tablet__portrait) {
                display: flex;
            }

            @include rem-calc(35, padding-bottom);
            button {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-shrink: 0;
                @include rem-calc(0 20, padding);
                border-radius: 5px;
                border: 1px solid #4A4A4A;
                @include rem-calc(50, height);
                font-weight: 400;
                color: #4A4A4A;
                font-family: $font-derailed__regular;
                @include fontsize(16);
                font-style: normal;
                font-weight: 400;
                @include rem-calc(24, line-height);
                justify-content: center;
                flex-grow: 1;
            }
        }

        .eventResultsDisplay {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include rem-calc(20 0, padding);
            #eventFilters {
                @include rem-calc(5 10, padding);
                border-radius: 5px;
                border: 1px solid #4A4A4A;
                background: #FFF;
                color: #4A4A4A;
                font-family: $font-derailed__regular;
                @include fontsize(16);
                font-weight: 400;
                @include rem-calc(21, line-height);
            }
        }

        .eventsWrapper {
        
            .swiperWrapper {
                position: relative;

                .whiteCta {
                    color: #4A4A4A;
                    border: 1px solid #4A4A4A;
                    background: #FFF;
                    width: 100%;
                    @include rem-calc(30, margin-top);

                    @media screen and (min-width: $bp-tablet__portrait) {
                        width: 50%;
                        @include rem-calc(40, margin-top);
                    }
                    @media screen and (min-width: $bp-desktop__minimum) {
                        width: 33.33%;
                    }
                }


            }

            .eventCard {
                border-radius: 5px;
                border: 2px solid $eurus-brand-blue;
                overflow: hidden;
                background-color: #FFF;
                height: 420px;
                margin-top: 15px;
                margin-bottom: 15px;
                @media screen and (min-width: $bp-desktop__minimum) {
                    height: 420px;
                }

                &:focus:active {
                    background-color: #FFF;
                }

                &:hover {
                    border-color: $eurus-mid-blue;
                    text-decoration: none;
                    cursor: pointer;

                    .imageWrapper {
                        h3 {
                            text-decoration: underline;

                            &::after {
                                @include rem-calc(10, left);
                                display: inline-block;
                            }
                        }
                    }

                }

                &:focus {
                    background-color: #FFF;
                    outline: 4px dashed #007494;
                    text-decoration: none;
                }

                .imageWrapper {
                    background-color: $global-brand-blue;
                    @include rem-calc(130, height);
                    @include rem-calc(10, padding);
  
                    h3 {
                        position: relative;
                        font-family: $font-derailed__bold;
                        @include fontsize(18);
                        padding: 0;
                        color: white;

                        &:after {
                            position: relative;
                            font-family: 'Font Awesome 5 Free';
                            content: "\f061";
                            padding-left: 5px;
                            @include rem-calc(0, left);
                            @include animation(left, 0.3s);
                        }
                    }

                    .alertWrapper {
                        position: absolute;
                        bottom: -20px;
                        transform: translateX(-50%);
                        left: 50%;
                        .alertInner {
                            color: #00523E;
                            font-feature-settings: 'clig' off, 'liga' off;
                            font-family: $font-derailed__bold;
                            @include fontsize(15);
                            font-size: 15px;
                            border-radius: 40px;
                            border: 4px solid #FFF;
                            background: #B6F9CD;
                            height: 45px;
                            min-width: 250px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            @include rem-calc(0 10, padding);       
                            
                            span {
                                display: flex;
                                align-items: center;
                                position: relative;
                                &:before {
                                    content: '\f06a';
                                    font-family: 'Font Awesome 5 Free';
                                    padding-right: 5px;
                                    @include fontsize(20);
                                }
                            }
                        }
                    }
                }
                .contentWrapper {
                    @include rem-calc(10, padding);
                    @media screen and (min-width: $bp-tablet__portrait) {
                        @include rem-calc(10, padding);
                    }
                    @media screen and (min-width: $bp-desktop__minimum) {
                        @include rem-calc(10, padding);
                    }

                    &:active {
                        background-color: transparent;
                    }

                    .contentWrapperInner {

                        p {
                            font-family: $font-sans-serif;
                            @include fontsize(14);
                            @include rem-calc(24, line-height);
                            padding-top: 0;
                            color: #4A4A4A;
                           // @include rem-calc(27, padding-bottom);
                            @include rem-calc(125, height);
                            @media screen and (min-width: $bp-tablet__portrait) {
                                @include rem-calc(115, height);
                            }                    
                            @media screen and (min-width: $bp-desktop__minimum) {
                                @include fontsize(16);
                                @include rem-calc(115, height);
                            }
                        }
                        .detailsWrapper {

                            svg {

                                margin-top: 1px;

                                &.date {
                                    width: 15px;
                                    min-width: 15px;
                                }

                                &.time {
                                    width: 15px;
                                    min-width: 15px;
                                }

                                &.location {
                                    width: 16px;
                                    min-width: 16px;
                                }
                            }
                            
                            .individualDetail {

                                display: flex;

                                .detail {
                                    display: block;
                                    color: $eurus-brand-blue;
                                    @include fontsize(14);
                                    @include rem-calc(8, padding-right);
                                    @include rem-calc(24, line-height);
                                    @include rem-calc(5, margin-left);
                                    @media screen and (min-width: $bp-desktop__minimum) {
                                        @include fontsize(16);
                                    }
                                    &:not(:last-of-type){
                                        @include rem-calc(5, padding-bottom)
                                    }
                                }
                                

                            }

                        }
                        .additionalPointsWrapper {
                            display: flex;
                            flex-direction: column;
                            row-gap: 5px;
                            border: 1px solid $eurus-tertiary-tint-primary;
                            border-radius: 5px;
                            @include rem-calc(20, margin-top);
                            @include rem-calc(10, padding);

                            @media screen and (min-width: $bp-tablet__small) {
                                @include rem-calc(20, padding);
                            }

                            &:not(:has(.additionalPoint)){
                                display: none;
                            }
                            
                            .additionalPoint {

                                display: flex;
                                align-items: center;
                                column-gap: 5px;

                                span {
                                    @include fontsize(14);
                                    font-family: $font-derailed__bold;
                                    color: $eurus-brand-blue;
                                    @media screen and (min-width: $bp-tablet__small) {
                                        @include fontsize(15);
                                    }
                                }

                                svg {

                                    &.onlineViewing {
                                        align-self: start;
                                        margin-top: 2px;
                                    }

                                }

                            }

                        }
                    }
                }

            }

            .slider-next {
                position: absolute;
                right: 0px;
                top: 50%;
                height: 40px;
                z-index: 10;
                transform: translateY(-50%);
                cursor: pointer;
                @media screen and (min-width: $bp-tablet__small) {
                    right: 50px;
                }
                @media screen and (min-width: $bp-tablet__portrait) {
                    right: 5px;
                    transform: translateY(-50%) scale(1.25);
                }
                @media screen and (min-width: $bp-desktop__minimum) {
                    right: 15px;
                    transform: translateY(-50%) scale(1.4);
                }
                @media screen and (min-width: $bp-content) {
                    right: 30px;
                    transform: translateY(-50%) scale(1.6);
                }
            }
            .slider-prev {
                position: absolute;
                left: 0px;
                top: 50%;
                height: 40px;
                z-index: 10;
                transform: translateY(-50%) rotate(180deg);
                cursor: pointer;
                @media screen and (min-width: $bp-tablet__small) {
                    left: 50px;
                }
                @media screen and (min-width: $bp-tablet__portrait) {
                    left: 5px;
                    transform: translateY(-50%) scale(1.25) rotate(180deg);
                }
                @media screen and (min-width: $bp-desktop__minimum) {
                    left: 15px;
                    transform: translateY(-50%) scale(1.4) rotate(180deg);
                }
                @media screen and (min-width: $bp-content) {
                    left: 30px;
                    transform: translateY(-50%) scale(1.6) rotate(180deg);
                }
            }

        }

    }
    .spinnerBox{
        display:block;
        text-align:center;
        margin:3rem 0;
        grid-column: span 2 / span 2;
        span{
            color: $clearing-blue;
            @extend %fa-icon;
            @extend .fas;
            @include spinIcon(1s, $fa-var-spinner);
            @include rem-calc(60, font-size);
        }
    }
}