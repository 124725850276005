.cards {
    &:last-of-type {
        margin-bottom: 2.5rem !important;
    }

    .card {
        box-shadow: 0px 0px 9px 0px #00000040;
        text-decoration: none;
        @include rem-calc(10, border-radius);
        @include rem-calc(30, padding);
        @include rem-calc(20, margin-bottom);
        display: flex;
        flex-direction: column;
        position: relative;

        @media screen and (min-width: $bp-tablet__landscape) {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }

        &__details {
            margin-bottom: 1rem;
            position: relative;

            @media screen and (min-width: $bp-tablet__landscape) {
                width: 72%;
            }

            &.into {

                .courseSubjectArea,
                .courseNumber {
                    display: none;
                }
                
                .courseTerm {
                    padding-bottom: 0;
                }

                .into-icon {
                    position: absolute;
                    top: -9px;
                    right: 0;
                    width: 50px;
                }

                h4 {
                    @include rem-calc(15, padding-top);
                    @media screen and (min-width: $bp-tablet__landscape) {
                        padding-top: 0;
                    }
                }

            }

            .courseQualification strong,
            .courseTerm strong,
            .courseSubjectArea strong,
            .courseNumber strong {
                color: $eurus-brand-blue;
            }

            h4 {
                color: #0080a5;
                @include rem-calc(22, font-size);
                @include rem-calc(25.78, line-height);
                font-weight: 700;
                padding-bottom: 0.75rem;
                padding-top: 0;

                &:after {
                    @extend %fa-icon;
                    @extend .fas;
                    content: fa-content($fa-var-chevron-right);
                    position: relative;
                    @include rem-calc(16.5, font-size);
                    @include rem-calc(-2.3, top);
                    @include rem-calc(11, left);
                    @include animation(left, 0.3s);
                }

                &:hover {
                    &:after {
                        @include rem-calc(20, left);
                    }
                }

                a {
                    text-decoration: none;

                    &:hover,
                    &:focus {
                        color: inherit;
                    }
                }
            }

            p {
                color: #4c4c4c;
            }

            p:first-of-type {
                padding-bottom: 0;
            }
        }

        &__image {
            @media screen and (min-width: $bp-tablet__landscape) {
                @include rem-calc(236, width);
                @include rem-calc(162, height);
            }

            img {
                @include rem-calc(10, padding-top);
            }
        }

        &:not(.no-link){
            &:hover,
            &:focus {
                box-shadow: 0px 0px 9px 0px #0080a5;
            }
        }

        &.no-link {
            cursor: default;
            .card__details{
                h4{
                    &::after{
                        content: "";
                    }
                }
            }
        }

        // added for the generic card styling
        &:not(.shown) {
            display: none;
        }

        &.module {

            border-radius: 10px;
            border: 1px solid $eurus-base-tint-primary;
            box-shadow: 0px 0px 9px 0px rgba(0, 58, 101, 0.40);

            .card__details {
                width: 100%;

                h4 {
                    color: $eurus-mid-blue;
                    @include rem-calc(0 0 10 0, padding);

                    &::after {
                        font-family: "Font Awesome\ 5 Free";
                        content: "\f061";
                        position: relative;
                        font-size: 1.03125rem;
                        top: -.14375rem;
                        left: .6875rem;
                        -webkit-transition: left .3s ease-in-out;
                        transition: left .3s ease-in-out;
                    }
                }
                .module_description {
                    @include rem-calc(15, padding-top);
                    color: #4a4a4a;
                }
                .module_info {
                    color: $eurus-logo-red;
                    @include fontsize(16);

                    span {
                        color: $eurus-brand-blue
                    }
                }
                .card_tags {
                    color: $eurus-brand-blue;
                    @include fontsize(16);
                    @include rem-calc(20, padding-top);
                    display: flex;
                    flex-direction: column;
                    row-gap: 5px;

                    .subjectArea {
                        font-weight: 700;
                        display: inline-flex;
                        column-gap: 8px;
                        align-items: center;
                    }
                }

            }
        }
    }
}

#courseSearchDisplay {
    .cards {

        .card {

            &__details {
                h4 {
                    color: $eurus-mid-blue;
                    &::after {
                        font-family: 'Font Awesome\ 5 Free';
                        content: '\f061';
                    }
                }

                .withdrawnAlert {
                    border: 1px solid $eurus-mid-blue;
                    font-family: $font-derailed__bold;
                    @include fontsize(18);
                    color: $eurus-brand-blue;
                    @include rem-calc(10, padding);
                    
                    span {
                        &::before {
                            font-family: 'Font Awesome\ 5 Free';
                            content: '\f06a';
                            color: $eurus-mid-blue;
                            margin-right: 5px;
                        }
                    }
                }

                .dropdown {
                    margin-top: 0;
                    border: 1px solid $eurus-mid-blue;
                    background: $eurus-white;

                    .dropDownHeading {
                        font-family: $font-derailed__bold;
                        @include fontsize(18);
                        color: $eurus-brand-blue;
                        @include rem-calc(10, padding);
                        border-bottom: 0;
                        border-color: $eurus-mid-blue;

                        &::before {
                            font-family: 'Font Awesome\ 5 Free';
                            content: '\f06a';
                            color: $eurus-mid-blue;
                        }

                        &.dropdownOpen {
                            .toggle {
                                transform: translateY(-50%);
                                &::after{
                                    transform: translate(-50%, -50%) rotate(180deg);
                                }
                            }
                        }

                    }
                    
                    .dropDownSummary {
                        padding-top: 0;
                        
                        p {
                            padding-top: 0;
                        }
                    }

                    .toggle {
                        border-radius: 5px;
                        background: $eurus-light-blue;         
                        @include rem-calc(10, right); 

                        @media screen and (min-width: $bp-desktop__minimum) {
                            height: 1.5rem;
                            width: 1.5rem;
                        }

                        &::after {
                            font-size: 1rem;
                            font-family: 'Font Awesome\ 5 Free';
                            content: '\f078';
                            background: none;
                            height: unset;
                            width: unset;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            position: absolute;
                        }

                    }
                }

            }

            &:not(.withdrawn){
                &:hover {
                    h4 {
                        text-decoration: underline;
                        &::after {
                            @include rem-calc(20, left);
                        }
                    }
                }
            }

            &.withdrawn{

                p:first-of-type {
                    @include rem-calc(15, padding-bottom);
                }

/*                 h4 {
                    &::after {
                        content: "";
                    }
                } */

                &:hover {
                    box-shadow: none;
                    border: 2px solid $eurus-base-tint-primary;
                    h4 {
                        text-decoration: underline;
                        &::after {
                            @include rem-calc(20, left);
                        }
                    }
                }
            }


        }

    }
}

.eurus {
    .cards {

    }
}