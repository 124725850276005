// Helper Classes

// Helper Classes

.accessiblyHidden {
    @include accessible-hidden-element;
}

.hidden {
    display: none !important;
}

.visible {
    visibility: visible !important;
}

.tabHighlight {
    a,
    area,
    button,
    input,
    select,
    textarea,
    div {
        &:focus {
            outline: 4px dashed $linktext;

            & + label {
                &:before {
                    outline: 4px dashed $linktext;
                }
            }
        }
    }
}

.mobile {
    @media screen and (min-width: $bp-tablet__portrait) {
        display: none;
    }
}

.tablet {
    display: none;

    @media screen and (min-width: $bp-tablet__portrait) {
        display: inherit;
    }
}

.desktop {
    display: none;

    @media screen and (min-width: $bp-tablet__landscape) {
        display: inherit;
    }
}

.noScroll {
    overflow: hidden;
    position: fixed;
}

.noScrollX {
    overflow-x: hidden;
    position: fixed;
}

.noScrollY {
    overflow-y: hidden;
    position: fixed;
}

.clearfix {
    &:after {
        clear: both;
        content: ' ';
        display: block;
    }
}

iframe.override {
    width: auto;
}