// CTA Block Styles

.ctaBlock {
    @include fontsize(10);
    @include rem-calc(260, min-height);                     /* changed from 390 */
    @include rem-calc(40 0, padding);

    align-items: center;
    background-color: $global-brand-blue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    position: relative;

    @media screen and (min-width: $bp-desktop__minimum) {
        @include rem-calc(313, min-height);                 /* changed from 470 */
    }

    h2 {
        color:white;
    }

    &.fullWidthRibbon {
        background-color: $global-brand-blue;

        &.colourA {
            background-color:$global-brand-blue;
        }

        &.colourB {
            background-color: $global-brand-blue;
        }

        &.colourC {
            background-color: $global-brand-blue;
        }

        .ctaContainer {
            justify-content: center;

            .ctaColumn {
                max-width: $width-full;

                &:only-child {
                    max-width: $width-full;

                    @media screen and (min-width: $bp-tablet__portrait) {
                        margin-left: $quarter;
                        margin-right: $quarter;
                        max-width: $half;
                    }
                }
            }
        }
    }
}

.ctaBlock.fullWidthRibbon {
    min-height: 0;
}

.ctaBlockContent {
    position: relative;
    z-index: 1;

    h3 {
        @include fontsize(25);
        @include rem-calc(5, margin-bottom);

        color: $ctablock__color;

        @media screen and (min-width: $bp-tablet__landscape) {
            @include fontsize(50);
        }
    }
}

.ctaBlockLink {
    @include fontsize(15);
    @include rem-calc(5, margin-top);

    color: $ctablock__color;
    float: right;
    text-decoration: underline;

    @media screen and (min-width: $bp-tablet__landscape) {
        @include fontsize(14);
        @include rem-calc(15, margin-top);
    }

    &:visited,
    &:hover,
    &:focus {
        color: $ctablock__color;
    }
}
