.search {
    @include fontsize(10);

    z-index: $zindex-search;
    position: relative;

    .container > & {
        background-color: $search__bgcolor;
    }

    header & {

        .row {
            @include rem-calc(0 16, padding);
            background-color: $search-hero__bgcolor;
            max-width: $search-hero__maxwidth;

            @media screen and (min-width: $bp-tablet__landscape) {
                @include rem-calc(0 30, padding);
            }
        }
    }

    .searchCenter {
        border-radius:3px;
        padding: 0 1.2rem;
    }

    form {
        @include fontsize(14);
        @include rem-calc(16 0, padding);
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @media screen and (min-width: $bp-tablet__landscape) {
            @include fontsize(18);
            @include rem-calc(36 0, padding);
        }
    }

    .inputWrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > * + * {

            @media screen and (min-width: $bp-mobile__landscape) {
                @include rem-calc(24, margin-left);
            }
        }

        legend {
            @include fontsize(20);
            font-family: $font-search-label;
            line-height: 1.25;
            width: 100%;
            margin-bottom: 0.5rem;

            @media screen and (min-width: $bp-mobile__landscape) {
                width: auto;
            }

            @media screen and (min-width: $bp-tablet__landscape) {
                line-height: 1.4;
                margin-bottom:0;
            }
        }
    }

    .fieldWrapper {
        position: relative;
    }

    .filterChoice {

        label {
            @include fontsize(16);
            font-family: $font-search-label;

            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }

        & + .filterChoice {
            @include rem-calc(16, margin-left);
        }
    }

    .filterSubmit {
        @include rem-calc(1, padding);
        @include rem-calc($search-box__width, max-width);
        @include rem-calc($search-box__height, max-height); // max height fix for ie11 <
        border: 1px solid $white;
        position: absolute;
        right: -6px;
        top: 0;
        width: 100%;
        height: 100%;
    }

    input[type='radio'] + label:before {
        @include rem-calc(16, height);
        @include rem-calc(16, width);
        @include rem-calc(10, margin-right);
        @include rem-calc(-8, margin-top);

        background: $search-radio__color;
        border: 3px solid $search-radio__color;
        border-radius: $half;
        content: '';
        display: inline-block;
        vertical-align: middle;

        @media screen and (min-width: $bp-content) {
            @include rem-calc(24, height);
            @include rem-calc(24, width);

            border-width: 5px;
        }
    }

    input[type='radio']:checked + label:before {
        background: $search-radio-selected__color;
    }

    .searchBoxInput {
        @include fontsize(18);
        @include rem-calc($standard-unit, padding);
        @include rem-calc($search-box__width, height);
        width: calc(100% - #{$search-box__width - 1px});

        @media screen and (min-width: $bp-tablet__landscape) {
            @include fontsize(24);
        }
        &.fullWidth{
            width:100%;
        }
    }

    .searchFilters {
        @include rem-calc(20, margin-bottom);
    }

    .searchSubmit {

        &:hover,
        &:active,
        &:focus {
            background-color: $accenthover;
            border-color: transparent;
        }
    }
    .searchCenter, &{

        &.colour0{
            background-color: $search-colour0__bgcolor;
        }

        &.colourA {
            background-color: $search-colourA__bgcolor;
        }

        &.colourB {
            background-color: $search-colourB__bgcolor;
        }

        &.colourC {
            background-color: $search-colourC__bgcolor;
        }
        &.colourClearing{
            background: $clearing-brand-accent;
        }
        &.colourTransparent{
            background-color: transparent;
            padding:0;
            p{
                color: $font-body__color;
            }
            .searchBoxInput{
                color: #4c4c4c;
                border: 1px solid #4a4a4a;
                &:hover{
                    background-color: #f7f7f7;
                }
                &:focus{
                    background-color: $white;
                    border:2px solid #037EAF;
                }
                &::placeholder{
                    color: $slate-gamma;
                }
            }
            .filterSubmit{
                border: 1px solid #4a4a4a;
                background-color: #4c4c4c;
            }
        }
        &.homepage-search{
            form{
                @include rem-calc(19.5 16, padding);
                @media screen and (min-width: $bp-tablet__landscape) {
                    @include rem-calc(23 0, padding);
                }
                .searchFilters{
                    @media screen and (max-width: $bp-tablet__landscape) {
                        margin-bottom:1rem;
                    }
                    .inputWrapper{
                        label{
                            @include rem-calc(18, font-size);
                            @include rem-calc(21.1, line-height);
                            font-family: $search-bold;
                            @media screen and (min-width: $bp-tablet__landscape) {
                                @include rem-calc(25, font-size);
                                @include rem-calc(29.3, line-height);
                            }
                            
                        }
                    }
                }
                .searchBox{
                    .fieldWrapper{
                        display: flex;
                        flex-wrap: wrap;
                        @media screen and (min-width: $bp-tablet__landscape) {
                            flex-wrap:nowrap;
                        }
                        .selectBox{
                            width:100%;
                            @include rem-calc(7, margin-bottom);
                            @media screen and (min-width: $bp-tablet__landscape) {
                                @include rem-calc(10, margin-right);
                                @include rem-calc(10, margin-bottom);
                            }
                            .wrap{
                                margin-bottom:0;
                                overflow: hidden;
                                position: relative;
                                display: block;
                                @extend %fa-icon;
                                @extend .fas;
                                select{
                                    font-family: Bariol;
                                    @include rem-calc(18, font-size);
                                    @include rem-calc(19.84, line-height);
                                    color: $color-beta;
                                    background-color: white;
                                    width:100%;
                                    @include rem-calc(43, height);
                                    border: 1px solid #fff;
                                    @include rem-calc(0 16, padding);
                                    appearance: none;
                                    @media screen and (min-width: $bp-tablet__landscape) {
                                        height:4rem;
                                        @include rem-calc(24, font-size);
                                        @include rem-calc(26.45, line-height);
                                    }

                                    &.error{
                                        border:3px solid #D6062A;
                                        @include rem-calc(46, padding-left);
                                        @media screen and (min-width: $bp-tablet__landscape) {
                                            @include rem-calc(62, padding-left);
                                        }
                                    }
                                }
                                &:after{
                                    content: fa-content($fa-var-chevron-down);
                                    position: absolute;
                                    pointer-events: none;
                                    @include rem-calc(14, right);
                                    @include rem-calc(14, top);
                                    color: $color-beta;
                                    @include rem-calc(20, font-size);
                                    @media screen and (min-width: $bp-tablet__landscape) {
                                        @include rem-calc(20, right);
                                        @include rem-calc(20, top);
                                        @include rem-calc(31, font-size);
                                    }
                                }

                                &.error{
                                    &:before{
                                        content: fa-content($fa-var-exclamation-triangle);
                                        color: #D6062A;
                                        position: absolute;
                                        pointer-events: none;
                                        backface-visibility: visible;
                                        z-index: 1;
                                        @include rem-calc(10, left);
                                        @include rem-calc(10, top);
                                        @include rem-calc(25, font-size);
                                        @media screen and (min-width: $bp-tablet__landscape) {
                                            @include rem-calc(23, left);
                                            @include rem-calc(18, top);
                                            @include rem-calc(28, font-size);
                                        }
                                    }
                                }
                            }

                            @media screen and (min-width: $bp-tablet__landscape) {
                                width: 35%;
                                margin-bottom:0;
                            }
                        }
                        .searchBoxInput{
                            font-family: Bariol;
                            font-weight:400;
                            @include rem-calc(24, font-size);
                            @include rem-calc(26.45, line-height);
                            background-color: $white;
                            color: $color-beta;
                            border: 1px solid $white;
                            width:60%;
                            @include rem-calc(43, height);
                            @include rem-calc(7, margin-right);
                            &:focus{
                                background-color: $white;
                                border:2px solid #037EAF;
                            }
                            &::placeholder{
                                color: $color-beta;
                            }
                            @media screen and (min-width: $bp-tablet__landscape) {
                                height:4rem;
                                @include rem-calc(10, margin-right);
                            }
                            @media screen and (max-width: $bp-tablet__landscape) {
                                @include rem-calc(18, font-size);
                                @include rem-calc(19.84, line-height);
                                flex:1;
                            }
                        }
                        .filterSubmit{
                            right:0;
                            max-width: auto;
                            max-height: auto;
                            position: relative;
                            @include rem-calc(43, width);
                            @include rem-calc(43, height);
                            border-radius:5px;
                            @media screen and (min-width: $bp-tablet__landscape) {
                                width:4rem;
                                height:4rem;
                            }
                        }
                    }
                }
            }

            .courseLinksContainer{
                position: relative;
                height:auto;
                @include rem-calc(10 16, padding);
                @media screen and (min-width: $bp-tablet__landscape) {
                    @include rem-calc(56, height);
                    padding:0;
                }
                .overlay{
                    background-color: rgba(0,0,0,.2);
                }
                .row{
                    height:100%;
                }
                ul{
                    display:block;
                    @media screen and (min-width: $bp-tablet__landscape) {
                        display:flex;
                        align-items: center;
                    }
                    height:100%;
                    margin:0;
                    list-style-type: none;
                    padding:0;
                    li{
                        margin-bottom:0;
                        @include rem-calc(43, margin-right);
                        a{
                            @include rem-calc(14, font-size);
                            @include rem-calc(24, line-height);
                            @media screen and (min-width: $bp-tablet__landscape) {
                                @include rem-calc(16, font-size);
                            }
                            color: $white;
                            &:hover, &:focus{
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}


.search {

    &.searchMulti {

        @include rem-calc(30, padding-top);

        form {

            @include rem-calc(0 0 20 0, padding);
            @media screen and (min-width: $bp-tablet__landscape) {
                @include rem-calc(30, padding-bottom);
            }

            .searchFilters {

                align-items: center;

                .inputWrapper {

                    label {
                        @include fontsize(24);

                        @media screen and (min-width: $bp-tablet__landscape) {
                            @include fontsize(35);
                        }
                    }

                    legend {
                        margin-bottom: 0;
                    }
                }
            }
            
            .searchBox {

                .fieldWrapper {
                    .selectBox {
                        .wrap {
                            select {
                                color: $eurus-brand-blue;
                                @include fontsize(15);
                                font-family: $font-derailed__semibold;

                                @media screen and (min-width: $bp-mobile__landscape) {
                                    @include fontsize(18);
                                }
                            }

                            &::after {
                                content: "\f0d7";
                                color: $eurus-brand-blue;
                                @include fontsize(30);
                                transform: translateY(-50%);
                                top: 50%;
                                @media screen and (min-width: $bp-mobile__landscape) {
                                    @include fontsize(40);
                                }
                            }
                        }
                    }
                    .searchBoxInput {
                        font-family: $font-derailed__regular;
                        @include fontsize(15);

                        @media screen and (min-width: $bp-mobile__landscape) {
                            @include fontsize(18);
                        }
                    }
                    .filterSubmit {
                        position: relative;
                        overflow: hidden;
/*                         &:after {
                            content: fa-content($fa-var-search);
                            color: $eurus-white;
                            font-family: 'Font Awesome 5 Free';
                            font-size: 1.5rem;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }*/


                        button {

                            position: relative;
                            height: 100%;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            border: none;
                            background: none;
                            cursor: pointer;
                            padding: 0;
                            svg {
                                transform: translate(-50%, -50%);
                                position: absolute;
                                background: transparent;
                                left: 48%;
                                top: 48%;
                            }
                            .searchSubmit {
                                background: none;
                                @include rem-calc(60, width);
                            }

                            &:hover {
                                .searchSubmit {
                                    @include animation(all);
                                    background-color: transparent;
                                }
                            }

                        }


                    }
                }

            }


        }

        .courseLinksContainer {

            // border-top: 1px solid $eurus-white;
            height: auto;
            // @include rem-calc(30 0, padding);

            .courseLinksContainerInner {
                @media screen and (min-width: $bp-tablet__landscape) {
                    padding: 0 0.9375rem;
                }

                ul {
                    border-top: 1px solid #fff;
                    padding: 0.9rem 0;
                    display:flex;
                    flex-direction: column;
                    row-gap: 5px;
                    @media screen and (min-width: $bp-tablet__portrait) {
                        display:flex;
                        align-items: center;
                        padding: 1.875rem 0;
                        flex-direction: row;
                    }
                    a {
                        text-decoration: underline;
                    }
                }
            }

        }


    }
    &.searchLayout {

        form {
            .searchFilters {
                align-items: center;

                .inputWrapper {
                    align-items: center;
                    legend {
                        margin-bottom: 0;
                    }
                    label {
                        &::before {
                            margin-top: -0.25rem;
                        }
                    }
                }
            }           
        }


        &.midBlueSearch {
            background-color: $eurus-mid-blue;
            form {
                .searchFilters {
                    .inputWrapper {
                        label {
                            color: $eurus-white;
                        }
                    }
                }
                .searchBox {
                    .fieldWrapper {
                        .searchBoxInput {
                            &:focus {
                                border: 2px solid $eurus-dark-blue;
                            }
                        }
                    }
                }

                .filterSubmit {
                    button {
                        svg {
                            color: $eurus-white;
                        }
                    }
                    &:hover {
                        background-color: $eurus-dark-blue;
                    }
                }
            }
            legend {
                color: $eurus-white;
            }
        }
        &.brandBlueSearch {
            background-color: $eurus-brand-blue;
            color: $eurus-white;
            form {
                .searchFilters {
                    .inputWrapper {
                        label {
                            color: $eurus-white;
                        }
                    }
                }
                .filterSubmit {
                    button {
                        svg {
                            color: $eurus-white;
                        }
                    }
                    &:hover {
                        background-color: $eurus-dark-blue;
                    }
                }
                legend {
                    color: $eurus-white;
                }
            }
        }
        &.whiteSearch {
            background-color: $eurus-white;
            form {
                .searchFilters {
                    .inputWrapper {
                        label {
                            color: $eurus-dark-blue;
                        }
                    }
                }
                
                .searchBox {
                    .fieldWrapper {
                        .selectBox {
                            border: 1px solid $eurus-dark-blue;
                        }
                        .searchBoxInput {
                            border: 1px solid $eurus-dark-blue;
                            &:focus {
                                border: 2px solid $eurus-mid-blue;
                            }
                        }
                        .filterSubmit{
                            border: 1px solid $eurus-dark-blue;
                            &:after {
                                color: $eurus-brand-blue;
                            }
                            button {
                                svg {
                                    color: $eurus-dark-blue;
                                }
                            }
                            &:hover {
                                background-color: $eurus-dark-blue;
                                svg {
                                    color: $eurus-white;
                                }
                            }
                        }
                    }
                }
                legend {
                    color: $eurus-dark-blue;
                }
            }
            .courseLinksContainer {
                .courseLinksContainerInner {

                    ul {
                        border-top: 1px solid $eurus-tertiary-tint-primary;
                        a {
                            color: $eurus-mid-blue;
                        }
                    }
                }

            }
        }
    }
    &.courseLevelSpecific {
        .searchBox {
            .searchBoxInput {
                flex: 1;
            }
        }
    }

}