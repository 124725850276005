// Global & HTML Content Variables

$body__bgcolor: $white;
$scrollbar__opacity: 0.8;

$font-body__color: $bodytext;
$font-body__fontweight: 400;

$selection__color: $white;
$selection__bgcolor: $generic;
$selection-primary__bgcolor: $brand-primary;
$selection-secondary__bgcolor: $brand-secondary;
$selection-tertiary__bgcolor: $brand-tertiary;

$logo__width: 167px;
$logo__height: 62px;

$heading__color: $headings;
$heading-h3__color: $headings-accentA;  // added to test different heading colour in Study
$heading-h4__color: $headings-accentB;  // added to test different heading colour in Study

$global-hyperlink__color: $linktext;
$global-hyperlink-hover__color: $linkhover;
$global-hyperlink-hover__bgcolor: $linkhover__bgcolor;
$global-hyperlink-visited__color: $linkvisited;

$global-paragraph__fontsize-mobile: $fontsize-body; // either $font-context by default or custom based on design
$global-paragraph__fontsize: 20; // either $font-context by default or custom based on design

$global-htmlcontent-leadparagraph__fontsize: 17;
$global-htmlcontent-leadparagraph__fontweight: 600;
$global-htmlcontent-blockquote__color: $headings;
$global-htmlcontent-blockquote__fontfamily: $font-heading;
$global-htmlcontent-blockquote__fontweight: 600;
$global-htmlcontent-blockquote__borderleft: 0.5em solid $headings;
$global-htmlcontent-cite__color: $bodytext;
$global-htmlcontent-table__border: 1px solid $generic;
$global-htmlcontent-table-head__bgcolor: lighten($generic, 10%);
$global-htmlcontent-table-head__color: #fff;
$global-htmlcontent-table-head__fontfamily: $font-heading;

$list__border: 0;
$list__bgcolor: 0;

// Spacing between content blocks (pixels)
$section-margin-mobile: 20;
$section-margin-desktop: 40;

// Safe area for top and bottom of iOS11
$ios11-safe-area: 85;
