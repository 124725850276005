// CTA Variables

$cta__fontfamily: $font-button;
$cta__color: $global-brand-blue;
$cta-hover__color: $white;
$cta__bgcolor: $eurus-yellow;
$cta_border-radius: 5px;
$cta-hover__bgcolor: $eurus-dark-blue;

// link button
$cta-link__bgcolor: $white;
$cta-link__bghovercolor: $eurus-brand-blue;

// ghost header btn
$cta-ghost__bgcolor: $white;
$cta-ghost__bordercolor: $white;

// jump btn
$cta-jump__bgcolor: $eurus-light-blue;

$ctablock__color: $white;
