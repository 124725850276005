//Full Width Banner Variables

$banner__height: 300;
$banner-image__desktop__height: 400;
$banner-image__mobile__height: 200;

$banner__tablet__min-height: 300;
$banner-breakout__min-height: 400;

$banner_opacity: 0.5;

$banner-overlay: $overlay;
$banner_gradient_colour_from: $gradient-bottom;      /* changed from rgba($color-epsilon, 0.1);*/
$banner_gradient_colour_to: $gradient-top;        /* changed from rgba($color-epsilon, 0.2); */  