.ucas {

    ol {
        margin-left: 1rem;
    }

    select {
        @include rem-calc(8 12, padding);
        font-family: $font-derailed__regular;
        font-size: 1rem;

        @media screen and (min-width: $bp-tablet__portrait) {
            @include rem-calc(8 15 8 12, padding);
        }

    }

    button.cta::after{
        content: '';
    }

    .innerContainer {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        @media screen and (min-width: $bp-tablet__portrait) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
     
        .half {

            &:first-of-type {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border: 2px solid lightgrey;
                border-radius: 5px;
                @include rem-calc(15 10, padding);

                @media screen and (min-width: $bp-tablet__portrait){
                    @include rem-calc(25 20, padding);
                } 

            }

            @media screen and (min-width: $bp-tablet__portrait){
                padding: 1.5rem 1.25rem;
            }
            #qualificationsWrapper {
                h3 {
                    font-size: 1.5rem;
                    font-weight: 600;
                    padding-bottom: 1.5rem;
                    @media screen and (min-width: $bp-tablet__portrait){
                        font-size: 2.0rem;
                        padding-bottom: 2rem;
                    }
                }
            }   
        }

        #ucasInstructions {
            background-color: #f7f7f7;
            padding: 0.75rem 1.5rem;
            border-radius: 5px;
            filter: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.07));
            ol {
                padding-top: 0;
            }
        }

        .qualification {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 10px;
            &:not(:first-of-type){
                padding-top: 10px;
                border-top: 1px solid rgb(236, 236, 236);
            }
            &:last-of-type{
                padding-bottom: 0px;
            }
            .qualificationWrapperInner{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                row-gap: 10px;
                width: 100%;
                .number {
                    width: 25px;
                }
                .selectWrapper {
                    overflow: hidden;
                    position: relative;
                    display: block;
                    width: calc(100% - 50px);
                    &:after {
                        content: "\f078";
                        position: absolute;
                        pointer-events: none;
                        color: #4a4a4a;
                        right: 20px;
                        top: 4px;
                        font-size: 1.25rem;
                        font-family: 'Font Awesome 5 Free';
                        /* transform: translateY(-50%); */
                        transform: translate(50%);
                    }
                    &.grades {
                        margin-left: 25px; 
                    }
                    .qualificationSelect {                    
                        appearance: none;
                        border: solid 1px black;
                        border-radius: 2px;
                        width: 100%;
                    }
                    .gradeSelect {
                        appearance: none;
                        border: solid 1px black;
                        border-radius: 2px;
                        width: 100%;
                    }
                }

            }
        }

        .removeQualification {
            width: 30px;
            height: 30px;
            text-align: center;
            border-radius: 5px;
            background-color: #088300;
            color: #ffffff;
            &::after {
                content:"\f1f8";
                font-family: Font Awesome\ 5 Free;
            }
        }

        .buttonWrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            @include rem-calc(100, margin-top);
            @media screen and (min-width: $bp-tablet__portrait){
            
            }


            .cta {
                width: 100%;
                margin: 0;
                margin-top: 0.75rem;
            }

            .btnOutline {
                background-color: transparent;
                color: #000;
                border-color: #000;
                font-size: 1.0rem;
                border: 1px solid #000;
                border-radius: 5px;
                padding: 0.5rem 0.75rem;
                font-family: $font-bariol;
            }

            #ucasResults {
                font-family: $font-derailed__bold;
                width: 100%;
                margin-top: 1.0rem;
                font-size: 1.50rem;
                border-radius: 5px;

                #points {
                    color: #0080a5;
                }
            }

        }
    }

}