// Promo Banner


.promoBanner {
    height: auto;

    p {
        @include rem-calc(16, margin-top);
    }

    .bannerContainer {
        display: flex;
        height: auto;
        width: $width-full;
        position: relative;
        background-color: $imagepanel-bgcolor;

        @media screen and (min-width: $bp-desktop__minimum) {
            @include rem-calc($promoBanner_min-height, min-height);
            @include rem-calc($promoBanner_max-height, max-height);
        }

        &:before {
            @include stretch;
            background: linear-gradient(180deg, rgba(0,0,0,.54) 0%, rgba(0,0,0,.85) 100%);
            z-index: $zindex-promoBanner-overlay;	
				
			  @media screen and (min-width: $bp-desktop__minimum) {
                  @include background-gradient($promoBanner_gradient_colour_from, $promoBanner_gradient_colour_to);	
				  
       		  }		
       		}

    } 

    .row {
        display: flex;
    }

    .banner-wrapper {
        display: flex;
        flex-direction: column;
        width: $width-full;
        @include rem-calc(60, padding-top);

        @media screen and (min-width: $bp-tablet__portrait) {
            flex-direction: row;
            justify-content: space-between;
            padding-top:0;
        }
    }

    .text-container {
        @include fontsize(16);
        @include rem-calc(16 16 16 16, padding);

        color: $white;
        position: relative;
        width: $width-full;
        z-index: $zindex-promoBanner-text-container;

        @media screen and (min-width: $bp-tablet__portrait) {
            @include rem-calc(100 32 32 32, padding);

            display: flex;
            flex-direction: column;
            flex: auto; 
            justify-content: flex-end;
            position: relative;
        }
    }
                                                		
	.ctaContainer {                                             /* NEW for CTA button */
        z-index: $zindex-promoBanner-text-container;	
        			
	        @media screen and (min-width: $bp-tablet__portrait) {
		        display: flex;
		        flex: 0 0 $one-third;
        }	
		
	}
	
	.ctaColumn {
		align-self: flex-end; 
        @include rem-calc(0 8, margin);
        
		    @media screen and (min-width: $bp-tablet__portrait) {
		        width: $width-full;
		        max-width: $width-full;
		        @include rem-calc(0 20 0 0, margin); 
        }
	
    }                                                   /* end new for CTA button */
    
    .headline {
        @extend .h2;
        color: $white;
        padding: 0;

        @media screen and (min-width: $bp-tablet__landscape) {
            @include fontsize(32);
        }
    }

    .image-overlay {
        @include stretch;

        background-position: center;
        background-size: cover;
    }

}

.eurus {
    .promoBanner {
        h2 {
            color: $eurus-white !important;
        }
    }
}
