// Carousel Styling - Video, Image and Combined

.no-js {
    .slide img,
    .slide iframe {
        width: auto;
    }

    .noJSWarning {
        display: block;
        text-align: center;
    }

    .slickSlider {
        min-height: 0;

        &.loading {
            background: none;

            .slide {
                @include rem-calc($standard-unit * 2, margin-bottom);

                opacity: 1;
                width: $width-full;
            }
        }
    }
}

/* -----------------
SACS gallery styling
------------------*/
.gallery:not(.mediaGallery) {
    @include fontsize(10);
    @include rem-calc(128, padding-bottom); 

    overflow: hidden;

    @media screen and (min-width: $bp-tablet__landscape) {
        @include rem-calc(96, padding-bottom);
    }

    .slickSlider {
        position: relative;

        @media screen and (min-width: $bp-tablet__portrait) {
            @include rem-calc($gallery-original__height, min-height);
        }

        > * {
            transition: opacity 1s ease-in;
        }

        &.loading {
            background: url($ajax-loading) center center no-repeat;

            > * {
                opacity: 0;
            }
        }
    }

    .slick-list {
        overflow: visible;
    }

    .slide {
        @include rem-calc(0 15, padding);

        backface-visibility: visible;
        height: auto;
        position: relative;
        width: $viewport-width-max;

        @media screen and (min-width: $bp-tablet__portrait) {
            @include rem-calc(12, padding-left);
            @include rem-calc(12, padding-right);

            float: left;
            height: auto;
            margin-bottom: 0;
            width: auto;
        }

        &.videoSlide {
            img {
                height: auto;
                width: auto;
            }
        }

        &:not(.videoSlide) {
            img {
                @include rem-calc(375, max-height);
            }
        }

        img,
        iframe {
            display: block;
            height: auto;
            margin: 0 auto;
            max-width: $width-full;
            width: $width-full;

            @media screen and (min-width: $bp-tablet__portrait) {
                width: auto;
            }
        }
    }

    .videoSlide {
        @media screen and (min-width: $bp-tablet__portrait) {
            @include rem-calc(714, width);
            @include rem-calc($gallery-original__height, min-height);

            max-width: $width-full;
        }
    }

    .slick-arrow {
        @include fontsize(10);
        @include rem-calc(46, height);
        @include rem-calc(46, width);
        @include rem-calc(-23, margin-top);

        background-color: $gallery-original-arrow__color;
        border: none;
        border-radius: $half;
        cursor: pointer;
        display: block;
        opacity: 0.8;
        padding: 0;
        position: absolute;
        text-indent: -9999px;
        top: calc(((#{$viewport-width-max} - 4em) * 0.53) / 2);
        transition: opacity 0.3s ease-in-out;
        z-index: 1;

        @media screen and (min-width: $bp-tablet__portrait) {
            @include rem-calc(94, height);
            @include rem-calc(94, width);

            top: 55%;
        }

        &:focus,
        &:hover {
            opacity: 1;
        }

        &:after {
            @include rem-calc(29, height);
            @include rem-calc(19, width);

            background: url($carousel__arrow) 0 0 no-repeat;
            background-size: $width-full auto;
            content: '';
            display: block;
            left: $half;
            position: absolute;
            top: $half;
            transform: translate(-60%, -$half);

            @media screen and (min-width: $bp-tablet__landscape) {
                @include rem-calc(56, height);
                @include rem-calc(34, width);
            }
        }
    }

    &.colourA {
        .slick-arrow {
            background-color: $colourA-gallery-original-arrow__color;
        }
    }

    &.colourB {
        .slick-arrow {
            background-color: $colourB-gallery-original-arrow__color;
        }
    }

    &.colourC {
        .slick-arrow {
            background-color: $colourC-gallery-original-arrow__color;
        }
    }

    .slick-prev {
        @include rem-calc(5, left);

        @media screen and (min-width: $bp-tablet__portrait) {
            @include rem-calc(32, left);

            transform: translateY(-$half);
        }
    }

    .slick-next {
        @include rem-calc(5, right);

        transform: rotate(-180deg);

        @media screen and (min-width: $bp-tablet__portrait) {
            @include rem-calc(32, right);

            transform: rotate(-180deg) translateY($half);
        }
    }

    .galleryCaption {
        @include fontsize(15);
        @include rem-calc(15.7, left);

        opacity: 0;
        position: absolute;
        top: $height-full;
        transition: opacity 0.4s ease-in-out;
        width: calc(#{$width-full} - 3.14em);

        @media screen and (min-width: $bp-tablet__landscape) {
            @include rem-calc(12.2, left);

            width: calc(#{$width-full} - 2.44em);
        }
    }

    .videoGalleryOverlay {
        @include rem-calc(12, left);
        @include rem-calc($gallery-original__height, height);

        width: calc(#{$width-full} - 2.4em);
        z-index: 1;

        &:after {
            opacity: 0;
        }

        &:hover {
            &:after {
                opacity: 0;
            }
        }
    }

    .slick-current {
        .galleryCaption {
            opacity: 1;
        }

        .videoGalleryOverlay {
            &:after {
                opacity: 0.8;
            }

            &:hover {
                &:after {
                    opacity: 1;
                }
            }
        }
    }

    .fluid-width-video-wrapper {
        z-index: 0;
        visibility: visible;

        @media screen and (min-width: $bp-tablet__portrait) {
            visibility: hidden;
        }

        &.visible {
            visibility: visible;
        }

        iframe,
        object,
        embed {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
}

/* -----------------
SACS gallery NOJS styling
------------------*/
.noJSWarning {
    display: none;
}

.no-js {
    .slide {
        img,
        iframe {
            width: auto;
        }
    }

    .slickSlider.loading .slide {
        margin-bottom: 2em;
        opacity: 1;
        width: 100%;
    }

    .videoSlide {
        display: none;
        min-height: 0;
    }

    .galleryCaption {
        margin: 1em auto;
        max-width: 69em;
        opacity: 1;
        position: static;
    }

    .noJSWarning {
        display: block;
        text-align: center;
    }

    .slickSlider {
        min-height: 0;

        &.loading {
            background: none;

            > * {
                opacity: 1;
            }
        }
    }
}

/* -----------------
UG2018 media gallery
------------------*/
.gallery.mediaGallery {
    @include rem-calc(40, padding-top);

    overflow: hidden;

    @media screen and (min-width: $bp-content) {
        @include rem-calc(80, padding-top);
    }

    h2,
    h3 {
        margin-top: 0;

        &:empty {
            display: none;
        }
    }

    h2 {
        @include rem-calc(10, margin-bottom);
    }

    h3 {
        @include fontsize(28);
        @include rem-calc(10, margin-bottom);

        color: $generic;
        text-transform: $gallery-slide-caption-title__texttransform;

        @media screen and (min-width: $bp-content) {
            color: $white;
        }

        a {
            @include chevron($line-height: 1.2);
            pointer-events: auto; // return mouse pointer events to just the title if it has a link 
            color: $generic;

            @media screen and (min-width: $bp-content) {
                color: $white;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }


    .description {
        @include rem-calc($standard-unit * 2, margin-bottom);

        > * {
            @media screen and (min-width: $bp-desktop__minimum) {
                @include fontsize(20);
            }
        }

        &:empty {
            display: none;
        }

        p {
            &:first-child {
                @include fontsize(18);

                font-weight: $gallery-slide-description-leading__fontweight;
                margin-bottom: 0;

                @media screen and (min-width: $bp-desktop__minimum) {
                    @include fontsize(24);
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.mediaGallery {
        @include rem-calc(40, padding-bottom);

        @media screen and (min-width: $bp-content) {
            @include rem-calc(80, padding-bottom);
        }

        .caption {
            color: $font-body__color;
            pointer-events: none;
            z-index: $zindex-video-caption;

            @media screen and (min-width: $bp-content) {
                @include rem-calc($standard-unit * 1.5, padding);

                bottom: 0;
                color: $white;
                display: flex;
                flex-direction: column;
                height: 80%;
                justify-content: flex-end;
                left: 0;
                position: absolute;
                width: $width-full;
            }
        }

        .videoSlide {
            @media screen and (min-width: $bp-content) {
                max-width: $width-full;
                min-height: $gallery-slide__height;
                width: 996px;
            }

            iframe {
                height: $height-full;
                width: $width-full;
            }

            .videoOverlay {
                @include rem-calc($gallery-slide-mobile__spacing, padding-left);
                @include rem-calc($gallery-slide-mobile__spacing, padding-right);
                @include rem-calc(560, max-height);

                background-color: transparent;
                height: 49vw;
                left: 0;
                width: $width-full;

                @media screen and (min-width: $bp-content) {
                    padding: 0;
                }
            }
        }

        .slick-arrow {
            @include rem-calc($standard-unit * 12, top);

            @media screen and (min-width: $bp-content) {
                top: $half;
            }
        }

        &.light {
            background-color: $gallery-media-lighttheme__bgcolor;

            h3 {
                color: $generic;

                @media screen and (min-width: $bp-content) {
                    color: $white;
                }
            }
        }

        &.dark {
            background-color: $gallery-media-darktheme__bgcolor;

            h2,
            h3,
            h3 a,
            .description,
            .description * {
                color: $white;
            }

            .caption {
                color: $white;
            }

            .btn {
                border: 1px solid $white;
            }
        }
    }

    .slickSlider {
        position: relative;

        @media screen and (min-width: $bp-content) {
            min-height: $gallery-slide__height;
        }

        > * {
            transition: opacity 1.5s ease-in;
        }

        &.loading {
            > * {
                opacity: 0;
            }

            &:after {
                @include translateXY(-$half, -$half);

                background: $white url($ajax-loading) center center no-repeat;
                border-radius: $half;
                content: '';
                height: 50px;
                left: $half;
                position: absolute;
                top: $half;
                width: 50px;
            }
        }

        // reposition slides if the carousel is not initialised (two or less slides)
        > .slide {
            float: none;
            margin: 0 auto;

            & + .slide {
                @include rem-calc($standard-unit * 2, margin-top);
            }
        }
    }

    .caption {
        @include rem-calc(0, margin);
        @include rem-calc($standard-unit, margin-top);
        @include animation(all, 0.4s);

        position: static;

        @media screen and (min-width: $bp-content) {
            @include background-gradient(
                $gallery-slide-overlay-gradientfrom__bgcolor,
                $gallery-slide-overlay-gradientto__bgcolor
            );
        }

        p {
            @include fontsize(18);

            margin-bottom: 0;
        }
    }

    .slick-list {
        overflow: visible;
    }

    .slide {
        @include rem-calc(0, padding);
        @include rem-calc($gallery-slide-mobile__spacing, padding-left);
        @include rem-calc($gallery-slide-mobile__spacing, padding-right);

        position: relative;
        width: $viewport-width-max;
        background-color: $imagepanel-bgcolor;

        @media screen and (min-width: $bp-content) {
            @include rem-calc($gallery-slide__spacing, margin-left);
            @include rem-calc($gallery-slide__spacing, margin-right);

            float: left;
            margin-bottom: 0;
            max-height: $gallery-slide__height;
            max-width: $bp-content;
            overflow: hidden;
            padding: 0;
            width: auto;
        }

        img,
        iframe {
            display: block;
            height: auto;
            margin: 0 auto;
            max-width: $width-full;
            width: $width-full;

            @media screen and (min-width: $bp-content) {
                width: auto;
            }
        }
    }

    .slick-current .caption {
        opacity: 1;
    }

    .slick-slide {
        backface-visibility: visible;
    }

    .slick-arrow {
        @include rem-calc($standard-unit * 3, width);
        @include rem-calc($standard-unit * 3, height);
        @include rem-calc(-($standard-unit * 1.5), left);
        @include animation(opacity);
        @include fontsize($standard-unit);
        @include translateY(-$half);

        background: $gallery-slide-arrow__bgcolor;
        border: none;
        border-radius: $half;
        cursor: pointer;
        display: block;
        opacity: $gallery-slide-arrow__opacity;
        padding: 0;
        position: absolute;
        text-indent: -9999px;
        top: $half;
        z-index: $zindex-carousel-navigation;

        @media screen and (min-width: $bp-content) {
            @include rem-calc($standard-unit * 4.5, width);
            @include rem-calc($standard-unit * 4.5, height);
            @include rem-calc(50, left);

            top: $half;
        }

        &:hover {
            opacity: 1;
        }

        &:after {
            @include translateXY(-$half, -$half);

            background: url($carousel__arrow) 0 0 no-repeat;
            background-size: 100% auto;
            content: '';
            display: block;
            height: 29px;
            left: $half;
            position: absolute;
            top: $half;
            width: 19px;

            @media screen and (min-width: $bp-content) {
                height: 42px;
                width: 25px;
            }
        }
    }

    .slick-next {
        @include transforms(translateY(-$half) rotate(-180deg));
        @include rem-calc(-($standard-unit * 1.5), right);

        left: auto;

        @media screen and (min-width: $bp-content) {
            @include rem-calc(50, right);

            left: auto;
        }
    }

    .slick-initialized {
        .caption {
            opacity: 0;
        }

        .fluid-width-video-wrapper {
            visibility: visible;

            @media screen and (min-width: $bp-tablet__portrait) {
                visibility: hidden;
            }

            &.visible {
                visibility: visible;
            }
        }

        .videoOverlay {
            &:after {
                @include animation(opacity);

                opacity: 0;
            }
        }

        .slick-current {
            .caption {
                opacity: 1;
            }

            .videoOverlay {
                &:after {
                    @include animation(opacity);

                    opacity: 1;
                }
            }
        }
    }

    .buttons {
        display: none;
        text-align: right;

        &.show {
            @include rem-calc($standard-unit * 2, margin-top);

            display: block;
        }

        .btn {
            @extend .cta;
        }
    }
}
