// Breadcrumb Styles

.eurus {
    .breadcrumb {
        background-color: $eurus-mid-blue;
        .row{
            ul{
                li{
                    &:after {
                        color: $eurus-white;
                    }
                    &:first-of-type{
                        &:before{
                            color: $eurus-white;
                        }
                    }
                    a {
                        color: $eurus-white;
                        &:hover{
                            text-decoration: underline;
                            color:$eurus-white !important;
                        }
                        &:visited {
                            color: $eurus-white;
                        }
                    }
                }
            }
        }               
    }
}

.breadcrumb {
 
    //@include fontsize(10);
    //@include rem-calc($section-margin-mobile 0, margin);
    @include rem-calc(10, padding);
    margin-top:0;
    margin-bottom:0;
    color: $breadcrumb__color;
    background-color:#f5f5f5;
    .row{
        ul{
            display: flex;
            list-style-type: none;
            margin: 0;
            align-items: center;
            padding: 0;
            flex-wrap: wrap;
            li{
                &:after, &:before{
                    @extend %fa-icon;
                    @extend .fas;
                    position:relative;
                    top: -1.5px;
                    margin-right: 2px;
                }
                margin:0;
                &:after {
                    @include rem-calc(0 10, margin);
                    @include rem-calc(13, font-size);
                    color: $slate-gamma;
                    content: fa-content($fa-var-chevron-right);
                }
                &:first-of-type{
                    &:before{
                        color: $slate-gamma;
                        @include rem-calc(13, font-size);
                        content: fa-content($fa-var-home); 
                    }
                    a{
                        margin-left:0;
                    }
                }
                &:last-of-type{
                    &:after {
                        content: "";
                    }
                    a{
                        font-weight:600;
                    }
                }
                a {
                    @include rem-calc(13, font-size);
                    text-decoration: none;
                    color: $breadcrumb__color;
                    margin: 0 10;
                    &:hover{
                        text-decoration: underline;
                        color:#007494 !important;
                    }
                    &:visited {
                        color: $breadcrumb__color;
                    }
                }
            }
        }
    }   
}
