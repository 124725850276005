//Search Box Variables

$search-radio__color: $white;
$search-radio-selected__color: $black;
$search__bgcolor: $generic;
$search-hero__bgcolor: rgba($black, 0.6);
$search-hero__maxwidth: 73rem; // 1168 / 16
$search-input-selected__bgcolor: $black;
$search-input-focus__bordercolor: $brand-secondary;
$search-box__width: 64;
$search-box__height: 64;

$search-regular: 'Derailed-Regular';
$search-bold: 'Derailed-Bold';
$search-extrabold: 'Derailed-ExtraBold';

$search-colourA__bgcolor: $global-brand-blue;
$search-colourB__bgcolor: $global-brand-blue;
$search-colourC__bgcolor: $global-brand-blue;
$search-colour0__bgcolor: $white;
$search-colour__homepage: #003151;