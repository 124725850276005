/* ---------------------------------------------
Alert Content
----------------------------------------------*/
.alert {

    border: 1px solid $smoke-beta;
    @include rem-calc(15, padding);

    &.no-border{
        border:none;
        padding:0;
    }

    p {
        padding: 0;
    }

    a {
        text-decoration: underline;
    }

    .alertInfo {
        display: flex;
        align-items: flex-start;
    }

    .alertIcon {
        @include fontsize(12);
        @include rem-calc(24, min-height);
        @include rem-calc(24, min-width);
        @include rem-calc(10, margin-right);
        @include rem-calc(24, line-height);
        @extend %fa-icon;
        @extend .fas;
        color: $white;
        background-color: $color-epsilon;
        border-radius: $half;
        text-align: center;

        &:after {
            content: fa-content($fa-var-exclamation);
        }

        &.tick {
            background-color: $color-gamma;
            &:after {
                content: fa-content($fa-var-check);
            }
        }

        &.tick {
            background-color: $color-gamma;
            &:after {
                content: fa-content($fa-var-check);
            }
        }

        &.error{
            background-color: #ca0707;
        }

        &.tickCircle {
            &:after {
                content: fa-content($fa-var-check-circle);
            }
        }

        &.downloadpdfIcon{
            display:none;
        }

    }
    
}
