// Parallax Panels (Listicle and Q&A) variables

$parallax-layout__bgcolor: $black;

$parallax-panel__bgcolor: $generic;
$parallax-panel-headline-dark__color: $white;

$parallax-panel-dark__color: $white;
$parallax-panel-light-headline__color: $headings;
$parallax-panel-light__color: $bodytext;

$parallax-panel-imgoverlay-dark__bgcolor: $overlay;
$parallax-panel-imgoverlay-dark__opacity: 0.6;
$parallax-panel-imgoverlay-light__bgcolor: $white;
$parallax-panel-imgoverlay-light__opacity: 0.8;

$parallax-panel-coloroverlay-dark__bgcolor: $overlay;
$parallax-panel-coloroverlay-light__bgcolor: $white;

$parallax-panel-cta__bgcolor: $generic;
$parallax-panel-dark-cta__bordercolor: $white;
$parallax-panel-light-cta__bgcolor: transparent;
$parallax-panel-light-cta__color: $generic;
$parallax-panel-cta-hover__bgcolor: $cta-hover__bgcolor;
$parallax-panel-cta-hover__color: $cta-hover__color;

$parallax-panel-marker__color: $white;
$parallax-panel-marker__lineheight: 1.6;
$parallax-panel-marker-default__bgcolor: $brand-secondary;
$parallax-panel-marker-accentA__bgcolor: $brand-primary;
$parallax-panel-marker-accentB__bgcolor: $brand-secondary;
$parallax-panel-marker-accentC__bgcolor: $brand-tertiary;

// Scroll button
$scroll-button__bgcolor: rgba($black, 0.3);
$scroll-button__hover-bgcolor: rgba($black, 0.7);
$scroll-button__color: $white;
$scroll-button__font-family: $font-parallax-scroll-button;
