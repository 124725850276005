.keyMessagesPanels{
     padding:2.6rem 0 0 0;
     margin:0;
     h2, h3{
       color:$white;
     }
     .promoPanelsContainer, .colourPanelGrid{
          h4, p{
               color:$white;
          }
     }
     &.colourA{
          background:$colourA-panelGrid__bgcolor;
     }
     &.colourB{
          background:$colourB-panelGrid__bgcolor;
     }
     &.colourC{
          background:$colourC-panelGrid__bgcolor;
     }

}
.colourPanelGrid{
     // border-top:3px solid $panelGrid__bgcolor;
     border-top:3px solid $eurus-white;
     .promoLink{
          padding:1.6rem 0 2.2rem 0;
     }
     h4{
          @include rem_calc(23, line-height);
          @include rem_calc(18, font-size);
          &:after{
               @include rem_calc(1.6, margin-top);
               font-weight:600;
               @media screen and (min-width: $bp-tablet__portrait) {
                    @include rem_calc(-1, margin-top);
               }
          }
     }
     P{
          @include rem_calc(24, line-height);
          @include fontsize(16);
     }
     &.colourA {
          border-top-color: $colourA-panelGrid__bgcolor;
     }
     &.colourB {
          border-top-color: $colourB-panelGrid__bgcolor;
     }
     &.colourC {
          border-top-color: $colourC-panelGrid__bgcolor;
     } 
}