// Filters

.filters {
    position: relative;

    .no-js & {
        display: none;
    }

    .row {
        padding: 0;

        @media screen and (min-width: $bp-desktop__minimum) {
            @include rem-calc(0 15, padding);

            display: flex;
            justify-content: space-between;
        }
    }

    ul {
        margin: 0;
        padding: 0;

        @media screen and (min-width: $bp-desktop__minimum) {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
        }
    }

    li,
    .filter-item {
        & + li,
        & + .filter-item {
            @include rem-calc($standard-unit/2, margin-top);
        }
    }

    li {
        list-style-type: none;
        margin-bottom: 0;
        width: $width-full;

        @media screen and (min-width: $bp-desktop__minimum) {
            @include rem-calc($standard-unit/2, margin-bottom);

            width: auto;
        }

        & + li {
            @media screen and (min-width: $bp-desktop__minimum) {
                @include rem-calc($standard-unit, margin-left);

                margin-top: 0;
            }
        }
    }

    input[type='radio'],
    input[type='checkbox'] {
        @include accessible-hidden-element();

        // set the tab higlighting
        .tabHighlight & {
            &:focus ~ label {
                outline: 4px dashed $linktext;
            }
        }

        &:checked ~ label {
            @include rem-calc($standard-unit/2 40 $standard-unit/2 $standard-unit/2, padding);

            background-color: $articles-filters-tab-selected__bgcolor;
            border: $articles-filters-tab-selected__border;
            color: $articles-filters-tab-selected__color;

            &:after {
                opacity: 1;
            }
        }
    }

    .filter-labels {
        @include animation(all);
        @include rem-calc($standard-unit/2, padding);

        background-color: $articles-filters-tab__bgcolor;
        border: $articles-filters-tab__border;
        color: $articles-filters-tab__color;
        display: block;
        font-family: $font-article-filters;
        position: relative;

        &:after {
            @include animation(opacity);
            @include rem-calc($articles-filters-check__width, width);
            @include rem-calc($articles-filters-check__height, height);
            @include rem-calc($standard-unit/2, right);

            background: transparent url($radiocheck-white) center center no-repeat;
            background-size: $width-full auto;
            content: '';
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        &:hover,
        &:focus {
            background-color: $articles-filters-tab-hover__bgcolor;
        }
    }

    label {
        &:not(.filter-by) {
            cursor: pointer;
        }

        &.filter-by {
            @include fontsize(18);
            @include rem-calc($standard-unit 0, padding);

            border-bottom: $articles-filterby-border;
            border-top: $articles-filterby-border;
            display: block;
            font-family: $font-article-filterby;
            position: relative;
            text-align: center;
            width: 100%;

            @media screen and (min-width: $bp-desktop__minimum) {
                @include rem-calc($standard-unit/2 0, padding);
                @include rem-calc($standard-unit, margin-right);

                border: 0;
                width: auto;
            }

            &:after {
                @include animation(transform);
                @include rem-calc($standard-unit, width);
                @include rem-calc($standard-unit, height);
                @include rem-calc($standard-unit, right);

                background: transparent url($article-filters-chevron__down) center center no-repeat;
                background-size: cover;
                content: '';
                position: absolute;
                top: $half;
                transform: translateY(-$half);

                @media screen and (min-width: $bp-desktop__minimum) {
                    display: none;
                }
            }
        }
    }

    // overrides for when the filter panel is open
    &.open {
        label.filter-by {
            &:after {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }
}

.filter-wrapper {
    background-color: #fcfcfc;
    box-shadow: 0 1px 1px rgba($black, 0.3);
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: relative;
    transition: opacity 0.2s ease-in-out, height 0.2s ease-in-out;
    width: 100vw;

    @media screen and (min-width: $bp-desktop__minimum) {
        background-color: transparent;
        box-shadow: none;
        display: flex;
        height: auto;
        justify-content: space-between;
        opacity: 1;
        overflow: visible;
        width: 100%;
    }

    .filter-by + & {
        @media screen and (min-width: $bp-desktop__minimum) {
            width: auto;
        }
    }

    .filters.open & {
        height: auto;
        opacity: 1;
        overflow: visible;
    }

    form {
        @include rem-calc($standard-unit, padding);

        @media screen and (min-width: $bp-desktop__minimum) {
            padding: 0;
        }
    }
}

.filter-button {
    @include fontsize(16);
    @include animation(all);
    @include rem-calc($standard-unit, padding);
    @include rem-calc($standard-unit + $filters-button-chevron__size, padding-right);

    appearance: none;
    background-color: $filters-button__background;
    border: none;
    border-bottom: 1px solid $filters-button__border;
    border-radius: 0;
    border-right: none;
    box-shadow: none;
    color: $filters-button__color;
    display: block;
    font-family: $font-article-filters;
    font-weight: 400;
    line-height: 1.5;
    position: relative;
    width: 100%;

    @media screen and (min-width: $bp-desktop__minimum) {
        @include fontsize(16);
        @include rem-calc($standard-unit/2, padding);
        @include rem-calc($standard-unit + $filters-button-chevron__size, padding-right);

        background-color: $filters-button-desktop__background;
        border: 1px solid $filters-button-desktop__border;
        display: none;
        width: auto;
    }

    &:after {
        @include animation(transform);
        @include rem-calc($standard-unit, width);
        @include rem-calc($standard-unit, height);
        @include rem-calc($standard-unit, right);

        background: transparent url($article-filters-chevron__down) center center no-repeat;
        background-size: $width-full auto;
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @media screen and (min-width: $bp-desktop__minimum) {
            @include rem-calc($filters-button-chevron__size, width);
            @include rem-calc($filters-button-chevron__size, height);
            @include rem-calc($standard-unit/2, right);
        }
    }

    &:hover,
    &:focus {
        background-color: $filters-button-hover__background;
    }

    &.open {
        &:after {
            transform: translateY(-50%) rotate(0.5turn);
        }
    }
}

.filter-items {
    display: none;

    @media screen and (min-width: $bp-desktop__minimum) {
        display: block;
    }

    &.open {
        display: block;
    }
}

/* -----------------
Months Dropdown
------------------*/
.filter-months {
    @media screen and (min-width: $bp-desktop__minimum) {
        @include rem-calc($standard-unit * 12, min-width);
        @include rem-calc($standard-unit * 4, margin-right);
    }

    &-button {
        @media screen and (min-width: $bp-desktop__minimum) {
            display: block;
        }
    }

    &-dropdown {
        @media screen and (min-width: $bp-desktop__minimum) {
            @include rem-calc($filters-dropdown__width, max-width);
            @include rem-calc($standard-unit / 2, padding);

            background: $filters-dropdown__background;
            box-shadow: $filters-dropdown__shadow;
            display: none;
            position: absolute;
            width: 100vw;
            z-index: 2;
        }

        &.open {
            display: block;
        }
    }

    &-item {
        & + & {
            @media screen and (min-width: $bp-desktop__minimum) {
                @include rem-calc($standard-unit / 2, margin-top);
            }
        }
    }

    &-labels {
        @include fontsize(14);

        @media screen and (min-width: $bp-desktop__minimum) {
            @include fontsize(16);
        }
    }
}
