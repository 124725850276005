// News Feed Styles

.newsFeed {
    @include fontsize(10);
}

.newsItems {
    height: $height-full;
    width: $width-full;
}

.newsItem {
    @include fontsize(15);
    @include rem-calc(0 0 20 0, margin);
    @include rem-calc(225, min-height);
    @include rem-calc(40 20 20, padding);

    background-color: $newsfeed-item__bgcolor;
    color: $newsfeed-item__color;
    float: left;
    font-family: $newsfeed__fontfamily;
    overflow: hidden;
    position: relative;
    width: $width-full;

    @media screen and (min-width: $bp-tablet__portrait) {
        margin-bottom: 2%;
        margin-right: 2%;
        width: 49%;
    }

    @media screen and (min-width: $bp-tablet__landscape) {
        @include fontsize(18);
        @include rem-calc(250, height);
        @include rem-calc(45, padding-top);

        width: 24%;
    }

    &:nth-of-type(3n-2) {
        margin-right: 0;
        width: $width-full;

        @media screen and (min-width: $bp-tablet__landscape) {
            margin-right: 2%;
            width: 24%;
        }

        .newsItemBackground,
        .newsItemOverlay {
            display: block;

            @media screen and (min-width: $bp-tablet__landscape) {
                display: none;
            }
        }
    }

    &:nth-of-type(4n - 3),
    &:nth-of-type(12n-1) {
        @media screen and (min-width: $bp-tablet__landscape) {
            width: 48%;
        }

        .newsItemBackground,
        .newsItemOverlay {
            @media screen and (min-width: $bp-tablet__landscape) {
                display: block;
            }
        }
    }

    &:nth-of-type(3n) {
        margin-right: 0;
    }

    &.colourA {
        background-color: $colourA-newsfeed-item__bgcolor;
    }

    &.colourB {
        background-color: $colourB-newsfeed-item__bgcolor;
    }

    &.colourC {
        background-color: $colourC-newsfeed-item__bgcolor;
    }
}

.newsItemBackground,
.newsItemOverlay {
    display: none;
}

.newsItemBackground {           // stop bkg img stretching
        min-width: 100%;
       }

.newsItemText {
    @include rem-calc(10 0, margin);
    @include fontsize(16);

    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-family: $newsfeed__fontfamily;
    line-height: 1.2;
    min-height: 6em;
    overflow: hidden;
    position: relative;
    z-index: 1;

    @media screen and (min-width: $bp-tablet__portrait) {
        @include rem-calc(6 0, margin);
        @include fontsize(18);

        max-height: 6em;
    }

    &.twitter {
        @media screen and (min-width: $bp-tablet__portrait) {
            max-height: 9.6em;
        }
    }

    p {
        font-family: $newsfeed__fontfamily;
        overflow: hidden;
        padding: 0;
    }

    .tweet {
        @include fontsize(13.5);
    }
}

.newsTwitterLink {
    color: $newsfeed-item__color;

    &:focus,
    &:hover,
    &:visited {
        color: $newsfeed-item__color;
    }
}

.newsReadMore {
    @include rem-calc(55, height);
    @include rem-calc(215, max-width);
    @include rem-calc(0 45 0 15, padding);

    background-color: $newsfeed-readmore__bgcolor;
    border: 1px solid $newsfeed-item__color;
    border-radius: $cta_border-radius;
    color: $newsfeed-item__color;
    cursor: pointer;
    display: inline-block;
    font-family: $newsfeed-button__fontfamily;
    line-height: 3.6;
    margin: 0;
    position: relative;
    transition: background-color 0.5s ease;
    width: $width-full;
    z-index: 1;

    @media screen and (min-width: $bp-tablet__landscape) {
        @include rem-calc(60, height);
        @include rem-calc(0 50 0 20, padding);

        line-height: 3.3;
    }

    &:focus,
    &:hover,
    &:visited {
        color: $newsfeed-item__color;
    }

    &:focus,
    &:hover {
        background-color: rgba($newsfeed-readmore-hover__bgcolor, 0.3);
        color: $newsfeed-item__color;
        text-decoration: none;
    }
}

.eurus {
    .newsItem {
        background-color: $eurus-dark-blue;
        .labelContainer {
            .label {
                background-color: $eurus-teal;
                .labelText {
                    color: $eurus-brand-blue;
                }
            }
        }
        .overlay {
            background: $eurus-brand-blue-overlay-seventy;
            &.newsItemOverlay {
                display: block;
            }
        }
        .newsReadMore {
            background-color: $eurus-white;
            color: $eurus-brand-blue;
            font-family: $font-bariol;
            line-height: 2.4;
            height: auto;
            width: fit-content;
            max-width: auto;
            @media screen and (min-width: $bp-tablet__landscape) {
                @include fontsize(20);
            }
            &:after {
                top: 50%;
                transform: translateY(-50%);
                @media screen and (min-width: $bp-tablet__landscape) {
                    @include fontsize(20);
                }
            }
        }
        &.colourA, 
        &.colourB, 
        &.colourC {
            background-color: $eurus-dark-blue;
            color: $eurus-white;
        }
    }
}