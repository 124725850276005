.stickyCTA {
    
    background: $eurus-yellow;
    @include rem-calc(0 20, padding);
    @include rem-calc(200, bottom);
    @include rem-calc(42, height);
    @include rem-calc(16, font-size);
    color: $eurus-brand-blue;
    border-radius: 5px 0px 0px 5px;
    position: fixed;
    right: 0;
    font-family: 'Bariol';
    font-style: normal;
    z-index:9999;
    display: flex;
    align-items: center;
    cursor: pointer;

    @media screen and (min-width: $bp-desktop__minimum) {
        @include rem-calc(58, height);
        @include rem-calc(24, font-size);
        @include rem-calc(185, bottom);
    }

    .stickyCTAText {
        display: flex;
        align-items: center;
        &:after{
            @extend %fa-icon;
            @extend .fas;
            font-family: 'Font Awesome\ 5 Free';
            content: '\f054';
            position: relative;
            margin-left: 6px;
        }
    }

    &:visited {
        color: $eurus-brand-blue;
    }

    &:hover {
        text-decoration: none;
        .stickyCTAText {
            color: $eurus-brand-blue;
            &::after {
                left: 2px;
            }
        }
/*         .stickyCTAIcon {
            left: 6px;
            bottom: 2px;
            @media screen and (min-width: $bp-desktop__minimum) {
                left: 8px;
            }            
        } */
    }

    .stickyCTAIcon{
        /*         position: relative;
                left: 4px;
                @media screen and (min-width: $bp-desktop__minimum) {
                    left: 6px;
                }
                svg {
                    width: 15px;
                    height: 15px;
                    @media screen and (min-width: $bp-desktop__minimum) {
                        width: 20px;
                        height: 20px;
                    }
                } */
            }
}