/* ---------------------------------------------
Quote Box
----------------------------------------------*/
/* -----------------
Quote Box Styles
------------------*/
.quoteBox {

    @include fontsize(10);
    background-color: $eurus-base-tint-quinternary;

    &.primary{
        background-color: $eurus-base-tint-quinternary;
        color: $eurus-dark-blue;

        blockquote {

            p {
                color: $eurus-dark-blue;

                &:before,
                &:after {
                    color: $eurus-dark-blue;
                }
            }
            footer {
                color: $eurus-dark-blue;
            }
            cite {
                color: $eurus-dark-blue;
            }
        }
    }

    &.dark{
        background-color: $eurus-brand-blue;
        color: $eurus-white;

        blockquote {

            p {
                color: $eurus-white;

                &:before,
                &:after {
                    color: $eurus-white;
                }
            }
            footer {
                color: $eurus-white;
            }
            cite {
                color: $eurus-white;
            }
        }
    }

    &.light {
        background-color: $eurus-white;
        color: $eurus-dark-blue;
        blockquote {

            p {
                color: $eurus-dark-blue;

                &:before,
                &:after {
                    color: $eurus-dark-blue;
                }
            }
            footer {
                color: $eurus-dark-blue;
            }
            cite {
                color: $eurus-dark-blue;
            }
        }
    }

    figure {
        @include rem-calc(30 20, padding);

        border: none;
        position: relative;

        @media screen and (min-width: $bp-tablet__landscape) {
            @include rem-calc(60 40, padding);
        }

        p {
            @include fontsize(20);
            @include rem-calc(0 15, padding);

            color: $eurus-dark-blue;
            font-family: $quoteBoxFontFamily;
            font-weight: normal;
            line-height: 1.2;
            position: relative;
            text-align: center;

            @media screen and (min-width: $bp-tablet__landscape) {
                @include fontsize(40);
                @include rem-calc(0 35, padding);
            }

            &:before,
            &:after {
                @include fontsize(60);
                @include rem-calc(40, height);
                @include rem-calc(20, width);

                color: $eurus-dark-blue;
                display: block;
                line-height: 1;
                overflow: hidden;
                position: absolute;
                z-index: 1;

                @media screen and (min-width: $bp-tablet__landscape) {
                    @include fontsize(110);
                    @include rem-calc(70, height);
                    @include rem-calc(40, width);
                }
            }

            &:before {
                @include rem-calc(-20, left);
                @include rem-calc(-10, top);

                content: '\201C';

                @media screen and (min-width: $bp-tablet__landscape) {
                    @include rem-calc(-40, left);
                }
            }

            &:after {
                @include rem-calc(-10, bottom);
                @include rem-calc(-20, right);

                content: '\201D';

                @media screen and (min-width: $bp-tablet__landscape) {
                    @include rem-calc(-40, right);
                }
            }
        }

        footer {
            @include rem-calc(20, margin-top);

            color: $eurus-dark-blue;
            text-align: center;
        }

        cite {
            @include fontsize(14);

            color: $eurus-dark-blue;
            font-style: normal;

            @media screen and (min-width: $bp-tablet__landscape) {
                @include fontsize(18);
            }
        }
    }

/*     background-color: $quoteBoxBackgroundColor;

    &.colourA {
        background-color: $colourAquoteBoxBackgroundColor;
    }

    &.colourB {
        background-color: $colourBquoteBoxBackgroundColor;
    }

    &.colourC {
        background-color: $colourCquoteBoxBackgroundColor;
    }

    &.light {
        background-color: $quoteBoxLightBackgroundColor;

        p {
            color: $quoteBoxLightColor;

            &:before,
            &:after {
                color: $quoteBoxLightColor;
            }
        }

        footer,
        cite {
            color: $quoteBoxLightColor;
        }
    } */

    blockquote {
        @include rem-calc(30 20, padding);

        border: none;
        position: relative;

        @media screen and (min-width: $bp-tablet__landscape) {
            @include rem-calc(60 40, padding);
        }

        p {
            @include fontsize(20);
            @include rem-calc(0 15, padding);

            color: $eurus-dark-blue;
            font-family: $quoteBoxFontFamily;
            font-weight: normal;
            line-height: 1.2;
            position: relative;
            text-align: center;

            @media screen and (min-width: $bp-tablet__landscape) {
                @include fontsize(40);
                @include rem-calc(0 35, padding);
            }

            &:before,
            &:after {
                @include fontsize(60);
                @include rem-calc(40, height);
                @include rem-calc(20, width);

                color: $eurus-dark-blue;
                display: block;
                line-height: 1;
                overflow: hidden;
                position: absolute;
                z-index: 1;

                @media screen and (min-width: $bp-tablet__landscape) {
                    @include fontsize(110);
                    @include rem-calc(70, height);
                    @include rem-calc(40, width);
                }
            }

            &:before {
                @include rem-calc(-20, left);
                @include rem-calc(-10, top);

                content: '\201C';

                @media screen and (min-width: $bp-tablet__landscape) {
                    @include rem-calc(-40, left);
                }
            }

            &:after {
                @include rem-calc(-10, bottom);
                @include rem-calc(-20, right);

                content: '\201D';

                @media screen and (min-width: $bp-tablet__landscape) {
                    @include rem-calc(-40, right);
                }
            }
        }

        footer {
            @include rem-calc(20, margin-top);

            color: $eurus-dark-blue;
            text-align: center;
        }

        cite {
            @include fontsize(14);

            color: $eurus-dark-blue;
            font-style: normal;

            @media screen and (min-width: $bp-tablet__landscape) {
                @include fontsize(18);
            }
        }
    }
}


// New Styling for the '[EUR] Full Width Quote' Component (Exact Name tbc)
    

     .fullWidthQuote {
        // @include rem-calc(30 0, padding);
        @media screen and (min-width: $bp-desktop__minimum) {
            // @include rem-calc(50 0, padding);
            padding: 0;
        }
        .fullWidthContainerInner{
            @include rem-calc(0 40, padding);
        }
    } 
