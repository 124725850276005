// Listicle and Q&A Panel styling, Parallax Structural Layout styling

.parallax {
    // body content wrapper styling
    #content {
        background-color: $parallax-layout__bgcolor;
    }

    header.headerSmall {
        // hero header overrides
        @include rem-calc(16, padding-top);

        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: calc(#{$viewport-height-max} - 140px);

        @media screen and (min-width: $bp-tablet__landscape) {
            @include rem-calc(150, padding-top);

            display: block;
            min-height: $viewport-height-max;
        }

        .overlayImage {
            background-position: 50% 0;
            background-size: cover;
        }

        .wrapper {
            width: $width-full;
        }

        .headerContent {
            margin: 0;

            @media screen and (min-width: $bp-tablet__landscape) {
                @include rem-calc(40 auto, margin);
            }
        }

        h1 {
            @include fontsize(20);

            @media screen and (min-width: $bp-mobile__landscape) {
                @include fontsize(36);
            }

            @media screen and (min-width: $bp-tablet__landscape) {
                @include fontsize(54);
            }
        }

        .intro {
            @include fontsize(16);

            padding-top: 0;

            @media screen and (min-width: $bp-mobile__landscape) {
                @include fontsize(22);
            }

            @media screen and (min-width: $bp-tablet__landscape) {
                @include fontsize(30);
            }
        }

        .timer {
            @include rem-calc(24 0, margin);
            @include rem-calc(16 0 16 48, padding);
            @include fontsize(16);

            background: transparent url($parallax__clock) left center no-repeat;
            background-size: 34px 35px;
        }

        .readOn {
            @include rem-calc(32, margin-top);

            display: none;
            position: relative;
            text-align: center;
            width: 100%;
            z-index: 1;
        }

        .btn-readOn {
            @include animation(all);
            @include fontsize(14);
            @include rem-calc(8 24, padding);
            @include rem-calc(4, letter-spacing);

            border: 1px solid white;
            color: white;
            display: inline-block;
            text-transform: uppercase;

            @media screen and (min-width: $bp-tablet__landscape) {
                @include rem-calc(16 48, padding);
            }

            &:hover {
                background-color: white;
                color: black;
            }
        }
    }

    section.parallax-item {
        display: flex;
        flex-direction: column;
        justify-content: center; // position of content for animation
        margin: 0;
        position: relative;

        &.slide {
            .image {
                display: none;
            }
        }

        &.panel {
            .color {
                display: none;
            }
        }

        .wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            min-height: $viewport-height-max;

            > section {
                z-index: 1;
            }
        }

        // marker styling
        // ---------------------------------------------------------

        .answer-wrapper {
            position: relative;
        }

        .marker {
            @include fontsize(24);
            @include size(40);

            background-color: $parallax-panel-marker-default__bgcolor;
            border-radius: $half;
            color: $parallax-panel-marker__color !important; // must override default styling
            display: inline-block;
            font-family: $font-parallax-marker;
            font-weight: 700;
            line-height: $parallax-panel-marker__lineheight;
            text-align: center;

            @media screen and (min-width: $bp-tablet__landscape) {
                @include size(85);
                @include fontsize(50);
            }
        }

        .introColumn,
        .textArea,
        .dualPanel {
            position: relative;

            .marker {
                position: absolute;
                top: 0;

                // alter whatever is the direct sibling of this element
                & + *:not(.marker) {
                    @include rem-calc(50, margin-left);
                    @include rem-calc(40, min-height);

                    display: inline-flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-bottom: 0;
                    padding-top: 0;

                    @media screen and (min-width: $bp-tablet__landscape) {
                        @include rem-calc(100, margin-left);
                        @include rem-calc(85, min-height);
                    }
                }

                + h2 {
                    @include rem-calc(8, margin-bottom);

                    @media screen and (min-width: $bp-tablet__landscape) {
                        @include rem-calc(16, margin-bottom);
                    }
                }

                &.answer + * {
                    display: inline-block;
                    min-height: 0;
                }
            }

            .answer-wrapper {

                .marker {

                    & + *:not(.marker) {
                        @include rem-calc(16, padding-bottom);
                    }
                }
            }
        }

        .quoteBox {
            .marker {
                @include rem-calc(16, margin-bottom);
            }
        }

        &.dark {
            *:not(.marker),
            *:before,
            *:after {
                color: $parallax-panel-dark__color;
            }

            .cta {
                border-color: $parallax-panel-dark-cta__bordercolor;

                &:hover,
                &:focus {
                    border-color: transparent;
                }
            }
        }

        &.light {
            .cta {
                background-color: $parallax-panel-light-cta__bgcolor;
                color: $parallax-panel-light-cta__color;

                &:hover,
                &:focus {
                    background-color: $parallax-panel-cta-hover__bgcolor;
                    color: $parallax-panel-cta-hover__color;
                }
            }
        }

        // panel and slide styling
        &.panel {
            .image {
                background-position: top center;
                background-size: cover;

                &:after {
                    @include stretch();

                    content: '';
                }
            }

            &.dark {
                .image:after {
                    background-color: $parallax-panel-imgoverlay-dark__bgcolor;
                    opacity: $parallax-panel-imgoverlay-dark__opacity;
                }
            }

            &.light {
                .image:after {
                    background-color: $parallax-panel-imgoverlay-light__bgcolor;
                    opacity: $parallax-panel-imgoverlay-light__opacity;
                }

                .quoteBox {
                    blockquote {
                        p,
                        cite {
                            color: $parallax-panel-light__color;
                        }
                    }
                }
            }
        }

        &.slide {
            &.dark {
                .overlay {
                    background-color: $parallax-panel-coloroverlay-dark__bgcolor;
                }
            }

            &.light {
                .overlay {
                    background-color: $parallax-panel-coloroverlay-light__bgcolor;
                }

                .quoteBox {
                    blockquote {
                        p,
                        cite {
                            color: $parallax-panel-light__color;
                        }
                    }
                }
            }
        }

        // general overlay styling and overrides
        // stretch mixin not used as bottom and right need to be unset
        .overlay {
            height: $viewport-height-max * 1.2; // additional height added over $viewport-height-max so background shift can be used
            left: 0;
            position: absolute;
            top: 0;
            width: $viewport-width-max;
            z-index: 0;
        }

        .content {
            position: relative;
            z-index: 1;
        }
    }

    // module overrides
    // -------------------------------------------------

    // dual panels
    section.dualPanel {
        .panel-wrapper {
            background-color: transparent;
            padding: 0;

            &:not(.quote) {
                @media screen and (min-width: $bp-tablet__landscape) {
                    flex-direction: column;
                }
            }

            &:first-child {
                @media screen and (min-width: $bp-tablet__landscape) {
                    @include rem-calc(24, padding-right);
                }
            }

            & + .panel-wrapper {
                @include rem-calc(24, padding-top);

                @media screen and (min-width: $bp-tablet__landscape) {
                    padding: 0;
                }
            }
        }

        .panelContainer .panel-wrapper.media {
            height: auto;
            overflow: visible;

            .media-container {
                height: auto;
                margin-bottom: 0;

                &:not(.youtubeVid) {
                    align-items: center;
                    display: flex;
                    justify-content: center;
                }

                img {
                    max-height: 50vh;
                    max-width: 100%;
                    top: 0;
                    transform: none;
                    width: auto;

                    @media screen and (min-width: $bp-tablet__landscape) {
                        @include rem-calc(420, max-height);
                    }
                }
            }
        }

        h2 {
            a {
                @include chevron($line-height: 0.9);

                color: inherit;
                font-family: inherit;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .text-container {
            > :first-child {
                margin-top: 0;
                padding-top: 0;
            }
        }
    }

    // full-width quote
    section.quoteBox {
        background-color: inherit;
        color: inherit;

        blockquote {
            padding-bottom: 0;
            padding-top: 0;
            text-align: center;
        }

        p {
            &:before,
            &:after {
                color: inherit;
            }
        }
    }

    // footer section
    footer {
        position: relative;
    }

    // cta
    .cta {
        @include fontsize(14);

        line-height: 3;

        @media screen and (min-width: $bp-tablet__landscape) {
            @include fontsize(24);

            line-height: 2.4;
        }
    }

    // intro panel
    .introText {
        > :first-child {
            margin-top: 0;
            padding-top: 0;
        }
    }
}

// js only panel/slide styling
// -------------------------------------------------
.parallax body.js {
    .header {
        .readOn {
            display: block;
        }
    }

    section.parallax-item {
        .wrapper {
            transform: translateY($viewport-height-max * 0.05); // offset position of content from center
        }
    }

    // slide heights, used to control transition timing between panels
    // listicle/qa panel overrides
    .panel {
        min-height: $viewport-height-max * 1.5;
    }

    //listicle/qa slide overrides
    .slide {
        min-height: $viewport-height-max * 1.5;
    }
}

// listicle nth-child marker styling
// ---------------------------------------------------------

.parallax {
    &.qa {
        section.parallax-item {
            .marker {
                &.numeric {
                    display: none;
                }
            }
        }
    }

    &.listicle {
        section.parallax-item {
            .marker {
                &.question,
                &.answer {
                    display: none;
                }

                &.question,
                &.answer {
                    & + * {
                        margin-left: 0;
                    }
                }
            }

            &:nth-child(3n + 1) {
                .marker {
                    background-color: $parallax-panel-marker-accentA__bgcolor;
                }
            }

            &:nth-child(3n + 2) {
                .marker {
                    background-color: $parallax-panel-marker-accentB__bgcolor;
                }
            }

            &:nth-child(3n + 3) {
                .marker {
                    background-color: $parallax-panel-marker-accentC__bgcolor;
                }
            }
        }
    }
}

.scrollButton {
    @include rem-calc(10, padding);
    @include rem-calc(20, bottom);

    align-items: center;
    background: $scroll-button__bgcolor;
    color: $scroll-button__color;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-family: $font-parallax-scroll-button;
    left: $half;
    position: fixed;
    text-transform: uppercase;
    transform: translateX(-$half);
    transition: visibility 0.3s ease-in-out, opacity 0.6s ease-in-out, background-color 0.3s ease;
    z-index: $zindex-parallax-scroll-button;

    &.scrollButtonDisabled {
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
    }

    &:hover {
        background-color: $scroll-button__hover-bgcolor;
    }
}

.scrollButtonText {
    display: none;

    @media screen and (min-width: $bp-tablet__portrait) {
        @include fontsize(18);

        display: block;
    }
}

.scrollButtonArrow {
    @include rem-calc(35, height);
    @include rem-calc(25, width);

    background: url($parallax__arrow) center center no-repeat;
    background-size: $width-full;
    display: block;
}
