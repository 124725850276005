.eurus {
    .parallax {
        margin: 0;
        .parallax-effect-container {
            margin: 25px 13px;
            overflow: hidden;
            @media screen and (min-width: $bp-tablet__small) {
                height: 100%;
                min-height: 800px;
                display: block;
                position: relative;
                margin: 0;
            }

            .parallax-effect-content {

                position: relative;
                background-color: $eurus-white;
                @media screen and (min-width: $bp-tablet__small) {
                    position: absolute;
                    display: block;
                    height: 100%;
                    max-width: 100%; 
                    top: 50%;
                    left: 0;
                    padding: 16px;
                    width: 100%;
                    transform-style: flat;
                    transform: translate3d(0,-50%,0);
                    background-color: transparent;;
                }
                
                .parallax-content-wrapper { 

                    position: relative;
                    top: 0;
                    left: 0;
                    
                    @media screen and (min-width: $bp-tablet__small) {
                        background: rgba(255,255,255,0.9);
                        color: #4a4a4a;
                        @include rem-calc(560, max-width);
                        @include rem-calc(30, padding);
                        display: flex;
                        position: absolute;
                        top: 20px;
                        left: 20px;
                        overflow: hidden;
                    }
                    @media screen and (min-width: $bp-desktop__minimum) {
                        top: 110px;
                        left: 140px;
                        @include rem-calc(615, max-width);
                        @include rem-calc(31 50, padding);
                        @include fontsize(27);

                    }

                    .quote-line {
                        position: absolute;
                        width: 10px;
                        background-color: #da1a35;
                        // padding-bottom: 100px;                       
                        min-height: 1000px;
                    }
                    
                    .quote-wrapper {

                        position: relative;
                        //left: 20px;
                        display: flex;
                        flex-direction: column;
                        padding: 0 20px;

                        blockquote {
                            border-left: 0;
                            padding: 0;
                            @include fontsize(21);
                            @media screen and (min-width: $bp-tablet__portrait) {
                                @include fontsize(22);
                            }
                        }
            
                        svg {
                            height: 30px;
                            width: 30px;
                            position: relative;
                            color: $eurus-logo-red;
                        }
            
                        p.name {
                            color: $eurus-brand-blue;
                            font-weight: 800;
                            padding-bottom: 0;
                            font-family: $font-derailed__extrabold;
                            font-weight: 700;
                            @include fontsize(21);
                            @media screen and (min-width: $bp-tablet__portrait) {
                                @include fontsize(27);
                                max-width: 80%;
                            }
                        }
                        
                        p.roleTitle {
                            color: $eurus-brand-blue; 
                            font-family: $font-derailed__bold; 
                            @include fontsize(21);
                            @media screen and (min-width: $bp-tablet__portrait) {
                                @include fontsize(27);
                                max-width: 80%;
                            }
                        }
                    }
                }
            }
            .parallax-effect-image {

                display: none;
                @media screen and (min-width: $bp-tablet__small) {
                    display: block;
                    position: absolute; 
                    top:0;
                    left:0;
                    width: 100%;
                    height: 100%;
                    bottom: 0;
                    right: 0;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-attachment: scroll;
                    background-attachment: fixed;
                    // opacity: .7;
                }
            }
        }
    }
}