// Quote
.quote {

    @include fontsize(18);

    blockquote {

        border: none;
        font-family: $quoteFontFamily;
        font-weight: normal;
        padding: 0;
        width: 100%;
        position: relative;

        .beforeQuote {
            position: absolute;
            left: -20px;
            top: -5px;
            color: $eurus-logo-red;

            &.red {
                color: $eurus-logo-red;
            }
            &.teal {
                color: $eurus-teal-alt;
            }        
            &.blue {
                color: $eurus-brand-blue;
            }
        }
    
        .afterQuote {
            position: absolute;
            right: -10px;
            bottom: 0px;
            color: $eurus-logo-red;
            
            &.red {
                color: $eurus-logo-red;
            }
            &.teal {
                color: $eurus-teal-alt;
            }        
            &.blue {
                color: $eurus-brand-blue;
            }
        }

        h3 {
            @include fontsize(18);
            @include rem-calc(0 20, padding);
            line-height: 1.25;
            color: $eurus-dark-blue;
            font-family: $font-derailed__bold;
            position: relative;
            text-align: left;

            @media screen and (min-width: $bp-tablet__landscape) {
                @include fontsize(22);
                @include rem-calc(0 20, padding);
            }

/*             &:before,
            &:after {
                @include fontsize(60);

                display: block;
                line-height: 0.75;
                position: absolute;

                @media screen and (min-width: $bp-tablet__landscape) {
                    @include fontsize(130);
                }
            }

            &:before {
                @include rem-calc(0, left);
                @include rem-calc(0, top);

                content: '\201C';
            }

            &:after {
                @include rem-calc(-32, bottom);
                @include rem-calc(0, right);

                content: '\201D';

                @media screen and (min-width: $bp-tablet__landscape) {
                    @include rem-calc(-64, bottom);
                }
            } */
        }

        footer {
            @include rem-calc(20, margin-top);
        }

        cite {
            @include fontsize(14);

            color: $quoteColor;
            font-style: normal;
            font-weight: normal;
            text-align: center;

            @media screen and (min-width: $bp-tablet__landscape) {
                @include fontsize(18);
            }
        }
    }

    figcaption {
        @include fontsize(15);
        color: $eurus-brand-blue;
        text-align: left;
        @include rem-calc(10 20 0, padding);

        @media screen and (min-width: $bp-tablet__landscape) {
            @include fontsize(18);
        }

        &:not(:has(span)){
            display: none;
        }

        span {
            display: block;
            @include fontsize(14);

            @media screen and (min-width: $bp-tablet__landscape) {
                @include fontsize(16);
            }

            &.author {
                font-family: $font-derailed__bold;
                font-size: 1.125rem
            }
            &.role {
                font-family: $font-sans-serif;
                line-height: 1.3;           
            }
            
        }
    }
}
