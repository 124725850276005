// Intro Panel
.introContent {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $bp-tablet__portrait) {
        flex-direction: row;
    }

    &.reverse {
        flex-direction: column-reverse;

        @media screen and (min-width: $bp-tablet__portrait) {
            flex-direction: row-reverse;
        }
    }
}

.introColumn {
    @media screen and (min-width: $bp-tablet__portrait) {
        flex: 1 1 $half;
    }

    &:first-of-type {
        @media screen and (min-width: $bp-tablet__portrait) {
            @include rem-calc(0 40 0 0, margin);
        }

        .reverse & {
            @media screen and (min-width: $bp-tablet__portrait) {
                @include rem-calc(0 0 0 40, margin);
            }
        }
    }

    &:last-of-type {
        @media screen and (min-width: $bp-tablet__portrait) {
            @include rem-calc(0, margin-right);
        }
    }

    & + & {
        @include rem-calc(35, margin-top);

        @media screen and (min-width: $bp-tablet__portrait) {
            @include rem-calc(0 0 0 40, margin);
        }

        .reverse & {
            @include rem-calc(0 0 35, margin);

            @media screen and (min-width: $bp-tablet__portrait) {
                @include rem-calc(0 40 0 0, margin);
            }
        }
    }
}

.introText {
    @include fontsize(18);
}

.introImage,
.introQuote {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}

.introQuote {
    .ctaContainer {
        @include rem-calc(20, padding-top);
    }
}

.eurus {
    .introColumn {
            &.introText{
            height: 100%;
            // color: $eurus-body-text;
            h2 {
                // color: $eurus-brand-blue;
            }
        }
    }
}