.tableContainer {
    table {
        thead {
            
            border-bottom: 1px solid $eurus-brand-blue;

            th {
                @include rem-calc(10, padding);
                background-color: $eurus-white;
                color: #4A4A4A;
                font-family: $font-derailed__bold;
                @include fontsize(15);
                border: none;
                text-align: left;

                @media screen and (min-width: $bp-tablet__portrait) {
                    @include fontsize(18);
                }
            }
        }
        tbody {
            tr {
                border-bottom: 1px solid $eurus-brand-blue;
                
                td {
                    @include rem-calc(10, padding);
                    border: none;  
                    @include fontsize(14);
                    font-family: $font-sans-serif;
                    @media screen and (min-width: $bp-tablet__portrait) {
                        @include fontsize(16);
                    }
                }
            }
        }
    }
    &.hideHeaders {
        table {
            thead {
                display: none;
            }
        }
    }
    &.alternatingRows {
        table {
            tbody {
                tr {
                    &:nth-child(odd){
                        background-color: $eurus-base-tint-quinternary;
                    }
                    &:nth-child(even){
                        background-color: $eurus-white;
                    }
                }
            }
        }
    }
    &.firstColHeaders {
        table {
            tbody {
                tr {
                    td {
                        &:first-of-type {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}