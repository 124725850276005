// Global Mixins

@mixin fontsize-em($target, $context: $font-context) {
    font-size: ($target / $context) + 0em;
}

@mixin fontsize($target) {
    font-size: ($target / $font-context) + 0rem;
}

@mixin em-calc($list, $property, $context: $font-context) {
    $values: ();

    @each $i in $list {
        @if $i != 'auto' {
            $values: append($values, em-calculation($i, $context));
        } @else {
            $values: append($values, $i);
        }
    }

    #{$property}: $values;
}

@mixin rem-calc($list, $property) {
    $values: ();

    @each $i in $list {
        @if $i != 'auto' {
            $values: append($values, rem-calculation($i));
        } @else {
            $values: append($values, $i);
        }
    }

    #{$property}: $values;
}

@mixin size($width, $height: $width) {
    @if _is-size($height) {
        height: rem-calculation($height);
    } @else {
        @error '`#{$height}` is not a valid length for the `$height` argument ' +
           'in the `size` mixin.';
    }

    @if _is-size($width) {
        width: rem-calculation($width);
    } @else {
        @error '`#{$width}` is not a valid length for the `$width` argument ' +
           'in the `size` mixin.';
    }
}

@mixin zero() {
    margin: 0;
    padding: 0;
}

@mixin clearfix() {
    *zoom: 1;

    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }
}

@mixin box-sizing($type: border-box) {
    box-sizing: $type;
}

@mixin accessible-hidden-element() {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@mixin accessible-shown-element() {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

@mixin animation($property, $duration: 0.2s, $easing: ease-in-out) {
    transition: $property $duration $easing;
}

@mixin infiniteSpin($duration) {
    animation: rotate $duration infinite linear;
    @keyframes rotate {
        from {
            @include rotate(0deg);
        }
        to {
            @include rotate(359deg);
        }
    }
}

@mixin transforms($transforms) {
    transform: $transforms;
}

@mixin translateXY($translateX, $translateY) {
    transform: translate($translateX, $translateY);
}

@mixin translateX($translateX) {
    transform: translateX($translateX);
}

@mixin translateY($translateY) {
    transform: translateY($translateY);
}

@mixin rotate($rotation) {
    transform: rotate($rotation);
}

@mixin scale($scale) {                    /* NEW mixin for icons*/
    transform: scale($scale);
}

@mixin remove-button-styling() {
    appearance: none;
    border: none;
}

@mixin image-replace() {
    color: transparent;
    display: inline-block;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
}

@mixin background-gradient($from, $to) {
    background-image: linear-gradient(to top, $from, $to);
}

@mixin input-placeholders {
    &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        @content;
    }

    &::-moz-placeholder {
        /* Firefox 19+ */
        @content;
    }

    &:-ms-input-placeholder {
        /* IE 10+ */
        @content;
    }

    &:-moz-placeholder {
        /* Firefox 18- */
        @content;
    }
}

@mixin customScrollbar($color: #fff, $fade: 0.5, $bar_bgcolor: none, $width: 8px, $height: 8px, $radius: 4px) {
    &::-webkit-scrollbar {
        height: $height;
        width: $width;
    }

    &::-webkit-scrollbar-track {
        background: $bar_bgcolor;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba($color, $fade);
        border-radius: $radius;
    }

    &::-webkit-scrollbar-thumb:window-inactive {
        background: rgba($color, 0.3);
    }

    &::-webkit-scrollbar-corner {
        background: rgba(0, 0, 0, 0);
    }
}

@mixin customScrollbar2($color: #fff, $fade: 0.5, $bar_bgcolor: none, $bar_width: 8px, $bar_height: 8px, $track_width: 8px) {
    &::-webkit-scrollbar {
        height: $bar_height;
        width: $bar_width;
    }

    &::-webkit-scrollbar-track {
        background: $bar_bgcolor;
        width: $track_width
    }

    &::-webkit-scrollbar-thumb {
        background: rgba($color, $fade);
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:window-inactive {
        background: rgba($color, 0.3);
    }

    &::-webkit-scrollbar-corner {
        background: rgba(0, 0, 0, 0);
    }
}

@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}

@mixin placeholder {
    @include optional-at-root('::-webkit-input-placeholder') {
        @content;
    }

    @include optional-at-root(':-moz-placeholder') {
        @content;
    }

    @include optional-at-root('::-moz-placeholder') {
        @content;
    }

    @include optional-at-root(':-ms-input-placeholder') {
        @content;
    }
}

@mixin stretch() {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

@mixin chevron($color: inherit, $font-size: inherit, $padding: 10, $line-height: 1) {
    &:after {
        @include rem-calc($padding, padding-left);
        @include animation(margin-left);

        color: $color;
        content: '>';
        font-family: $font-chevron;
        font-size: $font-size;
        font-weight: normal;
        line-height: $line-height;
        position: absolute;
    }

    &:hover:after {
        margin-left: 8px;
    }
}

/* Animation mixin for font awesome CTA icons */
@mixin icon($fa-var-icon: $fa-var-external-link-alt, $duration: 0.4s, $scale: 1.2) {
    &:after {
        content: fa-content($fa-var-icon); @include animation(transform, $duration);
    }

    &:hover:after {
        @include scale($scale); @include rem-calc(24, right);
    }
}

@mixin spinIcon($duration, $fa-var-icon: $fa-var-external-link-alt) {
    @include infiniteSpin($duration);
    &:after {
        content: fa-content($fa-var-icon);
    }
}

@mixin grayscaleFilter($property) {
    filter: grayscale($property);
}

@mixin breakout($containerHeight, $imgHeight) {
    background-position: bottom center;
    margin-top: calc(#{rem-calculation($containerHeight)} - #{rem-calculation($imgHeight)});
}

@mixin backgroundImage($imgHeight) {
    @include rem-calc($imgHeight, height);

    background-repeat: no-repeat;
    background-size: contain;
    width: $width-full;
}

@mixin videoOverride() {
    height: 50vw;

    @media screen and (min-width: $bp-desktop__minimum) {
        height: 100%;
        min-height: 100%;
    }
}

@mixin iPhoneXSafeArea() {
    @supports (padding-top: constant(safe-area-inset-top)) {
        @content;
    }

    @supports (padding-top: env(safe-area-inset-top)) {
        @content;
    }
}

@mixin createArticleList($flexType: flex, $nthchild: '1n+4') {
    @media screen and (min-width: $bp-desktop__minimum) {
        display: $flexType;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    // fixes item alignment in three-column flex layout by adding a dummy
    &:after {
        content: '';
        width: $width-full;

        @media screen and (min-width: $bp-desktop__minimum) {
            max-width: $column-thirds;
        }
    }

    .item {
        width: 100%;

        @media screen and (min-width: $bp-desktop__minimum) {
            width: $column-thirds;
        }

        & + .item {
            @include rem-calc($standard-unit * 2, margin-top);

            @media screen and (min-width: $articles-filters-breakpoint) and (max-width: adjust-breakpoint($bp-desktop__minimum)) {
                @include rem-calc($standard-unit, margin-top);
            }

            @media screen and (min-width: $bp-desktop__minimum) {
                margin-top: 0;
            }
        }

        &:nth-child(#{$nthchild}) {
            @media screen and (min-width: $bp-desktop__minimum) {
                @include rem-calc($standard-unit * 3, margin-top);
            }
        }
    }
}

@mixin image-retina($filename,$extension,$width,$height) {
    background-image: url($filename + '.' + $extension);
  
    @media (-webkit-min-device-pixel-ratio: 2), (-moz-min-device-pixel-ratio: 2) {
      & {
        background-image: url($filename + '@2x.' + $extension);
        background-size: $width $height;
      }
    }
  }

// css variables mixin with default fallback - $default is required in sass variables library
@mixin css-variables($property, $var, $default) {
    #{$property}: $default;
    #{$property}: var(--#{$var}, #{$default});
}

@mixin not-last-child($selector) {
    & #{$selector}:not(:last-child) {
      @content;
    }
}

@mixin anchor-tag-override($colour, $hover-focus, $visited, $text-decoration, $text-decoration-hover){
    color: $colour;
    text-decoration: $text-decoration;

    &:visited {
        color: $visited;
    }

    &:hover,
    &:focus {
        color: $hover-focus;
        text-decoration: $text-decoration-hover;
    }
}

@mixin branding-line($colour, $mobile-width: 5px, $desktop-width: 10px){
    background-color: $colour;
    width: $mobile-width;
    @media screen and (min-width: $bp-tablet__portrait) {
        width: $desktop-width;
    }
}

@mixin text-background($red, $green, $blue, $opacity: 1){
    background-color: rgba($red, $green, $blue, $opacity);
}