// Header Styling

.eurus {

    .navigation {

        &.isFullScreen {
            @media screen and (min-width: $bp-desktop__minimum) {
                .primary {
                    background-color: rgba(0, 58, 101, 0.8);
                }
                .secondary {
                    display: none !important;
                }
                .breadcrumb {
                    display: none !important;
                }

                .menu-wrapper {
                    li {
                        &:hover {
                            .dropdown {
                                top: 100%;
                            }
                        }
                    }
                }

            }
        }

    }

    .header {

        &:not(.inverse){
            background-color: $eurus-brand-blue;
            .headerContent{
                h1,
                p {
                    color: $eurus-white;
                }
            }

            &.events {
                background-color: $eurus-white;
                .headerContent{
                    h1,
                    p {
                        color: $eurus-brand-blue;
                    }
                }
            }
        }
        &.inverse {
            background-color: $eurus-white;
            border-bottom: 1px solid $eurus-dark-blue;
            .headerContent{
                h1,
                p {
                    color: $eurus-brand-blue;
                }

                .ctaContainer {
                    .ctaColumn {
                        .cta {
                            &.ghost {
                                border-color: $eurus-brand-blue;
                            }
                        }
                    }
                }


            }

        }
        &.videoPresent {
            // background-color: transparent;
            background-color: $eurus-brand-blue;
            @include rem-calc(60, padding-bottom);
            
            @media screen and (min-width: $bp-tablet__portrait) {
                @include rem-calc(110, padding-bottom);
            }
        
            @media screen and (min-width: $bp-desktop__minimum) {
                @include rem-calc(115, padding-bottom);
            }

            &.fullScreen{
                @media screen and (max-width: 479px) {
                    padding-top: 0;
                }

                @media screen and (min-width: $bp-mobile__landscape) {
                    padding-top: 75px;
                }

                .branding-line{
                    @media screen and (min-width: $bp-mobile__landscape) and (max-width: tablet__portrait){
                        top: 75px;
                    }
                }
   
                padding-top: 75px;
            
                @media screen and (min-width: $bp-desktop__minimum) {
                    padding-top: 98px;
                }

            }

            .headerContent {
                .headerPadding {
                    display: block;
                    @include rem-calc(90, padding-top);
                    @media screen and (min-width: $bp-mobile__landscape) {
                        @include rem-calc(196, padding-top);
                    }
                    @media screen and (min-width: $bp-tablet__portrait) {
                        @include rem-calc(115, padding-top);
                    }
                    @media screen and (min-width: $bp-desktop__minimum) {
                        @include rem-calc(160, padding-top);
                    }                               
                }
            }
        }
        &.fullScreen {
            @media screen and (min-width: $bp-tablet__landscape) {
                height: 100vh;
                @include rem-calc(98, padding-top);
            }

            @media screen and (min-width: $bp-desktop) {
                h1 {
                    font-size: 97px !important;
                }
                p {
                    font-size: 40px !important;
                }
            }
        }
        .overlay {
            position: absolute;
            top: 0;
            left: 0; 
            right: 0;
            bottom: 0;
            background-color: rgba(0,58,101,0.8);    
        }

        &.backgroundGradient {

            .intro {
                @media screen and (min-width: $bp-desktop__minimum) {
                    width: 55%;
                }
            }

            .overlay {
                position: absolute;
                top: 0;
                left: 0; 
                right: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                z-index: 1;
                background-color: rgba(0,58,101,0.8);    
                @media screen and (min-width: $bp-desktop__minimum) {
                    background: rgb(0,58,101);
                    background: linear-gradient(90deg, rgba(0, 58, 101, 1) 0%, rgba(0, 58, 101, 1) 65%, rgba(0, 58, 101, 0.6) 75%, rgba(0, 58, 101, 0) 100%);
                    // width: 75%;
                }
            }

            .overlayImage {
                min-width: auto;
                min-height: auto;
                background-size: auto;
                background-position: 0;
                transform: translate(-50%, -50%);
                width: 100%;
                object-fit: cover;
            }


            &:has(.intro){
                .overlayImage{
                    @media screen and (min-width: $bp-mobile__landscape) {
                        height: calc(100% - 60px);
                        transform: translate(-50%, -28%);
                    }

                    @media screen and (min-width: $bp-tablet__portrait) {
                        height: calc(100% - 50px);
                        transform: translate(-50%, -32%);
                    }

                    @media screen and (min-width: $bp-desktop__minimum) {
                        transform: translate(0%, -26%);
                        // height: 100%;
                        height: calc(100% - 100px);
                        width: 50%;
                    }
                }
            }

            &:not(:has(.intro)){
                .overlayImage {
                    @media screen and (min-width: $bp-mobile__landscape) {
                        height: calc(100% - 80px);
                        transform: translate(-50%, -20%);
                    }

                    @media screen and (min-width: $bp-tablet__portrait) {
                        height: calc(100% - 60px);
                        transform: translate(-50%, -25%);
                    }

                    @media screen and (min-width: $bp-desktop__minimum) {
                        transform: translate(0%, -15%);
                        // height: 100%;
                        height: calc(100% - 140px);
                        width: 50%;
                    }                    
                }
            }

        }

        &.events {


            .headerContent {
                .branding-line {
                    @include branding-line($eurus-logo-red, 12px, 15px);
                    left: 0;
                }
                @media screen and (max-width: $bp-tablet__small) {
                    overflow: visible;
                    margin-bottom: 100px;
                }

            }


            h1 {
                @include fontsize(28);
                padding-bottom: 10px;
                margin-left: 0 !important;
                padding-left: 20px !important;

                @media screen and (min-width: $bp-tablet__small) {
                    padding-left: 40px !important;
                }
                @media screen and (min-width: $bp-desktop__minimum) {
                    @include fontsize(56);
                    
                }
            }
            .detailsWrapper {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                row-gap: 5px;
                @include rem-calc(20, padding-left);
                //@include rem-calc(0, margin-left);
                color: $eurus-brand-blue;
                
                @media screen and (min-width: $bp-tablet__small) {
                    padding-left: 40px;
                }

                &.top {
                    padding-bottom: 5px;
                }

                .date, .time, .location, .spacer {
                    display: flex;
                    font-family: $font-bariol;
                    @include fontsize(18);
                    line-height: normal;
                    @media screen and (min-width: $bp-desktop__minimum) {
                        @include fontsize(25);
                    }
                    span {
                        font-family: $font-bariol;
                        line-height: normal;

                        &.title {
                            font-weight: 700;
                            padding-right: 5px;
                        }
                        &.value {
                            font-weight: 400;
                        }
                    }

                }

                .location {
                    display: block;
                    .title {
                        position: relative;
                        top: -3px;
                    }
                }

                .spacer {
                    padding: 0 5px;
                    font-family: $font-bariol;
                    font-weight: 400;
                    @include fontsize(18);
                    @media screen and (min-width: $bp-desktop__minimum) {
                        @include fontsize(25);
                    }
                }

                a {
                     &.directions {
                        
                        color: $eurus-brand-blue;
                        font-family: $font-bariol;
                        font-weight: 700;
                        border-bottom: 1px solid $eurus-brand-blue;
                        @include fontsize(16);

                        &::after{
                            font-family: 'Font Awesome 5 Free';
                            content: "\f041";
                            padding-left: 3px;
                        } 

                        @media screen and (min-width: $bp-desktop__minimum) {
                            position: relative;
                        }

                        &:hover,
                        &:focus {
                            text-decoration: none;
                            color: $eurus-mid-blue;
                            border-bottom-color: $eurus-mid-blue;
                            cursor: pointer;
                        }
                    }

                }
            }
            .optionalTags {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                column-gap: 30px;
                @include rem-calc(20, padding-left);
                @include rem-calc(15, padding-top);
                //@include rem-calc(16, margin-left);

                @media screen and (min-width: $bp-tablet__small) {
                    padding-left: 40px;
                    @include rem-calc(10, padding-top);
                    grid-template-columns: repeat(2, 1fr);
                    margin-bottom: 10px;
                }

                @media screen and (min-width: $bp-desktop__minimum) {
                    display: flex;
                }

                &:not(.optionalTags:has(.tag)) {
                    display: none;
                }

                .tag {
                    color: $eurus-brand-blue;
                    display: flex;
                    align-items: center;
                    column-gap: 5px;
                    @include rem-calc(30, height);
                    @media screen and (min-width: $bp-tablet__small) {
                        @include rem-calc(30, height);
                    }
                    @media screen and (min-width: $bp-desktop__minimum) {
                        column-gap: 10px;
                        @include rem-calc(40, height);
                    }

                    span {
                        font-family: $font-derailed__extrabold;
                        padding-top: 3px;
                        @include fontsize(12);

                        @media screen and (min-width: $bp-tablet__small) {
                            @include fontsize(16);
                            padding-top: 3px;
                        }                       
                    }
                    svg {
                        height: 19px;
                        width: 25px;
                        @media screen and (min-width: $bp-desktop__minimum) {
                            height: 25px;
                        }
                    }


                }
            }
            .ctaContainer {

                padding-left: 0 !important;
                margin-left: 0 !important;
                
                @media screen and (min-width: $bp-tablet__small) {
                    padding-left: 40px !important;
                }
                

                @media screen and (max-width: $bp-tablet__small) {
                    position: absolute;
                    bottom: -130px;
                    width: 100%;                

                }

                &:not(:has(.ctaColumn)) {
                    display: none;
                }
                .cta {
                    &:not(.ghost){
                        &::after{
                            content: "\f061";
                        }
                    }
                    &.ghost {
                        border: 1px solid $eurus-brand-blue;

                        &.calendar {
                            &::after{
                                content: "\f073";
                            }
                        }
                    }
                }

                .calendarButton {
                    width: 100%;
                    button{
                        @include fontsize(18);
                        @include rem-calc(43, height);
                        @include rem-calc(7.5 0, margin);
                        @include rem-calc(0 35 0 15, padding);
                        display: inline-block;
                        font-family: $cta__fontfamily;
                        line-height: 2.3;
                        overflow: hidden;
                        position: relative;
                        text-align: left;
                        text-decoration: none;
                        transition: background-color 0.2s ease, border-color 0.3s ease;
                        width: 100%;
                        @media screen and (min-width: $bp-tablet__landscape) {
                            @include fontsize(24);
                            @include rem-calc(60, height);
                            @include rem-calc(15 0, margin);
                            @include rem-calc(0 50 0 20, padding);
                            
                            line-height: 2.4;
                        }

                        &::after{
                            content: "\f073";
                            position: absolute;
                            right: 1.5rem;

                        }
                    }
                    
                }
            }
        }

        padding-bottom: 0;
        // @include rem-calc(112, padding-top);
        padding-top: 0;
        @include rem-calc(60, padding-bottom);

        @media screen and (min-width: $bp-tablet__portrait) {
            @include rem-calc(112, padding-top);
            @include rem-calc(48, padding-bottom);
        }

        @media screen and (min-width: $bp-desktop__minimum) {
            @include rem-calc(160, padding-top);
            @include rem-calc(80, padding-bottom);
        }

        .overlayImage {
            object-fit: cover;
        }

        .headerContent {
            margin: 0;
            overflow: hidden;
            .headerPadding {
                @include rem-calc(64, padding-top);
                @media screen and (min-width: $bp-mobile__landscape) {
                    @include rem-calc(192, padding-top);
                }
                @media screen and (min-width: $bp-tablet__portrait) {
                    @include rem-calc(80, padding-top);
                }
                @media screen and (min-width: $bp-desktop__minimum) {
                    @include rem-calc(120, padding-top);
                }            
            }
            &.bgPresent {
                h1,
                p {
                    width: fit-content;
                    @include rem-calc(12, padding);
                    @include rem-calc(20, margin-left);
                }
                p {
                    @include rem-calc(10, margin-top);
                }
                .ctaContainer{
                    @include rem-calc(10, margin-left);
                    @include rem-calc(10, padding-left);
                    @media screen and (min-width: $bp-tablet__portrait) {
                        @include rem-calc(10, padding-left);
                    }
                    .ctaColumn {
                        @media screen and (min-width: $bp-tablet__portrait) {
                            max-width: 31%;
                        }
                        .cta{
                            @media screen and (min-width: $bp-tablet__portrait) {
                                margin-bottom:0;
                            }
                            &:last-of-type {
                                @media screen and (max-width: $bp-tablet__portrait) {
                                    margin-bottom: 0;   
                                }
                            }
                        }
                    }
                }                      
            }
/*             &.yellowBg {
                h1,
                p {
                    color: $eurus-brand-blue;
                    @include text-background(253, 200, 47, 0.92);
                }
            } */
            &.blueBg {
                h1,
                p {
                    color: $eurus-white;
                    @include text-background(0, 115, 188, 0.92);
                }
            }
            &:not(.bgPresent){
                h1 {
                    @include rem-calc(10, padding-left);
                    @include rem-calc(16, margin-left);
                    @media screen and (min-width: $bp-tablet__portrait) {
                        //@include rem-calc(20, padding-left);
                    }
                    @media screen and (min-width: $bp-tablet__portrait) {
                        //@include rem-calc(20, padding-left);
                    }                
                }
                p {
                    @include rem-calc(10, padding-left);
                    @include rem-calc(16, margin-left);
                    padding-bottom: 0;
                    padding-top: 0;
                    @media screen and (min-width: $bp-tablet__portrait) {
                        //@include rem-calc(20, padding-left);
                    }
                }
                .ctaContainer{
                    @include rem-calc(10, margin-left);
                    @include rem-calc(16, padding-left);
                    @media screen and (min-width: $bp-tablet__portrait) {
                        @include rem-calc(17, padding-left);
                    }
                    .ctaColumn {
                        @media screen and (min-width: $bp-tablet__portrait) {
                            max-width: 31%;
                        }
                        .cta{
                            @media screen and (min-width: $bp-tablet__portrait) {
                                margin-bottom:0;
                            }
                            &:last-of-type {
                                @media screen and (max-width: $bp-tablet__portrait) {
                                    margin-bottom: 0;   
                                }
                            }
                        }
                    }
                }           
            }             
            h1 {
                z-index: 1;
                @include fontsize(28);
                //@include rem-calc(10, padding-left);
                //@include rem-calc(10, margin-left);
                @media screen and (min-width: $bp-tablet__portrait) {
                    @include fontsize(56);
                    //@include rem-calc(20, padding-left);
                }
                @media screen and (min-width: $bp-tablet__portrait) {
                    //@include rem-calc(20, padding-left);
                }                
            }
            p {
                z-index: 1;
                @include fontsize(18);
                //@include rem-calc(10, padding-left);
                //@include rem-calc(10, margin-left);
                //padding-bottom: 0;
                @media screen and (min-width: $bp-tablet__portrait) {
                    @include fontsize(25);
                // @include rem-calc(20, padding-left);
                }
            }
            .branding-line {
                z-index: 2;
                @include branding-line($eurus-logo-red);
                position: absolute;
                height: 100%;
                padding: 0;
                left: 10px;
                @media screen and (min-width: $bp-tablet__portrait) {
                    left: 2px;
                }
            }

            &.animateHeader {
                h1 {
                    transform: translateX(-150%);
                    animation: slide-in-left 500ms ease-in-out 1500ms forwards;              
                }
                p {
                    transform: translateX(-150%);
                    animation: slide-in-left 500ms ease-in-out 1500ms forwards;
                }
                .branding-line {
                    height: 0;
                    animation: slide-down-top 1000ms ease-in-out 500ms forwards;
                }
                .ctaContainer{
                    transform: translateX(-150%);
                    animation: slide-in-left 500ms ease-in-out 2000ms forwards;
                    .ctaColumn {
                        @media screen and (min-width: $bp-tablet__portrait) {
                            max-width: 31%;
                        }
                    }
                }                
            }
        }
    }

    .headerVideo {
        @include stretch();
        object-fit: cover;
        background-color: $header-video__bgcolor;
        max-height: 100%;
        width: 100%;
        height: 100%;
    /*     @media screen and (max-width: $bp-tablet__landscape){
            max-height: 100%;
            width: 100%;
            height: 100%;
        } */
    }
}

.header {

    @include fontsize(10);
    @include rem-calc(120, padding-top);
    @include rem-calc(16, padding-bottom);
    background:$header__background;
    color: $header__color;
    overflow: hidden;
    position: relative;
    width: $width-full;

    @media screen and (min-width: $bp-tablet__portrait) {
        @include rem-calc(220, padding-top);
        @include rem-calc(30, padding-bottom);
    }

    &:not(.headerSmall) {
        @include rem-calc(440, min-height);

        @media screen and (min-width: $bp-tablet__landscape) {
            @include rem-calc(770, min-height);
        }
    }

    h1 {
        color: $header__color;
        padding: 0;

        @media screen and (min-width: $bp-tablet__landscape) {
            @include fontsize(76);
        }
    }

    .intro {
        @include fontsize(20);
        @include rem-calc(12 0, padding);

        color: $header__color;
        font-family: $header-intro__fontfamily;
        line-height: 1.2;

        @media screen and (min-width: $bp-tablet__landscape) {
            @include fontsize(30);
        }

        &:empty {
            display: none; // hide .intro element if no content
        }


        span {                      // Resize icons for intro text
            @include fontsize(18);
            @include rem-calc(10, margin);
            @include rem-calc(2, vertical-align);  
                       
                   @media screen and (min-width: $bp-tablet__landscape) {
                       @include fontsize(22);
                   }
            }
    }


    // Add icons header text

    span { 
         @extend %fa-icon;
         @extend .fas;
 
        &.calendar {
                   
            &:before {
                content: fa-content($fa-var-calendar-alt);
                }
            }
    
        &.directions {
                    
            &:before {
                content: fa-content($fa-var-map-marker-alt);
                }
            }
          
        }


    .ctaContainer {
        position: relative;
        z-index: 1;
        justify-content:flex-start;
    }

    .content {
        position: relative;
        z-index: $zindex-header-content;

        @media screen and (min-width: $bp-tablet__portrait) {
            display: flex;

            // reset to 100% if no right side column is present
            > div:only-child {
                flex-basis: $width-full;
            }

        }

        .headerRight {
            max-width: $header-rightpanel__maxwidth;

            @media screen and (min-width: $bp-tablet__portrait) {
                @include rem-calc(32, padding-left);
                flex-basis: $one-third;
                max-width: initial;
            }

            &.colourA {
                .panelButton {
                    background-color: $hero-ctapanel-button-colourA__bgcolor;
                }
            }

            &.colourB {
                .panelButton {
                    background-color: $hero-ctapanel-button-colourB__bgcolor;
                }
            }

            &.colourC {
                .panelButton {
                    background-color: $hero-ctapanel-button-colourC__bgcolor;
                }
            }

            &.colourA,
            &.colourB,
            &.colourC {
                .panelButton:hover {
                    background-color: $hero-ctapanel-button-hover__bgcolor;
                }
            }
        }

        .headerContent {

            @media screen and (min-width: $bp-tablet__portrait) {
                flex-basis: $two-thirds;
            }

            .eventContent{
                .intro{
                    @media screen and (min-width: $bp-tablet__landscape) {
                        @include fontsize(26);
                        line-height:1.5;
                    }
                }
                .eventInfo{
                    margin: 0;
                    list-style-type: none;
                    display: flex;
                    flex-direction: column;
                    @media screen and (min-width: $bp-tablet__landscape) {
                        flex-direction: row;
                    }

                    li{
                        display:flex;
                        @include rem-calc(5, gap);
                        @media screen and (min-width: $bp-tablet__landscape) {
                            @include rem-calc(30, margin-right)
                        }
                    }
                }

                #eventDirections{
                    @include anchor-tag-override($white, $white, $global-hyperlink-visited__color, underline, underline);
                    @media screen and (min-width: $bp-tablet__landscape) {
                        @include fontsize(20);
                    }
                    font-weight: bold;
                }
            }
        }
    }

    .timer {
        @include rem-calc($standard-unit, padding);
        background-color: rgba(0,0,0,0.8);
    }

    .deadline {
        text-align: center;

        & + .panelButton {
            @include rem-calc($standard-unit, margin-top);
        }
    }
    
    .subtitle {
        @include fontsize(16);
        font-family: $hero-ctapanel-labels__fontfamily;

        @media screen and (min-width: $bp-desktop__minimum) {
            @include fontsize(20);
        }
    }

    .date {
        @include fontsize(16);
        font-family: $hero-ctapanel-labels-bold__fontfamily;
        display: block;

        @media screen and (min-width: $bp-desktop__minimum) {
            @include fontsize(24);
        }
    }

    .panelButton {
        @include rem-calc($standard-unit*2, padding);
        @include rem-calc($standard-unit/2, padding-top);
        @include rem-calc($standard-unit/2, padding-bottom);
        @include fontsize(16);
        @include animation(background-color, 0.2s);
        font-family: $hero-ctapanel-button__fontfamily;
        font-weight: 700;
        text-align: center;
        display: block;
        text-decoration: none;
        background-color: $hero-ctapanel-button__bgcolor;
        color: white;
        border: 1px solid $white;

        &:hover {
            background-color: $hero-ctapanel-button-hover__bgcolor;
        }

        @media screen and (min-width: $bp-tablet__landscape) {
            @include fontsize(24);
        }
    }
}
.headerTop {
    background-color: $navigation-primary__bg;
    overflow: hidden;
    width: $width-full;
    z-index: $zindex-navigation;

    @media screen and (min-width: $bp-tablet__landscape) {
        @include rem-calc(35 50 0, padding);

        background-color: transparent;
        display: flex;
        justify-content: space-between;
        position: absolute;
    }
}

.headerContent,
.headerRight {
    @include rem-calc(40 auto, margin);

    position: relative;
    z-index: $zindex-content;
}

.headerCol {
    &.headerColLeft {
        @media screen and (min-width: $bp-tablet__landscape) {
            display: inline-block;
            float: left;
        }
    }

    &.headerColRight {
        @media screen and (min-width: $bp-tablet__landscape) {
            float: right;
        }

        .topbar {
            @include rem-calc(100, min-height);

            @media screen and (min-width: $bp-tablet__landscape) {
                min-height: 0;
            }
        }
    }
}

.logo-image {
    @include image-replace();
    @include rem-calc(133, width);
    @include rem-calc(133, min-width); // required for correct logo sizing for iOS9+
    @include rem-calc(40, height);
    background: transparent url($logo__desktop) top left no-repeat;
    background-size: 100%;
    display: block;
    z-index: $zindex-homelogo;

    @media screen and (min-width: $bp-desktop__minimum) {
        @include rem-calc(183, width);
        @include rem-calc(55, height);
        background-image: url($logo__desktop);
    }

    body.unit & {
        @include rem-calc(40, width);
        @include rem-calc(40, min-width); // required for correct logo sizing for iOS9+
        @include rem-calc(47, height);
        background: transparent url($logo__mobile) top left no-repeat;

        @media screen and (min-width: $bp-desktop__minimum) {
            @include rem-calc(183, width);
            @include rem-calc(55, height);
            background-image: url($logo__desktop);
        }
    }

    img {
        display: block;
        height: auto;
        width: $width-full;
    }
    
}

.headerVideo {
    @include stretch();
    object-fit: cover;
    background-color: $header-video__bgcolor;
}

.headerSearchIcon {
    @include rem-calc(0 $standard-unit/2 $standard-unit/2, padding);

    display: block;
    text-align: right;

    @media screen and (min-width: $bp-tablet__landscape) {
        display: none;
    }

    .search-icon {
        @include rem-calc($search-input__width, width);
        @include rem-calc($navigation-primary__height, height);

        background: $white url($navigation-search__dark) right to-px($standard-unit/2) center no-repeat;
        border-radius: $search-input__borderradius;
        cursor: pointer;
        display: inline-block;
    }

    &.close {
        background-image: url($close__light);
    }
}

.headerSearch {
    @include rem-calc(40, margin);
}

.headerSearchBox {
    @include stretch();
    @include rem-calc(
        $standard-unit * 5 $standard-unit * 2,
        padding
    ); // padding to the search box to position the search field

    background: $search-panel__bgcolor url($close__light) top to-px($standard-unit * 2) right to-px($standard-unit * 2)
        no-repeat;
    display: none;
    z-index: $zindex-searchbox-mobile;

    @media screen and (min-width: $bp-tablet__landscape) {
        background: none;
        display: inline-block;
        padding: 0;
        position: relative;
        z-index: $zindex-navigation;
    }

    &.fadeIn {
        display: block;
        opacity: 0;
    }
}

.headerSearchBoxFull {
    float: none;
    margin-right: 0;
    width: $width-full;
}

.searchBoxHeader {
    @include rem-calc($search-input__width, min-width);

    width: $width-full;
}

.searchBoxSmall {
    min-width: auto;
}

.videoControls {
    @include rem-calc(10, bottom);
    @include rem-calc(10, left);

    overflow: hidden;
    position: absolute;
    z-index: $zindex-content;

    @media screen and (min-width: $bp-tablet__portrait) {
        @include rem-calc(20, bottom);
        @include rem-calc(20, left);
    }
}

.videoButton {
    @include rem-calc(30, height);
    @include rem-calc(30, width);

    -webkit-appearance: none;
    background: url($video__pause) center center no-repeat;
    background-size: 90% 90%;
    border: none;
    cursor: pointer;
    display: block;
    margin: 0;
    opacity: $header-video-button__opacity;
    overflow: hidden;
    padding: 0;
    transform: scale(0.95);
    transition: opacity 0.5s ease, transform 0.2s ease;

    @media screen and (min-width: $bp-tablet__portrait) {
        @include rem-calc(50, height);
        @include rem-calc(50, width);
    }

    &:hover {
        opacity: 1;
        transform: scale(1);
    }

    &.videoButtonPaused {
        background-image: url($video__play);
    }
}

@keyframes customScrollAnimation {
    0% {
      color: $eurus-white; /* Start white */
      opacity: 2.5;          /* Fully visible */
      transform: translate(-50%, -20%); /* Starting position */
    }
    20% {
        color: $eurus-white; /* Quickly change to teal */
      }
    45% {
      color: $eurus-teal; /* Quickly change to teal */
    }
    100% {
      color: $eurus-teal;
      opacity: 0; /* Fade out at the end */
      transform: translate(-50%, 75%); /* Move downwards */
    }
  }

.headerScrollTo {

    display: none;
    @media screen and (min-width: $bp-tablet__landscape) {
        display: block;
        position: absolute;
        top: 80%;
        left: 50%;
        transform: translate(-50%, 75%);
        z-index: 10;
    
        &:visited {
            color: $eurus-white;
        }
    
        span {
            font-family: $font-derailed__regular;
            font-size: 1.5rem;
            color: $eurus-white;
            line-height: normal;
    
            &::after {
                content: "\f078";
                font-family: Font Awesome\ 5 Free;
                position: absolute;
                left: 50%;
                bottom: -130%;
                transform: translate(-50%, -50%);
                font-size: 2.3rem;
                /* Apply the custom animation */
                animation: customScrollAnimation 1.7s ease-in-out infinite;
            }
    
        }   
    }
}
