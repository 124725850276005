.calendarButton {

    position: relative;
    width: fit-content;
    
    button {
        position: relative;
        padding: 0 25px 0 20px;
        border-radius: 5px;
        border: 1px solid $eurus-brand-blue;
        background: $eurus-white;
        color: $eurus-brand-blue;
        display: flex;
        height: 43px;
        justify-content: center;
        align-items: center;
        font-family: $font-bariol;
        font-weight: 400;
        @include fontsize(18);
        @include rem-calc(23, line-height);

        &::after {
            font-family: 'Font Awesome\ 5 Free';
            content: '\f055';
            position: relative;
            right: -10px;
        }
    }

    .calendarOptions {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        background-color: $eurus-white;
        box-shadow: 0px 0px 9px 0px rgba(0, 58, 101, 0.40);
        border-radius: 5px;
        border: 1px solid $eurus-base-tint-primary;
        padding: 5px 25px;
        font-family: $font-sans-serif;
        @include fontsize(16);

        a {

            color: #4A4A4A;
            height: 30px;
            display: flex;
            align-items: center;
            gap: 5px;
            
            &:visited {
                color: #4A4A4A;
            }

            &:hover,
            &:focus {
                text-decoration: none;
                color: $eurus-mid-blue;
            }

        }
    }

}