// Font Variables

// font directory variable
$fonts-dir: '/webtemplate/fonts/' !default;

// font awesome directory
$fa-font-path: '/webtemplate/fonts/fa';

@font-face {
    font-family: 'Raleway-ExtraBold';
    font-weight: 800;
    src: url($fonts-dir+'Raleway-ExtraBold.woff2') format('woff2'),
        url($fonts-dir+'Raleway-ExtraBold.woff') format('woff'),
        url($fonts-dir+'Raleway-ExtraBold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Raleway-Bold';
    font-weight: 600;
    src: url($fonts-dir+'Raleway-Bold.woff2') format('woff2'), url($fonts-dir+'Raleway-Bold.woff') format('woff'),
        url($fonts-dir+'Raleway-Bold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Raleway-Regular';
    font-weight: 400;
    src: url($fonts-dir+'Raleway-Regular.woff2') format('woff2'), url($fonts-dir+'Raleway.woff') format('woff'),
        url($fonts-dir+'Raleway.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Derailed-ExtraBold';
    font-weight: 800;
    src: url($fonts-dir+'derailed-extrabold-webfont.woff2') format('woff2'),
        url($fonts-dir+'derailed-extrabold-webfont.woff') format('woff'),
        url($fonts-dir+'derailed-extrabold-webfont.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Derailed-SemiBold';
    font-weight: 800;
    src: url($fonts-dir+'derailed-semibold-webfont.woff2') format('woff2'),
        url($fonts-dir+'derailed-semibold-webfont.woff') format('woff'),
        url($fonts-dir+'derailed-semibold-webfont.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Derailed-Bold';
    font-weight: 600;
    src: url($fonts-dir+'derailed-bold-webfont.woff2') format('woff2'),
        url($fonts-dir+'derailed-bold-webfont.woff') format('woff'),
        url($fonts-dir+'derailed-bold-webfont.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Derailed-Regular';
    font-weight: 400;
    src: url($fonts-dir+'derailed-regular-webfont.woff2') format('woff2'),
        url($fonts-dir+'derailed-regular-webfont.woff') format('woff'),
        url($fonts-dir+'derailed-regular-webfont.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Bariol';
    font-weight: 400;
    src: url($fonts-dir+'bariol_regular.woff2') format('woff2'), url($fonts-dir+'bariol_regular.woff') format('woff'),
        url($fonts-dir+'bariol_regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'droid_sansbold';
    font-weight: 600;
    src: url($fonts-dir+'DroidSans-Bold-webfont.woff2') format('woff2'),
        url($fonts-dir+'DroidSans-Bold-webfont.woff') format('woff'),
        url($fonts-dir+'DroidSans-Bold-webfont.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'droid_sansregular';
    font-weight: 400;
    src: url($fonts-dir+'DroidSans-webfont.woff2') format('woff2'),
        url($fonts-dir+'DroidSans-webfont.woff') format('woff'),
        url($fonts-dir+'DroidSans-webfont.ttf') format('truetype');
    font-display: swap;
}

$font-sans-serif: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
$font-derailed__extrabold: 'Derailed-ExtraBold', 'Raleway-ExtraBold', $font-sans-serif;
$font-derailed__semibold: 'Derailed-SemiBold', 'Raleway-Bold', $font-sans-serif;
$font-derailed__bold: 'Derailed-Bold', 'Raleway-Bold', $font-sans-serif;
$font-derailed__regular: 'Derailed-Regular', 'Raleway-Regular', $font-sans-serif;
$font-bariol: 'Bariol', $font-sans-serif;
$font-droidsans: 'droid_sansbold', $font-sans-serif;
$font-droidsans-regular: 'droid_sansregular', $font-sans-serif;

$font-primary: $font-sans-serif !default;
$font-heading: $font-derailed__bold !default;
$font-heading-intro: $font-bariol !default;
$font-navigation: $font-derailed__regular !default;
$font-navigation-bold: $font-derailed__bold !default;
$font-navigation-search: $font-derailed__regular !default;
$font-pagination: $font-bariol !default;
$font-pagination-arrows: $font-bariol !default;
$font-search: $font-bariol !default;
$font-search-header-label: $font-derailed__bold !default;
$font-search-label: $font-derailed__regular !default;
$font-search-submit: $font-bariol !default;
$font-button: $font-bariol !default;
$font-quote: $font-bariol !default;
$font-heading__light: $font-derailed__bold !default;
$font-heading__heavy: $font-derailed__extrabold !default;
$font-accordion: $font-bariol !default;
$font-newsfeed: $font-bariol !default;
$font-newsfeed-button: $font-derailed__bold !default;
$font-label: $font-bariol !default;
$font-chevron: $font-bariol !default;
$font-footer: $font-derailed__bold !default;
$font-parallax-marker: $font-bariol !default;
$font-parallax-scroll-button: $font-bariol !default;
$font-article-filters: $font-derailed__regular !default;
$font-article-filterby: $font-derailed__bold !default;
$font-article-filters-submit: $font-derailed__bold !default;
$font-article-featured: $font-derailed__bold !default;
$font-article-list: $font-derailed__bold !default;
$font-datawidget-default: $font-bariol !default;
$font-datawidget-selected: $font-bariol !default;
$font-courseaccordion-headline: $font-derailed__bold !default;
$font-courseaccordion-subtitle: $font-derailed__regular !default;
$font-courseaccordion-description: $font-derailed__regular !default;
$font-event: $font-bariol !default;
$font-hero-ctapanel: $font-derailed__regular !default;
$font-hero-ctapanel-bold: $font-derailed__bold !default;
$font-hero-ctapanel-button: $font-bariol !default;

$font-coursepage__regular: $font-derailed__regular !default;
$font-coursepage__bold: $font-derailed__bold !default;

// default font context, equal to body default, used by .em-calc() and .fontsize-em() mixins
$font-context: 16 !default;
$fontsize-body: $font-context !default;

$line-height: 1.5 !default;
$line-height-adjust: 1.2 !default; // used on headlines

$font-heading__texttransform: none !default;
$font-heading__fontweight: 400 !default;

$font-heading-h1_fontfamily: $font-heading__heavy !default;
$font-heading-h1_fontweight: 400 !default;
$font-heading-h2_fontfamily: $font-heading__heavy !default; /* $DerailedExtraBold */
$font-heading-h2_fontweight: 400 !default; /* $DerailedExtraBold */
$font-heading-h3_fontfamily: $font-bariol !default;
$font-heading-h3_fontweight: 400 !default;
$font-heading-h4_fontfamily: $font-heading !default; /* $DerailedBold */
$font-heading-h4_fontweight: 400 !default; /* $DerailedBold */
$font-heading-h5_fontfamily: $font-heading !default; /* $DerailedBold */
$font-heading-h5_fontweight: 400 !default; /* $DerailedBold - NEW */
$body_fontfamily: $font-primary !default;
