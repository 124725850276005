// START AddThis override styling, mostly hacks to get around their use of !important
.addthis_inline_share_toolbox {
    @include fontsize($fontsize-body);
    @include rem-calc(28, min-height); // exact height of inline AddThis button
    @include rem-calc(100, min-width);

    display: inline-block;

    .at4-show {
        @include rem-calc(2, margin-left);

        display: inline-block !important;
        vertical-align: middle;
    }

    .share-label {
        @include fontsize(14);
        @include rem-calc(3, letter-spacing);

        font-weight: 600;
        text-transform: uppercase;
    }

    a[role='button'] {
        @include rem-calc(2, margin-bottom);

        background: transparent url($article-list__share) center center no-repeat !important;
    }

    // hide the + <svg> icon
    svg {
        display: none;
    }
}
// END AddThis override styling
