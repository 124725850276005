/* To replace uniBuddyContainer */
.uniBuddy {
  /* The container of each uniBuddy item, currently uniBuddyItem  */
  .item {
    width: 100%;
    background: #ffffff;
    border-radius: 5px;
    /* filter: drop-shadow(2px 4px 10px rgba(0, 0, 0, 0.44)); */
    box-shadow: 2px 4px 10px rgba(0,0,0,.44);
    overflow: hidden;
    display: flex;
    flex-direction: column;

    /* The container of the header - containing the coloured block, image, and name. Currently uniBuddyHeader */
    .headerContainer {
      position: relative;
      @include rem-calc(100, min-height);

      .titleContainer {
        /* for the coloured block and block title */

        background: linear-gradient(90deg, #06368b 0%, #4b116c 100%);
        @include rem-calc(45, min-height);

        h2 {
          font-weight: 800;
          color: white;
        }
      }

      .nameContainer {
        display: flex;
        align-items: flex-end;
        position: absolute;
        /* margin-bottom: 15px; */
        padding: 0rem 1.25rem;
        bottom: 0px;
        @include rem-calc(15, left);

        h3 {
          font-family: $font-derailed__extrabold;
          @include fontsize(21);
          @include rem-calc(25, line-height);
          color: #000000;
          @include rem-calc(0 0 10 8, padding);
          @media screen and (min-width: $bp-tablet__portrait) {
            @include fontsize(27);
          }
        }

        img {
          background: url(../../webtemplate/img/test/hero.jpg);
          border-radius: 100%;
          @include rem-calc(80, height);
          @include rem-calc(80, width);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          filter: drop-shadow(0px 4px 4px rgba(207, 207, 207, 0.7));
          border: 3px solid #ffffff;
        }
      }
    }

    /* The container of the body content - containing the course, country, quote and button */
    .bodyContainer {
      margin: 0.5rem 1.25rem 1.5rem 1.25rem;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: baseline;

      @media screen and (min-width: $bp-tablet__portrait) {
        flex-direction: row;
      }

      .bodyInnerTop {
        /*         @media screen and (min-width: $bp-desktop__minimum) {
          width: auto;
        } */

        .countryContainer {
          display: flex;
          padding: 0.5rem 1.25rem;
          /* column-gap: 0.5rem; */

          .flag {
            @include rem-calc(30, width);
            @include rem-calc(20, height);
            object-fit: cover;
          }
          svg {
            @include rem-calc(20, height);
            @include rem-calc(30, min-width);
          }
          span {
            font-family: $font-derailed__regular;
            @include fontsize(15);
            @include rem-calc(18, line-height);
            color: #000000;
            @include rem-calc(8, padding-right);
            @media screen and (min-width: $bp-tablet__portrait) {
              @include fontsize(18);
              @include rem-calc(21, line-height);
            }
          }
          h5 {
            padding: 0 0 0 0.5rem;
            font-family: $font-derailed__bold;
            font-weight: 700;
            @include fontsize(15);
            @include rem-calc(18, line-height);
            color: #000000;
            @media screen and (min-width: $bp-tablet__portrait) {
              @include fontsize(18);
              @include rem-calc(21, line-height);
            }
          }
        }

        .studyContainer {
          display: flex;
          padding: 0.5rem 1.25rem;

          span {
            font-family: $font-derailed__regular;
            font-style: normal;
            font-weight: 400;
            @include fontsize(15);
            @include rem-calc(18, line-height);
            color: #000000;
            @include rem-calc(8, padding-right);
            @media screen and (min-width: $bp-tablet__portrait) {
              @include fontsize(18);
              @include rem-calc(21, line-height);
            }
          }
          h5 {
            padding: 0;
            font-family: $font-derailed__bold;
            font-style: normal;
            font-weight: 700;
            @include fontsize(15);
            @include rem-calc(18, line-height);
            color: #000000;
            @media screen and (min-width: $bp-tablet__portrait) {
              @include fontsize(18);
              @include rem-calc(21, line-height);
            }
          }
        }
      }

      .bodyInnerBottom {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 100%;
        justify-content: space-between;

        /*         @media screen and (min-width: $bp-desktop__minimum) {
          width: auto;
        } */

        .quoteContainer {
          background: #f5f4f4;
          border-radius: 5px;
          position: relative;
          margin-top: 0.5rem;

          blockquote {
            position: relative;
            font-family: $font-sans-serif;
            font-weight: 400;
            border: none;
            @include fontsize(14);
            @include rem-calc(22, line-height);
            color: #090909;
            padding: 1.25rem 1.75rem 1.25rem 2.75rem;
            @media screen and (min-width: $bp-tablet__portrait) {
              @include fontsize(16);
            }
          }
          svg:first-of-type {
            position: absolute;
            @include rem-calc(10, top);
            @include rem-calc(15, left);
          }
          svg:last-of-type {
            position: absolute;
            @include rem-calc(10, bottom);
            @include rem-calc(15, right);
            transform: rotate(180deg);
          }

          .cta {
            border-radius: 5px;
            @include fontsize(18);
            @include rem-calc(23, line-height);

            @media screen and (min-width: $bp-tablet__portrait) {
              width: 50%;
              margin-left: auto;
              @include fontsize(24);
              @include rem-calc(26.45, line-height);
            }
          }
        }
      }
      .cta {
        border-radius: 5px;
        margin: 0;
        margin-top: 1rem;
      }
    }
    &.gridItem {
      @media screen and (min-width: $bp-desktop__minimum) {
        .bodyContainer {
          flex-direction: column;
        }
        .bodyInnerTop {
          width: auto;
        }
        .bodyInnerBottom {
          width: auto;
        }
      }
    }
  }

  .uniBuddyContainer {
    display: flex;
    flex-direction: column;
    @include rem-calc(22, row-gap);
    &.twoItems {
      @media screen and (min-width: $bp-desktop__minimum) {
        flex-direction: row;
        @include rem-calc(22, column-gap);
        .bodyContainer {
          flex-direction: column;
          .bodyInnerTop {
            width: 100%;
          }
          .bodyInnerBottom {
            width: 100%;
          }
        }
      }
    }
    &.uniBuddyGrid {
      @media (min-width: $bp-tablet__portrait) and (max-width: $bp-desktop__minimum) {
        .bodyInnerTop {
          width: 50%;
        }
        .bodyInnerBottom {
          width: 50%;
        }
      }
      @media screen and (min-width: $bp-desktop__minimum) {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        @include rem-calc(22, column-gap);
        .bodyInnerTop {
          width: 100%;
        }
        .bodyInnerBottom {
          width: 100%;
        }
      }

      .nameContainer {
        h3 {
          @media screen and (min-width: $bp-desktop__minimum) {
            @include fontsize(27);
            @include rem-calc(31, line-height);
          }
        }
      }
    }

    &:not(.uniBuddyGrid) {
      &.twoItems {
        @media screen and (min-width: $bp-desktop__minimum) {
          .item {
            width: 50%;
          }
        }
      }

      .titleContainer {
        /* for the coloured block and block title */

        h2 {
          @include fontsize(20);
          padding: 0.75rem 0 0.5rem 7.25rem;

          @media screen and (min-width: $bp-tablet__portrait) {
            @include fontsize(25);
            padding: 0.5rem 0 0.5rem 9.375rem;
          }
          @media screen and (min-width: $bp-desktop__minimum) {
            @include fontsize(35);
            padding: 0.5rem 0 0.5rem 9.375rem;
          }
        }
      }

      .nameContainer {
        h3 {
          @media screen and (min-width: $bp-tablet__portrait) {
            @include fontsize(27);
            @include rem-calc(31, line-height);
          }
          @media screen and (min-width: $bp-desktop__minimum) {
            @include rem-calc(0 0 0 8, padding);
          }
        }
      }

      @media (min-width: $bp-tablet__portrait) and (max-width: $bp-desktop__minimum) {
        .bodyInnerTop {
          width: 50%;
        }
        .bodyInnerBottom {
          width: 50%;
        }
      }
      @media screen and (min-width: $bp-desktop__minimum) {
        .bodyInnerTop {
          width: 50%;
        }
        .bodyInnerBottom {
          width: 50%;
        }
      }
    }

    @media screen and (max-width: $bp-mobile__large) {
      .bodyInnerTop {
        width: 100%;
      }
      .bodyInnerBottom {
        width: 100%;
      }
    }
  }
  [tabindex="0"]:focus {
    outline: 4px dashed #007494;
  }
}
