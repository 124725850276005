// Related People Styles

.relatedPeople {
    @include fontsize(10);
    .staffResultsContainer{
        margin-bottom:2rem;
        @media screen and (min-width: $bp-tablet__portrait) {
            display:flex;
            align-items:flex-start;
        }
    }
    .staffCount{
        display:none;
        margin:1rem 0;
        text-align:right;
        p{
            color:$slate-gamma;
            padding:0;
            @media screen and (min-width: $bp-tablet__portrait) {
                font-size:1.2rem;
            }
            margin-top:0;
            span{
                color: #007494;
                font-weight:bold;
            }
        }
    }
    .alert{
        margin-bottom:2rem;
        display:none;
        .alertText{
            color:$slate-gamma;
        }
    }
}
.staffPositions{
    display:none;
    width:100%;
    @media screen and (min-width: $bp-tablet__portrait) {
        @include rem-calc(400, width);
        padding-right:2rem;
    }
    .relatedPositions{
        display:none;
        margin: 0 0 2rem 0;
        .positionTitle{
            @extend %fa-icon;
            @extend .fas;
            display:flex;
            text-align:center;
            color:#007494;
            font-size:1.2rem;
            &:before{
                content: fa-content($fa-var-filter);
                margin-right:.5rem;
            }
            h4{
                padding-bottom:0;
                font-size:1.2rem;
            }
        }
        span{
            a{
                font-size:1rem;
                text-decoration:underline;
            }
        }
        .positionsContainer{
            ul{
                max-height: 216px;
                overflow-y: scroll;
                list-style-type: none;
                margin:0;
                @media screen and (min-width: $bp-tablet__portrait) {
                    max-height:none;
                    overflow-y:auto;
                }
                li{
                    margin:0;

                    label{
                        background: #007494;
                        color:$white;
                        font-weight:bold;
                        @include rem-calc(7 12, padding);
                        display:block;
                        margin:2px;
                        border-radius: 3px;
                        cursor:pointer;
                        transition:all .3s ease;
                        font-size:0.9rem;
                        &:hover{
                            background:#0489ad;
                        }
                        &.selected{
                            &::after{
                                content: "\2713";
                                margin:0 10px;
                                background:$white;
                                color:#007494;
                                padding:2px 5px;
                                @include rem-calc(59, border-radius);
                            }
                        }
                    }

                    input[type='checkbox']{
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        -o-appearance: none;
                        appearance: none;
                        display:none;

                    }
                }
            }
        }
    }
}

.staffGroupings, .filterSidebar{
    display:none;
    width:100%;
    background: #f1f5f8;
    padding: 16px 20px;
    border-radius: 4px;
    border: 1px solid #dde6ee;
    @media screen and (max-width: $bp-tablet__portrait) {
        @include rem-calc(180, max-height);
        overflow-x: scroll;
    }
    @media screen and (min-width: $bp-tablet__portrait) {
        @include rem-calc(300, width);
        margin-right: 2.5rem;
    }
    ul{
        list-style-type: none;
        margin-left:0;
        padding:0;
        li{
            padding: .58rem 0;
            a{
                font-size:1rem;
                &.active{
                    font-weight:bold;
                }
            }
        }
    }
}
// new simple list presentation

.relatedPeopleList{                      
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex: 1;
    @media screen and (max-width: $bp-tablet__portrait) {
        margin-top:2rem;
    }

.relatedPerson {                            
   @include rem-calc(20, margin-bottom);
   display: flex;
   flex-direction: column;
   width: $width-full;
   border-bottom: $related-person__border;
   word-break: break-word;
   display: block;
   transition: all .4s linear;
   padding: 0 0 1rem 0;

    h4 {                                
        @include rem-calc(0, padding);
        display: inline-block;
      
        &:hover,
        &:focus {
            color: $global-hyperlink-hover__color;
            text-decoration: underline;
       }
    }

    &.hide{
        display:none;
        opacity:0;
    }

    &.selected{
        display:block;
        opacity:1;
    }

}

.relatedPersonImage {
    display: none;
}

.relatedPersonDetails {              
    @include fontsize(14);
    @include rem-calc(12, margin-bottom);
    display: flex;
    flex-wrap: wrap;
    
    }

    .relatedPersonPosition {   
        @include rem-calc(5 0 25 0, margin);                 
        width: $width-full;
        font-weight: bold;
        @media screen and (min-width: $bp-tablet__portrait) {
            font-size:1rem;
        }
    }

    .relatrelatedPersonTel{
        width: 100%;
        margin-bottom: .5rem;
        @media screen and (min-width: $bp-tablet__portrait) {
            width:auto;
            margin-bottom:0;
        }
        .relatedPersonLinkTel { 
            @include rem-calc(30, margin-right);
            flex: 1 1 auto;
            font-size:1rem;
        }
    }

    .relatedPersonEmail{
        width:100%;
        @media screen and (min-width: $bp-tablet__portrait) {
            width:auto;
        }
    }

.relatedPersonLink {
    @include rem-calc(25, padding-left);
    position: relative;

    &:before {
        @include rem-calc(15, height);
        @include rem-calc(18, width);
        background: url($relatedPeople__sprite) no-repeat 0 0;
        content: ' ';
        display: block;
        left: 0;
        position: absolute;
        top: $half;
        transform: translateY(-$half);
    }
}

.relatedPersonLinkMail {                    
     flex: 1 1 auto;
     font-size:1rem;
                                     
    &:before {
        background-position: 0 rem-calculation(-15);
    }

}

}

/* Staff profile fulltext page webservice placeholder img width fix */
/* Img source: https://includes.ncl.ac.uk/cmswebservices/myimpact/2018ws/picture/blankprofile.jpg */
/* Script file: https://includes.ncl.ac.uk/cmswebservices/myimpact/2018ws/profiles/zephyrprofile.php */

img[src$="blankprofile.jpg"]
{
    width: auto;
}

