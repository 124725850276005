.sidebarContainer{
    display:flex;
    align-items: flex-start;
    flex-direction: column;
    align-items: baseline;
    @media screen and (min-width: $bp-tablet__portrait) {
        flex-direction: row;
    }
    .sidebar{
        width:100%;
        background: #f1f5f8;
        border-radius: 4px;
        border: 2px solid #dde6ee;
        margin:0;
        @media screen and (min-width: $bp-tablet__portrait) {
            width:34%;
            @include rem-calc(345, max-width);
            margin-right: 2.5rem;
        }
        .sidebar-close{
            margin: 0 1rem 0 1rem;
            padding: 0rem 5px; 
            text-align: right;
            a{
                @extend %fa-icon;
                @extend .fas;
                &:after{
                    content: fa-content($fa-var-times);
                    color:#1b7ab5;
                    font-size:1.4rem;
                }
            }
            @media screen and (min-width: $bp-desktop__minimum) {
                display:none;
            }
        }
        .sidebar-content{
            &.short{
                padding:0
            }
            margin: 0 1rem 0 1rem;
            padding: 1.125rem 5px;
            &.toggleBox{
                border-bottom: 1px solid #e2eaf0;
            }
            .cta{
                text-align:center;
            }
        }
        .dropdown{
            margin-top:0;
            &__btn {
                display: flex;
                justify-content: space-between;
                width: 100%;
                flex-direction: row-reverse;
                font-size:1rem;
                align-items: center;
                color:#4a4a4a;
                &:before{
                    @extend %fa-icon;
                    @extend .fas;
                    content: fa-content($fa-var-chevron-down);
                    transition: transform 0.3s;
                    color:#1B7AB5;
                    font-size:1.2rem;
                }
            }
            &__container {
                display: none;
                padding: 10px 18px;
                max-height: 350px;
                overflow-y: scroll;
                .checkbox{
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: space-between;
                    align-items: center;
                    padding: .3rem;
                    margin-bottom: 3px;
                    input[type=checkbox]{
                       transform: scale(2); 
                       margin-left:5px;
                    }
                    label{
                        font-size:1rem;
                    }
                }
            }
        } 

        .dropdown.is-open{
            .toggleBox{
                border-bottom: none;
            }
        }
        
        .dropdown.is-open .dropdown__btn:before {
            transform: rotate(180deg);
        }
          
        .dropdown.is-open .dropdown__container {
            display: block;
        }

        .stay-open{
            .toggleBox{
                .dropdown__btn{
                    cursor:default;
                    &:before{
                        content:"";
                    }
                }
            }
        }
    }
    &.sidebarFullWidth{
        display:flex;
        align-items: stretch;
        gap: 0;
        @media screen and (min-width: $bp-desktop__medium) {
            gap: 16px;
        }
        .sidebar{
            border-radius:0;
            border:none;
            margin-right:0;
        }
    }
}