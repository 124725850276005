/* ---------------------------------------------
Events Feed
----------------------------------------------*/

.eventsHeading {
    @extend .h4;
}

.eventsIntro {
    @include rem-calc($standard-unit, margin-bottom);

    @media screen and (min-width: $bp-desktop__minimum) {
        @include rem-calc($standard-unit * 2, margin-bottom);
    }
}

.events {
    overflow: hidden;
    position: relative;
    width: 100%;

    @media screen and (min-width: $bp-desktop__minimum) {
        align-items: center;
        display: flex;
        flex-direction: column;
    }
}

.eventsContainer {
    @include rem-calc($standard-unit, padding);

    width: 100%;

    @media screen and (min-width: $bp-desktop__minimum) {
        @include rem-calc($standard-unit * 2 0, padding);
        @include rem-calc($event-small__width * 3, max-width);

        display: flex;
        flex-wrap: wrap;
    }

    @media screen and (min-width: $bp-desktop__medium) {
        max-width: 100%;
    }

    @media screen and (min-width: $bp-desktop__extra-large) {
        @include rem-calc($event-extra-large__width * 5, max-width);
    }

    &.isCentered {
        justify-content: center;
    }
}

.event {
    @include rem-calc($standard-unit * 2, padding);

    background-color: $event__background;
    color: $event__color;
    display: flex;
    flex-direction: column;
    font-family: $font-event;
    justify-content: center;
    text-align: center;

    @media screen and (min-width: $bp-desktop__minimum) {
        @include rem-calc($event__height, min-height);
        @include rem-calc($event-small__width, min-width);

        flex: 1 0 33%;
        max-width: 33%;
    }

    @media screen and (min-width: $bp-desktop__medium) {
        flex-basis: 20%;
        max-width: 20%;
    }

    @media screen and (min-width: $bp-desktop__large) {
        @include rem-calc($event-large__width, min-width);
    }

    &:nth-child(2n) {
        background-color: $event-alt__background;
        // color: $event-alt__color;
        color: $eurus-brand-blue;
    }

    & + & {
        @include rem-calc($standard-unit, margin-top);

        @media screen and (min-width: $bp-desktop__minimum) {
            margin-top: 0;
        }
    }
}

.eventIconContainer {
    display: none;
    justify-content: center;

    @media screen and (min-width: $bp-desktop__minimum) {
        @include rem-calc($standard-unit * 2, margin-bottom);

        display: flex;
    }
}

.eventIconWrapper {
    @include rem-calc($event-icon__size, border-radius);
    @include rem-calc($event-icon__size, height);
    @include rem-calc($event-icon__size, width);
    @include rem-calc($standard-unit * 0.75, padding);

    align-items: center;
    border: 2px solid $event-icon__border-color;
    display: flex;
    justify-content: center;

    .event:nth-child(2n) & {
        @media screen and (min-width: $bp-desktop__minimum) {
            border-color: $event-alt-icon__border-color;
        }
    }
}

.eventIcon {
    @extend %fa-icon;
    @extend .fas;

    @media screen and (min-width: $bp-desktop__minimum) {
        @include fontsize(26);
    }

    &:before {
        content: fa-content($fa-var-calendar-alt);
    }

    .event[data-category="art shows"] & {
        &:before {
            content: fa-content($fa-var-palette);
        }
    }

    .event[data-category="concerts and performances"] & {
        &:before {
            content: fa-content($fa-var-music);
        }
    }

    .event[data-category="lectures and seminars"] & {
        &:before {
            content: fa-content($fa-var-brain);
        }
    }

    .event[data-category="forums"] & {
        &:before {
            content: fa-content($fa-var-comments);
        }
    }

    .event[data-category="networking and social events"] & {
        &:before {
            content: fa-content($fa-var-hands-helping);
        }
    }

    .event[data-category="open days"] & {
        &:before {
            content: fa-content($fa-var-flag);
        }
    }

    .event[data-category="awards ceremonies"] & {
        &:before {
            content: fa-content($fa-var-award);
        }
    }

    .event[data-category="reading"] & {
        &:before {
            content: fa-content($fa-var-book-open);
        }
    }

    .event[data-category="sporting events"] & {
        &:before {
            content: fa-content($fa-var-volleyball-ball);
        }
    }

    .event[data-category="workshops"] & {
        &:before {
            content: fa-content($fa-var-tools);
        }
    }

    .event[data-category="fairs and festivals"] & {
        &:before {
            content: fa-content($fa-var-lightbulb);
        }
    }

    // .event[data-category="exhibitions"] & {
    //     &:before {
    //         content: fa-content($fa-var-calendar-alt);
    //     }
    // }

    .event[data-category="outreach events"] & {
        &:before {
            content: fa-content($fa-var-child);
        }
    }

    .event[data-category="careers events"] & {
        &:before {
            content: fa-content($fa-var-address-card);
        }
    }

    .event[data-category="outdoor events"] & {
        &:before {
            content: fa-content($fa-var-tree);
        }
    }

    .event[data-category="food events"] & {
        &:before {
            content: fa-content($fa-var-carrot);
        }
    }

    // .event[data-category="religious observances"] & {
    //     &:before {
    //         content: fa-content($fa-var-calendar-alt);
    //     }
    // }

    .event[data-category="wellbeing"] & {
        &:before {
            content: fa-content($fa-var-spa);
        }
    }

    .event[data-category="international"] & {
        &:before {
            content: fa-content($fa-var-globe-americas);
        }
    }

    .event[data-category="webinars and online events"] & {
        &:before {
            content: fa-content($fa-var-desktop);
        }
    }

    .event[data-category="working with business"] & {
        &:before {
            content: fa-content($fa-var-briefcase);
        }
    }

    .event[data-category="graduation ceremonies"] & {
        &:before {
            content: fa-content($fa-var-graduation-cap);
        }
    }

    .event[data-category="awareness days"] & {
        &:before {
            content: fa-content($fa-var-bullhorn);
        }
    }

    .event[data-category="campus tour"] & {
        &:before {
            content: fa-content($fa-var-map-signs);
        }
    }

    .event[data-category="accommodation"] & {
        &:before {
            content: fa-content($fa-var-home);
        }
    }

    // .event[data-category="other none"] & {
    //     &:before {
    //         content: fa-content($fa-var-calendar-alt);
    //     }
    // }
}

.eventLink {
    color: inherit;

    &:visited,
    &:focus,
    &:hover {
        color: inherit;
    }
}

.eventHeading {
    @extend .h4;

    .event:nth-child(1n) & {
        color: $eurus-white;
    }

    .event:nth-child(2n) & {
        color: $eurus-brand-blue;
    }

    @include fontsize(18);

    //color: inherit;
    line-height: 1.16;
    margin: 0;
    padding: 0;

    @media screen and (min-width: $bp-desktop__minimum) {
        @include rem-calc(41.7, height); // (font-size * line-height) * 2

        overflow: hidden;
    }

    @media screen and (min-width: $bp-desktop__large) {
        @include fontsize(24);
        @include rem-calc(55.6, height); // (font-size * line-height) * 2
    }
}

.eventDetails {
    @include rem-calc($standard-unit, padding-top);

    @media screen and (min-width: $bp-desktop__minimum) {
        @include rem-calc($standard-unit * 2, padding-top);
        @include rem-calc($standard-unit * 2, margin-top);

        // border-top: 1px solid $event__border-color;
        border-top: 1px solid rgba(255,255,255,0.25);
    }

    .event:nth-child(2n) & {
        @media screen and (min-width: $bp-desktop__minimum) {
            border-color: $event-alt__border-color;
        }
    }
}

.eventDate,
.eventTime {
    @include fontsize(16);

    display: block;
    font-weight: 600;

    @media screen and (min-width: $bp-desktop__large) {
        @include fontsize(18);
    }
}

.eventLocation {                                       
    @include fontsize(16);
    @include rem-calc($standard-unit, margin-top);
    height: auto;

    @media screen and (min-width: $bp-desktop__minimum) {     
        
      //  @include rem-calc(24, min-height); // (font-size * line-height)

        overflow: hidden;
    }

    @media screen and (min-width: $bp-desktop__large) {
        @include fontsize(18);
     //   @include rem-calc(27, min-height); // (font-size * line-height)
    }
}

.eventLocationIcon {
    @extend %fa-icon;
    @extend .fas;

    @include fontsize(20);
    @include rem-calc($standard-unit, margin-right);


    &:before {
        content: fa-content($fa-var-map-marker-alt);
    }
}

.moreEvents {
    @include rem-calc($standard-unit, padding-bottom);

    width: 100%;

    @media screen and (min-width: $bp-desktop__minimum) {
        @include rem-calc($standard-unit * 2, padding-bottom);
    }
}

.eventsCta {
    display: flex;
    justify-content: center;
}
