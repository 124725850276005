// Data-driven Widgets

$select__maxwidth: 640;
$select__fontsize: 18;
$select-input__borderradius: 5px;
$select-input__height: 42 ;
$select-input__color: $white;
$select-small__fontsize: 12;
$select-small-input__height: 36;
$select-dropdown__padding: 16;
$select-dropdown-option__bgcolor: $snow-beta;
$select-dropdown-option-highlight__bgcolor: $smoke-gamma;
$select-arrow__bordercolor: $white;
$select-list__maxheight: 300;

$select-default__fontfamily: $font-datawidget-default;
$select-current__fontfamily: $font-datawidget-selected;

// colour theme settings
$select-input__bgcolor: $generic;
$select-input-colourA__bgcolor: $brand-primary;
$select-input-colourB__bgcolor: $brand-secondary;
$select-input-colourC__bgcolor: $brand-tertiary;
$infopanel__bordercolor: $generic;
$infopanel-colourA__bordercolor: $brand-primary;
$infopanel-colourB__bordercolor: $brand-secondary;
$infopanel-colourC__bordercolor: $brand-tertiary;