.dotted{
    background-color:$white;
    color:$white;
    border: $separator-border;
    border-style: $separator-border-style;
    margin: $separator-margin;
}
.eurus {
    .solid-red{
        background-color:$white;
        color:$white;
        border: 1px solid $eurus-logo-red;
        margin: $separator-margin;
    }
}