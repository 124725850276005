.fundingSearchContainer{
    .courseSearch{
        .count{
            text-align: right;
            margin-bottom:1rem;
            span{
                font-weight:600;
                font-size:1.25rem;
                color:#4a4a4a;
            }
        }
    }
    .fundingSearch{
        opacity:0;
        .filterToggle{
            display:flex;
            align-items:center;
            width:100%;
            @media screen and (min-width: $bp-tablet__portrait) {
                display:none;
            }
            .cta{
                text-align:center;
                &.clearBtn{
                    margin-right:10px;
                    width:35%;
                    background: #D1D1D1;
                    border-color: #D1D1D1;
                    color: #4a4a4a;
                    font-weight: 600;
                }
                &.refineBtn{
                    flex:1;
                }
            }
        }
        .courseSearchResults{
            .courseSearchResults__box{
                h3 p{
                    font-size: 1.25rem;
                    @media screen and (min-width: $bp-tablet__portrait) {
                        font-size:1.5625rem;
                    }
                    margin-bottom:15px;
                    color:#00634a;
                }
                .fundingOverview{
                    display:none;
                    @media screen and (min-width: $bp-tablet__portrait) {
                        display:block;
                        margin:1rem 0;
                    }
                }
                .fundingBorder{
                    padding:14px;
                    border: 1px solid #ebebeb;
                    border-radius:10px;
                    margin-bottom:20px;
                    .title{
                        font-size:18px;
                        color:#000;
                    }
                    p{
                        padding-top:0;
                    }
                }
            }
            .fundingListContainer{
                margin:1rem 0;
                h3{
                    padding-top:0;
                    margin-bottom:1rem;
                    p{
                        font-size:1.4rem;
                        @media screen and (min-width: $bp-tablet__portrait) {
                            @include rem-calc(40, font-size);
                        }
                        padding-top:.2rem;
                    }
                }
                .fundingListTitles{
                    font-weight: 600;
                    padding-bottom: 0;
                    font-size: 1.25rem;
                    @media screen and (min-width: $bp-tablet__portrait) {
                        @include rem-calc(30, font-size);
                    }
                    color: #00634a;
                }
            }
        }
    }
}