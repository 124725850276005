/* ---------------------------------------------
Responsive Tables
----------------------------------------------*/
.responsiveTable {
    &.scroll {
        @include customScrollbar($color: $table__scrollbar-color, $bar_bgcolor: $table__scrollbar-background-color);
        @include rem-calc($standard-unit, margin-bottom);
        @include rem-calc($standard-unit, padding-bottom);

        overflow-x: auto;

        table {
            margin-bottom: 0;

            th {
                h4 {
                    // color: $eurus-white;
                    text-align: left;
                    padding-bottom: 0;
                    color: #4A4A4A !important;
                    font-family: $font-derailed__bold;
                    @include fontsize(15);
                    @media screen and (min-width: $bp-tablet__portrait) {
                        @include fontsize(18);
                    }
                }
            }
        }
    }
}
