.findCountry {
    background-color: $eurus-mid-blue;
    font-family: Derailed;
    @include rem-calc(0 0 30 0, margin);
       
        .countryHeading{
            
            font-size: 35px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            color: $white;
            @include rem-calc(30 0 20 0, padding);
        }

        .countryListContainer{
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            @include rem-calc(30, padding-bottom);
            border-bottom: 1px;
            border-style: solid;
            border-color: $white;
            .countryListWrapper{
                position: relative;
                display: block;
                width: 100%;
                margin-right: 8px;
                &::after{
                    content: "\f0d7";
                    font-family: Font Awesome\ 5 Free;
                    position: absolute;
                    transform: translateY(-50%);
                    top: 50%;
                    right: 15px;
                    font-size: 45px;
                    color: #003A65;
                    pointer-events: none;
                }
                label {
                    display: flex;
                    height: 100%;
                    
                    &.error {
                        border: 2px solid red;

                        &::before {
                            font-family: Font Awesome\ 5 Free;
                            content: "\f071";
                            color: #d6062a;
                            position: absolute;
                            pointer-events: none;
                            -webkit-backface-visibility: visible;
                            backface-visibility: visible;
                            z-index: 1;
                            left: .75rem;
                            top: 50%;
                            transform: translateY(-50%);
                            @include fontsize(20);

                            @media screen and (min-width: $bp-tablet__portrait) {
                                left: .625rem;
                                font-size: 1.5625rem;
                            }
                        }

                        .countryList {
                            padding-left: 40px;
                        }

                    }
                    
                    .countryList {
                        color: $navigation-secondary__bg-color;
                        width: 100%;
                        @include rem-calc(60, height);
                        @include rem-calc(15 20 15 20, padding);
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        border-color: white;
                        /* for Firefox */
                        -moz-appearance: none;
                        /* for Safari, Chrome, Opera */
                        -webkit-appearance: none;
                    
                        &:focus {
                            outline: 4px dashed $eurus-dark-blue;
                        }

                        .country{
                            display: flex;
                            width: 204px;
                            align-items: center;
                            @include rem-calc(15 20 15 20, padding);
                            color: $navigation-secondary__bg-color;
                        }
                    }                    
                }

                
            }
            .countrySubmitWrapper{
                .countryButton {
                    height: 100%;
                    width: 60px;
                    position: relative;
                    border: 1px solid $eurus-white;
                    border-radius: 5px;

                    &:focus {
                        outline: 4px dashed $eurus-dark-blue;
                    }
                    &:hover {
                        background: $eurus-brand-blue;
                    }
                }
            }
        }

    .linkContainer{
        @include rem-calc(30 15 30 15, padding);
            .countryListLink{
                padding: 0;
                a {
                    color: $white;
                    text-decoration: none;
                    text-decoration-line: underline;
                    text-decoration-style: solid;
                    text-decoration-skip-ink: none;
                    text-decoration-thickness: auto;
                    text-decoration-color: $white;
                    font-size: 16px;
                }
            }
    }
}