// Promo Panels Styles

.promoPanels {
    @include fontsize(10);

    overflow: hidden;
}

.promoPanelsContainer {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $bp-tablet__portrait) {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }
}

.promo {
    @include rem-calc(20, margin-bottom);

    position: relative;

    @media screen and (min-width: $bp-tablet__portrait) {
        @include rem-calc(22, margin-right);

        flex: 1 0 31%;
        max-width: 32%;
    }

    &:last-child,
    &:nth-child(3n) {
        @media screen and (min-width: $bp-tablet__portrait) {
            margin-right: 0;
        }
    }

    img {
        display: block;
        height: auto;
        width: $width-full;
        object-fit: cover;
    }

    h4 {
        @include fontsize(18);
        @include rem-calc(8 0, margin);

        color: $promo-title__color;
        padding: 0;

        @media screen and (min-width: $bp-tablet__landscape) {
            @include fontsize(20);
        }
    }

    p {
        @include fontsize(15);

        line-height: 1.2;
        padding: 0;

        @media screen and (min-width: $bp-tablet__landscape) {
            @include fontsize(16);
        }
    }
}

.promoLink {
    color: $promo__color;
    display: block;
    text-decoration: none;

    /* New link styles */
    h4 {
        @include chevron();

        position: relative;

        &:after {
            @include rem-calc(-2, margin-top);
        }

        &:hover {
            text-decoration: underline;
        }
    }

    /* end new link styles */
    &:hover,
    &:focus {
        text-decoration: none;
    }

    &:hover,
    &:focus,
    &:visited {
        color: $promo__color;
    }
}

.eurus {
    .promo {

        a {
            &:hover {
                text-decoration: none;
            }
        }

        h4 {
            color: $eurus-mid-blue;
            @include fontsize(18);
            @media screen and (min-width: $bp-tablet__portrait) {
                @include fontsize(20);
            }
            @include chevron();
            position: relative;

            &:hover, &:focus{
                text-decoration: none;
            }

            &:after{
                font-weight:600;
                // @include rem-calc(19.9, line-height); 
                @include rem-calc(21, font-size);
                @media screen and (min-width: $bp-tablet__portrait) {
                    // @include rem-calc(23.2, line-height);
                }
            }
            


        }  
        p {  
            // color: $slate-gamma;
            @include fontsize(14);
            @media screen and (min-width: $bp-tablet__portrait) {
                @include fontsize(16);
            }
        }
        .labelContainer {
            .label {
                background-color: $eurus-teal;
                .labelText {
                    color: $eurus-brand-blue;
                }
            }
        }
    }
    .keyMessages,
    .newsArticles {
        a {
            &:hover {
                text-decoration: none;
            }
        }
        h4 {
            color: $eurus-mid-blue;
            @media screen and (min-width: $bp-tablet__portrait) {
                max-width: 90%;
            }
            //margin: 2rem 0;
            &:focus,
            &:hover {
                //color: $cta-hover__color;
                text-decoration: none;
            }
        
            &:hover {
                //background-color: $cta-hover__bgcolor;
                &:after {                            /* new styles for animated chevron */
                    @include rem-calc(-16, right);
                }
            }
        
            &:after {                                /* new styles for icons */
                @extend %fa-icon;
                @extend .fas;
                @include rem-calc(-10, right);
                @include animation(right, 0.3s);
                /* for right animation */
                @include fontsize(16);
        
                color: inherit;
                content: fa-content($fa-var-chevron-right);
                font-family: 'Font Awesome 5 Free';
                font-weight: normal;
                position: relative;
                top: -2px;
        
            }
            
        }
        &.bgPresent {
            margin: 0;
            padding: 1.25rem 0;
            @media screen and (min-width: $bp-desktop__minimum) {
                padding: 2.5rem 0;
            }
        }
        &.brandDefault {
            background-color: $eurus-brand-blue;
            color: $eurus-white;
            h2,
            h4,
            p {
                color: $eurus-white;
            }
        }
        &.inverse {
            background-color: $eurus-white;
            color: $eurus-brand-blue;
            h4 {
                color: $eurus-brand-blue;
            }
        }
        &.baseTintFive {
            background-color: $eurus-base-tint-quinternary;
            h4 {
                color: $eurus-mid-blue;
            }
        }
        &:not(.bgPresent) {
            .sectionTitle {
                padding-top: 1.5rem;
            }
        }
        &.textOnly {
            .promo {
                border-top: $eurus-logo-red 2px solid;

                h4 {
                    margin: 13px 0;
                    &::after {
                        content: "\f061";
                        padding-left: 0;
                        margin-left: 0;
                        @include rem-calc(-5, right);
                        font-size: 1.2rem;
                    }
                    &:hover {
                        //background-color: $cta-hover__bgcolor;
                        &::after {                            /* new styles for animated chevron */
                            @include rem-calc(-10, right);
                        }
                    }
                }
            }
        }
    }
}