.instagramAPI {
    #jsonExample {
        display: flex;
        flex-direction: column;
        gap: 2px;
        padding-left: 20px;
        p {
            padding: 0;
            span {
                font-weight: 600;
                color: $eurus-brand-blue;
                padding-right: 3px;
            }
        }
    }

    #otherNotes {
        margin-bottom: 3rem;
    }

    #filterFunctionality {
        margin-bottom: 3rem;

        #inputField {
            padding: 10px;
        }

        #hashtagSearch {
            background-color: $eurus-brand-blue;
            color: $eurus-white;
            padding: 10px;
            border-radius: 10px;
        }

        #clearSearch {
            border: 2px solid $eurus-brand-blue;
            color: $eurus-brand-blue;
            padding: 10px;
            border-radius: 10px;
            margin-left: 5px;
        }
    }

    #instagramHeader {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;

        h2 {
            align-self: center;
            padding-bottom: 0;
            font-size: 2rem;
            font-weight: 500;
            color: $eurus-brand-blue;
        }

        .follow-us {
            display: none;
            @media screen and (min-width: $bp-mobile__landscape) {
                display: flex;
            }
            align-items: center;
            justify-content: center;
            padding: 10px 20px;
            border-radius: 25px;
            color: $eurus-white;
            background-color: $eurus-brand-blue;

            &:visited,
            &:hover {
                color: $eurus-white;
                text-decoration: none;
            }

            svg {
                height: 25px;
                width: 30px;
            }
        }
    }

    #instagramFeed {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        @media screen and (min-width: $bp-tablet__portrait) {
            grid-template-columns: repeat(4, minmax(0, 1fr));
            gap: 20px;
        }
        margin-bottom: 3rem;

        article{
            &.post-card {
                position: relative;
                overflow: hidden;

                .post-overlay {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    color: white;
                    background-color: rgba(0, 0, 0, 0.6);
                    opacity: 0;
                    transition: opacity 0.5s;
                    justify-content: center;
                    align-items: center;
                }

                img {
                    aspect-ratio: 1 / 1;
                    object-fit: cover;
                } 
            }
            &:hover {
                .post-overlay {
                    display: flex;
                    opacity: 1;
                    transition: opacity 0.5s
                }
            }
            &:nth-of-type(7),
            &:nth-of-type(8) {
                display: none;
                @media screen and (min-width: $bp-tablet__portrait) {
                    display: block;
                }
            }
        }

    }
}

