// General Video Styling

.videoBlock {
    @include fontsize(10);

    .row {
        position: relative;
    }

    iframe {
        width: $width-full;
    }

    // component overrides for fullwidth
    &.fullwidth {
        .row {
            max-width: none;
            padding: 0;
            width: $width-full;
        }

        .videoOverlay {
            left: 0;
            max-height: none;
            width: $width-full;
        }
    }

    .videoOverlay {
        @include rem-calc(15, left);

        width: calc(#{$width-full} - 3em);
    }
}

.videoOverlay {
    background: $video-overlay__bgcolor;
    display: none;
    height: $height-full;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;

    @media screen and (min-width: $bp-tablet__portrait) {
        display: block;
    }

    &:after {
        @include rem-calc(110, width);
        @include rem-calc(110, height);

        background: $video-overlay-playbutton__bgcolor url($video__play) left 60% center no-repeat;
        background-size: auto 60%;
        border-radius: $half;
        content: ' ';
        display: block;
        left: $half;
        opacity: 0.8;
        position: absolute;
        top: $half;
        transform: translate(-$half, -$half);
        transition: opacity 0.3s ease-in-out;
        z-index: 2;

        @media screen and (min-width: $bp-tablet__landscape) {
            @include rem-calc(155, height);
            @include rem-calc(155, width);
        }
    }

    &:hover,
    &:focus {
        &:after {
            opacity: 1;
        }
    }

    &.videoPlayed {
        display: none;
    }
}

.videoOverlayImage {
    display: block;
    height: auto;
    width: $width-full;
}

.noJSWarning {
    display: none;
    text-align: center;

    .no-js & {
        display: block;
    }
}

.no-js {
    .videoBlock {
        display: none;
    }
}

.eurus {
    .videoOverlay {
        &:after {

            @include rem-calc(110, width);
            @include rem-calc(110, height);
            opacity: 1;
            background: $eurus-video-play url($video__play) left 60% center no-repeat;

            @media screen and (min-width: $bp-tablet__landscape) {
                @include rem-calc(139, height);
                @include rem-calc(139, width);
            }
        }
    }    
}