.ctaFiltersWrapper {
    display: grid; 
    grid-template-columns: repeat(2, minmax(0, 1fr)); 
    gap: 10px;
    padding: 2rem 0rem;
    border-top: solid 1px #CCDBE5;

    @media screen and (min-width: $bp-tablet__portrait) {
        grid-template-columns: repeat(3, minmax(0, 1fr)); 
    }
    @media screen and (min-width: $bp-desktop__minimum) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .ctaFilter {
        text-align: center;
        margin: 0;
        font-weight: 400;

        &.noIcon.link {
            @include fontsize(16);
            @include rem-calc(40, height);
            @include rem-calc(0 20, padding);
            border-width: 1px;
            @media screen and (min-width: $bp-desktop__minimum) {
                @include rem-calc(50, height);
            }

/*             &:focus {
                background-color: unset;
            } */
            
            &.active {
                background-color: #4a4a4a;
                color: white;
            }
        }

    }
}