// Header and Navigation Variables
$header__background: #1c1c1c;
$header__color: $white;
$header-icon__bordercolor: $white;
$header-video__bgcolor: transparent;
$header-video-button__opacity: 0.3;
$header-intro__fontfamily: $font-heading-intro;
$header-rightpanel__maxwidth: 300px;

$navigation-column__bgcolor: #f0f0f0;
$navigation-topbar__height: 75;
$navigation-topbar__bgcolor: #101616;
$navigation-primary__height: 35;
$navigation-primary__bgcolor: rgba($black, 0.75);
$navigation-primary-mobile__bgcolor: #1e1e1e;

$navigation-secondary__color: $white;
$navigation-secondary__bgcolor: $navigation-secondary__bg-color; // change for scss-based theming
$navigation-secondary__fontfamily: $font-navigation;
$navigation-secondary-home__width: 18;
$navigation-secondary-home__height: 14;
$navigation-secondary-home__color: $white;

$navigation-toplevel__color: $white;
$navigation-toplevel-hover__color: $bodytext;
$navigation-toplevel-hover__bgcolor: $white;
$navigation-toplevel__fontfamily: $font-navigation-bold;
$navigation-toplevel-mobile__fontfamily: $font-navigation;
$navigation-toplevel-mobile__boxshadow: -10px 0 5px 5px rgba(12,12,12,0.3);
$navigation-toplevel-mobile__bordertop: 1px solid #1f1f1f;
$navigation-toplevel-mobile__borderbottom: 1px solid #131313;
$navigation-toplevel-highlight__borderleft: 1px solid #555;

$navigation-menu-mobile__bgcolor: #1e1e1e;
$navigation-menu__bgcolor: rgba(255, 255, 255, 0.96);
$navigation-menu-item__color: $bodytext;
$navigation-menu-item__fontfamily: $font-navigation;
$navigation-menu-item-hover__color: $navigation-secondary__bg-color; // change for scss-based theming
$navigation-menu-item-hover__bgcolor: #f0f0f0;
$navigation-menu-item-mobile__bordertop: 1px solid #dfdfdf;
$navigation-menu-item-mobile__borderleft: 1px solid #dfdfdf;
$navigation-menu-item-haschild__bgcolor: $navigation-secondary__bg-color; // change for scss-based theming
$navigation-menu-headline__color: $headings;

$navigation-dropdown-mobile__bgcolor: $white;
$navigation-dropdown-back-bar__bgcolor: #4a4a4a;
$navigation-dropdown-back__bgcolor: $slate-alpha; // change for scss-based theming
$navigation-dropdown__boxshadow: 0 10px 20px 0 rgba(0,0,0,0.3);

$navigation-search-icon-desktop__size: 36;
$navigation-search-icon-mobile__size: 48;
$navigation-search-inline__bgcolor: #e9ecef;
$navigation-search-input__border: 1px solid $white;
$navigation-search-input-mobile__border: 1px solid $slate-gamma;

$navigation-sectionaccent__color: $white; // change for scss-based theming
$navigation-sectionaccent__bgcolor: $eurus-mid-blue; // change for scss-based theming
$navigation-sectionaccent__fontfamily: $font-navigation;

$navigation-unit-title__fontfamily: $font-navigation;

$overlay__bgcolor: $overlay;

$navigation-search-box__fontfamily: $font-search;
$navigation-search-box__color: $black;
$search-panel__bgcolor: $navigation-searchpanel__bg;
$search-input__width: 75;
$search-input__borderradius: 50px;
$search-input-focus__width: 225;
$search-submit__width: 29;
$search-submit__height: 29;

$search-box__fontfamily: $font-navigation-search;
$search-input__color: $white;
$search-label__color: $white;

$hero-ctapanel-labels__fontfamily: $font-hero-ctapanel;
$hero-ctapanel-labels-bold__fontfamily: $font-hero-ctapanel-bold;
$hero-ctapanel-button__bgcolor: $generic;
$hero-ctapanel-button-hover__bgcolor: $accenthover;
$hero-ctapanel-button__fontfamily: $font-hero-ctapanel-button;

$hero-ctapanel-button-colourA__bgcolor: $brand-primary;
$hero-ctapanel-button-colourB__bgcolor: $brand-secondary;
$hero-ctapanel-button-colourC__bgcolor: $brand-tertiary;