#studentStoriesDisplay {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    @include rem-calc(20, gap);
    @media screen and (min-width: $bp-tablet__small) {
        grid-template-columns: repeat(2, minmax(0, 1fr)); 
    }
    @media screen and (min-width: $bp-desktop) {
        grid-template-columns: repeat(3, minmax(0, 1fr)); 
    }

    &.cards {

        .studentStoryItem {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            flex-shrink: 0;
            border-radius: 5px;
            border: 2px solid $eurus-base-tint-primary;
            background: $eurus-white;
            padding: 0;
            @include rem-calc(490, height);
            box-shadow: none;

            &:not(.shown){
                display: none;
            }

            .imageWrapper {

                position: relative;
                width: 100%;
                border-bottom: 1px solid #f1f1f1;

                .profileImage {

                    object-fit: cover;
                    border-radius: 5px 0;

                    @include rem-calc(188, height);

                    @media screen and (min-width: $bp-mobile__landscape) {
                        @include rem-calc(188, max-height);
                    }

                    @media screen and (min-width: $bp-desktop__minimum) {
                        @include rem-calc(188, max-height);                   
                    }
                }

                .flagImage {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    object-fit: fill;
                    max-width: 70px;
                    max-height: 40px;
                    height: 100%;
                }
            }

            .textWrapper {

                @include rem-calc(10 20 20 20, padding);
                
                @media screen and (min-width: $bp-desktop__minimum) {
                    @include rem-calc(240, height);
                    height: fit-content;
                }

                h3 {
                    color: $eurus-brand-blue;
                    font-family: $font-derailed__extrabold;
                    @include fontsize(15);
                    padding: 0;

                    @media screen and (min-width: $bp-desktop__minimum) {
                        @include fontsize(18);
                    }

                }
                .courseTitle {
                    color: #4A4A4A;
                    font-family: $font-sans-serif;
                    @include fontsize(14);
                    @include rem-calc(19, line-height);

                    @media screen and (min-width: $bp-desktop__minimum) {
                        @include fontsize(16);
                        @include rem-calc(23, line-height);
                    }


                }

                .studentQuote {
                    position: relative;
                    color: $eurus-mid-blue;
                    font-family: $font-derailed__extrabold;
                    @include fontsize(15);
                    @include rem-calc(24, line-height);
                    @include rem-calc(20, padding-top);
                    @include rem-calc(20, padding-right);
                    @include rem-calc(20, padding-bottom);

                    @media screen and (min-width: $bp-desktop__minimum) {
                        @include fontsize(18);
                    }

                    &::after {
                        font-family: Font Awesome\ 5 Free;
                        content: '\f061';
                        position: relative;
                        right: 0px;
                        transition: right 0.3s ease;
                        display: inline-block;
                    }

                }
            }

            &:hover {

                text-decoration: none;
                border-color: $eurus-mid-blue;
                box-shadow: none;

                .studentQuote {

                    text-decoration: underline;

                    &::after {
                        right: -3px;
                    }

                }
            }

        }
    }

}

#studentStorySwiper {

    // display: flex;
    flex-direction: column;

    &.bgPresent {
        &.brandDefault {
            background-color: $eurus-brand-blue;

            h2 {
                color: $eurus-white;
            }

            .studentStoryItem {
                box-shadow: none;
            }

            .whiteCta {
                background-color: $eurus-white;
                border: none;
                color: $eurus-brand-blue;
                &:hover {
                    background-color: $eurus-dark-blue;
                    color: $eurus-white;
                }
            }
            .slider-next {
                &:hover {
                    background-color: $eurus-dark-blue;
                    color: $eurus-white;
                }
            }
            .slider-prev {
                &:hover {
                    background-color: $eurus-dark-blue;
                    color: $eurus-white;
                }
            }
            .slick-dots {
                li {
                    &.slick-active {
                        button {
                            &::before {
                                color: $eurus-white;
                            }
                        }
                    }
    
                    button {
                        &::before {
                            color: $eurus-white;
                            font-size: 15px;
                        }
                    }
                }
            }
        }
        &.lightBlue {
            background-color: $eurus-base-tint-quinternary;

            h2 {
                color: $eurus-brand-blue;
            }

            .whiteCta {
                background-color: $eurus-white;
                border: $eurus-brand-blue 1px solid;
                color: $eurus-brand-blue;
                &:hover {
                    background-color: $eurus-dark-blue;
                    color: $eurus-white;
                }
            }
            .slider-next {
                &:hover {
                    background-color: $eurus-dark-blue;
                    color: $eurus-white;
                }
            }
            .slider-prev {
                &:hover {
                    background-color: $eurus-dark-blue;
                    color: $eurus-white;
                }
            }
            .slick-dots {
                li {
                    &.slick-active {
                        button {
                            &::before {
                                color: $eurus-white;
                            }
                        }
                    }
    
                    button {
                        &::before {
                            color: $eurus-white;
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
    &:not(.bgPresent) {
            background-color: $eurus-white;
            h2 {
                color: $eurus-brand-blue;
            }
            .studentStoryItem {
                box-shadow: none;
            }
            .whiteCta {

                background-color: $eurus-brand-blue;
                border: none;
                color: $eurus-white;

                &:hover {
                    background-color: $eurus-mid-blue;
                    color: $eurus-white;
                }
            }
            .slider-next {
                &:hover {
                    background-color: $eurus-dark-blue;
                    color: $eurus-white;
                }
            }
            .slider-prev {

                &:hover {
                    background-color: $eurus-dark-blue;
                    color: $eurus-white;
                }
            }
            .slick-dots {
                li {
                    &.slick-active {
                        button {
                            &::before {
                                color: $eurus-mid-blue;
                            }
                        }
                    }
    
                    button {
                        &::before {
                            color: $eurus-mid-blue;
                            font-size: 15px;
                        }
                    }
                }
            }
    }


    @media screen and (min-width: $bp-tablet__portrait) {
        //padding: 50px 0px 70px 0px; 
    }    
    @media screen and (min-width: $bp-desktop__minimum) {
        flex-direction: row;
    }

    .slick-track {
        padding: 10px 0;
    }
 
    .studentStorySwiperWrapper {
    
        flex-grow: 1; 
        @include rem-calc(50, padding-top);
        @include rem-calc(25, padding-bottom);

        .swiperWrapper {

           
            @media screen and (min-width: $bp-tablet__portrait) {
                padding: 0 3.25rem;
            }  

            @media screen and (min-width: $bp-desktop__minimum) {
                padding: 0 5rem;
            }

            .studentStoryItem {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex-shrink: 0;
                border-radius: 5px;
                border: 2px solid $eurus-base-tint-primary;
                background: $eurus-white;
                @include rem-calc(490, height);

                &:not(.slick-current) {
                    opacity: 0.4;
                    pointer-events: none;
                    @media screen and (min-width: $bp-content) {
                        opacity: 1;
                        pointer-events: auto;
                    }
                }

                .imageWrapper {

                    position: relative;
                    width: 100%;
                    border-bottom: 1px solid #f1f1f1;

                    .profileImage {

                        object-fit: cover;

                        @include rem-calc(188, height);

                        @media screen and (min-width: $bp-desktop__minimum) {
                            @include rem-calc(188, max-height);                   
                        }
                    }

                    .flagImage {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        object-fit: fill;
                        max-width: 70px;
                        max-height: 40px;
                        height: 100%;
                    }
                }

                .textWrapper {

                    @include rem-calc(10 20 20 20, padding);
                    
                    @media screen and (min-width: $bp-desktop__minimum) {
                        @include rem-calc(285, height);
                    }

                    h3 {
                        color: $eurus-brand-blue;
                        font-family: $font-derailed__extrabold;
                        @include fontsize(14);
                        padding: 0;
                        transition: text-decoration 0.3s ease;

                        @media screen and (min-width: $bp-desktop__minimum) {
                            @include fontsize(18);
                        }

                    }
                    .courseTitle {
                        color: #4A4A4A;
                        font-family: $font-sans-serif;
                        @include fontsize(13);
                        @include rem-calc(19, line-height);

                        @media screen and (min-width: $bp-desktop__minimum) {
                            @include fontsize(14);
                            @include rem-calc(23, line-height);
                        }


                    }

                    .studentQuote {
                        position: relative;
                        color: $eurus-mid-blue;
                        font-family: $font-derailed__extrabold;
                        @include fontsize(15);
                        @include rem-calc(24, line-height);
                        @include rem-calc(20, padding-top);
                        @include rem-calc(20, padding-right);
                        @include rem-calc(20, padding-bottom);

                        @media screen and (min-width: $bp-desktop__minimum) {
                            @include fontsize(18);
                        }

                        &::after {
                            font-family: Font Awesome\ 5 Free;
                            content: '\f061';
                            position: relative;
                            right: 0px;
                            transition: right 0.3s ease;
                            display: inline-block;
                        }

                    }
                }

                &:hover {

                    text-decoration: none;
                    box-shadow: none;
                    border-color: $eurus-mid-blue;

                    .studentQuote {

                        text-decoration: underline;

                        &::after {
                            right: -3px;
                        }

                    }
                }

            }

        }

    
        .whiteCta {


            width: 100%;
            @include rem-calc(30, margin-top);

            @media screen and (min-width: $bp-tablet__portrait) {
                width: 50%;
                @include rem-calc(40, margin-top);
            }
            @media screen and (min-width: $bp-desktop__minimum) {
                width: 33.33%;
            }

            &::after {
                font-family: Font Awesome\ 5 Free;
                content: '\f061';
                position: absolute;
                right: 20px;
                transition: right 0.3s ease;
            }

            &:hover {

                background-color: $eurus-dark-blue;
                color: $eurus-white;

                &::after {
                    right: 15px;
                }
            }

        }


        .slider-next {
            position: absolute;
            right: -5px;
            top: 50%;
            height: 35px;
            z-index: 10;
            transform: translateY(-50%);
            cursor: pointer;
            background: #8ED8F8;
            color: $eurus-dark-blue;
            width: 35px;
            padding: 5px;
            border-radius: 5px;
            @media screen and (min-width: $bp-tablet__portrait) {
                right: -60px;
                width: 40px;
                height: 40px;
            }
            @media screen and (min-width: $bp-desktop__minimum) {
                right: -80px;
            }

/*             &:focus {
                outline: 4px dashed #007494;
            } */
        }
        .slider-prev {
            position: absolute;
            left: -5px;
            top: 50%;
            height: 35px;
            z-index: 10;
            transform: translateY(-50%) rotate(180deg);
            background: #8ED8F8;
            color: $eurus-dark-blue;
            width: 35px;
            padding: 5px;
            border-radius: 5px;
            cursor: pointer;
            @media screen and (min-width: $bp-tablet__portrait) {
                left: -60px;
                width: 40px;
                height: 40px;
            }
            @media screen and (min-width: $bp-desktop__minimum) {
                left: -80px;
            }
/*             &:focus {
                outline: 4px dashed #007494;
            } */
        }

        .slick-dots {
            position: absolute;
            bottom: -40px;
            @media screen and (min-width: $bp-desktop__minimum) {
                display: none !important;
            }
        }
    }
}