// Events Feed

$event__accent: $global-brand-blue;
$event__accent-light: rgba($event__accent, 0.15);

$event__height: 360;
$event-small__width: 313;
$event-large__width: 380;
$event-extra-large__width: 425;
$event-icon__size: $standard-unit * 3.5;

$event__background: $event__accent;
$event__color: $white;
$event__border-color: rgba($event__accent-light, 0.25);
$event-icon__border-color: $event__color;

$event-alt__background: $event__accent-light;
$event-alt__color: $slate-alpha; /* $generic */
$event-alt__border-color: rgba($event__accent, 0.25);
$event-alt-icon__border-color: $event-alt__color;
