.genericForm{
    margin:1rem 0;
    fieldset{

        &.genericSearchBox{
            @media screen and (min-width: $bp-desktop__minimum) {
               
            display: flex;
            justify-content: space-between;
            align-items: center;
                
            }
        }

        label{
            color:$black;
            display:block;

            @media screen and (min-width: $bp-tablet__portrait) {
                font-size:1.2rem;
            }
        }

        input[type=text]{
            width:100%;
            @media screen and (min-width: $bp-desktop__minimum) {
                width: 24rem;
            }
            padding: .7rem;
            margin: .7rem 0;
            font-size: 1.2rem;
            border: 1px solid #4a4a4a;
            border-radius: 4px;
            display: inline-block;
            &:focus{
                border: 1px solid #037EAF;
                outline: 0;
            }       
        }

        input[type=submit]{
            @media screen and (max-width: $bp-desktop__minimum) {
                width:100%;
            }
            background-color: #088300;
            padding: .7rem;
            border: 1px solid #088300;
            border-radius:4px;
            transition: background-color .2s ease, border-color .3s ease;
            cursor:pointer;
            color:$white;
            border-radius:4px;
            &:hover{
                border-color:#0ea504;
                background-color:#0ea504;
            }
        }

        select{
            width: 100%;
            @media screen and (min-width: $bp-desktop__minimum) {
                width:15rem;
                font-size: 1.2rem;
            }
            padding: .7rem;
            margin: .7rem 0;
            border: 1px solid #4a4a4a;
            border-radius: 4px;
            &:focus{
                border: 1px solid #037EAF;
                outline: 0;
            }  
        }

        /* EXTRA FORM ELEMENTS FOR SPECIFIC PURPOSES */
        /* remove arrow from IE */
        .select::-ms-expand {
            display: none;
        }
        .select{
            /* styling */
            width:100%;
            background-color: $white;
            border: 1px solid #4a4a4a;
            border-radius: 4px;
            display: inline-block;
            font: inherit;
            font-size:1.2rem;
            line-height: 1.7rem;
            padding: 0.5rem 3.5rem 0.5rem 1rem;

            /* reset browser defaults */
            margin: 0;      
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-appearance: none;
            -moz-appearance: none;

            /* arrow */
            background-image:
                linear-gradient(45deg, transparent 50%, #4a4a4a 50%),
                linear-gradient(135deg, #4a4a4a 50%, transparent 50%),
                linear-gradient(to right, #ccc, #ccc);
            background-position:
                calc(100% - 20px) calc(1em + 2px),
                calc(100% - 15.1px) calc(1em + 2px),
                calc(100% - 2.5em) 0.5em;
            background-size:
                5px 5px,
                5px 5px,
                1px 1.5em;
            background-repeat: no-repeat;

            &:focus{
            background-image:
                linear-gradient(45deg, #037EAF 50%, transparent 50%),
                linear-gradient(135deg, transparent 50%, #037EAF 50%),
                linear-gradient(to right, #ccc, #ccc);
            background-position:
                calc(100% - 15.1px) 1em,
                calc(100% - 20px) 1em,
                calc(100% - 2.5em) 0.5em;
            background-size:
                5px 5px,
                5px 5px,
                1px 1.5em;
            background-repeat: no-repeat;
            border: 1px solid #037EAF;
            outline: 0;
            }

            &:-moz-focusring {
                color: transparent;
                text-shadow: 0 0 0 #000;
            }
        }
    }
}