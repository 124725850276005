//Full Width Banner

.fullWidthBanner {
    height: auto;

    @media screen and (min-width: $bp-tablet__portrait) {
        @include rem-calc($banner__tablet__min-height, min-height);
    }

    .bannerContainer {
        display: flex;
        position: relative;
        background-color: $imagepanel-bgcolor;

        @media screen and (min-width: $bp-tablet__portrait) {
            @include rem-calc($banner__tablet__min-height, min-height);
        }

        &:before {
            @include stretch;

            background-color: $banner-overlay;
            opacity: $banner_opacity;
            z-index: $zindex-banner-overlay;
        }

        &.colourA:before {
            background-color: $brand-primary;
        }

        &.colourB:before {
            background-color: $brand-secondary;
        }

        &.colourC:before {
            background-color: $brand-tertiary;
        }

        &.noColour-gradient:before {				/* changed */
            background-color: transparent;
            @include background-gradient($promoBanner_gradient_colour_from, $promoBanner_gradient_colour_from);	
			
			@media screen and (min-width: $bp-desktop__minimum) {
                @include background-gradient($banner_gradient_colour_from, $banner_gradient_colour_to);   	
				}		
        }
    }

    .row {
        display: flex;
        overflow: visible;
    }

    .banner-wrapper {
        display: flex;
        flex-direction: column;
        overflow: visible;
        width: $width-full;

        @media screen and (min-width: $bp-tablet__portrait) {
            flex-direction: row;
        }

        &.reverse {
            flex-direction: column-reverse;
            width: $width-full;

            @media screen and (min-width: $bp-tablet__portrait) {
                flex-direction: row-reverse;
            }
        }
    }

    .text-container {
        @include fontsize(18);
        @include rem-calc(32 0, padding);

        color: $white;
        justify-content: flex-end;
        position: relative;
        width: $width-full;
        z-index: $zindex-banner-text-container;

        @media screen and (min-width: $bp-tablet__portrait) {
            display: flex;
            flex-direction: column;
            width: $half;
        }

        @media screen and (min-width: $bp-tablet__portrait) {
            @include rem-calc($banner__tablet__min-height, min-height);
        }

        a {
            @include chevron();

            color: $white;

            @media screen and (min-width: $bp-tablet__landscape) {
                @include fontsize(45);
            }

            &:hover,
            &:visited {
                color: $white;
                text-decoration: none;
            }
        }
    }

    .image-container {
        position: relative;
        width: $width-full;
        z-index: $zindex-banner-image-container;

        @media screen and (min-width: $bp-tablet__portrait) {
            width: $half;
        }
    }

    .hero-img {
        @include backgroundImage($banner-image__mobile__height);

        background-position: bottom center;
        background-size: auto 100%;
        position: relative;

        @media screen and (min-width: $bp-tablet__portrait) {
            background-size: contain;
            height: $height-full;
            position: absolute;
        }
    }

    .image-overlay {
        @include stretch;

        background-position: center;
        background-size: cover;
        z-index: $zindex-banner-image-overlay;
    }

    &.breakout-container {
        min-height: auto;

        @media screen and (min-width: $bp-tablet__portrait) {
            @include breakout($banner-image__desktop__height, $banner__height);
            @include rem-calc($banner-breakout__min-height, min-height);
        }

        .hero-img {
            @include backgroundImage($banner-image__mobile__height);

            margin-top: 0;

            @media screen and (min-width: $bp-tablet__portrait) {
                @include backgroundImage($banner-image__desktop__height);

                bottom: 0;
                position: absolute;
            }
        }
    }
}
