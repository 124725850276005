.graphCardRow {
  ol, p, pre, ul {
    padding: 0; 
  }
  display: grid;
  gap: 20px;
  @media screen and (min-width: $bp-tablet__landscape) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .graphCard {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(90deg, $eurus-base-tint-quinternary 29.69%, $eurus-base-tint-quaternary 100%);
    @include rem-calc(95.206, height);
    @media screen and (min-width: $bp-tablet__landscape) {
        @include rem-calc(160, height);
    }

    // Text section of card
    .graphCardText {
      padding: 0;
      @include rem-calc(10 0 10 10, margin);
      @media screen and (min-width: $bp-tablet__landscape) {
        @include rem-calc(20 0 20 20, margin);
      }
      
      .itemName {
            color: $eurus-body-text;
            font-family: $font-derailed__bold;
            padding: 0;
            line-height: normal;

            @include fontsize(14);
            @media screen and (min-width: $bp-tablet__small){
                @include fontsize(18);
            }
            @media screen and (min-width: $bp-tablet__portrait) {
                @include fontsize(22);
            }
            @media screen and (min-width: $bp-tablet__landscape) {
                @include fontsize(16);
            }
            @media screen and (min-width: $bp-content) {
                @include fontsize(18);
            }
      }

      .cities {
        display: flex;
        @include rem-calc(10, gap);
        @include rem-calc(10, padding-top);
        @media screen and (min-width: $bp-tablet__landscape) {
          display: block;
        }
        
        .city {
            font-family: $font-derailed__bold;
            line-height: normal;
            
            @include fontsize(14);
            @media screen and (min-width: $bp-tablet__small){
                @include fontsize(17);
                }
            @media screen and (min-width: $bp-tablet__portrait) {
                @include fontsize(24);
            }
            @media screen and (min-width: $bp-tablet__landscape) {
                @include fontsize(19);
            }
            @media screen and (min-width: $bp-content) {
                @include fontsize(22);
            }
            &.city-1 {
                color: $eurus-teal-alt;
                // padding: 0;
            }
            &.city-2 {
                color: $eurus-logo-red;
            }
        }
      }
    }

    // Graph section of card
    .svg-item {
      max-width:40%;
      flex-shrink: 0;
      @include rem-calc(7 7 7 10, margin);
      @media screen and (min-width: $bp-tablet__portrait) {
        @include rem-calc(7 40 7 10, margin);
      }
      @media screen and (min-width: $bp-tablet__landscape) {
        @include rem-calc(13 0, margin);
      }

      .donut-ring {
        stroke: $eurus-base-tint-tertiary;
      }
    
      .donut-segment {
        transform-origin: center;
        stroke: $eurus-teal-alt;
      }

      .donut-text {
      
        .donut-percent {
            font-size: 0.7em;
            line-height: 1;
            // transform: translateY(0.5em);
            font-family: $font-derailed__extrabold;
            fill: $eurus-teal-alt;

            @media screen and (min-width: $bp-tablet__portrait){
                font-size: 0.6rem;
            }
            @media screen and (min-width: $bp-tablet__landscape){
                font-size: 0.5rem;
            }

        }
      
      .donut-data {
            font-size: 0.4em;
            line-height: 1;
            // transform: translateY(0.5em);
            text-align: center;
            text-anchor: middle;
            font-family: $font-derailed__extrabold;
            fill: $eurus-teal-alt;

            @media screen and (min-width: $bp-tablet__portrait){
                font-size: 0.35rem;
            }
            @media screen and (min-width: $bp-tablet__landscape){
                font-size: 0.3rem;
            }
        }
          
      }
    }     
    
  } 
}