// Header Search Input Styling - Global

.search {
    color: $search-label__color;
}

.searchBox {
    @include fontsize(10);

    color: $search-label__color;
    position: relative;

    label {
        @include fontsize(20);
        @include rem-calc($standard-unit, margin-bottom);
        display: block;
        font-family: $font-search-label;
    }
}

.searchBoxInput {
    @include animation(background-color);
    @include fontsize(15);

    @include placeholder {
        color: $search-input__color;
    }

    &:hover {
        background-color: rgba($search-input-selected__bgcolor, 0.25);
    }

    &:focus {
        background-color: rgba($search-input-selected__bgcolor, 0.5);
    }

    border: 1px solid $white;
    color: $search-input__color;
    font-family: $search-box__fontfamily;
    width: $width-full;
    background-color: transparent;
    border-radius: 0;

    &.altColourScheme{
        background-color: $white;
        color: #4a4a4a;
        @include placeholder {
            color: #4a4a4a;
        }
    }

    @media screen and (min-width: $bp-tablet__landscape) {
        @include fontsize(20);
    } 
}

.searchSubmit {
    @include animation(all);
    @include image-replace;
    background: url($search__icon) center center no-repeat;
    padding: 0;
    color: transparent;
    border: none;
    border-radius: 0;
    cursor: pointer;
    display: block;
    margin: 0;
    width: $width-full;
    height: $height-full;
}

.searchWrapper {

    display: flex;
    margin-top: 1.25rem;
    @media screen and (min-width: $bp-tablet__portrait) {
        margin-top: 2.5rem;
    }

    .searchForm {

        width: 100%;
        display: flex;

        .searchInput {

            @include rem-calc(10 20, padding);
            @include fontsize(16);
            @include rem-calc(10, margin-right);
            font-family: $font-derailed__regular;
            color: #4A4A4A;
            border: 1px solid $eurus-dark-blue;
            font-weight: 600;
            flex-grow: 1;
            width: auto;

            @media screen and (min-width: $bp-tablet__portrait){
                min-height: 52px;
            }

            @media screen and (min-width: $bp-desktop__minimum){
                @include rem-calc(11 17, padding);
                @include fontsize(25);
                min-height: 64px;
            }

            &:placeholder{
                font-weight: 400;
            }

            &:active {
                border-color: $eurus-mid-blue;
            }
        }
    }

    button {

        &.searchCourse {
            height: 40px;
            width: 40px;
            @media screen and (min-width: $bp-tablet__portrait){
                height: 52px;
                width: 52px;
            }
            @media screen and (min-width: $bp-desktop__minimum){
                height: 64px;
                width: 64px;
            }
            &:after {
                padding: 0;
            }
            &:hover {
                background-color: $eurus-dark-blue;
            }
            &::after {
                &:hover {
                    color: $eurus-white;
                }
            }
        }
        
    }
}

